import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { rgba } from "polished";
import "./MolAudio.scss";
import AtIcon from "../AtIcon/AtIcon";
import { isMobile } from "react-device-detect";
import Draggable from "react-draggable";
import Heartbeat from "../../layouts/widgets/Heartbeat";
import { getKey, exists } from "../../utils/helpers";
import { Colors, Animations } from "../../theme";

const LoadingIcon = styled(AtIcon)`
  position: absolute;
  top: calc(50% - 70px);
  left: calc(50% - 20px);
  svg {
    filter: drop-shadow(0px 1px 2px ${rgba(Colors.black, 0.4)});
  }
  animation: ${Animations.Rotate} 1s linear infinite;
`;

class MolAudio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: -1,
      mute: false,
      playing: false,
      percent: 0,
      idLoaded: []
    };
  }
  playAudio(e) {
    e.preventDefault();
  }
  downloadAudio(e, file) {
    e.preventDefault();
    global.window.open(file);
  }
  showCurrentPlayer(e, id) {
    const targetCaller = e.target;
    let idTrget = null;
    if (targetCaller.tagName === "path") {
      idTrget = targetCaller.parentElement.id;
    } else if (targetCaller.tagName === "svg") {
      idTrget = targetCaller.id;
    }
    if (idTrget === "IconFileDownload") return;

    if (this.state.active !== id) {
      this.hideAllSpinner();
      this.setState({
        active: id,
        mute: false,
        playing: true
      });
      const audioElement = document.querySelector(
        `audio#player-${this.state.active}`
      );
      if (audioElement !== undefined && audioElement !== null) {
        const playerid = audioElement.currentSrc;
        global.window.mhbt[playerid] &&
          global.window.mhbt[playerid].trackPlay();
      }
    } else {
      this.togglePlay();
    }
  }
  close(e) {
    e.preventDefault();
    this.setState({ active: -1 });
  }
  muteAudio(e) {
    e.preventDefault();
    this.setState({
      mute: !this.state.mute
    });
  }
  togglePlay(e, id) {
    if (id !== undefined) {
      this.setState({
        playing: !this.state.playing,
        active: id
      });
    } else {
      this.setState({
        playing: !this.state.playing
      });
    }
    const audioElement = document.querySelector(
      `audio#player-${this.state.active}`
    );
    if (audioElement === undefined || audioElement === null) {
      return;
    }
    const playerid = audioElement.currentSrc;
    if (this.state.playing) {
      global.window.mhbt[playerid] && global.window.mhbt[playerid].trackPlay();
      audioElement.play();
    } else {
      global.window.mhbt[playerid] && global.window.mhbt[playerid].trackPause();
      audioElement.pause();
    }
  }
  hideAllSpinner() {
    document.querySelectorAll(".loadingContainer").forEach(el => {
      el.classList.add("hidden");
    });
  }
  pauseAllAudios() {
    const audiosTags = document.querySelectorAll("audio");
    for (let index = 0; index < audiosTags.length; index++) {
      const element = audiosTags[index];
      try {
        element.currentTime = 0;
        element.pause();
      } catch (err_pausing) {}
    }
  }
  previewAudio(e, id) {
    e.preventDefault();
    const { audios } = this.props;
    for (let index = 0; index < audios.length; index++) {
      const element = audios[index];
      if (element.id === id) {
        if (index === 0) {
          this.setState({
            active: audios[audios.length - 1].id,
            mute: false
          });
        } else {
          this.setState({
            active: audios[index - 1].id,
            mute: false
          });
        }
        return;
      }
    }
  }
  nextAudio(e, id) {
    e && e.preventDefault();
    id = id === undefined ? this.state.active : id;
    const { audios } = this.props;
    for (let index = 0; index < audios.length; index++) {
      const element = audios[index];
      if (element.id === id) {
        if (index + 1 >= audios.length) {
          this.setState({
            active: audios[0].id,
            mute: false
          });
        } else {
          this.setState({
            active: audios[index + 1].id,
            mute: false
          });
        }
        return;
      }
    }
  }
  getcurrentAudio() {
    const id = this.state.active;
    const { audios } = this.props;
    for (let index = 0; index < audios.length; index++) {
      const element = audios[index];
      if (element.id === id) {
        return element;
      }
    }
    return null;
  }

  timeUpdateHandle(data) {
    const audioElement = document.querySelector(
      `audio#player-${this.state.active}`
    );
    if (audioElement !== undefined && audioElement !== null) {
      global.window.currentTime[audioElement.currentSrc] =
        data.target.currentTime;
    }

    const currentPercent =
      (data.target.currentTime / data.target.duration) * 100 + "%";
    const currentPercentValue = Math.ceil(
      (data.target.currentTime / data.target.duration) * 100
    );

    if (!this.isNewLayout()) {
      if (this.state.percentValue !== currentPercentValue) {
        this.setState({
          percent: currentPercent,
          percentValue: currentPercent
        });
      }
    } else {
      const { active } = this.state;
      const currentValueElement = document.querySelector(
        `.progress-${active} > div.value`
      );
      currentValueElement.style.width = currentPercent;
    }
  }
  endedHandle() {
    const audioElement = document.querySelector(
      `audio#player-${this.state.active}`
    );
    if (audioElement !== undefined && audioElement !== null) {
      const playerid = audioElement.currentSrc;
      global.window.mhbt[playerid] &&
        global.window.mhbt[playerid].trackComplete();
      global.window.mhbt[playerid] &&
        global.window.mhbt[playerid].trackSessionEnd();
    }

    this.nextAudio();
  }
  componentDidMount() {
    global.window.mediaObject = {};
    global.window.mhbt = {};
    global.window.analyticsProvider = {};
    global.window.currentTime = {};
    global.window.mediaDelegate = {};
    setTimeout(() => {
      if (
        global.window.ADB !== undefined &&
        global.window.ADB.va !== undefined
      ) {
        global.window.MediaHeartbeat = global.window.ADB.va.MediaHeartbeat;
        global.window.MediaHeartbeatConfig =
          global.window.ADB.va.MediaHeartbeatConfig;
        global.window.MediaHeartbeatDelegate =
          global.window.ADB.va.MediaHeartbeatDelegate;
      }
    }, 800);
  }
  isIdLoaded(id) {
    for (var i = 0; i < this.state.idLoaded.length; i++) {
      if (this.state.idLoaded[i] === id) return true;
    }
    return false;
  }
  componentDidUpdate(prevProps, prefState) {
    if (this.state.active === -1) {
      this.pauseAllAudios();
      this.hideAllSpinner();
    } else if (this.state.active !== prefState.active) {
      this.pauseAllAudios();

      const audioElement = document.querySelector(
        `audio#player-${this.state.active}`
      );

      if (audioElement !== undefined) {
        audioElement.muted = this.state.mute;
        audioElement.play();
        if (!this.isIdLoaded(this.state.active)) {
          document
            .querySelector(".loadingSpinner" + this.state.active)
            .classList.toggle("hidden");
        }

        audioElement.addEventListener(
          "timeupdate",
          this.timeUpdateHandle.bind(this)
        );
        audioElement.addEventListener("canplaythrough", event => {
          this.state.idLoaded.push(this.state.active);
          this.hideAllSpinner();
        });
        audioElement.addEventListener("ended", this.endedHandle.bind(this));

        this.initAudioSession(audioElement);
      }
    } else if (this.state.mute !== prefState.mute) {
      const audioElement = document.querySelector(
        `audio#player-${this.state.active}`
      );
      audioElement.muted = this.state.mute;
    } else if (this.state.playing !== prefState.playing) {
      const audioElement = document.querySelector(
        `audio#player-${this.state.active}`
      );
      if (!this.state.playing) {
        audioElement.pause();
      } else {
        audioElement.play();
      }
    }
  }
  seek(percent) {
    if (percent === Infinity) return;
    const audioElement = document.querySelector(
      `audio#player-${this.state.active}`
    );
    const duration = audioElement.duration;
    const secondToSeek = (duration / 100) * percent;
    audioElement.pause();
    audioElement.currentTime = secondToSeek;
    audioElement.play();
    this.setState({
      playing: true,
      percent: percent + "%",
      percentValue: Math.ceil(percent)
    });
  }
  handleStop(e, data) {
    const valueToSubstrac = data.x;
    const currentWith = data.node.offsetParent.clientWidth;
    const totalWith = data.node.offsetParent.offsetParent.clientWidth;
    const newWidth = currentWith + valueToSubstrac;
    const newPercent = (newWidth / totalWith) * 100;

    this.seek(newPercent);
  }
  clickProgress(e, id) {
    if (id !== this.state.active) {
      this.pauseAllAudios();
      this.togglePlay(e, id);
      return;
    }
    const _target = e.target.classList.contains("progressBar")
      ? e.target
      : e.target.parentElement;
    const posClicked = e.clientX;
    const currentPos = this.getPosition(_target).x;
    const totalWith = _target.clientWidth;
    const newWidth = posClicked - currentPos;
    const newPercent = (newWidth / totalWith) * 100;
    this.seek(newPercent);
  }
  getOffset(el) {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: _y, left: _x };
  }
  convertToTime(num) {
    let mins_num = parseFloat(num, 10); // don't forget the second param
    let hours = Math.floor(mins_num / 60);
    let minutes = Math.floor(mins_num - (hours * 3600) / 60);
    let seconds = Math.floor(mins_num * 60 - hours * 3600 - minutes * 60);

    // Appends 0 when unit is less than 10
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  }
  showToolTipInfo(e, id) {
    const audioElement = document.querySelector(`audio#player-${id}`);
    const duration = audioElement.duration;
    const currentProgress = document.querySelector(`.progress-${id}`);
    const rectProgress = currentProgress.getBoundingClientRect();

    const d = document.documentElement,
      b = document.body;

    const xaxis = d.scrollLeft + e.clientX + b.scrollLeft - rectProgress.left;
    const totalWidth = document.querySelector(".hoverTime" + id).parentElement
      .clientWidth;
    const rect = this.getOffset(
      document.querySelector(".contentAudioPlaylist")
    );
    const currentPosition = xaxis - rect.left - 22;
    const minutes = isNaN(parseInt(duration / 60))
      ? -1
      : parseInt(duration / 60);
    if (minutes === -1) {
      return;
    }
    const currentMinute = minutes * (currentPosition / totalWidth);
    const currentElementHover = document.querySelector(".hoverTime" + id);
    currentElementHover.style.display = "block";
    currentElementHover.style.left = currentPosition + "px";
    currentElementHover.innerHTML = this.convertToTime(currentMinute);
  }
  hideToolTipInfo(e, id) {
    const currentElementHover = document.querySelector(".hoverTime" + id);
    currentElementHover.style.display = "none";
  }
  getPosition(el) {
    var xPosition = 0;
    var yPosition = 0;

    while (el) {
      if (el.tagName === "BODY") {
        // deal with browser quirks with body/window/document and page scroll
        var xScrollPos = el.scrollLeft || document.documentElement.scrollLeft;
        var yScrollPos = el.scrollTop || document.documentElement.scrollTop;

        xPosition += el.offsetLeft - xScrollPos + el.clientLeft;
        yPosition += el.offsetTop - yScrollPos + el.clientTop;
      } else {
        xPosition += el.offsetLeft - el.scrollLeft + el.clientLeft;
        yPosition += el.offsetTop - el.scrollTop + el.clientTop;
      }

      el = el.offsetParent;
    }
    return {
      x: xPosition,
      y: yPosition
    };
  }

  VideoAnalyticsProviderAudio(player) {
    if (
      player === undefined ||
      player === null ||
      global.window.MediaHeartbeatConfig === undefined
    ) {
      return;
    }
    const { titleProduction } = this.props;
    const currentAudio = this.getcurrentAudio();
    const name =
      (exists(titleProduction) ? titleProduction + " - " : "") +
      getKey(currentAudio, "title", "");
    const urlaudio = player.currentSrc;
    global.window.currentTime[urlaudio] = player.currentTime;

    var mediaConfigAudio = new global.window.MediaHeartbeatConfig();
    mediaConfigAudio.trackingServer = "rtvcplay.hb.omtrdc.net";
    mediaConfigAudio.playerName = "html5-mp3";
    mediaConfigAudio.channel = "RTVCPlay";
    mediaConfigAudio.debugLogging = false;
    mediaConfigAudio.appVersion = "";
    mediaConfigAudio.ssl = true;
    mediaConfigAudio.ovp = "HTML5";
    global.window.mediaDelegate[
      urlaudio
    ] = new global.window.MediaHeartbeatDelegate();
    global.window.mediaDelegate[urlaudio].getCurrentPlaybackTime = function() {
      return global.window.currentTime[urlaudio];
    };
    var duration = player !== undefined && player.duration;
    global.window.mhbt[urlaudio] = new global.window.MediaHeartbeat(
      global.window.mediaDelegate[urlaudio],
      mediaConfigAudio,
      typeof global.window._satellite.getToolsByType === "function"
        ? global.window._satellite.getToolsByType("sc")[0].getS()
        : undefined
    );
    global.window.mediaObject[
      urlaudio
    ] = global.window.MediaHeartbeat.createMediaObject(
      name,
      urlaudio,
      duration,
      global.window.MediaHeartbeat.StreamType.VOD
    );
  }
  initAudioSession = function(player) {
    if (player === undefined || player === null) {
      return;
    }
    const urlaudio = player.currentSrc;
    global.window.analyticsProvider[
      urlaudio
    ] = this.VideoAnalyticsProviderAudio(player);
    var customVideoMetadata = {};
    global.window.mhbt[urlaudio] &&
      global.window.mhbt[urlaudio].trackSessionStart(
        global.window.mediaObject[urlaudio],
        customVideoMetadata
      );
  };
  isPodcast() {
    const { taxonomy } = this.props;
    for (let index = 0; index < taxonomy.length; index++) {
      const element = taxonomy[index];
      if (element.tid === 10) return true;
    }
    return false;
  }
  isNewLayout() {
    return this.props.version === 2;
  }
  render() {
    const { audios, isGeoBlocked } = this.props;
    const isNewLayout = this.isNewLayout();

    return (
      <Fragment>
        <div
          className={`contentGeneralPlayAudioList${
            isMobile || isNewLayout ? "" : " isDesktop"
          }`}
        >
          {audios &&
            audios.map(
              (
                { file, id, title, duration, chapter_number, description },
                index
              ) => (
                <div
                  id={id}
                  key={id}
                  className={`contentMolaudio ${isNewLayout &&
                    "newLayout"} ${isNewLayout && "newLayout"} ${this.state
                    .active === id && "active"}`}
                >
                  <div
                    className={`loadingContainer hidden loadingSpinner${id}`}
                  >
                    <LoadingIcon
                      id={`spinner-${id}`}
                      name="Spinner"
                      color={Colors.white}
                      size={32}
                      hasInnerContent={false}
                    />
                  </div>
                  <ul
                    onClick={e =>
                      !isGeoBlocked && this.showCurrentPlayer(e, id)
                    }
                  >
                    <li
                      className="index"
                      style={{
                        backgroundImage: `url(https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/assets/texture.png)`
                      }}
                    >
                      {chapter_number || index + 1}
                    </li>
                    <li className="title">{title}</li>
                    <li className={`duration ${isNewLayout && "newLayout"}`}>
                      {duration && <span>{duration}</span>}
                    </li>
                    <li>
                      {!isGeoBlocked && (
                        <a
                          href="#playBar"
                          onClick={e => this.togglePlay(e, id)}
                        >
                          <AtIcon
                            name={
                              this.state.playing && this.state.active === id
                                ? "Pause"
                                : "PlayCircle"
                            }
                            size={
                              this.state.active === id && this.state.playing
                                ? 28
                                : 32
                            }
                            className={
                              this.state.active === id && this.state.playing
                                ? "PauseBorder"
                                : ""
                            }
                          />
                        </a>
                      )}
                      {this.isPodcast() && (
                        <a
                          href={encodeURI(file)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="fileDownload"
                        >
                          <AtIcon name="FileDownload" size={25} />
                        </a>
                      )}
                    </li>
                  </ul>
                  <div
                    className={`audioPlayer ${isNewLayout &&
                      "newLayout"} ${(this.state.active === id ||
                      isNewLayout) &&
                      "active"}`}
                    style={
                      isNewLayout
                        ? {}
                        : {
                            backgroundImage:
                              "url(https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/assets/texture.png)"
                          }
                    }
                  >
                    <div className={`hoverTime hoverTime${id}`}>12:56</div>
                    <div
                      className={`progressBar progress-${id}`}
                      onClick={e => !isGeoBlocked && this.clickProgress(e, id)}
                      onMouseMove={e =>
                        !isGeoBlocked && this.showToolTipInfo(e, id, duration)
                      }
                      onMouseOut={e =>
                        !isGeoBlocked && this.hideToolTipInfo(e, id)
                      }
                    >
                      <div
                        className="value"
                        style={
                          isNewLayout
                            ? {}
                            : {
                                width:
                                  this.state.percentValue <= 2
                                    ? "2%"
                                    : this.state.percent
                              }
                        }
                      >
                        {!isGeoBlocked && (
                          <Draggable
                            axis="x"
                            handle=".selector"
                            bounds={`.progress-${id}`}
                            position={{ x: 0, y: 0 }}
                            onStop={(e, data) => this.handleStop(e, data)}
                          >
                            <span className="selector" />
                          </Draggable>
                        )}
                      </div>
                    </div>
                    <div
                      className={`description ${isNewLayout && "newLayout"}`}
                      dangerouslySetInnerHTML={{ __html: description || title }}
                    />
                    <div className={`controls ${isNewLayout && "newLayout"}`}>
                      <div className="audioTag">
                        <audio id={"player-" + id} controls preload={"none"}>
                          <source type="audio/mpeg" src={encodeURI(file)} />
                          Your browser does not support the audio tag.
                        </audio>
                      </div>
                      <ul>
                        <li>
                          <a
                            href="#preview"
                            onClick={e => this.previewAudio(e, id)}
                            className="centered"
                          >
                            <AtIcon name="Backward" size={24} />
                          </a>
                          <a href="#play" onClick={e => this.togglePlay(e, id)}>
                            <AtIcon
                              name={
                                this.state.playing
                                  ? "PauseCircleFilled"
                                  : "PlayCircleFilled"
                              }
                              size={35}
                              color="#f0d13a"
                            />
                          </a>
                          <a
                            href="#next"
                            onClick={e => this.nextAudio(e, id)}
                            className="centered"
                          >
                            <AtIcon name="Forward" size={24} />
                          </a>
                        </li>
                        <li>
                          <a
                            href="#toggleVolumen"
                            onClick={e => this.muteAudio(e)}
                            className={`volume centered`}
                          >
                            <AtIcon
                              name={
                                this.state.mute ? "VolumeMute" : "VolumeDown"
                              }
                              size={18}
                            />
                          </a>
                        </li>
                        {!isNewLayout && (
                          <li className="rounded closeButton">
                            <a href="#close" onClick={e => this.close(e)}>
                              <AtIcon name="Close" size={28} color="#4c86c1" />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              )
            )}
        </div>
        <Heartbeat />
      </Fragment>
    );
  }
}
export default MolAudio;
