import React, { Component } from "react";
import AtLogo from "../AtLogo/AtLogo";
import ErrorBoundary from "../ErrorBoundary";
import { ContentLogoutForm, SubTitle, Title } from "./LogoutForm";
import { getRefererContent } from "../../../store/utils/helpers";

class FormLogout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ref: getRefererContent() || "/"
    };
  }
  render() {
    return (
      <ErrorBoundary>
        <ContentLogoutForm>
          <AtLogo className="logo" expanded />
          <Title>Cerrando sesión</Title>
          <SubTitle>
            <b>
              Estamos cerrando sesión, si en los siguientes segundos no ha sido
              redireccionado, puede cerrar esta página.
            </b>
          </SubTitle>
        </ContentLogoutForm>
      </ErrorBoundary>
    );
  }
}

export default FormLogout;
