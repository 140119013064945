import React, { Component } from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";

import AtImg from "../AtImg/AtImg";

const ImgLink = styled.a`
  display: block;
  background-image: url(${props => props.imageBackground});
  opacity: ${props => props.opacity};
  img {
    width: 100%;
    max-width: 100%;
  }
`;
class AtImgLink extends Component {
  static defaultProps = {
    retina: false,
    responsive: false,
    target: "",
    withLazyLoad: true,
    opacity: 1
  };
  render() {
    const {
      href,
      imageSource,
      imageBackground,
      imageTitle,
      imageAlt,
      className,
      retina,
      responsive,
      target,
      withLazyLoad,
      opacity
    } = this.props;
    return (
      <ImgLink
        imageBackground={imageBackground}
        href={href}
        target={target}
        className={className}
        opacity={opacity}
      >
        <AtImg
          src={imageSource}
          title={imageTitle}
          alt={imageAlt}
          retina={retina}
          responsive={responsive}
          withLazyLoad={withLazyLoad}
        />
      </ImgLink>
    );
  }
}

export default AtImgLink;
