import styled from "styled-components";
import { Colors, Wp } from "../../theme";
import AtDescription from "../AtDescription";
import AtImg from "../AtImg/AtImg";
import AtIcon from "../AtIcon/AtIcon";
import { rgba } from "polished";

export const Wrapper = styled(Wp.Container)`
  padding-top: 5%;
`;

export const InnerWrapper = styled(Wp.Page)`
  width: 100%;
  position: relative;
  min-height: 447px;
  padding: 0;
  @media screen and (min-width: 768px) {
    min-height: 505px;
  }
`;

export const ImagePodcast = styled.img`
  display: inline;
  width: 260px;
  @media screen and (min-width: 768px) {
    width: auto;
    height: 357px;
  }
`;

export const WrapperWidget = styled.div``;
export const SocialButtonsWrapper = styled.div`
  position: relative;
`;
export const MetatagContainer = styled.div`
  width: 100%;
  position: relative;
  bottom: 0;
  text-align: center;
  @media screen and (min-width: 768px) {
    margin-left: 2%;
    margin-rigth: 2%;
  }
`;

export const Description = styled(AtDescription)`
  && {
    margin-bottom: 10px;
  }
`;

export const BackGradient = styled.div`
  position: absolute;
  height: 220px;
  width: 100%;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
  bottom: 0;
  right: 0;
  z-index: 0;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const Img = styled(AtImg)`
  position: absolute;
  width: 50%;
  top: 0;
  left: 0;
  img {
    width: 50%;
  }
`;

export const WrapperPodsctas = styled.div`
  width: 100%;
  margin: 10px auto;
  overflow: hidden;
  overflow-x: auto;

  @media screen and (min-width: 768px) {
    overflow: auto;
    margin: 30px auto;
    justify-content: center;
  }
`;
export const InnerWrapperPodsctas = styled.div`
  width: 200%;
  display: inline-flex;
  gap: 20px;
  margin-left: 20px;
  @media screen and (min-width: 768px) {
    width: 100%;
    gap: 60px;
    margin-left: initial;
  }
`;

export const WrapperProduction = styled.div`
  max-width: 357px;
`;

export const LinkWrapper = styled.a`
  text-decoration: none;
  color: white;
  display: inline-block;
`;

export const ListenNowContainer = styled(Wp.Container)`
  width: 260px;
  display: inline-flex;
  color: #ffffff;
  height: 128px;
  span {
    margin-left: 5px;
    font-size: 1em;
    font-weight: 600;
    margin-top: 5px;
  }
  margin-bottom: 21px;
  margin-top: 30px;
  @media screen and (min-width: 768px) {
    width: 100%;
  }
`;

export const LitlePlayIcon = styled(AtIcon)`
  opacity: 1;
`;

export const Link = styled.a`
  display: inline-block;
  text-decoration: none;
`;

export const PlayIconescuchar = styled(AtIcon)`
  margin-top: 3px
  display: inline-block;
  vertical-align: top;
  color: ${Colors.white};
  margin-right: 11px;
  filter: drop-shadow(0px 1px 2px ${rgba(Colors.black, 0.4)});
  pointer-events: none;
`;

export const EscucharContainer = styled.div`
  display: inline-block;
  width: 180px;
  height: 42px;
  background: #00adff;
  &:hover {
    background: #00adff61;
  }
  text-decoration: none;
  font-family: "Dosis", sans-serif;
  color: ${Colors.white};
  font-size: 18px;
  font-weight: 600;
  line-height: 2em;
  text-transform: uppercase;
  padding-top: 3px;
  margin-rigth: 9px;

  svg {
    margin-top: 0;
    vertical-align: sub;
    margin-right: 5px;
  }

  @media screen and (min-width: 768px) {
    width: 129px;
    font-size: 19px;
  }
`;

export const WrapperAuthor = styled.div`
  display: inline-block;
  margin-left: 10px;
  font-size: 20px;
  padding-left: 10px;
  width: 185px;
  height: 110px;
  text-align: left;
  border-left: 1px solid white;
  letter-spacing: 0px;

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 0;
  @media screen and (min-width: 768px) {
    height: 104px;
  }
`;
