import { signUpUser } from "../../../store/actions/auth";
import { getKey } from "../../utils/helpers";
import { getRefererContent } from "../../../store/utils/helpers";

export const signUp = async (data, callback) => {
  const result = await signUpUser({
    name: {
      value: data.email
    },
    field_full_name: {
      value: data.name
    },
    mail: {
      value: data.email
    },
    field_gender: {
      value: "NI"
    },
    field_cell_phone_number: {
      value: null
    },
    field_birthdate: {
      value: null
    },
    field_preferences: [],
    pass: {
      value: data.pass
    },
    field_image_social: {
      value: getKey(data, "image")
    },
    field_from_social_network: {
      value: true
    },
    field_ref: { value: getRefererContent() || "/" }
  });

  if (!result.error) {
    callback();
  } else {
    console.log("ERROR signup:", result);
  }
};
