import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { getUniqKey } from "../../../utils/helpers";
import { Wp } from "../../../theme";
import HorizontalList from "../../../components/OrHorizontalList";

import "./DetrasDeCamaras.scss";

const PageWrapper = styled(Wp.Page)``;

class DetrasDeCamaras extends Component {
  constructor(props) {
    super(props);

    let currentSection = "video";

    this.state = {
      section: currentSection
    };
  }

  showDiv(e, section) {
    e.preventDefault();
    this.setState({ section: section });
  }

  render() {
    const {
      id,
      titulo_paragraph,
      videos,
      imagenes,
      streaming_provider,
      isNinos,
      isEducation
    } = this.props;

    let customStyles = `.contentDetrasDeCamaras { background: #1c1c1c; padding-bottom: 4em; border-bottom: none;}`;

    if (!videos && this.state.section === "video") {
      this.setState({ section: "fotos" });
    }

    return (
      <Fragment>
        <style>{customStyles}</style>
        <div className={`contentDetrasDeCamaras`}>
          <PageWrapper>
            <div className={`contentDetrasDeCamaras_title`}>
              <ul className={`contentDetrasDeCamaras_title_ul`}>
                <li className={`contentDetrasDeCamaras_title_ul_title`}>
                  <h2 className={`title`}>{titulo_paragraph}</h2>
                </li>
                {imagenes &&
                  videos && (
                    <>
                      <li
                        className={`contentDetrasDeCamaras_title_ul_fotos ${this
                          .state.section === "fotos" && "selected"}`}
                      >
                        <a
                          href={_ => {}}
                          onClick={e => this.showDiv(e, "fotos")}
                        >
                          Fotos
                        </a>
                      </li>
                      <li
                        className={`contentDetrasDeCamaras_title_ul_video ${this
                          .state.section === "video" && "selected"}`}
                      >
                        <a
                          href={_ => {}}
                          onClick={e => this.showDiv(e, "video")}
                        >
                          Video
                        </a>
                      </li>
                    </>
                  )}
              </ul>
            </div>
          </PageWrapper>
          <div className={`widgetContainer`}>
            {videos && (
              <div
                className={`widgetList ${this.state.section === "video" &&
                  "selected"}`}
              >
                <HorizontalList
                  key={getUniqKey(id)}
                  contents={videos}
                  isShow={false}
                  initialSlide={-1}
                  view="cover_originales"
                  showModal={true}
                  isNinos={isNinos}
                  isEducation={isEducation}
                  streaming_provider={streaming_provider}
                  showTitle={false}
                  className={`contentDetrasDeCamaras_videos`}
                  styles={`
                    padding-top: 0px !important
                  `}
                />
              </div>
            )}
            {imagenes && (
              <div
                className={`widgetList ${this.state.section === "fotos" &&
                  "selected"}`}
              >
                <HorizontalList
                  key={getUniqKey(id)}
                  isShow={false}
                  contents={imagenes}
                  initialSlide={-1}
                  view="cover_originales"
                  isNinos={isNinos}
                  isEducation={isEducation}
                  isLightBox={true}
                  showTitle={false}
                  className={`contentDetrasDeCamaras_fotos`}
                  styles={`
                  padding-top: 0px !important
                `}
                />
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default DetrasDeCamaras;
