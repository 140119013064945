/**
 * Represents Horizontal list widget
 * @type {string}
 */
export const HORIZONTAL_LIST = "horizontalList";

/**
 * Represents Vertical list widget
 * @type {string}
 */
export const VERTICAL_LIST = "verticalList";

/**
 * Represents Season List widget
 * @type {string}
 */
export const SEASON_LIST = "seasonList";

/**
 * Represents Banner widget
 * @type {string}
 */
export const BANNER = "banner";

/**
 * Represents Slider widget
 * @type {string}
 */
export const SLIDER = "slider";

/**
 * Represents Intro widget
 * @type {string}
 */
export const INTRO = "intro";

/**
 * Represents outstanding2cover widget
 * @type {string}
 */
export const OUT_STANDING_TWO_COVER = "outstanding2cover";

/**
 * Represents opening widget
 * @type {string}
 */
export const OPENING = "opening";

/**
 * Represents opening kids widget
 * @type {string}
 */
export const OPENING_KIDS = "openingKids";

/**
 * Represents unitary widget
 * @type {string}
 */
export const UNITARY = "unitary";

/**
 * Represents highlight widget
 */
export const HIGHLIGHT = "highlight";

/**
 * Represents TRAILER widget
 * @type {string}
 */
export const TRAILER = "trailer";

/**
 * Represents ELENCO widget
 * @type {string}
 */
export const ELENCO = "elenco";

/**
 * Represents ENTREVISTA widget
 * @type {string}
 */
export const ENTREVISTAS = "entrevistas";

/**
 * Represents PODCASTS widget
 * @type {string}
 */
export const PODCASTS = "podcasts";

/**
 * Represents PODCASTS widget
 * @type {string}
 */
export const DETRAS_DE_CAMARAS = "detras_de_camaras";

/**
 * Represents PODCASTS widget
 * @type {string}
 */
export const BOTONES_ORIGINALES = "botones_originales";

/**
 * Represents GRID chapter video
 */
export const CHAPTER_VIEW_GRID = "grid";

/**
 * Represents SEASON chapter video
 */
export const CHAPTER_VIEW_SEASON = "seasons";

/**
 * Represents Estreno Widget
 */
export const PREMIERE_WIDGET = "premiere";

/**
 * Represents Producción Info Trailer Widget
 */
export const PRODINFOTRA_WIDGET = "prodInfoTrailer";

/**
 * Represents The Podcast Widget
 */
export const PODCAST_WIDGET = "podcastWidget";

/**
 * Represents Cinema Widget
 */
export const CINEMA_WIDGET = "cinema";

/**
 * Represents SliderKids Widget
 */
export const SLIDERKIDS_WIDGET = "sliderKids";

/**
 * Represents Originales Widget
 */
export const ORIGINALES_WIDGET = "originales";

/**
 * Represents Opening Podcast Widget
 */
export const OPENING_PODCAST_WIDGET = "openingPodcast";

/**
 * Represents Promote Widget
 */
export const PROMOTE_WIDGET = "promote";

/**
 * Represents Podcast single category promote
 */
export const PODCAST_SINGLE_CATEGORY_PROMOTE = "podcastSingleCategoryPromote";

/**
 * Represents the Podcast Category Promote Widget
 */
export const PODCAST_CATEGORY_PROMOTE = "podcastCategoryPromote";

/**
 * Represents the Cinema Promotion Widget
 */
export const CINEMA_PROMOTION_WIDGET = "cinema_promotion";

/**
 * Represents the Podcast Single Widget
 */
export const PODCAST_SINGLE_WIDGET = "podcast_single";
