import styled from "styled-components";
import { Wp } from "../../../../theme";

export const WrapperOpening = styled(Wp.Container)``;

export const ContentOpening = styled.div`
  position: relative;
  display: inline-block;
  padding-bottom: 0;
  line-height: 0;
  width: 100%;
  & > img {
    width: 100%;
  }
`;

export const WrapperMetadata = styled.div`
  position: absolute;
  text-align: left;
  width: 100%;
  margin: auto;
  color: #fff;
  bottom: 0px;
  @media screen and (min-width: 769px) {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(14, 14, 14, 0.5) 41%,
      rgba(34, 34, 34, 1) 100%
    );
  }
  .links {
    margin-top: 0px;
    ul {
      margin: 0px;
      padding: 0px;
      list-style: none;
      width: 100%;
      position: relative;
      li {
        display: inline-block;
        vertical-align: top;
        margin: 0 8px;
        a {
          display: inline-block;
          text-decoration: none;
          font-family: "Dosis", sans-serif;
          color: #ffffff;
          font-size: 1em;
          font-weight: 600;
          line-height: 2em;
          text-transform: uppercase;
        }
      }
    }
  }
`;
export const InnerWrapperMetadata = styled(Wp.Page)`
  padding: 0;
  @media screen and (min-width: 768px) {
    display: flex;
  }
`;
export const TitleContainer = styled(Wp.Page)``;

export const Title = styled.h1`
  font-size: 25px;
  font-family: Dosis;
  color: white;
  line-height: 1.2;
  text-align: left;
  font-weight: 400;
  margin: 0px;
  padding: 0px;
  margin-bottom: 5px;

  @media screen and (min-width: 768px) {
    font-size: 35.99px;
    margin-bottom: 20px;
  }
`;
export const TrailerPlayerContainer = styled.div`
  display: block;
  width: 100%;
  @media screen and (min-width: 768px) {
    display: inline-flex;
    width: 70%;
  }
`;

export const MetaData = styled.div`
  display: block;
  width: 100%;
  margin: auto;
  text-shadow: 0px 0px 3px #000;
  & > div {
    margin-left: 20px;
  }
  h4 {
    font-weight: bold;
    margin: 0px;
    padding: 0px;
    font-size: 19.99px;
    color: white;
    line-height: 1.2;
    @media screen and (max-width: 767px) {
      margin-bottom: 0px;
    }
  }
  h1 {
    font-size: 25px;
    font-family: "BebasNeue Regular", sans-serif;
    color: white;
    line-height: 1.2;
    text-align: left;
    font-weight: 400;
    margin: 0;
    padding: 0;

    @media screen and (min-width: 768px) {
      font-size: 35.99px;
      margin-bottom: 5px;
    }
  }

  @media screen and (max-width: 1024px) {
    max-width: 100%;
    background: linear-gradient(to bottom, transparent, #222222 75%);
  }
  @media screen and (min-width: 768px) {
    display: inline-flex;
    width: 30%;
    padding-left: 40px;
    & > div {
      margin-left: 0;
    }
  }
`;

export const LongDescriptionWrapper = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #fffefe;
  line-height: 1.2;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 35px;
  @media screen and (max-width: 1024px) {
    max-width: 97%;
    margin: 5px auto 20px auto;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
  strong,
  em {
    font-size: 18px;
    line-height: 1.2;
  }
`;

export const BrandLogo = styled.img`
  display: block;
  width: initial;
  height: initial;
  max-width: 140px;
  max-height: 16px;
  margin-top: 5px;
  margin-bottom: 0;
  @media screen and (min-width: 768px) {
    max-height: 32px;
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

export const ProductionLogo = styled.img`
  display: block;
  width: initial;
  height: initial;
  max-width: 180px;
  max-height: 30px;

  @media screen and (min-width: 768px) {
    max-height: 60px;
    margin-bottom: 10px;
  }
`;

export const Category = styled.span`
  color: #ffffff;
  font-family: "Dosis", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  display: inline-flex;
  line-height: 1.9375em;
  &::after {
    content: "|";
    color: #429779;
    margin-left: 13px;
    font-size: 28px;
    font-weight: 100;
  }
`;
export const RatingContainer = styled.span`
  color: #ffffff;
  font-family: "Dosis", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  display: inline-flex;
  line-height: 1.9375em;
  margin-left: 13px;
`;

export const SocialButtonsContainer = styled.div`
  position: relative;
`;
