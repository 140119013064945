import React, { Fragment, useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const ArrowTooltip = props => {
  const { arrow, ...classes } = useStylesArrow();
  const [arrowRef, setArrowRef] = useState(null);

  const [open, setOpen] = useState(props.open);

  function handleTooltipClose() {
    setOpen(false);
    props.handleTooltipClose();
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        classes={classes}
        PopperProps={{
          popperOptions: {
            modifiers: {
              arrow: {
                enabled: Boolean(arrowRef),
                element: arrowRef
              }
            }
          }
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        {...props}
        title={
          <Fragment>
            {props.title}
            <span className={arrow} ref={setArrowRef} />
          </Fragment>
        }
      />
    </ClickAwayListener>
  );
};
function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.95em",
      width: "2em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${color} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.95em",
      width: "2em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${color} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.95em",
      height: "2em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${color} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.95em",
      height: "2em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${color}`
      }
    }
  };
}

const useStylesArrow = makeStyles(theme => ({
  tooltip: {
    position: "relative",
    maxWidth: "400px",
    "& > ul": {
      "list-style": "none",
      margin: "0px",
      padding: "0px",
      "& > li": {
        textAlign: "left",
        color: "#ccc",
        borderBottom: "1px solid #000",
        "& > a": {
          padding: "5px",
          display: "block"
        },
        "& > a > svg": {
          marginRight: "10px"
        }
      }
    }
  },
  arrow: {
    position: "absolute",
    fontSize: 6,
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid"
    }
  },
  popper: {
    ...arrowGenerator(theme.palette.grey[700]),
    zIndex: 999999,
    "pointer-events": "all"
  }
}));

export default ArrowTooltip;
