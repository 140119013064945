import React, { Component } from "react";
import styled from "styled-components";
import { rgba } from "polished";
import Swiper from "react-id-swiper";

import AtIcon from "../AtIcon/AtIcon";
import "react-id-swiper/src/styles/scss/swiper.scss";

import { Wp, Colors } from "../../theme";

const Container = styled(Wp.Container)`
  position: relative;
  padding-top: ${props => (props.ratio ? `${props.ratio}%` : `0`)};
  width: 100%;
  height: ${props => (props.ratio ? `0px` : `initial`)};
  overflow: ${props => (props.ratio ? `hidden` : `initial`)};
  .swiper-container {
    position: ${props => (props.ratio ? `absolute` : `relative`)};
    top: 0;
    left: 0;
    width: ${props => (props.ratio ? `100%` : `initial`)};
    height: ${props => (props.ratio ? `100%` : `initial`)};
    .swiper-button-next,
    .swiper-button-prev {
      background: none;

      display: flex;
      align-items: center;
      justify-content: center;
      width: ${props => props.iconSize + 5}px;
      height: ${props => props.iconSize + 5}px;
      transition: opacity 100ms ease-in;
      &.swiper-button-disabled {
        opacity: 0;
      }
    }
    .swiper-pagination {
      .swiper-pagination-bullet {
        background: ${Colors.white};
      }
    }
  }
`;
const IconButton = styled.button`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  .icon {
    border: 2px solid ${Colors.white};
    border-radius: 50%;
    filter: drop-shadow(0px 1px 2px ${rgba(Colors.black, 0.4)});
  }
`;
class TplSwiper extends Component {
  swiper = null;
  static defaultProps = {
    responsive: true,
    aspectRatio: "21-9",
    nextPrevButtonSize: 25
  };
  render() {
    const {
      swiperParams,
      children,
      aspectRatio,
      responsive,
      className,
      additions,
      nextPrevButtonSize,
      showPagination
    } = this.props;
    const aspectRatioValues = aspectRatio.split("-");

    const params = {
      pagination: showPagination
        ? {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true
          }
        : {},
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      allowTouchMove: true,
      renderPrevButton: () => (
        <IconButton className="swiper-button-prev">
          <AtIcon
            className="icon"
            name="KeyboardArrowLeft"
            size={nextPrevButtonSize}
          />
        </IconButton>
      ),
      renderNextButton: () => (
        <IconButton className="swiper-button-next">
          <AtIcon
            className="icon"
            name="KeyboardArrowRight"
            size={nextPrevButtonSize}
          />
        </IconButton>
      ),
      ...swiperParams
    };
    return (
      <Container
        iconSize={nextPrevButtonSize}
        className={className}
        ratio={
          responsive
            ? (aspectRatioValues[1] / aspectRatioValues[0]) * 100
            : null
        }
      >
        <Swiper
          ref={node => (this.swiper = node ? node.swiper : null)}
          containerClass="swiper-container"
          {...params}
        >
          {children}
        </Swiper>
        {additions && additions}
      </Container>
    );
  }
}

export default TplSwiper;
