import * as pageType from "../../../constants/pageType";
import Content from "../content";
import NotFound from "../notFound";
import Show from "../show";
import Section from "../section";
import Search from "../search";
import CommingSoon from "../commingSoon";
import GeoRestricted from "../GeoRestricted";

const globalPages = {
  /**
   * Page used as fallback
   * @constructor
   */
  [pageType.AUDIO]: Content,
  [pageType.VIDEO]: Content,
  [pageType.LIVESTREAM]: Content,
  [pageType.LIVESTREAMTV]: Content,
  [pageType.LIVESTREAMRADIO]: Content,
  [pageType.LIVESTREAMEVENT]: Content,
  [pageType.SECTION]: Section,
  [pageType.SHOW]: Show,
  [pageType.SHOWVIDEO]: Show,
  [pageType.BASICPAGE]: Content,
  [pageType.SHOWAUDIO]: Show,
  [pageType.SHOWKIDS]: Show,
  [pageType.SHOWLEARN]: Show,
  [pageType.SEARCH]: Search,
  [pageType.NOTFOUND]: NotFound,
  [pageType.CATEGORY]: Content,
  [pageType.SHOW_COMMING_SOON]: CommingSoon,
  [pageType.GEO_BLOCKED]: GeoRestricted,
};

export default globalPages;
