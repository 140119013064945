import React, { Component } from "react";

import Index from "../../../components/OrMainSlider";

class Slider extends Component {
  render() {
    return <Index {...this.props} />;
  }
}
export default Slider;
