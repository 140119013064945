import React from "react";
import styled from "styled-components";
import { Metrics } from "../../theme";

/**
 * SideBar External Platforms component
 *
 * @param {object} props - component props
 * @returns {JSX.Element}
 */
export const SideBarInternalPlatforms = ({ url_external_podcasts }) => {
  const imagePlatform = {
    radionacional:
      "https://rtvcplay-v2.s3-accelerate.amazonaws.com/s3fs-public/2021-03/Escuchar-RadioNacional.png",
    radionica:
      "https://rtvcplay-v2.s3-accelerate.amazonaws.com/s3fs-public/2021-03/Escuchar-Radionica.png",
  };
  const isInternal = platform => {
    return (
      platform === "brand" ||
      platform === "radionacional" ||
      platform === "radionica"
    );
  };

  const getInternalPlatforms = () => {
    if (url_external_podcasts) {
      const list = [];
      for (const [platform, url] of Object.entries(url_external_podcasts)) {
        if (!isInternal(platform) || platform === "brand" || url === null)
          continue;
        list.push({
          platform,
          url,
          image: imagePlatform[platform],
        });
      }
      return list;
    }
    return [];
  };

  const internalPlatforms = getInternalPlatforms();
  return internalPlatforms.length === 0 ? null : (
    <Wrapper>
      <List>
        {internalPlatforms.map(({ url, image }, indx) => (
          <Item key={indx}>
            <Link
              href={`${url.startsWith("http") ? "" : "https://"}${url}`}
              target="_blank"
            >
              <Image src={image} />
            </Link>
          </Item>
        ))}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media screen and (min-width: ${Metrics.size.tablet}) {
  }
`;

const List = styled.div`
  margin: 20px 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;

const Item = styled.div`
  vertical-align: top;

  @media screen and (min-width: ${Metrics.size.tablet}) {
  }
`;

const Link = styled.a`
  display: block;
  text-decoration: none;
  text-align: center;
`;

const Image = styled.img`
  width: 100%;
  border: 0;
  max-width: 259px;

  @media screen and (min-width: ${Metrics.size.tablet}) {
  }
`;
