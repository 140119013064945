import React, { Component, Fragment } from "react";
import styled from "styled-components";

import AtTextLink from "../AtTextLink/AtTextLink";

import { Colors, Wp, Metrics } from "../../theme";

const Navbar = styled(Wp.Navbar)`
  margin: 0px 20px;
`;
const NavbarButton = styled(AtTextLink)`
  display: block;
  box-sizing: border-box;
  padding: 0px 20px;
  line-height: ${props =>
    !!props.expanded
      ? Metrics.size.maxHeaderHeight
      : Metrics.size.minHeaderHeight};
  transition: line-height 0.5s ease;
  :hover {
    background-color: ${Colors.cerulean};
    text-decoration: none;

    :not([href]) {
      cursor: default;
    }
  }

  border-right: 1px solid ${Colors.greyishbrown};
`;
class NavbarButtonComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { mouseIn: false };
    this.myRef = React.createRef();
  }
  render() {
    const { data, expanded } = this.props;
    const isInfantilItem = data.label.toLocaleLowerCase() === "infantil";
    const { link } = data;
    return (
      <div ref={this.myRef}>
        <NavbarButton
          text={data.label}
          iconLeft={
            data.label.toLocaleLowerCase() === "en vivo"
              ? "LiveStream"
              : isInfantilItem
                ? "IconNinos"
                : null
          }
          href={isInfantilItem ? "/ninos" : link && link !== "" ? link : null}
          iconSize={isInfantilItem ? { width: 40, height: 18 } : 18}
          iconColor={this.state.mouseIn ? Colors.white : Colors.cerulean}
          expanded={expanded}
          onMouseOver={ev => {
            this.setState({ mouseIn: true });
            data.onMouseOver(ev);
          }}
          onMouseOut={ev => {
            this.setState({ mouseIn: false });
            data.onMouseOut(ev);
          }}
          onTouchStart={ev => {
            this.setState({ mouseIn: true });
            data.onTouchStart(ev);
          }}
        />
      </div>
    );
  }
}
class MolNavbar extends Component {
  static defaultProps = {
    data: []
  };
  render() {
    const { expanded, className, data, children } = this.props;
    return (
      <Fragment>
        <Navbar className={className}>
          {data.map((itm, index) => (
            <NavbarButtonComponent key={index} data={itm} expanded={expanded} />
          ))}
        </Navbar>
        {children}
      </Fragment>
    );
  }
}
export default MolNavbar;
