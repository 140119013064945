import styled from "styled-components";
import { Wp } from "../../theme";
import TplTitleBlock from "../TplTitleBlock/TplTitleBlock";
import Colors from "../../theme/Colors";
import rgba from "polished/lib/color/rgba";

export const Wrapper = styled(Wp.Container)`
  .swiper-pagination-bullets {
    margin: 10px 0 5px;
    .swiper-pagination-bullet {
      background: white;
      width: 9px;
      height: 9px;
    }
    .swiper-pagination-bullet-active {
    }

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .swiper-button-prev {
    &::before {
      content: "";
      top: calc(15px - 18em);
      left: -12px;
      position: absolute;
      width: 40px;
      height: 37em;
      background: linear-gradient(
        to left,
        ${rgba(Colors.bg2, 0)} 0%,
        ${rgba(Colors.bg2, 1)} 100%
      );
    }

    @media screen and (max-width: 767px) {
      left: 0px;
    }
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .swiper-button-next {
    &::before {
      content: "";
      top: calc(15px - 18em);
      right: -12px;
      position: absolute;
      width: 40px;
      height: 37em;
      background: linear-gradient(
        to right,
        ${rgba(Colors.bg2, 0)} 0%,
        ${rgba(Colors.bg2, 1)} 100%
      );
    }

    @media screen and (max-width: 767px) {
      right: 3px;
    }

    @media screen and (min-width: 768px) {
      display: none;
    }
  }
`;

export const InnerWrapper = styled(Wp.Page)`
  overflow: hidden;
`;
export const CinemaList = styled(Wp.Page)`
  padding: 0;
  @media screen and (min-width: 768px) {
    display: flex;
    position: relative;
    .swiper-wrapper {
      margin-right: 50px;
      justify-content: space-between;
      .swiper-slide {
        flex: inherit;
        width: auto;
      }
    }
  }
`;

export const TitleBlock = styled(TplTitleBlock)`
  padding-top: ${props => (props.showTitle ? 0.75 : 0)}em;
  padding-bottom: ${props => (props.showTitle || props.isNinos ? 1 : 0)}em;
  ${props => props.customStyles || ""};
  .inner-container {
    padding: 0;
  }
`;
