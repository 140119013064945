import Cookies from "js-cookie";

const INTRO_COOKIE_DAYS = 1;
const INTRO_COOKIE_HOURS = 3;

export const SET_INTRO_FINISHED = "intro/SET_INTRO_FINISHED";
export const SET_ENCODED_VIDEO = "intro/SET_ENCODED_VIDEO";
export const SET_COOKIE_ACCEPTED = "intro/SET_COOKIE_ACCEPTED";
export const SET_BANNER_APPS_ACCEPTED = "intro/SET_BANNER_APPS_ACCEPTED";
export const SET_BANNERAPP_FINISHED = "intro/SET_BANNERAPP_FINISHED";

const initialState = {
  finished: null,
  cookies: null,
  banner: null,
  video: null
};

// Reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ENCODED_VIDEO:
      return {
        ...state,
        video: action.payload,
        finished: false
      };

    case SET_INTRO_FINISHED:
      return {
        ...state,
        finished: action.payload
      };

    case SET_COOKIE_ACCEPTED:
      return {
        ...state,
        cookies: action.payload
      };
    case SET_BANNER_APPS_ACCEPTED:
      return {
        ...state,
        banner: action.payload
      };

    default:
      return state;
  }
};

export const setCookiesAccepted = (value = true) => dispatch =>
  dispatch({ type: SET_COOKIE_ACCEPTED, payload: value });

export const cookiesAccepted = () => dispatch => {
  dispatch({ type: SET_COOKIE_ACCEPTED, payload: true });

  const date = new Date();
  date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);

  Cookies.set("isCookiesAccepted", "1", { expires: date });
};
export const setBannerAppsAccepted = (value = true) => dispatch =>
  dispatch({ type: SET_BANNER_APPS_ACCEPTED, payload: value });

export const bannerAppsAccepted = () => dispatch => {
  dispatch({ type: SET_BANNER_APPS_ACCEPTED, payload: true });
  const date = new Date();
  date.setTime(date.getTime() + 1 * 12 * 60 * 60 * 1000);

  Cookies.set("isBannerAppsAccepted", "1", { expires: date });
};

export const setEncodedVideo = value => dispatch =>
  dispatch({ type: SET_ENCODED_VIDEO, payload: value });

export const setIntroFinished = (value = true) => dispatch =>
  dispatch({ type: SET_INTRO_FINISHED, payload: value });

export const introFinished = () => dispatch => {
  dispatch({ type: SET_INTRO_FINISHED, payload: true });

  const date = new Date();
  date.setTime(
    date.getTime() + INTRO_COOKIE_DAYS * INTRO_COOKIE_HOURS * 60 * 60 * 1000
  );

  Cookies.set("showIntroVideo", "1", { expires: date });
};

export const setBannerAppFinished = (value = true) => dispatch =>
  dispatch({ type: SET_BANNERAPP_FINISHED, payload: value });

export const bannerAppFinished = () => dispatch => {
  dispatch({ type: SET_BANNERAPP_FINISHED, payload: true });
  const date = new Date();
  date.setTime(
    date.getTime() + INTRO_COOKIE_DAYS * INTRO_COOKIE_HOURS * 60 * 60 * 1000
  );

  Cookies.set("showBannerAppVideo", "1", { expires: date });
};
