import React from "react";
import styled from "styled-components";
import { Fonts, Wp } from "../../../theme";
import { DropDownFilter } from "./DropDownFilter";

export const CategoryTreeFilter = ({ data: { name, children } }) => (
  <Wrapper>
    <InnerContainer>
      <Title>{name}</Title>
      <DropDownFilter data={children} />
    </InnerContainer>
  </Wrapper>
);

const Wrapper = styled.div`
  left: 0px;
  position: absolute;
  width: 100%;
`;

const InnerContainer = styled(Wp.Page)`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  margin-top: 96px;
  z-index: 99;
`;

const Title = styled(Fonts.blockTitle)`
  display: none;
  font-weight: 400;
  position: relative;
  text-transform: uppercase;

  @media screen and (min-width: 768px) {
    display: initial;
  }
`;
