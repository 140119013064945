import React from "react";
import styled from "styled-components";

const MetaInfo = ({ title, micro_synopsis }) => (
  <VideoTextContainer>
    <TextDecoration />
    <VideoTitle>{title}</VideoTitle>
    <VideoDescription dangerouslySetInnerHTML={{ __html: micro_synopsis }} />
  </VideoTextContainer>
);

const VideoTextContainer = styled.div`
  display: block;
  width: 35%;
  color: white;
  margin-left: 20px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
  }
`;

const TextDecoration = styled.div`
  background-color: #40b1d3;
  height: 3px;
  width: 66px;
  margin-bottom: 5px;
  opacity: 1;
`;
const VideoTitle = styled.h3`
  font-family: "BebasNeue Regular", sans-serif;
  text-transform: uppercase;
  font-size: 26px;
  line-height: 34px;
  margin: 0 0 20px;
  font-weight: normal;

  @media screen and (min-width: 768px) {
    font-size: 37px;
  }
`;
const VideoDescription = styled.div`
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export default MetaInfo;
