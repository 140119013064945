import React from "react";
import MolGallery from "../MolGallery/MolGallery";
import MolTrailerPlayer from "../MolTrailerPlayer";
import {
  Acercade,
  Container,
  DirectorContainer,
  OnlyDesktop,
  Sinopsys,
  SubSubtitle,
  Subtitle,
  Superior,
  TextDirector,
  Trailer,
  Wrapper
} from "./MolTrailer";

const MolTrailer = ({
  trailer,
  sinopsis,
  elenco,
  director,
  titulo,
  gallery,
  path,
  subtitulo,
  reproductor
}) => {
  let lineas = director.split("\n");
  return (
    <Container>
      <Wrapper>
        <Superior>
          <Trailer>
            <MolTrailerPlayer
              title={titulo}
              trailer={trailer}
              streaming_provider={reproductor}
            />
          </Trailer>
          <Acercade>
            <Subtitle>{subtitulo}</Subtitle>
            <SubSubtitle>Sinopsis</SubSubtitle>
            <Sinopsys>{sinopsis}</Sinopsys>
            {elenco !== "" ? (
              <OnlyDesktop>
                <SubSubtitle>Elenco</SubSubtitle>
                <Sinopsys>{elenco}</Sinopsys>
              </OnlyDesktop>
            ) : null}
          </Acercade>
        </Superior>
        {director !== "" ? (
          <DirectorContainer>
            <div style={styles.borderBlue}>Director</div>
            <TextDirector>
              <div>{lineas[0]}</div>
              <div>{lineas[1]}</div>
            </TextDirector>
          </DirectorContainer>
        ) : null}
        <div>
          {gallery.length !== 0 ? (
            <MolGallery
              gallery={gallery}
              path={path}
              titulo={titulo}
              descripcion={sinopsis}
            />
          ) : null}
        </div>
      </Wrapper>
    </Container>
  );
};

const styles = {
  borderBlue: {
    borderRight: "solid",
    paddingRight: "20px",
    borderColor: "#019BE3",
    fontSize: "22px",
    fontFamily: "Dosis"
  }
};

export default MolTrailer;
