import React from "react";
import styled, { css } from "styled-components";
import AtIcon from "../AtIcon/AtIcon";
import { Button } from "../OrLogin/Form/LoginForm";

const AtPlayNow = ({
  slug,
  backgroundColor,
  text = "Ver ahora",
  size = 30
}) => (
  <SeeNow
    onClick={_ => (window.location.href = slug)}
    backgroundColor={backgroundColor}
  >
    <AtIcon className="play-icon" name="PlayCircle" size={size} />
    {text}
  </SeeNow>
);

const SeeNow = styled(Button)`
  align-items: center;
  display: inline-flex;
  display: inline-flex;
  font-size: 18px;
  height: auto;
  margin-right: 5px;
  padding: 3px 10px;
  text-decoration: none;
  text-transform: uppercase;

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `} > svg {
    margin-right: 10px;
  }
`;

export default AtPlayNow;
