import styled, { css } from "styled-components";
import { Colors, Wp } from "../../../theme";
import AtTitle from "../../AtTitle";
import AtDescription from "../../AtDescription";
import AtIcon from "../../AtIcon/AtIcon";
import { getKey } from "../../../utils/helpers";

export const Wrapper = styled(Wp.Container)`
  display: inline-block;
  width: 100%;
  margin: 30px 0 0;
  @media screen and (min-width: 768px) {
    width: 50%;
    margin: inherit;
  }
`;

export const InnerWrapper = styled(Wp.Page)`
  position: relative;
  min-height: 465px;
  padding: 0;

  h3 {
    color: #00e9fe;
    font-weight: normal;
    font-size: 30px;

    @media screen and (min-width: 768px) {
      font-size: 42px;
    }
  }

  ${({ back }) =>
    !!back &&
    css`
      background-image: url(${getKey(back, "mobile.path")});
      background-size: cover;
      background-position: center center;

      @media screen and (min-width: 768px) {
        min-height: 610px;
        background-image: url(${getKey(back, "desktop.path")});
      }
    `};

  @media screen and (min-width: 768px) {
    min-height: 610px;
  }
`;
export const MetatagContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
  @media screen and (min-width: 768px) {
  }
`;

export const TitleWidget = styled(AtTitle)``;

export const DescriptionWrapper = styled.div`
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    max-width: 364px;
  }
`;

export const Description = styled(AtDescription)`
  @media screen and (min-width: 768px) {
    font-weight: 500;
  }
`;

export const ProductionList = styled.div`
  margin: 22px auto;
  overflow: hidden;
  width: 100%;
  overflow-x: auto;
  @media screen and (min-width: 768px) {
    display: block;
  }
`;

export const ProductionListInnerContainer = styled.div`
  gap: 10px;
  display: inline-flex;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    display: flex;
    width: 128%;
    margin: 0 0 0 15px;
  }
`;

export const CategoryListItem = styled.li`
  border: 1px solid ${Colors.white};
  display: inline-flex;
  gap: 10px;
  background: rgba(0, 0, 0, 0.3);

  &:hover {
    background: rgba(0, 0, 0, 1);
  }

  a {
    font-size: 12px;
    padding: 5px;
    color: ${Colors.white};
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
      font-size: 18px;
      padding: 5px;
    }
  }

  @media screen and (min-width: 768px) {
    gap: 20px;
  }
`;

export const ImagePodcast = styled.img`
  width: 100%;
  max-width: 198px;
`;

export const LitlePlayIcon = styled(AtIcon)`
  opacity: 1;
`;

export const Link = styled.a`
  display: block;
  text-decoration: none;
`;

export const ListenNowContainer = styled(Wp.Container)`
  width: inherit;
  max-width: 153px;
  color: #ffffff;
  a {
    color: #ffffff;
  }
  span {
    margin-left: 5px;
    font-size: 1em;
    font-weight: 600;
    margin-top: 5px;
    display: inline-block;
    vertical-align: super;
  }

  @media screen and (min-width: 768px) {
    max-width: 198px;
  }
`;

export const SocialButtonContainer = styled.div`
  position: relative;
`;

export const ListenButtonContainer = styled.div`
  position: relative;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
