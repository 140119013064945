import React from "react";
import { Image, InnerWrapper, Wrapper } from "./GeoRestricted";
import Page from "../index";

/**
 * GeoRestricted Page Layout
 *
 * @returns {JSX.Element}
 */
function GeoRestrictedPage() {
  return (
    <Page
      id="geoBlocked"
      title="Este contenido no está disponible en tu país"
      description="Este contenido no está disponible en tu país."
      noCrawl
    >
      <Wrapper>
        <InnerWrapper>
          <Image src="https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/2021-02/forbidden.jpg" />
        </InnerWrapper>
      </Wrapper>
    </Page>
  );
}

export default GeoRestrictedPage;
