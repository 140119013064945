import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import CinemaPromotionItem from "./CinemaPromotionItem";
import AtDescription from "../AtDescription";
import MolPlayer from "../MolPlayer/MolPlayer";
import { Metrics } from "../../theme";
import { getKey, hasKey } from "../../utils/helpers";

export default function OrCinemaPromotion({
  background,
  video_background,
  contents,
  description,
  logo,
  streaming_provider
}) {
  const [videoSrc, setVideoSrc] = useState(
    getKey(video_background, "video_desktop")
  );
  const videoAttr = () => {
    return {
      config: {
        file: {
          attributes: {
            autoPlay: true,
            poster: "",
            loop: true,
            controls: false,
            muted: true
          }
        }
      },
      url: [
        {
          src: videoSrc,
          preload: "auto"
        }
      ]
    };
  };

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      hasKey(video_background, "video_mobile")
    ) {
      if (window.innerWidth < 768) {
        setVideoSrc(getKey(video_background, "video_mobile"));
      }
    }
  });
  return (
    <>
      <Wrapper background={background}>
        {video_background && (
          <>
            <Player
              coverOverflow
              showAudioButton={false}
              showProgressBar={false}
              {...videoAttr()}
              loop
              isBackgroundVideo
            />
            <VideoBackground />
          </>
        )}
        <InnerContainer>
          <Logo logo={logo} />
          <DescriptionWrapper>{description}</DescriptionWrapper>
          <List>
            {contents.map(({ logo: logoItem, subtitle }, index) => (
              <ListItem key={`cinemaPromoItemOpening${index}`}>
                <Logo logo={logoItem} maxHeight={120} />
                <Subtitle>{subtitle}</Subtitle>
              </ListItem>
            ))}
          </List>
        </InnerContainer>
      </Wrapper>
      {contents.map((content, index) => (
        <CinemaPromotionItem
          key={`cinemaPromoItem${index}`}
          index={index}
          {...content}
          streaming_provider={streaming_provider}
        />
      ))}
    </>
  );
}

export const THEME = {
  DARK: "Oscuro",
  LIGHT: "Claro"
};

const Wrapper = styled.div`
  ${({ background }) =>
    background &&
    css`
      background-image: url(${getKey(background, "data.path")});
      background-size: cover;
      background-position: center;
    `} min-height: 383px;
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    min-height: 383px;
    justify-content: center;
    padding-top: 100px;
  }
  @media screen and (min-width: 1250px) {
    min-height: 538px;
    justify-content: center;
    padding-top: 100px;
  }
`;

const InnerContainer = styled.div`
  max-width: 308px;
  min-height: 266px;
  margin: 0 auto;
  text-align: center;
  z-index: 1;

  @media screen and (min-width: 780px) {
    max-width: 835px;
    min-height: 445px;
  }
`;

export const Logo = styled.img.attrs({
  src: ({ logo }) => logo.data.path
})`
  max-width: 227px;
  max-height: 94px;

  @media screen and (min-width: 768px) {
    max-width: 340px;
    max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : 140)}px;
  }
`;

const DescriptionWrapper = styled(AtDescription)`
  margin-top: 50px;
  display: none;
  @media screen and (min-width: 768px) {
    display: inherit;
  }
`;

const Subtitle = styled(AtDescription)`
  margin: 10px 0 0;
  font-style: italic;
  display: none;
  @media screen and (min-width: 768px) {
    display: inherit;
  }
`;

const List = styled.ul`
  display: inline-flex;
  padding: 0;
  margin: 40px 0 0;
  list-style: none;

  @media screen and (min-width: 768px) {
    margin: 0;
  }
`;

const ListItem = styled.li`
  border-right: 1px solid #fff;
  max-width: 250px;
  padding: 0 15px;
  &:last-child {
    padding-right: 0;
    border: 0;
  }

  @media screen and (min-width: 768px) {
    padding: 0 45px;
  }
`;

const Player = styled(MolPlayer)`
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  padding-top: 108%;

  @media (min-width: 400px) and (max-width: 700px) {
    padding-top: -100px;
    height: 100%;
  }
  @media (min-width: ${Metrics.size.tablet}) {
    padding-top: unset;
    height: 100%;
  }
`;

const VideoBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`;
