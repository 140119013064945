import React, { Component, Fragment } from "react";
import styled from "styled-components";
import AtIcon from "../../../app/components/AtIcon/AtIcon";

import "./MolShowTrailerButton.scss";

const Link = styled.a`
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
  text-decoration: none;
  align-items: center;
  color: white;

  span {
    text-transform: uppercase;
  }

  @media screen and (min-width: 768px) {
    font-weight: normal;
    flex-direction: row;
    gap: 5px;
  }
`;

class ShowTrailerButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderPlayer: false
    };
  }

  onOpenedEvent() {
    const { onOpened } = this.props;
    if (onOpened !== undefined && typeof onOpened === "function") {
      onOpened();
    }
  }
  onClosedEvent() {
    const { onClosed } = this.props;
    if (onClosed !== undefined && typeof onClosed === "function") {
      onClosed();
    }
  }

  divVideo(e) {
    e.stopPropagation();
  }

  detail(e) {
    e.preventDefault();

    const {
      trailer: { assetid, uuid }
    } = this.props;

    window.currentPlayer && window.currentPlayer.pause();

    const selector = `#trailer-${uuid || assetid} .contentTrailer`;
    global.window.document.querySelector(selector).classList.toggle("display");
    global.window.document
      .querySelector("body")
      .classList.toggle("disableScroll");

    const isOpen = document
      .querySelector(selector)
      .classList.contains("display");
    this.setState({
      renderPlayer: isOpen
    });

    if (isOpen) {
      this.onOpenedEvent();
    } else {
      this.onClosedEvent();
    }
  }

  render() {
    const { title, trailer: video } = this.props;
    const { assetid, uuid } = video;

    return (
      <div id={`trailer-${uuid || assetid}`} className="contentGeneralTrailer">
        <div className="contentTrailer">
          <div className="contentVideo">
            <div className="contentUpper">
              <div className="title">
                <span>Tr&aacute;iler</span>
                <h1>{title}</h1>
              </div>
              <a
                href={_ => {}}
                onClick={e => this.detail(e)}
                className="closeButton"
              >
                Cerrar <AtIcon name="Close" size={22} />
              </a>
            </div>
            <Fragment>
              {this.state.renderPlayer && (
                <iframe
                  title={title}
                  className="iframePlayerTrailer"
                  src={`/embed/${uuid}`}
                />
              )}
            </Fragment>
          </div>
        </div>
        <Link onClick={e => this.detail(e)}>
          <AtIcon name="Trailer" size={32} className="rounded" />
          <span>Ver Tr&aacute;iler</span>
        </Link>
      </div>
    );
  }
}
export default ShowTrailerButton;
