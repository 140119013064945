import React, { Component } from "react";
import styled from "styled-components";
import MetisMenu from "react-metismenu";

import AtLiveDisplay from "../AtLiveDisplay/AtLiveDisplay";
import AtTextLink from "../AtTextLink/AtTextLink";
import AtImgLink from "../AtImgLink/AtImgLink";
import AtIconButton from "../AtIconButton/AtIconButton";

import "react-metismenu/dist/react-metismenu-standart.min.css";

import { Images, Colors, Wp } from "../../theme";
import { getKey, isValidArray } from "../../utils/helpers";

const LiveDisplay = styled(AtLiveDisplay)`
  margin: 0px auto;
`;

const MenuMobile = styled(Wp.Container)`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 320px;
  height: 100vh;
  top: 0;
  left: ${props => (props.visible ? "0" : "-320px")};
  transition: left 400ms ease;
  z-index: 9999;
  background: ${Colors.bg2};

  .mobile-menu {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    background: none;
    .mobile-menu-container {
      padding: 0.5em 0;
      box-shadow: initial;
      background: none;
      .mobile-menu-link {
        height: 2.1em !important;
        display: block;
        &.imgStyleMobile {
          height: 42px !important;
          img {
            height: 100%;
            width: auto;
            max-width: auto;
          }
        }
      }
    }

    &.metismenu {
      :after {
        box-shadow: initial;
      }
    }
  }
`;

const Links = {
  root: styled(AtTextLink)`
    &.metismenu-link {
      text-shadow: initial;
      color: ${Colors.white} !important;
      :hover {
        background: none;
        text-decoration: none;
      }
    }
  `,
  submenu: styled(AtTextLink)`
    font-weight: normal;
    &.metismenu-link {
      text-shadow: initial;
      color: ${props => props.color || Colors.cerulean};
      :hover {
        background: none;
        text-decoration: none;
      }
    }
  `,
  smlive: styled(AtImgLink)`
    font-weight: normal;

    padding-left: 0px !important;
    line-height: initial !important;
    margin: 1em;

    &.metismenu-link {
      text-shadow: initial;
      color: ${Colors.white};
      :hover {
        background: none;
        text-decoration: none;
      }
    }
  `,
  link: styled(AtTextLink)`
    font-weight: normal;
    &.metismenu-link {
      text-shadow: initial;
      color: ${Colors.cerulean};
      :hover {
        background: none;
        text-decoration: none;
      }
    }
  `
};

const TopBar = styled(Wp.Container)`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  .close-button {
    margin-left: 0.5em;
  }
  .input-search {
    margin: 0.5em 1em 0.5em 0;
  }
`;

const ScrollView = styled(Wp.Container)`
  height: 100%;
  overflow-y: scroll;
`;

class MenuMobileLink extends Component {
  _onClick = e => {
    if (this.props.hasSubMenu) this.props.toggleSubMenu(e);
    else {
      this.props.activateMe({
        newLocation: this.props.to,
        selectedMenuLabel: this.props.label
      });
    }
  };
  render() {
    const { id, to, data, label, className, children } = this.props;
    const type = id.split("-")[0];
    const nLabel = JSON.parse(label);

    const submenuWithChild = !!children[2]
      ? {
          iconRight: "chevronBottom",
          iconSize: 10,
          iconColor: Colors.white,
          color: Colors.goldenPoppy
        }
      : {};

    const Attr = {
      root: {
        textTransform: "uppercase",
        iconSize: 12,
        iconColor: Colors.cerulean,
        text: nLabel
      },
      submenu: {
        text: nLabel,
        textTransform: "normal",
        ...submenuWithChild
      },
      smlive: {
        imageSource: nLabel.image || Images.logo,
        imageAlt: nLabel.label || ""
      },
      link: {
        text: nLabel,
        textTransform: "normal"
      }
    };
    const MenuLink = styled(Links[type])``;
    return (
      <MenuLink
        type={type}
        href={getKey(
          data.find(d => id.startsWith("root") && d.id === id),
          "to",
          to
        )}
        className={`${id} ${className} ${
          type === "smlive" ? "imgStyleMobile" : ""
        }`}
        onClick={this._onClick}
        withLazyLoad={false}
        {...Attr[type]}
      >
        {children}
      </MenuLink>
    );
  }
}

const MolMenuMobile = ({
  visible,
  data,
  liveDisplay,
  close,
  className,
  children
}) => {
  return (
    isValidArray(data) && (
      <MenuMobile visible={visible} className={className}>
        <TopBar>
          <AtIconButton
            className="close-button"
            name="ArrowBack"
            onClick={close}
            iconSize={32}
            color={Colors.transparent}
          />
        </TopBar>
        {liveDisplay && (
          <LiveDisplay
            text={liveDisplay.title}
            link={liveDisplay.slug || "#"}
          />
        )}
        <ScrollView>
          <MetisMenu
            className="mobile-menu"
            classNameContainer="mobile-menu-container"
            classNameLink="mobile-menu-link"
            LinkComponent={props => <MenuMobileLink data={data} {...props} />}
            content={data}
          />
          {children}
        </ScrollView>
      </MenuMobile>
    )
  );
};

export default MolMenuMobile;
