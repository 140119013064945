import React, { useCallback } from "react";
import Page from "../";
import OrSignUp from "../../../components/OrSignUp/Form";
import { setRefererContent } from "../../../../store/utils/helpers";

import { ContentSignUpPage, ContainerCloseButton } from "./Signup";
import AtCloseButton from "../../../components/AtCloseButton";
import { getKey, searchToObject } from "../../../utils/helpers";

const SignUp = ({ location: { search }, hideCloseButton }) => {
  const refer = searchToObject(search);
  setRefererContent(refer);
  const goBack = useCallback(
    () => {
      window.location.href = getKey(refer, "ref", "/");
    },
    [refer]
  );
  return (
    <Page id="Registro" title="Registro" description="Registro de RTVCPlay">
      <ContentSignUpPage>
        <OrSignUp />
        {!hideCloseButton && (
          <ContainerCloseButton>
            <AtCloseButton onClick={goBack} />
          </ContainerCloseButton>
        )}
      </ContentSignUpPage>
    </Page>
  );
};

export default SignUp;
