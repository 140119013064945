import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { exists } from "../../../utils/helpers";
import { Wp } from "../../../theme";
import AtIcon from "../../../components/AtIcon/AtIcon";
import TrailerPlayer from "../../../components/MolTrailerPlayer";

import "./Trailer.scss";

const PageWrapper = styled(Wp.Page)``;

const TrailerMarkUp = styled.div`
  @media screen and (min-width: 768px) {
    width: 55%;
  }
`;

class Trailer extends Component {
  showButtons(e) {
    e.preventDefault();
    if (exists(this.buttonsContainer)) {
      global.window.document
        .querySelector("#" + this.buttonsContainer.id)
        .classList.toggle("display");
    }
  }

  render() {
    const {
      titulo_paragraph,
      titulo_trailer,
      video_trailer,
      streaming_provider,
      title_redes_sociales,
      description,
      uri
    } = this.props;

    let customStyles = `.contentTrailer { background: #1c1c1c; padding-bottom: 4em; border-bottom: none;}`;

    let sizeButtons = isMobile ? 18 : 22;
    let url = "/";
    if (uri !== undefined) {
      url = uri.startsWith("http") ? uri : "https://www.rtvcplay.co" + uri;
    } else if (typeof window !== "undefined") {
      url = `https://www.rtvcplay.co${window.location.pathname}`;
    } else if (this.props.serverRequest) {
      url = this.props.serverRequest.headers.host;
    }

    return (
      <Fragment>
        <style>{customStyles}</style>
        <div className={`contentTrailer`}>
          <PageWrapper>
            <h2 className={`title`}>{titulo_paragraph}</h2>
            <div className={`widgetContainer`}>
              <TrailerMarkUp>
                <TrailerPlayer
                  title={titulo_trailer}
                  trailer={video_trailer}
                  streaming_provider={streaming_provider}
                />
              </TrailerMarkUp>
              <div className={`contentTrailer_body`}>
                <div className={`contentTrailer_body_Decoration`} />
                <h3 className={`contentTrailer_body_Title`}>
                  {titulo_trailer}
                </h3>
                <p className={`contentTrailer_body_Description`}>
                  {description}
                </p>
                <div className={`contentTrailer_body_RRSS`}>
                  <ul>
                    <li>
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${url}&t=${title_redes_sociales ||
                          ""}`}
                        target="_blank"
                        onClick={e =>
                          setTimeout(() => this.showButtons(e), 800)
                        }
                        rel="noopener noreferrer"
                      >
                        <AtIcon name="FacebookF" size={sizeButtons} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          "https://twitter.com/intent/tweet?source=" +
                          url +
                          "&text=" +
                          (title_redes_sociales || "") +
                          " " +
                          url
                        }
                        target="_blank"
                        onClick={e =>
                          setTimeout(() => this.showButtons(e), 800)
                        }
                        rel="noopener noreferrer"
                      >
                        <AtIcon name="Twitter" size={sizeButtons} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          "whatsapp://send?text=" +
                          (title_redes_sociales || "") +
                          " " +
                          url
                        }
                        onClick={e =>
                          setTimeout(() => this.showButtons(e), 800)
                        }
                      >
                        <AtIcon name="Whatsapp" size={sizeButtons} />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          "mailto:?subject=" +
                          (title_redes_sociales || "") +
                          "&body=" +
                          (title_redes_sociales || "") +
                          ", " +
                          (description || "") +
                          " " +
                          url
                        }
                        onClick={e =>
                          setTimeout(() => this.showButtons(e), 800)
                        }
                      >
                        <AtIcon name="Email" size={sizeButtons} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </PageWrapper>
        </div>
      </Fragment>
    );
  }
}

export default Trailer;
