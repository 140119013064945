// Base 16
const scale = {
  mobile: 0.75,
  desktop: 1
};

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  mobileXL: "650px",
  tablet: "768px",
  tabletL: "835px",
  laptop: "1024px",
  laptopM: "1300px",
  laptopL: "1440px",
  laptopXL: "1920px",
  desktop: "2560px",
  maxPageWidth: "1270px",
  minHeaderHeight: "56px",
  maxHeaderHeight: "80px"
};

export const mediaQ = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  tabletL: `(min-width: ${size.tabletL})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
};

export default {
  scale,
  size,
  mediaQ
};
