import React, { Component } from "react";
import * as widgetType from "../../../constants/widgetType";
import widgetLoader from "../../widgets";
import * as pageType from "../../../constants/pageType";
import {
  getUniqKey,
  isNinos,
  isEducation,
  getKey,
  getTaxonomy,
} from "../../../utils/helpers";
import Page from "../";

import OrLivestreamingTv from "../../../components/OrLivestreamingTv";
import OrLivestreamingRadio from "../../../components/OrLivestreamingRadio/OrLivestreamingRadio";
import OrLivestreamingEvent from "../../../components/OrLivestreamingEvent/OrLivestreamingEvent";
import OrVerticalList from "../../../components/OrVerticalList/OrVerticalList";
import OrBasicPage from "../../../components/OrBasicPage/OrBasicPage";

import "./content.scss";
import OrVideo from "../../../components/OrVideo";
import OrVideoEducation from "../../../components/OrVideoEducation";
import {
  CHAPTER_VIEW_GRID,
  HORIZONTAL_LIST,
} from "../../../constants/widgetType";
import { isNewPosterStyle } from "../../../utils/helpers";
import Audio from "../audio";
import { COVER_VIEW, PODCAST_VIEW } from "../../../constants";

class Content extends Component {
  _renderDefaultContent = ({ title, image, description, type }) => {
    return (
      <div className="wrapperContent">
        <span>{type}</span>
        <h1>{title}</h1>
        <img src={image} alt={title} />
        <p>{description}</p>
      </div>
    );
  };

  getSeasonListWidget() {
    const widgets = getKey(this.props, "currentContent.widgets", []);
    return (widgets ? widgets : []).filter(
      widget => widget.type === widgetType.SEASON_LIST,
    );
  }

  componentDidMount() {
    const { id, type, slug, another_categories } = this.props.currentContent;

    const fallbackNinos = isNinos({ id, type, slug, another_categories });

    if (fallbackNinos) {
      global.window.document.querySelector("body").classList.add("ninos");
    }
  }

  render() {
    const { currentContent } = this.props;
    const {
      meta_tags,
      widgets,
      id,
      type,
      slug,
      another_categories,
      production,
      taxonomy,
    } = currentContent;

    const ninosFallback = isNinos({
      id,
      type,
      slug,
      ...production,
      another_categories,
    });

    let content = this._renderDefaultContent(currentContent);
    let isGridMode = false;

    switch (currentContent.type) {
      case pageType.AUDIO:
        content = <Audio {...currentContent} />;
        break;
      case pageType.LIVESTREAMTV:
        content = <OrLivestreamingTv data={currentContent} />;
        break;
      case pageType.LIVESTREAMRADIO:
        content = <OrLivestreamingRadio data={currentContent} />;
        break;
      case pageType.LIVESTREAMEVENT:
        content = <OrLivestreamingEvent data={currentContent} />;
        break;
      case pageType.VIDEO:
        isGridMode =
          getKey(currentContent, "production.chapter_view") ===
          CHAPTER_VIEW_GRID;
        if (isEducation(currentContent)) {
          content = (
            <OrVideoEducation
              {...currentContent}
              isNinos={ninosFallback}
              seasonList={this.getSeasonListWidget()}
            />
          );
        } else {
          content = (
            <OrVideo
              {...currentContent}
              isNinos={ninosFallback}
              seasonList={this.getSeasonListWidget()}
            />
          );
        }
        break;
      case pageType.CATEGORY:
        content = (
          <OrVerticalList
            {...currentContent}
            isNinos={ninosFallback}
            page
            view={isNewPosterStyle(
              currentContent.contents.find(
                ({ new_poster_view }) => new_poster_view,
              ),
            )}
          />
        );
        break;
      case pageType.BASICPAGE:
        content = <OrBasicPage {...currentContent} />;
        break;
      default:
        break;
    }
    return (
      <Page
        id="content"
        uid={id}
        category={getTaxonomy(taxonomy, "categories")}
        meta_tags={meta_tags}
        title={currentContent.title}
        description={currentContent.description}
        image={currentContent.image}
      >
        {content}
        <div className={`wrapperContent${isGridMode ? " gridMode" : ""}`}>
          {widgets &&
            widgets.map((widget, idx) => {
              let LoadableWidgetComponent = widgetLoader[widget.type];
              return (
                <LoadableWidgetComponent
                  key={getUniqKey(idx)}
                  meta_tags={meta_tags}
                  {...widget}
                  isNinos={ninosFallback}
                  isEducation={isEducation}
                  isGridMode={isGridMode}
                  idKey={idx}
                  view={
                    currentContent.type === pageType.AUDIO &&
                    widget.type === HORIZONTAL_LIST &&
                    widget.view === COVER_VIEW
                      ? PODCAST_VIEW
                      : widget.view
                  }
                />
              );
            })}
        </div>
      </Page>
    );
  }
}
export default Content;
