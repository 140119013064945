import React, { Fragment, useState } from "react";
import { isUserLogged } from "../../../store/utils/helpers";
import { connect } from "react-redux";
import AtIcon from "../../../app/components/AtIcon/AtIcon";
import "./MolLikeButton.scss";
import {
  giveDislike,
  giveLike,
  undoVote,
} from "../../../store/actions/info-player";
import { Button, UncontrolledTooltip } from "reactstrap";
import styled from "styled-components";
import { Colors } from "../../theme";
import { hasUserFeaturesEnable } from "../../utils/helpers";
import MyListToolTip from "../MolMyListButton/MyListToolTip";

const ConnectedLikeButton = ({
  giveDislike,
  giveLike,
  idVideo,
  isLike = true,
  label,
  size,
  stateLike,
  undoVote,
  icon = "LikeDeselectedCustom",
  colorSelected = "#3C63AD",
}) => {
  const [isOpen, setOpen] = useState(false);
  const isLoggedIn = isUserLogged();

  const currentId = `btn-${!isLike ? "dis" : ""}like`;

  const clickHandler = e => {
    e.preventDefault();
    e.currentTarget.focus();
    if (!isLoggedIn) {
      setOpen(true);
    } else {
      if (isLike) {
        if (stateLike) {
          undoVote(idVideo);
        } else {
          giveLike(idVideo);
        }
      } else {
        if (stateLike === false) {
          undoVote(idVideo);
        } else {
          giveDislike(idVideo);
        }
      }
    }
  };

  return !hasUserFeaturesEnable() ? null : (
    <Fragment>
      <MyListToolTip currentId={currentId} isOpen={isOpen} setOpen={setOpen} />

      {isLoggedIn && (
        <UncontrolledTooltip
          placement="top"
          target={currentId}
          offset="7px"
          arrowClassName="arrow-like-uncontrolled-tooltip-custom-css"
        >
          <DarkUnconrolledToooltip>
            {label || "ME GUSTA"}
          </DarkUnconrolledToooltip>
        </UncontrolledTooltip>
      )}
      <Wrapper
        type="button"
        onClick={clickHandler}
        className="rounded"
        id={currentId}
      >
        <AtIcon
          name={isLike ? icon : "DislikeDeselectedCustom"}
          size={size || 20}
          color={
            isLoggedIn &&
            ((isLike && stateLike) || (!isLike && stateLike === false))
              ? colorSelected
              : "#FFFFFF"
          }
        />
      </Wrapper>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    stateLike: state.content.actionLike,
  };
}
const LikeButton = connect(mapStateToProps, {
  giveLike,
  giveDislike,
  undoVote,
})(ConnectedLikeButton);

const DarkUnconrolledToooltip = styled.div`
  font-family: Dosis, sans-serif;
  color: ${Colors.white};
  font-size: 12px;
  font-weight: 600;
  line-height: 2em;
  margin-left: 2px;
  padding: 3px;
  background-color: #222;
  border-radius: 5px;
  border: 1px solid #555555;
  border-bottom: 0px;
`;
const Wrapper = styled(Button)`
  background: transparent;
  border: 0;
`;

export default LikeButton;
