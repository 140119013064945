import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Wp, Colors, Metrics, Images } from "../../theme";

import TplSwiper from "../TplSwiper/TplSwiper";
import AtImg from "../AtImg/AtImg";
import { hasKey } from "../../utils/helpers";
import "./OrElencoSlider.scss";

const Swiper = styled(TplSwiper)`
  background: ${Colors.bg2};

  .swiper-container {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    overflow: initial;

    .swiper-container {
      padding-bottom: 30px;

      .swiper-pagination-bullets {
        bottom: 2px;
      }
    }
  }
  @media (max-width: ${Metrics.size.tabletL}) and (orientation: landscape) {
    padding-top: ${100 / (21 / 9)}% !important;
  }
  @media ${Metrics.mediaQ.tabletL} {
    padding-top: ${100 / (21 / 9)}% !important;
    .swiper-container {
      .swiper-button-next,
      .swiper-button-prev {
        display: ${props => (props.showButtonsNav ? "flex" : "none")};
      }
    }
  }
`;

const Slide = styled.div``;

const ImgContainer = styled(Wp.Container)`
  img,
  picture {
    width: 100%;
    max-width: 100%;
  }
`;

class OrElencoSlider extends Component {
  state = { windowWidth: null, windowHeight: null };

  static defaultProps = {
    contents: [],
    title: "",
    aspectRatio: "12-13",
    showTitle: true
  };

  _resize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
  };
  _imageAttr = image => {
    if (image.cover_mobile && image.cover_desktop) {
      return {
        title: image.cover_mobile.title || image.cover_desktop.title || "",
        alt: image.cover_mobile.alt || image.cover_desktop.alt || "",
        src: [
          {
            "": image.cover_mobile.path
          },
          {
            [Metrics.mediaQ.tabletL]: image.cover_desktop.path
          },
          {
            [`(max-width: ${
              Metrics.size.tabletL
            }) and (orientation: landscape)`]: image.cover_desktop.path
          }
        ],
        responsive: true
      };
    }
    return { src: null };
  };

  _fotoPesonajeAttr = image => {
    if (image.personajes) {
      return {
        title: image.personajes.title,
        alt: image.personajes.alt,
        src: image.personajes.path
      };
    }
    return { src: Images.coverTest };
  };

  _renderImage = cover => {
    return (
      <Fragment>
        <ImgContainer>
          <AtImg {...this._imageAttr(cover)} />
        </ImgContainer>
      </Fragment>
    );
  };

  _renderPersonaje = foto_personaje => {
    return (
      <Fragment>
        <ImgContainer className="contentElenco_foto_personaje">
          <AtImg {...this._fotoPesonajeAttr(foto_personaje)} />
        </ImgContainer>
      </Fragment>
    );
  };

  componentDidMount() {
    window.addEventListener("resize", this._resize);
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this._resize);
  }
  render() {
    const { aspectRatio, personajes } = this.props;

    return (
      <>
        <Swiper
          aspectRatio={aspectRatio}
          showButtonsNav={personajes.length > 1}
          swiperParams={{
            loop: true,
            keyboard: true,
            pagination: {
              el: ".swiper-pagination",
              type: "bullets",
              clickable: true
            },
            on: {
              init: function() {
                let swiper = this;
                document
                  .querySelectorAll(".contentElenco_poster_personaje")
                  .forEach((element, index) => {
                    element.addEventListener("click", function() {
                      swiper.slideTo(element.dataset.slide);
                    });
                  });
              },
              slideChange: function() {
                if (this.activeIndex === 0) {
                  this.slideTo(this.slides.length - 2);
                } else if (this.activeIndex === this.slides.length - 1) {
                  this.slideTo(1);
                }
              }
            }
          }}
          nextPrevButtonSize={45}
        >
          {personajes.map(
            //Aqui pasar propiedades de cada personaje a funcion _renderImage
            (
              {
                cover,
                descripcion,
                foto_personaje,
                nombre_personaje,
                nombre_real,
                otras_producciones,
                poster
              },
              index
            ) => (
              <Slide key={index}>
                {this._renderImage(cover)}
                {foto_personaje && this._renderPersonaje(foto_personaje)}
                <div className={`contentElenco_personaje`}>
                  <div className={`contentElenco_personaje_Decoration`} />
                  <h3 className={`contentElenco_personaje_nombre`}>
                    {nombre_personaje}
                  </h3>
                  <div className={`contentElenco_personaje_real`}>
                    {nombre_real}
                  </div>
                  <p className={`contentElenco_personaje_descripcion`}>
                    <div dangerouslySetInnerHTML={{ __html: descripcion }} />
                  </p>
                  {otras_producciones && (
                    <div className={`contentElenco_personaje_otras`}>
                      <h4 className={`contentElenco_personaje_otras_titulo`}>
                        Otras producciones
                      </h4>
                      <div className={`contentElenco_personaje_otras_cuerpo`}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: otras_producciones
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="contentElenco_poster">
                  {personajes.map(({ poster }, index2) => {
                    return (
                      index2 > index && (
                        <ImgContainer
                          key={index2 + "poster_personaje_1"}
                          className="contentElenco_poster_personaje"
                          data-slide={index2 + 1}
                        >
                          {hasKey(poster, "poster_elenco.path") && (
                            <AtImg src={poster.poster_elenco.path} />
                          )}
                        </ImgContainer>
                      )
                    );
                  })}
                  {personajes.map(({ poster }, index3) => {
                    return (
                      index3 < index && (
                        <ImgContainer
                          key={index3 + "poster_personaje_2"}
                          className="contentElenco_poster_personaje"
                          data-slide={index3 + 1}
                        >
                          {hasKey(poster, "poster_elenco.path") && (
                            <AtImg src={poster.poster_elenco.path} />
                          )}
                        </ImgContainer>
                      )
                    );
                  })}
                </div>
              </Slide>
            )
          )}
        </Swiper>
      </>
    );
  }
}

export default OrElencoSlider;
