import styled from "styled-components";
import TplTitleBlock from "../TplTitleBlock/TplTitleBlock";
import TplSwiper from "../TplSwiper/TplSwiper";
import { rgba } from "polished";
import { Metrics } from "../../theme";

export const TitleBlock = styled(TplTitleBlock)`
  background-color: ${props => props.backgroundColor};
  padding-top: ${props => (props.showTitle ? 0.75 : 0)}em;
  padding-bottom: ${props => (props.showTitle || props.isNinos ? 0.75 : 0)}em;
  border-top: ${props => props.borderTop};
  .block-body {
    padding-top: 1em;
  }
  ${props => props.customStyles || ""};
`;
export const Swiper = styled(TplSwiper)`
  .swiper-wrapper {
    transform: translate3d(1px, 0, 0);
  }
  .swiper-button-prev,
  .swiper-button-next {
    :before {
      content: "";
      position: absolute;
      display: block;
      width: 2.5em;
      height: 30em;
    }
    svg {
      display: none;
    }
  }
  .swiper-button-prev {
    :before {
      top: calc(15px - 15em);
      left: -12px;
      background: linear-gradient(
        to left,
        ${props => rgba(props.backgroundColor, 0)} 0%,
        ${props => rgba(props.backgroundColor, 1)} 100%
      );
    }
  }
  .swiper-button-next {
    :before {
      top: calc(15px - 15em);
      right: -12px;
      background: linear-gradient(
        to right,
        ${props => rgba(props.backgroundColor, 0)} 0%,
        ${props => rgba(props.backgroundColor, 1)} 100%
      );
    }
  }
  @media ${Metrics.mediaQ.tabletL} {
    .swiper-button-prev,
    .swiper-button-next {
      svg {
        display: inline-block;
      }
    }
  }
`;
