import Store from "../../store";
import { getKey, hasKey } from "../../../app/utils/helpers";
import { logoutUser, signUpUser } from "../../actions/auth";
import {
  getCurrentContent,
  setEnvToContent,
  setRefererToContent,
} from "../../actions/content";
import { addPlayerMyList, removePlayerMyList } from "../../actions/info-player";
import config from "../../../server/config";

export function getCurrentUser() {
  const storeState = Store.getState();
  return getKey(storeState, "auth.user", null);
}
export function isUserLogged(state) {
  const storeState = state || Store.getState();
  return getKey(storeState, "auth.authenticated", false);
}
export function logOut() {
  Store.dispatch(logoutUser(false));
}

export function signUp(user) {
  Store.dispatch(signUpUser(user));
}

export function fetchContent(path) {
  const storeState = Store.getState();

  !hasKey(storeState, "content.headerContent") &&
    Store.dispatch(getCurrentContent(path));
}

export function addToMyList(id) {
  return Store.dispatch(addPlayerMyList(id));
}

export function removeFromMyList(id) {
  return Store.dispatch(removePlayerMyList(id));
}

export function getCountryDetected() {
  const storeState = Store.getState();
  return getKey(storeState, "country", null);
}

export function setRefererContent({ ref }) {
  ref && Store.dispatch(setRefererToContent(ref || "/"));
}

export function getRefererContent() {
  const storeState = Store.getState();
  return getKey(storeState, "content.ref", "/");
}

export function setEnvContent(env) {
  env && Store.dispatch(setEnvToContent(env));
}

export function getEnvContent() {
  const storeState = Store.getState();
  return getKey(storeState, "content.env", null);
}

export function getCurrentFrontEndDomain(customEnv = null) {
  return config.frontent.domains[customEnv || getEnvContent()];
}

export function getCurrentFrontEndEnvironmentUrl(customEnv = null) {
  return config.frontent.environments[customEnv || getEnvContent()];
}

export function getMyList(state) {
  const storeState = state || Store.getState();
  return getKey(storeState, "content.myList", false);
}
