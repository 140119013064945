import React, { Component } from "react";
import styled from "styled-components";
import Page from "../";
import widgetLoader from "../../widgets";
import {
  getUniqKey,
  getTaxonomy,
  isNinos,
  isEducation,
  isPodcast,
  getKey,
} from "../../../utils/helpers";
import "./show.scss";

import { Wp } from "../../../theme";
import {
  CHAPTER_VIEW_GRID,
  HORIZONTAL_LIST,
} from "../../../constants/widgetType";
import AdultPopup from "../../../components/OrAdultPopup";
import PodcastPage from "./Podcast";
import { COVER_VIEW, PODCAST_VIEW } from "../../../constants";
const Container = styled(Wp.Container)``;

class Show extends Component {
  getMainSliderInfo() {
    const {
      title,
      id,
      resource,
      description,
      taxonomy,
      slug,
    } = this.props.currentContent;
    const { image, video } = resource;
    const category = getTaxonomy(taxonomy, "category");

    return [
      {
        id,
        slug,
        category: {
          label: category && category.value,
          link: category && category.uri,
        },
        title,
        summary: description,
        image,
        video,
      },
    ];
  }
  render() {
    const { currentContent } = this.props;

    const {
      title,
      id,
      description,
      firstEpisode,
      type,
      tags,
      widgets,
      unitary,
      meta_tags,
      chapter_view,
      rating,
      streaming_provider,
      slug,
      taxonomy,
    } = currentContent;

    const ninosFallback = isNinos(currentContent);
    const education = isEducation(currentContent);
    const isPodcastFallback = isPodcast(currentContent);
    const isGridMode = chapter_view === CHAPTER_VIEW_GRID;
    return (
      <Page
        id={type + "-" + id}
        uid={id}
        category={getTaxonomy(taxonomy, "categories")}
        meta_tags={meta_tags}
        title={title}
        description={description}
        tags={tags}
      >
        <Container>
          {unitary ? (
            <widgetLoader.unitary currentContent={currentContent} />
          ) : isPodcastFallback ? (
            <PodcastPage {...currentContent} location={this.props.location} />
          ) : (
            <>
              <h1 className="metaTitleHidden">{currentContent.title}</h1>
              <widgetLoader.opening
                {...currentContent}
                type={type}
                isNinos={ninosFallback}
                isEducation={education}
              />
            </>
          )}
          <div
            className={`${ninosFallback ? "ninosView " : ""}wrapperShow${
              isGridMode ? " gridMode" : ""
            }${isPodcastFallback ? " podcastView" : ""}`}
          >
            {widgets &&
              widgets.map((widget, idx) => {
                if (widgetLoader[widget.type] !== undefined) {
                  let LoadableWidgetComponent = widgetLoader[widget.type];
                  return (
                    <LoadableWidgetComponent
                      key={getUniqKey(idx)}
                      {...widget}
                      isNinos={ninosFallback}
                      isEducation={education}
                      isGridMode={isGridMode}
                      idKey={idx}
                      streaming_provider={streaming_provider}
                      title_redes_sociales={title}
                      uri={slug}
                      firstEpisode={firstEpisode}
                      view={
                        isPodcastFallback &&
                        widget.type === HORIZONTAL_LIST &&
                        widget.view === COVER_VIEW
                          ? PODCAST_VIEW
                          : widget.view
                      }
                    />
                  );
                }
                return <div key={getUniqKey(idx)} />;
              })}
          </div>
        </Container>
        {!unitary && <AdultPopup isVisible={getKey(rating, "tid") === 24} />}
      </Page>
    );
  }
}

export default Show;
