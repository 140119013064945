import styled, { css } from "styled-components";
import { Colors, Metrics, Wp } from "../../theme";
import AtTitle from "../AtTitle";
import AtDescription from "../AtDescription";
import { getKey } from "../../utils/helpers";
import MolPlayer from "../MolPlayer/MolPlayer";

export const Wrapper = styled(Wp.Container)``;

export const InnerWrapper = styled(Wp.Page)`
  position: relative;
  max-width: initial;
  min-height: 447px;
  padding: 0;
  ${({ back }) =>
    !!back &&
    css`
      background-image: url(${getKey(back, "mobile.path")});
      background-size: cover;

      @media screen and (min-width: 768px) {
        min-height: 505px;
        max-height: 505px;
        overflow: hidden;
        background-image: url(${getKey(back, "desktop.path")});
      }
    `};

  @media screen and (min-width: 768px) {
    min-height: 505px;
  }
`;
export const MetatagContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
  @media screen and (min-width: 768px) {
    top: calc(50% - 72px);
    max-width: 900px;
    left: calc(50% - 450px);
  }
`;

export const Title = styled(AtTitle)``;

export const Description = styled(AtDescription)`
  @media screen and (min-width: 768px) {
    font-weight: bold;
  }
`;

export const BackGradient = styled.div`
  position: absolute;
  height: 220px;
  width: 100%;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
  bottom: 0;
  right: 0;
  z-index: 0;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const CategoryList = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
  margin: 10px auto 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const CategoryListItem = styled.li`
  border: 1px solid ${Colors.white};
  display: inline-flex;
  gap: 10px;
  background: rgba(0, 0, 0, 0.3);

  &:hover {
    background: rgba(0, 0, 0, 1);
  }

  a {
    font-size: 12px;
    padding: 5px;
    color: ${Colors.white};
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
      font-size: 18px;
      padding: 10px;
    }
  }

  @media screen and (min-width: 768px) {
    gap: 20px;
  }
`;

export const Trailer = styled.div`
  width: 100%;

  @media screen and (min-width: 768px) {
    margin-top: -7%;
    margin-left: 0;
  }
`;

export const Player = styled(MolPlayer)`
  padding-top: ${100 / (16 / 9)}%;
  background: rgba(0, 0, 0, 0.7);
  @media (max-width: ${Metrics.size.tabletL}) {
    padding-top: 125%;
  }
`;
