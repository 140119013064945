import React, { Component, Fragment } from "react";
import "./AtVideo.scss";

class VideoOpening extends Component {
  static defaultProps = {
    videoAttr: {},
    className: ""
  };
  render() {
    const { id, ovp_id, type, player, videoAttr, className } = this.props;
    let htmlPlayer = "";

    switch (type) {
      case "video":
        htmlPlayer = `<video src=${player} ${videoAttr}></video>`;
        break;
      default:
        htmlPlayer =
          '<iframe id="iframePlayerCustom"' +
          (ovp_id || id) +
          ' src="https://playerssl.cdnmedia.tv/embed/' +
          player +
          "/" +
          (ovp_id || id) +
          '/?title=false" style="width: 100%; border: none; margin: 0px; padding: 0px; height: 100%;" allowfullscreen></iframe>';
        break;
    }

    return (
      <Fragment>
        <div
          className={`videoWrapper ${className}`}
          dangerouslySetInnerHTML={{ __html: htmlPlayer }}
        />
      </Fragment>
    );
  }
}
export default VideoOpening;
