import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Gateway from "../../app/layouts/pages/gateway";
import Search from "../../app/layouts/pages/search";
import Login from "../../app/layouts/pages/login";
import Logout from "../../app/layouts/pages/logout";
import Forgot from "../../app/layouts/pages/login/forgot";
import Welcome from "../../app/layouts/pages/welcome";
import Register from "../../app/layouts/pages/register";
import Reset from "../../app/layouts/pages/user/reset";
import SignUp from "../../app/layouts/pages/signup";
import MyList from "../../app/layouts/pages/myList";
import GeoRestricted from "../../app/layouts/pages/GeoRestricted";
import { isUserLogged } from "../../store/utils/helpers";

const Routes = () => (
  <Switch>
    <Route exact path="/no-disponible" component={GeoRestricted} />
    <Route exact path="/user/reset" component={Reset} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/logout" component={Logout} />
    <Route exact path="/login/recordar-contrasena" component={Forgot} />
    <Route exact path="/bienvenido" component={Welcome} />
    <Route exact path="/registro" component={Register} />
    <Route
      exact
      path="/registro"
      render={props =>
        !isUserLogged() ? <SignUp {...props} /> : <Redirect to="/mi-perfil" />
      }
    />
    <Route
      exact
      path="/mi-perfil"
      render={props =>
        isUserLogged() ? (
          <SignUp {...props} hideCloseButton />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
    <Route
      exact
      path="/mi-lista/"
      render={props =>
        isUserLogged() ? <MyList {...props} /> : <Redirect to="/registro" />
      }
    />
    <Route exact path="/buscar/:term/:page?" component={Search} />
    <Route exact path="/medios/:slug/categoria/:category" component={Gateway} />
    <Route exact path="/:slug/marca/:brand" component={Gateway} />
    <Route exact path="/:slug*" component={Gateway} />
  </Switch>
);

export default Routes;
