import React, { Component } from "react";
import styled, { css } from "styled-components";

import AtTextLink from "../AtTextLink/AtTextLink";

import { Colors, Wp } from "../../theme";
import { getKey } from "../../utils/helpers";

const Container = styled(Wp.Container)`
  ${({ hasRightComponent }) =>
    hasRightComponent &&
    css`
      display: flex;
      justify-content: start;
    `};
`;
const MenuBlock = styled(Wp.Container)`
  margin-top: 20px;
  width: auto;
  left: ${props => props.left + 10}px;
`;
const Menu = styled(Wp.Container)`
  ${props =>
    props.hasChildren &&
    css`
      width: initial;
      display: inline-block;
      padding-right: 28px;
      border-right: 1px solid ${Colors.cerulean};

      &:nth-child(n + 2) {
        margin-left: 37px;
      }
      &:last-child {
        padding-right: 0;
        border-right: none;
      }
    `};
`;
const MenuItem = styled(AtTextLink)`
  font-weight: normal;
  margin-bottom: 5px;

  &:not([href]) {
    cursor: default;
    &:hover {
      text-decoration: none;
    }
  }

  ${props =>
    props.hasChildren &&
    css`
      color: ${Colors.goldenPoppy};
      display: block;

      &::after {
        content: "";
        position: absolute;
        top: 11px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 8px solid #fff;
        clear: both;
        margin-left: 5px;
      }
    `};

  ${props =>
    props.isChild &&
    css`
      display: block;
    `};
`;
const ContentBlock = styled(Wp.Container)`
  width: auto;
  max-height: 86%;
  margin-top: 20px;
  margin-left: 20px;
  padding-left: 20px;
  left: ${props => props.left + 10}px;
  border-left: ${props => (props.children ? "1px" : "0px")} solid
    ${Colors.cerulean};
`;

class MolMenuCOD extends Component {
  static defaultProps = {
    data: [
      {
        label: "Para Ver",
        link: "#",
        data: [
          {
            label: "Originales RTVCPlay",
            link: "#"
          },
          {
            label: "Series",
            link: "#"
          },
          {
            label: "Documentales",
            link: "#"
          },
          {
            label: "Magazines",
            link: "#"
          },
          {
            label: "Películas",
            link: "#"
          }
        ]
      },
      {
        label: "Para Escuchar",
        data: [
          {
            label: "Podcasts",
            link: "#"
          },
          {
            label: "Documentales",
            link: "#"
          },
          {
            label: "Radioteatros",
            link: "#"
          }
        ]
      },
      {
        label: "Para Aprender",
        data: [
          {
            label: "Competencias básicas",
            link: "#"
          },
          {
            label: "Competencias ciudadanas",
            link: "#"
          }
        ]
      }
    ]
  };
  render() {
    const { data, className, clientRect, rightComponent } = this.props;

    return (
      <Container className={className} hasRightComponent={!!rightComponent}>
        <MenuBlock left={getKey(clientRect, "offsetLeft", 0)}>
          {data.map((itm, index) => {
            const childrenData = getKey(itm, "data", []);
            const hasChildren = childrenData.length > 0;
            const hrefLink =
              {
                href: itm.link
              } || {};

            return (
              <Menu key={index} hasChildren={hasChildren}>
                <MenuItem
                  key={index}
                  text={itm.label}
                  textTransform="normal"
                  hasChildren={hasChildren}
                  {...hrefLink}
                />
                {childrenData.map((childItm, indexChildItm) => (
                  <MenuItem
                    key={indexChildItm}
                    text={childItm.label}
                    textTransform="normal"
                    href={childItm.link}
                    isChild
                  />
                ))}
              </Menu>
            );
          })}
        </MenuBlock>
        {!!rightComponent && (
          <ContentBlock left={getKey(clientRect, "offsetLeft", 0)}>
            {rightComponent}
          </ContentBlock>
        )}
      </Container>
    );
  }
}

export default MolMenuCOD;
