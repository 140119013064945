import React, { Component } from "react";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3";

import { forgotPassword } from "../../../../store/actions/auth";

import { Colors } from "../../../theme";
import AtIcon from "../../AtIcon/AtIcon";

import {
  ContentForgotForm,
  Title,
  SubTitle,
  Form,
  Field,
  FieldContainer,
  Button,
  ModalContainer,
  ErrorWrapper,
  TextError
} from "./Forgot";
import { getReCaptchaKey } from "../../../utils/helpers";

class FormForgot extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      hasErrors: false,
      modalVisible: false,
      loading: false,
      googleToken: null,
      messageSent: false
    };
  }
  async handleSubmit(event) {
    event.preventDefault();
    if (event.target.checkValidity() && this.state.googleToken !== undefined) {
      this.setState({
        loading: true
      });
      const result = await this.props.forgotPassword(
        event.target.field_e.value
      );
      if (!result.error) {
        this.setState({
          hasErrors: true,
          modalVisible: false,
          modalMessage: result.message,
          loading: false
        });
        this.setState({ messageSent: true });
      } else {
        this.setState({
          hasErrors: true,
          modalVisible: false,
          modalMessage:
            result.error ||
            "Hubo un problema interno, intente de nuevo más tarde.",
          loading: false
        });
      }
    } else {
      if (
        event.target.field_e.value !== "" &&
        !event.target.field_e.checkValidity()
      ) {
        this.setState({
          hasErrors: true,
          modalVisible: false,
          modalMessage: "Ingrese un email válido."
        });
      } else {
        this.setState({
          hasErrors: true,
          modalVisible: false,
          modalMessage: "Todos los campos son necesarios."
        });
      }
    }
  }
  openModal() {
    this.setState({
      modalVisible: false
    });
  }

  closeModal() {
    this.setState({
      modalVisible: false
    });
  }
  setGoogleToken(token) {
    this.setState({ googleToken: token });
  }
  render() {
    const { hasErrors, messageSent } = this.state;
    return (
      <ContentForgotForm>
        <Title>RESTAURAR CONTRASEÑA</Title>
        <ErrorWrapper hasErrors={hasErrors}>
          <AtIcon name="ErrorOutline" size={48} color={Colors.orange} />
          <TextError>{this.state.modalMessage}</TextError>
        </ErrorWrapper>
        <SubTitle hide={messageSent}>
          Ingresa tu correo electrónico y te enviaremos las instrucciones para
          restaurar tu contraseña.
        </SubTitle>
        <Form hide={messageSent} onSubmit={this.handleSubmit} noValidate>
          <FieldContainer>
            <Field
              name="e"
              type="email"
              label="Email"
              disabled={this.state.loading && "disabled"}
              required
              className={hasErrors ? "hasErrors" : ""}
            />
          </FieldContainer>
          <FieldContainer>
            <Button disabled={this.state.loading && "disabled"}>
              Restablecer contraseña
            </Button>
          </FieldContainer>
        </Form>
        <ModalContainer>
          <Modal
            visible={this.state.modalVisible}
            width="370"
            height="142"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <div>
              <h1>Error</h1>
              <p>{this.state.modalMessage}</p>
              <GoogleReCaptchaProvider reCaptchaKey={getReCaptchaKey()}>
                <GoogleReCaptcha
                  onVerify={token => this.setGoogleToken(token)}
                />
              </GoogleReCaptchaProvider>
              <Button onClick={() => this.closeModal()}>CERRAR</Button>
            </div>
          </Modal>
        </ModalContainer>
      </ContentForgotForm>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ forgotPassword }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(FormForgot);
