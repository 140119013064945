import { Colors } from "../../../theme";
import React, { useState } from "react";
import styled from "styled-components";
import AtIcon from "../../AtIcon/AtIcon";
import { Tooltip } from "reactstrap";
import { useAudioPlayer } from "../../../contexts/AudioPageContext";
import { copyURLToClipboard } from "../../../utils/helpers";
import {
  getCurrentFrontEndEnvironmentUrl,
  getEnvContent,
} from "../../../../store/utils/helpers";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const TreeDotsButton = ({ audio }) => {
  const { id, slug } = audio;
  const [isOpen, setOpen] = useState(false);
  const currentID = `audtioTreeDotsOptions${id}`;
  const { openAudioCreditModal } = useAudioPlayer();
  const currentEnv = getEnvContent() === "local" ? "qa" : getEnvContent();
  const baseUrl = getCurrentFrontEndEnvironmentUrl(currentEnv);
  const notify = () =>
    toast("El enlace del capítulo fue copiado.", {
      type: toast.TYPE.SUCCESS,
      position: toast.POSITION.BOTTOM_CENTER,
    });
  const TreeDotsHandler = e => {
    e.stopPropagation();
    e.preventDefault();
    e.currentTarget.focus();
    setOpen(true);
  };

  const toggleButton = e => {
    if (e.type === "focusout") {
      setTimeout(() => setOpen && setOpen(!isOpen), 100);
    }
  };

  return (
    <>
      <Button id={currentID} onClick={TreeDotsHandler}>
        <TreeDotsIcon name="TreeDots" color={Colors.fedora} size={50} />
      </Button>
      <Tooltip
        className="TreeDotsTooltip"
        placement="top-start"
        isOpen={isOpen}
        target={currentID}
        toggle={toggleButton}
      >
        <ToolTipMessage>
          {audio.infoAdditional && (
            <Option
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                openAudioCreditModal(audio);
              }}
            >
              Ver créditos
            </Option>
          )}
          <Option
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();

              window.location.href = `${slug}`;
            }}
          >
            Agregar a mi lista
          </Option>
          <Option
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              copyURLToClipboard(`${baseUrl}${slug}`);
              notify();
            }}
          >
            Copiar enlace del capítulo
          </Option>
        </ToolTipMessage>
      </Tooltip>
      <ToastContainer />
    </>
  );
};

const Button = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 5px 0 0;
  margin: 0;
`;

const TreeDotsIcon = styled(AtIcon)`
  &:hover {
    g {
      fill: ${Colors.white};
    }
  }
  &:active {
    g {
      fill: ${Colors.orangeBright};
    }
  }
`;

export const ToolTipMessage = styled.div`
  color: #fff;
  font-size: 18px;
  font-family: "Dosis", serif;
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 12px;
`;

const Option = styled.div`
  font-family: "Dosis", sans-serif;
  font-size: 1.2rem;
  color: ${Colors.darkGray};
  text-align: left;
  font-weight: 400;
  border-bottom: 1px solid ${Colors.black};
  cursor: pointer;

  &:hover {
    color: ${Colors.orangeBright};
  }

  @media screen and (max-width: 767px) {
    text-align: left;
    border-bottom: 1px solid #333;
    padding: 6px;
    font-size: 1.5rem;
  }
`;
