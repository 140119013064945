import styled from "styled-components";

export const VideoContainer = styled.div`
  width: 100%;
  margin: auto;
  text-align: left;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    border: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .thumbnailVideoPlayer {
    cursor: pointer;
    z-index: 9996;
    position: absolute;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      width: 100%;
    }
    .containerButtonPlay {
      position: absolute;
      top: calc(50% - 50px);
      left: calc(50% - 50px);
      .backgroundShadow {
        position: absolute;
        top: 36%;
        left: 47%;
        width: 10px;
        height: 10px;
        box-shadow: 0px 0px 100px 40px #000;
      }
      .play-icon {
        position: relative;
      }
      .textCTA {
        position: relative;
        color: #fff;
        text-align: center;
        font-weight: bold;
        text-shadow: 0px 0px 5px #000;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: initial;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
`;
