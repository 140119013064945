import React, { useCallback, useRef } from "react";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";

import { getCurrentFrontEndEnvironmentUrl } from "../../../store/utils/helpers";
import { getKey } from "../../utils/helpers";

const FRONT_DOMAIN =
  getCurrentFrontEndEnvironmentUrl() || "https://www.rtvcplay.co";
const BASE_PROD_FRONT_DOMAIN = "https://www.rtvcplay.co"; // this should be the same that: default.frontent.environments.production

const defaultTitle = "RTVCPlay";
const defaultDescription =
  "Somos la opción de entretenimiento Online de RTVC donde puedes disfrutar de forma gratuita una gran variedad de contenidos de video y audio, cuando, como y donde quieras.";
const defaultTwitter = "@rtvcplay";
const defaultSep = " | ";

const Page = ({ children, id, className, meta_tags, location, ...rest }) => {
  const { title, description } = rest;
  const theTitle = useRef(title
      ? (title + defaultSep + defaultTitle).substring(0, 60)
      : defaultTitle);
  const theDescription = description
    ? description.substring(0, 155)
    : defaultDescription;

  const getMetaTagsFromCMS = useCallback(meta_tags => {
    let metaTags = [];
    for (let key in meta_tags) {
      if (meta_tags.hasOwnProperty(key)) {
        metaTags.push(meta_tags[key]["#attributes"]);
      }
    }
    metaTags.push({
      property: "og:url",
      content: FRONT_DOMAIN + location.pathname,
    });
    return metaTags;
  });

  const getSchemaTag = useCallback(meta_tags => {
    if (meta_tags) {
      const schemaData = {
        "@context": "https://schema.org/",
      };
      for (const [key] of Object.entries(meta_tags)) {
        if (new RegExp(/^schema_/i).test(key)) {
          schemaData[meta_tags[key]["#attributes"]["name"]] =
            meta_tags[key]["#attributes"]["content"];
        }
      }
      return (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
      );
    }
  });

  const getMetaTags = useCallback(
    (
      {
        title,
        description,
        contentType,
        twitter,
        noCrawl,
        published,
        updated,
        category,
        tags,
      },
      pathname,
    ) => {
      const isProduction = FRONT_DOMAIN === BASE_PROD_FRONT_DOMAIN;

      const metaTags = [
        { name: "description", content: theDescription },
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:site", content: defaultTwitter },
        { name: "twitter:title", content: theTitle.current },
        { name: "twitter:description", content: theDescription },
        { name: "twitter:creator", content: twitter || defaultTwitter },
        { name: "apple-itunes-app", content: "app-id=1478188466" },
        { name: "google-play-app", content: "app-id=co.rtvcplay" },
        { rel: " apple-touch-icon", href: "icon.png" },
        { rel: " android-touch-icon", href: "icon.png" },
        { property: "og:title", content: theTitle.current },
        { property: "og:type", content: contentType || "website" },
        { property: "og:url", content: FRONT_DOMAIN + pathname },
        { property: "og:description", content: theDescription },
        { property: "og:site_name", content: defaultTitle },
      ];

      if (published) {
        metaTags.push({ name: "article:published_time", content: published });
      }
      if (updated) {
        metaTags.push({ name: "article:modified_time", content: updated });
      }
      if (category) {
        metaTags.push({ name: "article:section", content: category });
      }
      if (tags) {
        metaTags.push({ name: "article:tag", content: tags });
      }

      if (!isProduction) {
        metaTags.push({
          name: "robots",
          content: "noindex, nofollow, nosnippet",
        });
      }

      return metaTags;
    },
  );
  const meta_tags_computed =
    meta_tags !== undefined
      ? getMetaTagsFromCMS(meta_tags)
      : getMetaTags(rest, location.pathname);

  const metaTagTitle = meta_tags_computed.find(meta => meta.name === "title")
  theTitle.current = metaTagTitle ? metaTagTitle.content : theTitle.current;
  return (
    <>
      <Helmet
        link={[
          {
            rel: "canonical",
            href: FRONT_DOMAIN + location.pathname,
          },
        ]}
        title={theTitle.current}
        meta={meta_tags_computed}
        script={[
          {
            src: "https://static.chartbeat.com/js/chartbeat_mab.js",
            type: "text/javascript",
          },
        ]}
      />
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            (function() {
              /** CONFIGURATION START **/
              var _sf_async_config = window._sf_async_config = (window._sf_async_config || {});
              _sf_async_config.uid = ${getKey(rest, "uid", 0)};
              _sf_async_config.domain = '${FRONT_DOMAIN.replace(
                "https://",
                "",
              )}';
              _sf_async_config.flickerControl = false;
              _sf_async_config.useCanonical = true;
              _sf_async_config.useCanonicalDomain = true;
              _sf_async_config.sections = '${getKey(
                rest,
                "category.value",
                getKey(rest, "title", ""),
              )}';
              _sf_async_config.authors = 'RTVCPlay';
              _sf_async_config.autoDetect = false;
              /** CONFIGURATION END **/
              function loadChartbeat() {
                  var e = document.createElement('script');
                  var n = document.getElementsByTagName('script')[0];
                  e.type = 'text/javascript';
                  e.async = true;
                  e.src = '//static.chartbeat.com/js/chartbeat.js';
                  n.parentNode.insertBefore(e, n);
              }
              loadChartbeat();
             })();
            `,
        }}
      />
      {getSchemaTag(meta_tags)}
      {children}
    </>
  );
};

export default withRouter(Page);
