import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Page from "../";
import "./notFound.scss";
import { Wp } from "../../../theme";
import { getKey } from "../../../utils/helpers";
import { getEnvContent } from "../../../../store/utils/helpers";

const Container = styled(Wp.Container)``;
const PageWrapper = styled(Wp.Page)``;

const NotFound = ({ currentContent }) => {
  const { error, text } = currentContent || {};
  return (
    <Page
      id="notFound"
      title="Página no encontrada"
      description="Página no encontrada."
      noCrawl
    >
      <Container>
        <PageWrapper>
          <div className="contentError">
            <ul>
              <li className="errorCode">
                <img
                  src="https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/assets/404.jpg"
                  title="Error 404"
                  alt="Error 404"
                />
              </li>
              <li className="message">
                <div>
                  {text ? (
                    <h1>{text}</h1>
                  ) : (
                    <div>
                      <h1>
                        Ups…
                        <br />
                        <span className="unnderline">no encontramos</span> esta
                        ruta,
                      </h1>
                      <h3>
                        pero tu entretenimiento <b>no puede tener pausa.</b>
                      </h3>
                    </div>
                  )}
                  <a className="backToHomeButton" href="/">
                    Vuelve al inicio
                  </a>
                </div>
              </li>
            </ul>
            {error && getEnvContent() !== "production" && (
              <pre>
                {getKey(error, "error.stack", getKey(error, "error.message"))}
              </pre>
            )}
          </div>
        </PageWrapper>
      </Container>
    </Page>
  );
};
NotFound.propTypes = {
  error: PropTypes.object,
  text: PropTypes.string,
};
export default NotFound;
