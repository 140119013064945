import React from "react";
import styled from "styled-components";
import { Colors, Metrics } from "../../theme";

/**
 * SideBar Internal Platforms component
 *
 * @param {object} props - component props
 * @returns {JSX.Element}
 */
export const SideBarExternalPlatforms = ({ url_external_podcasts }) => {
  const imagePlatform = {
    apple:
      "https://rtvcplay-v2.s3-accelerate.amazonaws.com/s3fs-public/2023-04/Apple-Podcasts.png",
    deezer:
      "https://rtvcplay-v2.s3-accelerate.amazonaws.com/s3fs-public/2023-04/deezer.png",
    google:
      "https://rtvcplay-v2.s3-accelerate.amazonaws.com/s3fs-public/2023-04/Google-Podcasts.png",
    spotify:
      "https://rtvcplay-v2.s3-accelerate.amazonaws.com/s3fs-public/2023-04/Spotify.png",
    radionacional:
      "https://rtvcplay-v2.s3-accelerate.amazonaws.com/s3fs-public/2021-03/Escuchar-RadioNacional.png",
    radionica:
      "https://rtvcplay-v2.s3-accelerate.amazonaws.com/s3fs-public/2021-03/Escuchar-Radionica.png",
  };
  const isInternal = platform => {
    return (
      platform === "brand" ||
      platform === "radionacional" ||
      platform === "radionica"
    );
  };

  const getExternalPlatforms = () => {
    if (url_external_podcasts) {
      const list = [];
      for (const [platform, url] of Object.entries(url_external_podcasts)) {
        if (isInternal(platform) || url === null) continue;
        list.push({
          platform,
          url,
          image: imagePlatform[platform],
        });
      }
      return list;
    }
    return [];
  };

  const externalPlatforms = getExternalPlatforms();
  return externalPlatforms.length === 0 ? null : (
    <Wrapper>
      <Title>Disponible en:</Title>
      <List>
        {externalPlatforms.map(({ url, image }, indx) => (
          <Item key={indx}>
            <Link
              href={`${url.startsWith("http") ? "" : "https://"}${url}`}
              target="_blank"
            >
              <Image src={image} />
            </Link>
          </Item>
        ))}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media screen and (min-width: ${Metrics.size.tablet}) {
  }
`;

const Title = styled.h3`
  margin: 10px 0;
  padding: 0;
  font-size: 22px;
  font-weight: 400;
  color: ${Colors.white};
  font-family: "Dosis", serif;
`;

const List = styled.div`
  margin: 20px 0 0;
  padding: 0;
  display: flex;
  width: 100%;
  gap: 15px;
`;

const Item = styled.div`
  vertical-align: top;

  @media screen and (min-width: ${Metrics.size.tablet}) {
  }
`;

const Link = styled.a`
  display: block;
  text-decoration: none;
  text-align: center;
`;

const Image = styled.img`
  width: 100%;
  border: 0;
  max-width: 40px;

  @media screen and (min-width: ${Metrics.size.tablet}) {
  }
`;
