import React from "react";
import OrOpeningTheatersNovelsWidget from "./PodcastCategoryPromoteItem";
import { Wrapper } from "./PodcastCategoryPromote";

export default function OrPodcastCategoryPromote({ contents }) {
  return (
    <Wrapper>
      {contents.map((content, index) => (
        <OrOpeningTheatersNovelsWidget
          key={`podcastCategory${index}`}
          {...content}
        />
      ))}
    </Wrapper>
  );
}
