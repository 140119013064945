import React from "react";
import PodcastOpening from "../../../../components/podcast/PodcastOpening";

import styled from "styled-components";
import { Wp } from "../../../../theme";
import { PodcastList } from "../../../../components/podcast/PodcastList";
import { SideBar } from "../../../../components/podcast/SideBar";
import { AudioPageProvider } from "../../../../contexts/AudioPageContext";
import { getKey } from "../../../../utils/helpers";

/**
 * Podcast Page Layout
 *
 * @param {object} props - page property
 * @returns {JSX.Element}
 */
function PodcastPage({
  id,
  audiosBySeason,
  seasonsPresent,
  slug,
  url_external_podcasts,
  geoblocking,
  location,
  ...rest
}) {
  const {
    taxonomy,
    title,
    resource: {
      image: {
        cover_podcast: { path: coverPodcast },
      },
    },
    infoAdditional,
  } = rest;

  return (
    <AudioPageProvider
      coverPodcast={coverPodcast}
      title={title}
      infoAdditional={infoAdditional}
      brand={getKey(
        taxonomy.find(({ vid }) => vid === "brands"),
        "value",
      )}
    >
      <WrapperBlur background={coverPodcast}>
        <InnerWrapper>
          <LeftContainer>
            <PodcastOpening {...rest} id={id} slug={slug} />
            <PodcastList
              audiosBySeason={audiosBySeason}
              coverPodcast={coverPodcast}
              seasonsPresent={seasonsPresent}
              geoblocking={geoblocking}
              taxonomy={taxonomy}
              title={title}
              location={location}
            />
          </LeftContainer>
          <RightContainer>
            <SideBar
              coverPodcast={coverPodcast}
              url_external_podcasts={url_external_podcasts}
            />
          </RightContainer>
        </InnerWrapper>
      </WrapperBlur>
    </AudioPageProvider>
  );
}

export const WrapperBlur = styled(Wp.Container)`
  background-image: url(${props => props.background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(100px); /* Adjust the blur value as desired */
    background-color: rgba(
      0,
      0,
      0,
      0.8
    ); /* Color to blend with the background */
    z-index: 0;
  }
`;
const InnerWrapper = styled(Wp.Page)`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const LeftContainer = styled.div`
  width: 100%;
  z-index: 1;
  @media screen and (min-width: 768px) {
    width: 75%;
  }
`;

const RightContainer = styled.div`
  position: relative;
  top: 120px;
  right: 0;
  width: 25%;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export default PodcastPage;
