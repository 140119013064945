import styled from "styled-components";
import AtIcon from "../../AtIcon/AtIcon";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border: 1px solid #737373;
  border-radius: 40px;
  cursor: pointer;
`;

export const LabelSelected = styled.div`
  width: 100%;
  position: relative;
  color: #ffffff;
  font-family: "Dosis", sans-serif;
  font-size: 1.25em;
  font-weight: 300;
  display: block;
  padding: 16px 25px;
  line-height: 1.25rem;
  z-index: 1;
`;
export const Select = styled.ul`
  position: absolute;
  border: 0;
  z-index: 1;
  appearance: none;
  background: transparent;
  outline: none;
  margin: 0;
  list-style: none;
  color: #ffffff;
  font-family: "Dosis", sans-serif;
  font-size: 1.25em;
  font-weight: 300;
  padding: 0;
  overflow: hidden;
  width: 90%;
  left: -19000px;
  box-shadow: 0 0 10px 0 #000;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &.active {
    left: 19px;
    opacity: 1;
  }
`;
export const Option = styled.li`
  width: 100%;
  border: 0;
  padding: 16px 25px;
  background: rgba(0, 0, 0, 0.9);
  border-bottom: 1px solid #222;
  font-weight: 600;
  &:hover {
    background: rgba(255, 255, 255, 0.9);
    color: black;
  }
`;
export const Arrow = styled(AtIcon)`
  position: absolute;
  right: 30px;
  top: calc(50% - 11px);
  z-index: 0;
`;
