export default {
  white: "#ffffff",
  snow: "#fffefe",
  black: "#000000",
  uglyblue: "#296b81",
  cerulean: "#009bdb",
  sickgreen: "#b9a12d",
  greyblue: "#69b7ce",
  deeprose: "#c74c5a",
  pastelpink: "#fec2e4",
  greybluetwo: "#79abb1",
  darkbluegrey: "#362458",
  darkgreyblue: "#402c64",
  darkGray: "#AEAEAE",
  greyishbrown: "#545454",
  bunker: "#424242",
  redlightup: "#F5274B",
  redlightborder: "#A41029",
  redlightshadow: "#FF3D5F",
  redlightdown: "#EC294B",
  onyx: "#131313",
  orange: "#ec9900",
  orangeBright: "#EA8526",
  gray: "#b4b4b4",
  livered: "#cd2947",
  transparent: "rgba(0,0,0,0)",
  bg1: "#222222", //Header bg
  bg2: "#212121", //Slider bg
  bg3: "#1c1c11", //Body bg
  bg4: "#2b2a2a", //Footer bg
  rating: "#4c86c1",
  rtvc: "#222222",
  ci: "#cc9c3c",
  sc: "#490db6",
  rn: "#ca721b",
  rk: "#c04e90",
  sm: "#85bb52",
  rtvcplay: "#68bcc9",
  ninosBackgroundCover: "#2c6e84",
  ninosBackgroundPoster: "#79abb1",
  tuatara: "#434343",
  alto: "#C8C8C8",
  educacionBackground: "#BE1242",
  cyan: "#62EBDC",
  originales: "#1c1c1c",
  bntCerrar: "#02d5ce",
  tituloWidget: "#00EAFF",
  goldenPoppy: "#f3d333",
  red: "#FF0000",
  pacificBlue: "#003cc5",
  celticBlue: "#3366cc",
  nobel: "#949393",
  raljah: "#FCA95C",
  orangeOpaque: "#D67B26",
  orangeDark: "#95741a",
  fedora: "#555455",
  dimGray: "#707070",
};

// RTVC #222222
// Señal Colombia #490db6
// Canal Institucional #cc9c3c
// Radio Nacional #ca721b
// Radiónica #c04e90
// Señal Memoria #85bb52
// RTVCPlay #000000
