import styled from "styled-components";
import AtField from "../AtField";
import { Colors } from "../../theme";

export const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 9999;
`;

export const PopupWrapper = styled.div`
  position: relative;
  width: 90%;
  color: #fff;
  background: #282828;
  border: 0.59px solid #fff;
  padding: 35px 10px;
  margin: 15% auto 0;
  font-family: "NexaBold", "Dosis", serif;
  font-display: fallback;
  @media screen and (min-width: 768px) {
    max-width: 830px;
    padding: 35px 25px;
  }
`;
export const PreTitleLabel = styled.label`
  display: block;
  font-family: "NexaBold", "Dosis", serif;
  font-display: fallback;
  font-size: 13.8px;
  margin: 0;
  padding: 0;
  @media screen and (min-width: 768px) {
    font-size: 24.8px;
  }
`;
export const TitleLabel = styled.label`
  display: block;
  font-family: "NexaLight", "Dosis", serif;
  font-display: fallback;
  font-size: 37.69px;
  margin: 0 0 0 -2px;
  padding: 0;
  @media screen and (min-width: 768px) {
    font-size: 65.69px;
  }
`;
export const SubtitleLabel = styled.label`
  display: block;
  font-family: "NexaLight", "Dosis", serif;
  font-display: fallback;
  font-size: 10.8px;
  line-height: 21.71px;
  margin: 0;
  padding: 0;
  @media screen and (min-width: 768px) {
    font-size: 18.42px;
  }
`;

export const Image = styled.img`
  position: absolute;
  right: 0;
  top: -57px;
  width: 147px;
  @media screen and (min-width: 768px) {
    top: -120px;
    right: -30px;
    width: 301px;
    max-height: 373px;
  }
`;

export const HeadWrapper = styled.div`
  position: relative;
  width: 100%;
  @media screen and (min-width: 768px) {
  }
`;
export const FormWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 30px 0 0;
  z-index: 999;
  @media screen and (min-width: 768px) {
  }
`;

export const DiscoverLabel = styled.label`
  color: #adadad;
  display: block;
  font-family: "NexaLight", "Dosis", serif;
  font-display: fallback;
  font-size: 11px;
  margin: 0 0 10px;
  padding: 0;
  max-width: 50%;

  @media screen and (min-width: 768px) {
    font-size: 16px;
    max-width: 100%;
  }
`;
export const Form = styled.form`
  position: relative;
  display: block;

  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

export const Field = styled(AtField)`
  font-family: "NexaLight", sans-serif;
  font-display: fallback;
  font-size: 18.42px;
  color: #181818;

  input {
    background-color: #7c7c7c;
    border: 0.59px solid #fff;
    max-height: 67px;
    padding: 14px;
    color: white;
    font-weight: bold;
    font-size: 15px;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #282828;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #282828;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #282828;
    }

    @media screen and (min-width: 768px) {
      padding: 23px;
      font-size: 1rem;
    }
  }

  &.hasErrors,
  &.hasErrors:invalid {
    border-color: red;
  }
`;

export const Button = styled.button`
  background: #00ffff;
  color: #202020;
  border: none;
  font-size: 17px;
  font-family: "NexaLight", sans-serif;
  font-display: fallback;
  padding: 0;
  margin: 7px 0;
  width: 100%;
  height: 40px;
  outline: none;
  cursor: pointer;

  &:hover {
    background: ${Colors.uglyblue};
  }
  &[disabled] {
    background: #ccc;
    color: #888;
    box-shadow: inset 0px 0px 5px 0px #000;
    cursor: progress;
  }

  @media screen and (min-width: 768px) {
    max-width: 275px;
    margin: 0 0 0 7px;
    height: 67px;
    font-size: 28px;
  }
`;

export const PrivacyWrapper = styled.div`
  display: flex;
  color: #ffff;
  font-family: "NexaLight", "Dosis", serif;
  font-display: fallback;
  font-size: 12px;
  line-height: 16px;
  justify-content: space-between;

  a {
    color: #ffff;
    text-decoration: underline;
    cursor: pointer;
  }
  @media screen and (min-width: 768px) {
    font-size: 12px;
  }
`;

export const PrivacyLabel = styled.div`
  margin: 20px 0 0;
  padding: 0;
  @media screen and (max-width: 767px) {
    font-size: 10px;
    a {
      font-size: 10px;
    }
  }
`;
export const NotInterestedLabel = styled.div`
  margin: 20px 0 0;
  padding: 0;
  @media screen and (max-width: 767px) {
    font-size: 10px;
    a {
      font-size: 10px;
    }
  }
`;

export const AlertMessage = styled.div`
  font-size: 11px;
  font-family: "NexaLight", sans-serif;
  font-display: fallback;
  position: absolute;
  margin-top: 5px;
  color: orange;
  text-transform: uppercase;
  font-weight: bold;
  max-width: 50%;

  @media screen and (min-width: 768px) {
    font-size: 15px;
    max-width: 100%;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: -45px;
  right: 0;
  border: 2px solid ${Colors.white};
  border-radius: 50%;
  padding: 4px;
  z-index: 1;
  cursor: pointer;
`;
