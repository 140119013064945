import React, { useCallback, useEffect, useState } from "react";

import {
  AlertMessage,
  PreTitleLabel,
  TitleLabel,
  Wrapper,
  SubtitleLabel,
  Image,
  PopupWrapper,
  HeadWrapper,
  FormWrapper,
  DiscoverLabel,
  Form,
  Field,
  Button,
  PrivacyWrapper,
  PrivacyLabel,
  NotInterestedLabel,
  CloseButton
} from "./Newsletter";
import { addNewsletter } from "../../../store/actions/auth";
import {
  addNewsletterCookie,
  hasNewsletterCookie
} from "../../utils/helpers/cookies";
import AtIcon from "../AtIcon/AtIcon";
import { getKey, hasKey } from "../../utils/helpers";

export default function OrNewsletter({
  category,
  id,
  image,
  label_field,
  subtitle,
  title,
  title_big
}) {
  const [isLoading, setLoading] = useState(false);
  const [close, setClose] = useState(true);
  const [message, setMessage] = useState(null);
  const CAMPAIGN = `newsLetterCampign${id}`;

  useEffect(
    _ => {
      const hasNewsletter = hasNewsletterCookie(CAMPAIGN);
      setClose(hasNewsletter);
    },
    [setClose]
  );

  const closePopup = useCallback(
    _ => {
      setClose(true);
    },
    [setClose]
  );
  const setNoneAndClose = useCallback(
    _ => {
      addNewsletterCookie(CAMPAIGN, "no-email");
      setClose(true);
    },
    [setClose]
  );

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      if (!event.target.checkValidity()) {
        setMessage("Debe ingresar un correo electrónico válido");
        return;
      }
      const emailAddress = event.target.field_e.value;
      if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(emailAddress)) {
        setMessage(null);
        setLoading(true);
        const result = await addNewsletter(
          emailAddress,
          id,
          category,
          CAMPAIGN
        );
        if (result.status === 201) {
          closePopup();
        }
        setMessage(result.message);
      }
      setLoading(false);
    },
    [isLoading, setLoading, message, setMessage, closePopup]
  );
  return (
    !close && (
      <Wrapper>
        <PopupWrapper>
          <CloseButton onClick={setNoneAndClose}>
            <AtIcon name="Close" size={22} />
          </CloseButton>
          <HeadWrapper>
            <PreTitleLabel>{title}</PreTitleLabel>
            <TitleLabel>{title_big}</TitleLabel>
            <SubtitleLabel>{subtitle}</SubtitleLabel>
            {message && <AlertMessage>{message}</AlertMessage>}
            {hasKey(image, "image.path") && (
              <Image src={getKey(image, "image.path")} />
            )}
          </HeadWrapper>
          <FormWrapper>
            <DiscoverLabel>{label_field}</DiscoverLabel>
            <Form onSubmit={handleSubmit} noValidate>
              <Field
                name="e"
                type="email"
                disabled={isLoading && "disabled"}
                required
                placeholder="TÚ DIRECCIÓN DE CORREO"
              />
              <Button disabled={isLoading && "disabled"}>ENVIAR</Button>
            </Form>
            <PrivacyWrapper>
              <PrivacyLabel>
                CONSULTA NUESTRA{" "}
                <a
                  href="https://www.rtvcplay.co/terminos-y-condiciones"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  POLÍTICA DE PRIVACIDAD.
                </a>
              </PrivacyLabel>
              <NotInterestedLabel>
                <a href={_ => {}} onClick={setNoneAndClose}>
                  NO, GRACIAS. EN OTRA OCASIÓN.
                </a>
              </NotInterestedLabel>
            </PrivacyWrapper>
          </FormWrapper>
        </PopupWrapper>
      </Wrapper>
    )
  );
}
