import styled from "styled-components";

import { Colors, Metrics, Wp } from "../../../theme";
import AtBadge from "../../AtBadge/AtBadge";
import AtIcon from "../../AtIcon/AtIcon";
import { LongDescriptionWrapper } from "../../../layouts/widgets/Opening/CommingSoon/CommingSoon";
import MolPlayer from "../../MolPlayer/MolPlayer";

export const Wrapper = styled.div`
  position: relative;
  min-width: 750px;
  width: 750px;
  text-align: right;
  margin-right: 20px;
  @media screen and (max-width: 767px) {
    width: 100%;
    display: inline-block;
    margin-right: 0;
    margin-bottom: 20px;
    min-width: unset;
  }
  .PauseBorder {
    border: 2px solid ${Colors.white};
    border-radius: 50%;
  }
`;
export const ExpanedView = styled.div`
  position: relative;
`;
export const CollapseView = styled.div`
  @media screen and (min-width: 768px) {
    opacity: 0.5;
  }
`;

export const Label = styled(AtBadge)`
  position: absolute;
  right: 20px;
  top: 20px;
  @media screen and (max-width: 767px) {
    display: none !important;
  }
`;

export const ImageCover = styled.img`
  display: block;
  width: 100%;
  min-height: 421px;
  @media screen and (min-width: 768px) {
  }
`;
export const ContentImageCover = styled.div`
  display: block;
  width: 100%;
  min-height: 421px;
  overflow: hidden;
  position: relative;

  & > img {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  @media screen and (min-width: 768px) {
  }
`;

export const MetadataContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  margin-top: -197px;
  display: flex;
  text-align: left;
  z-index: 2;
  @media screen and (max-width: 767px) {
    margin-top: -170px;
  }
`;

export const ImagePoster = styled.img`
  border: 1px solid #2c6d88;
  max-height: 256px;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const PlayerContainer = styled.div`
  display: block;
  width: 85%;
  @media screen and (min-width: 768px) {
    width: 68%;
    border-left: 1px solid white;
    margin-left: 10px;
    padding-left: 20px;
  }
`;

export const ProductionTitle = styled.h3`
  font-size: 25px;
  font-family: "BebasNeue Regular", sans-serif;
  color: white;
  line-height: 1.2;
  text-align: left;
  font-weight: 400;
  margin: 0;
  padding: 0;

  max-height: 86px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  @media screen and (min-width: 768px) {
    font-size: 35.99px;
  }
`;

export const Footer = styled.div`
  display: block;
  width: 100%;
  position: relative;
  text-align: left;
  margin: 35px 35px 25px;
  @media screen and (max-width: 767px) {
    margin: 18px;
  }
`;

export const ListenNowContainer = styled(Wp.Container)`
  margin-top: 15px;
  width: auto;
  display: inline-flex;
  color: #ffffff;
  a {
    color: #ffffff;
  }
  span {
    margin-left: 5px;
    font-size: 1em;
    font-weight: 600;
    margin-top: 5px;
  }
  margin-right: 20px;
`;

export const LitlePlayIcon = styled(AtIcon)`
  opacity: 1;
`;

export const Link = styled.a`
  display: inline-block;
  text-decoration: none;
  width: 100%;
`;

export const DescriptionWrapper = styled(LongDescriptionWrapper)`
  margin-bottom: 20px;
  height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  @media screen and (max-width: 767px) {
    display: -webkit-box;
    max-width: 99%;
  }
`;

export const Category = styled.span`
  color: #ffffff;
  font-family: "Dosis", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  display: inline-flex;
  margin-bottom: 10px;
`;

export const RatingContainer = styled.span`
  color: #ffffff;
  font-family: "Dosis", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  display: inline-flex;
  line-height: 1.9375em;
`;

export const MetadataInnerContainer = styled.div`
  margin-left: 20px;
  max-height: 295px;
  max-width: 410px;
  width: 100%;
  height: 100%;
`;
export const BackGradient = styled.div`
  position: absolute;
  height: 220px;
  width: 100%;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
  bottom: 61px;
  right: 0;
  z-index: 2;

  @media screen and (max-width: 767px) {
    bottom: 42px;
  }
`;

export const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  gap: 20px;
`;

export const ImagePosterInactive = styled.img`
  width: 100%;
`;
export const ContainerReflection = styled.div`
  position: absolute;
  top: 98%;
  left: 0;
  height: 55px;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    height: 30px;
    display: none;
  }
`;
export const LayerGradientReflection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, transparent, #222222);
  z-index: 1;
`;
export const ImagePosterInactiveReflected = styled.img`
  width: 100%;
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
`;

export const Player = styled(MolPlayer)`
  padding-top: ${100 / (16 / 9)}%;
  background: rgba(0, 0, 0, 0.7);
  @media (max-width: ${Metrics.size.tabletL}) {
    padding-top: 125%;
  }
`;
