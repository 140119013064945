import React, { Component } from "react";
import styled from "styled-components";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";

import * as pageType from "../../../constants/pageType";
import pageLoader from "../loader";

import { getUniqKey } from "../../../utils/helpers";
import Page from "../";

import { getSearchContent } from "../../../../store/actions/content";
import widgetLoader from "../../widgets";
import { Wp, Metrics } from "../../../theme";

const Container = styled(Wp.Container)`
  margin-top: ${Metrics.size.minHeaderHeight};
  @media ${Metrics.mediaQ.tablet} {
    margin-top: ${Metrics.size.maxHeaderHeight};
  }
`;

const frontload = async props => {
  return props.getSearchContent(
    props.match.params.term,
    props.match.params.page
  );
};

class Search extends Component {
  render() {
    const { searchContent } = this.props;
    const NotFoundPage = pageLoader[pageType.NOTFOUND];
    return (
      <Page id="search" title={`Búsqueda: ${searchContent.q}`}>
        <Container>
          {searchContent.widgets ? (
            searchContent.widgets.map((widget, idx) => {
              let LoadableWidgetComponent = widgetLoader[widget.type];
              return (
                <LoadableWidgetComponent
                  key={getUniqKey(idx)}
                  {...widget}
                  searchContent={searchContent}
                />
              );
            })
          ) : (
            <NotFoundPage
              currentContent={{
                text: `No se encontraron resultados para la búsqueda: ${
                  searchContent.q
                }`
              }}
            />
          )}
        </Container>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  searchContent: state.content.searchContent
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getSearchContent }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    noServerRender: false,
    onMount: true,
    onUpdate: false
  })(Search)
);
