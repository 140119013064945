import React from "react";
import Swiper from "react-id-swiper";
import "react-id-swiper/src/styles/scss/swiper.scss";

import {
  InnerWrapper,
  PromoteListContainer,
  TitleRelated
} from "./PromoteList";
import PromoteListItem from "../PromoteListItem";
import { IconButton } from "../../OrPodcastWidget/PodcastWidget";
import AtIcon from "../../AtIcon/AtIcon";

export default function PromoteList({
  contents,
  title_related_content,
  streaming_provider
}) {
  const params = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    renderPrevButton: () => (
      <IconButton className="swiper-button-prev">
        <AtIcon className="icon" name="KeyboardArrowLeft" size={25} />
      </IconButton>
    ),
    renderNextButton: () => (
      <IconButton className="swiper-button-next">
        <AtIcon className="icon" name="KeyboardArrowRight" size={25} />
      </IconButton>
    ),
    breakpoints: {
      3000: {
        slidesPerView: 1.4,
        spaceBetween: 0,
        allowTouchMove: false,
        direction: "horizontal",
        watchOverflow: true
      },
      767: {
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true
      }
    }
  };
  return (
    <PromoteListContainer>
      <TitleRelated title={title_related_content} animateTitle />
      <InnerWrapper>
        <Swiper {...params}>
          {contents.map((content, indx) => (
            <div key={`promoteListItem${indx}`}>
              <PromoteListItem
                {...content}
                streaming_provider={streaming_provider}
              />
            </div>
          ))}
        </Swiper>
      </InnerWrapper>
    </PromoteListContainer>
  );
}
