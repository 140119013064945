import styled, { css } from "styled-components";
import { Colors } from "../../theme";

export const Title = styled.h3`
  font-size: 25px;
  font-family: "BebasNeue Regular", sans-serif;
  color: white;
  line-height: 1.2;
  font-weight: 400;
  margin: 0;
  padding: 0;

  max-height: 86px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  ${({ isWidgetTitle }) =>
    isWidgetTitle &&
    css`
      color: #00e9fe;
      font-weight: normal;
      font-size: 30px;

      @media screen and (min-width: 768px) {
        font-size: 42px;
      }
    `} @media screen and (min-width: 768px) {
    font-size: 35.99px;
    margin-bottom: 5px;
  }
`;

export const TitleWidget = styled.h3`
  font-size: 25px;
  font-family: "BebasNeue Regular", sans-serif;
  color: ${Colors.tituloWidget};
  line-height: 1.2;
  font-weight: bold;
  margin: 0;
  padding: 0;

  max-height: 86px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  @media screen and (min-width: 768px) {
    font-size: 35.99px;
    margin-bottom: 5px;
  }
`;
