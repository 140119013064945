import React, { Component } from "react";
import styled from "styled-components";

import { Colors } from "../../theme";

class AtBadge extends Component {
  static defaultProps = {
    children: "Lorem Ipsum"
  };
  render() {
    const { color, className, children, href, fontSize, el } = this.props;

    const Badge = styled[el || "span"]`
      &.brand-rtvc {
        background-color: ${Colors.rtvc};
      }
      &.brand-ci {
        background-color: ${Colors.ci};
      }
      &.brand-sc {
        background-color: ${Colors.sc};
      }
      &.brand-rn {
        background-color: ${Colors.rn};
      }
      &.brand-rk {
        background-color: ${Colors.rk};
      }
      &.brand-sm {
        background-color: ${Colors.sm};
      }
      &.brand-rtvcplay {
        background-color: ${Colors.rtvcplay};
      }
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: "BebasNeue Regular", serif;
      line-height: 27px;
      font-size: ${props => props.fontSize || "22px"};
      font-weight: 400;
      padding: 0.25em 0.5em;
      margin: 0;
      text-decoration: none;
      text-transform: uppercase;
      color: ${Colors.white};
      background-color: ${props => props.color || Colors.cerulean};
    `;
    return (
      <Badge
        color={color}
        href={href}
        className={className}
        fontSize={fontSize}
      >
        {children}
      </Badge>
    );
  }
}

export default AtBadge;
