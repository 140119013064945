import styled from "styled-components";
import AtBadge from "../../AtBadge/AtBadge";
import { ListenNowContainer } from "../../OrPodcastWidget/PodcastMainItem/PodcastMainItem";
import { LongDescriptionWrapper } from "../../../layouts/widgets/Opening/CommingSoon/CommingSoon";
import Colors from "../../../theme/Colors";
import MolPlayer from "../../MolPlayer/MolPlayer";
import { Metrics } from "../../../theme";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: black;

  @media screen and (min-width: 768px) {
    background-color: transparent;

    min-width: 610px;
    min-height: 343px;
    &:hover {
      .containerHover {
        ${props => props.initialAxixX}: 0;
      }
    }
  }
`;

export const Image = styled.img`
  max-width: 610px;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
export const Link = styled.a`
  position: relative;
  display: inline-block;
  text-decoration: none;
`;

export const Label = styled(AtBadge)`
  position: absolute;
  left: 20px;
  top: 20px;
  @media screen and (max-width: 767px) {
    display: none !important;
  }
`;

export const HoverLayer = styled.div`
  width: 100%;
  top: 0px;
  height: 99%;
  background: black;
  z-index: 9999;

  @media screen and (min-width: 768px) {
    position: absolute;
    display: flex;
    width: 954px;

    transition: ${props => props.initialAxixX} 1s ease-in-out;
    ${props => props.initialAxixX}: -200%;
    box-shadow: ${props => (props.initialAxixX === "left" ? "20px" : "-20px")}
      22px 30px -10px black;
  }

  h3 {
    font-size: 26px;
    line-height: 32px;
    font-family: "Bebas Neue", "BebasNeue Regular", sans-serif;
    color: white;
    text-align: left;
    font-weight: bold;
    margin: 0;
    padding: 0;

    max-height: 86px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    @media screen and (min-width: 768px) {
      font-size: 35.99px;
      margin-bottom: 5px;
    }
  }
`;

export const TrailerContainer = styled.div`
  display: inline-flex;
  margin: 0 auto 10px;
  width: 100%;

  @media screen and (min-width: 768px) {
    display: inline-flex;
    margin: 0 auto 10px;
    width: 64%;
  }
`;

export const Trailer = styled.div`
  width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 768px;
    margin-top: 0;
    margin-left: 0;
  }
`;

export const MetadataContainer = styled.div`
  position: relative;
  width: 85%;
  z-index: 1;
  align-items: center;
  padding: 0 0 10px 0;

  @media screen and (min-width: 768px) {
    display: inline-flex;
    width: 35%;
    margin: 0 auto;
  }
`;
export const MetadataInnerContainer = styled.div`
  max-height: 295px;
  max-width: 410px;
  width: 100%;
  height: 100%;
  margin: 0 25px;

  @media screen and (max-width: 767px) {
    margin: 0 10px 30px 34px;
  }
`;

export const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  gap: 10px;
`;

export const Duration = styled.span`
  color: ${Colors.white};
  font-family: "Dosis", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  display: inline-flex;
  line-height: 1.9375em;
`;

export const ListenNow = styled(ListenNowContainer)`
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
`;

export const DescriptionWrapper = styled(LongDescriptionWrapper)`
  height: 106px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  @media screen and (max-width: 767px) {
    display: inline-block;
  }
`;

export const ToggleSound = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 10px;
  z-index: 9999;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const Player = styled(MolPlayer)`
  padding-top: ${100 / (16 / 9)}%;
  background: rgba(0, 0, 0, 0.7);
  @media (max-width: ${Metrics.size.tabletL}) {
  }
`;
