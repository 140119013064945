import styled from "styled-components";
import { Colors, Fonts } from "../../../theme";
import { getKey } from "../../../utils/helpers";
import AtField from "../../AtField";

export const ContentLoginForm = styled.div`
  position: relative;
  max-width: 440px;
  background-color: ${Colors.bg1};
  padding: 30px;
`;

export const Title = styled(Fonts.h1)`
  color: ${Colors.cerulean};
`;
export const SubTitle = styled(Fonts.slideLabel)``;
export const FieldContainer = styled.div`
  user-select: none;
  margin-top: 5px;
  margin-bottom: 15px;
  text-align: ${props => getKey(props, "align", "inherits")};
`;

export const Field = styled(AtField)`
  &.hasErrors,
  &.hasErrors:invalid {
    border-color: red;
  }
`;

export const Button = styled.button`
  background: ${Colors.cerulean};
  color: ${Colors.white};
  border: none;
  font-size: 1.375em;
  font-family: "Dosis", sans-serif;
  font-display: fallback;
  line-height: 0.864;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 59px;
  outline: none;
  cursor: pointer;

  &:hover {
    background: ${Colors.uglyblue};
  }
  &[disabled] {
    background: #ccc;
    color: #888;
    box-shadow: inset 0px 0px 5px 0px #000;
    cursor: progress;
  }
`;
export const ButtonSignUp = styled.button`
  background: ${Colors.transparent};
  color: ${Colors.white};
  border: 1px solid #fff;
  font-size: 1.375em;
  font-family: "Dosis", sans-serif;
  line-height: 0.864;
  padding: 0;
  margin: 0;
  margin-top: 3px;
  width: 100%;
  height: 59px;
  outline: none;
  cursor: pointer;

  &:hover {
    background: ${Colors.black};
  }
`;

export const Link = styled(Fonts.a)`
  font-weight: 300;
  margin-bottom: 10px;
  float: right;
  font-size: 1rem;
`;

export const ModalContainer = styled.div`
  & > div > div > div:first-child {
    background-color: ${Colors.bg1} !important;
    color: ${Colors.white} !important;
    padding: 10px;
    border-radius: 0px !important;
    button {
      height: 50px;
      font-size: 17px;
    }
  }
`;
export const Separator = styled.hr`
  border: 1px solid ${Colors.orange};
  margin: 20px 0px 20px 0px;
`;
export const ErrorWrapper = styled.div`
  display: ${props => (props.hasErrors ? "flex" : "none")};
  margin-top: 5px;
  margin-bottom: 5px;
  svg {
    display: inline-flex;
  }
`;
export const TextError = styled.span`
  display: inline-flex;
  color: ${Colors.orange};
  font-size: 20px;
  width: 300px;
  margin-left: 10px;
`;

export const createAccountText = {
  fontSize: "1.125rem"
};

export const ContainerSocialButtons = styled.div`
  position: relative;
  display: flex;
  margin-top: 10px;
`;
