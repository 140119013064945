import styled from "styled-components";

export const AddMyListButton = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  a {
    margin: 0 auto;
  }
`;
