import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";

import {
  AudioElement,
  ProgressBar,
  TimeText,
  Selector,
  Value,
  Wrapper,
  WrapperButtons,
  WrapperProgressBar
} from "./AudioPlayerWidget";
import AtIcon from "../../AtIcon/AtIcon";

const AudioPlayerWidget = ({
  file,
  id,
  slugPodcast,
  hideControls = false,
  onTogglePlay,
  toggle = false
}) => {
  const audioElementRef = useRef(null);
  const currentTimeElementRef = useRef(null);
  const totalTimeElementRef = useRef(null);
  const [percentValue, setPercentValue] = useState(0);
  const [percent, setPercent] = useState("0%");
  const [playing, setPlaying] = useState(false);
  const [intervalId, setIntervalId] = useState(-1);

  useEffect(() => {
    global.window.mediaObject = {};
    global.window.mhbt = {};
    global.window.analyticsProvider = {};
    global.window.currentTime = {};
    global.window.mediaDelegate = {};
    setTimeout(() => {
      if (
        global.window.ADB !== undefined &&
        global.window.ADB.va !== undefined
      ) {
        global.window.MediaHeartbeat = global.window.ADB.va.MediaHeartbeat;
        global.window.MediaHeartbeatConfig =
          global.window.ADB.va.MediaHeartbeatConfig;
        global.window.MediaHeartbeatDelegate =
          global.window.ADB.va.MediaHeartbeatDelegate;
      }

      const audioElement = audioElementRef.current;
      if (audioElement !== undefined && audioElement !== null) {
        currentTimeElementRef.current.innerHTML = secondsToTime(
          audioElement.currentTime
        );
        totalTimeElementRef.current.innerHTML = secondsToTime(
          audioElement.duration
        );
      }
    }, 800);
  });

  useEffect(
    () => {
      onTogglePlay && onTogglePlay(playing);
    },
    [playing]
  );

  useEffect(
    () => {
      if (toggle !== playing) {
        togglePlay();
      }
    },
    [toggle]
  );

  function seek(percent) {
    if (percent === Infinity) return;
    const audioElement = audioElementRef.current;
    const duration = audioElement.duration;
    if (isNaN(duration)) {
      document.querySelectorAll("audio").forEach(element => {
        element.pause();
      });
      audioElement.play();
      setTimeout(() => seek(percent), 100);
      return;
    }
    const secondToSeek = (duration / 100) * percent;
    audioElement.pause();
    audioElement.currentTime = secondToSeek;
    if (playing) {
      document.querySelectorAll("audio").forEach(element => {
        element.pause();
      });
      audioElement.play();
    }
    setPercent(`${percent}%`);
    setPercentValue(Math.ceil(percent));
  }
  function handleStop(e, data) {
    e.preventDefault();
    e.stopPropagation();

    const valueToSubstrac = data.x;
    const currentWith = data.node.offsetParent.clientWidth;
    const totalWith = data.node.offsetParent.offsetParent.clientWidth;
    const newWidth = currentWith + valueToSubstrac;
    const newPercent = (newWidth / totalWith) * 100;

    seek(newPercent);
  }
  function togglePlay(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const audioElement = audioElementRef.current;
    if (audioElement === undefined || audioElement === null) {
      return;
    }

    const playerid = audioElement.currentSrc;
    if (!playing) {
      document.querySelectorAll("audio").forEach(element => {
        element.pause();
      });
      audioElement.play();
      global.window.mhbt[playerid] && global.window.mhbt[playerid].trackPlay();
      const inter = setInterval(handleTimeupdate, 1000);
      setIntervalId(inter);
    } else {
      audioElement.pause();
      clearInterval(intervalId);
      global.window.mhbt[playerid] && global.window.mhbt[playerid].trackPause();
    }
    setPlaying(!playing);
  }
  function handleTimeupdate() {
    const audioElement = audioElementRef.current;
    if (audioElement !== undefined && audioElement !== null) {
      global.window.currentTime[audioElement.currentSrc] =
        audioElement.currentTime;
    }
    currentTimeElementRef.current.innerHTML = secondsToTime(
      audioElement.currentTime
    );
    totalTimeElementRef.current.innerHTML = secondsToTime(
      audioElement.duration
    );

    setPercent(`${(audioElement.currentTime / audioElement.duration) * 100}%`);
    setPercentValue(prevState =>
      Math.ceil((audioElement.currentTime / audioElement.duration) * 100)
    );
  }
  function secondsToTime(secs) {
    if (isNaN(secs)) return "00:00";

    let totalSeconds = Math.ceil(secs);
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${hours === 0 ? "" : hours < 10 ? `0${hours}:` : hours}${
      minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}`;
  }
  function catchClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const { clientX } = e;
    let target = e.target;

    if (target.id === `progressValue${id}`) {
      target = target.parentNode;
    } else if (target.id !== `progress${id}`) {
      return;
    }

    const rect = target.getBoundingClientRect();
    const x = clientX - rect.left; //x position within the element.
    const width = rect.width;
    const percent = Math.ceil((x / width) * 100);
    seek(percent);
  }
  return (
    <Wrapper>
      <WrapperProgressBar>
        <AudioElement>
          <audio ref={audioElementRef} controls preload={"none"}>
            <source type="audio/mpeg" src={encodeURI(file)} />
            Your browser does not support the audio tag.
          </audio>
        </AudioElement>
        <TimeText ref={currentTimeElementRef}>--:--</TimeText>
        <ProgressBar id={`progress${id}`} onClick={catchClick}>
          <Value
            id={`progressValue${id}`}
            style={{
              width: percentValue <= 2 ? "2%" : percent
            }}
          >
            <Draggable
              axis="x"
              handle={`.selector-${id}`}
              position={{ x: 0, y: 0 }}
              onStop={(e, data) => handleStop(e, data)}
            >
              <Selector className={`selector-${id}`} />
            </Draggable>
          </Value>
        </ProgressBar>
        <TimeText ref={totalTimeElementRef}>--:--</TimeText>
      </WrapperProgressBar>
      {!hideControls && (
        <WrapperButtons>
          <AtIcon name="StepBackward" size={20} color="#343434" />
          <div onClick={togglePlay}>
            <AtIcon
              name={playing ? "Pause" : "PlayCircle"}
              fill={"none"}
              size={playing ? 36 : 40}
              className={playing ? "PauseBorder" : ""}
            />
          </div>
          <a href={slugPodcast}>
            <AtIcon name="StepForward" size={20} />
          </a>
        </WrapperButtons>
      )}
    </Wrapper>
  );
};

export default AudioPlayerWidget;
