import styled from "styled-components";
import { Wp } from "../../theme";

export const Wrapper = styled(Wp.Container)`
  margin-bottom: 40px;
`;

export const InnerWrapper = styled(Wp.Page)`
  position: relative;
`;

export const TitleWrapper = styled.div`
  margin: 50px auto 20px;
  text-align: center;
  h3 {
    color: #00e9fe;
    display: inline-block;
  }

  @media screen and (min-width: 768px) {
    max-width: 728px;
  }
`;

export const PlayerWrapper = styled.div`
  width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 768px;
    margin-top: 0;
    margin-left: 0;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  @media screen and (min-width: 768px) {
    display: flex;
    max-width: 951px;
    margin: 0 auto;
    gap: 15px;
  }
`;

export const MetadataWrapper = styled.div`
  position: relative;
  img {
    max-width: 172px;
    width: 100%;
    display: none;
    margin: 40px 0 10px;

    @media screen and (min-width: 768px) {
      display: inherit;
    }
  }
  h3 {
    margin: 10px 5px 5px;

    @media screen and (min-width: 768px) {
      margin: 0;
    }
  }
  @media screen and (min-width: 768px) {
    max-width: 364px;
  }
`;

export const ShareButtonWrapper = styled.div`
  position: relative;
  margin: 10px 5px 10px;

  @media screen and (max-width: 767px) {
    && > div > a.rounded span {
      display: none;
    }
  }

  @media screen and (min-width: 768px) {
    margin: 10px 0;
  }
`;

export const DescriptionWrapper = styled.div`
  @media screen and (min-width: 768px) {
    & > div {
      margin-bottom: 0;
    }
  }
`;
