import React, { useCallback, useEffect, useState } from "react";

import {
  Wrapper,
  Image,
  PopupWrapper,
  CloseButton,
} from "./OrDocumentariesPopup";
import {
  addNewsletterCookie,
  hasNewsletterCookie,
} from "../../utils/helpers/cookies";
import AtIcon from "../AtIcon/AtIcon";
import { fetchPopup } from "../../../server/api/fetchApi";
import { Metrics } from "../../theme";

export const OrDocumentariesPopup = ({ slug }) => {
  const [close, setClose] = useState(true);
  const [popUp, setPopUp] = useState(null);
  const CAMPAIGN = `newsLetterCampignDocumentary`;
  let width,
    initialWidth = 0;
  let isMobile = false;
  if (typeof window !== "undefined") {
    const currentWidth = document.body.clientWidth;
    width = currentWidth;
  }

  if (width <= parseInt(Metrics.size.tablet.replace("px", "")) && width > 0) {
    if (width !== initialWidth) {
      isMobile = true;
      initialWidth = width;
    }
  }

  useEffect(() => {
    const fetchDataPopup = async () => {
      const omg = await fetchPopup();
      const obj = Object.values(omg);
      if (!obj[1]) {
        setPopUp(null);
        return;
      }
      const resp = Object.values(obj[1]);
      const data = Object.values(resp);
      if (data.length !== 0) {
        const url = Object.values(data[0]);
        const inc = Object.values(obj[2]);
        const dataimg = Object.values(inc[isMobile ? 1 : 0]);
        setPopUp({
          url: url[3].field_url.uri,
          image: dataimg[3].uri.url,
        });
      } else {
        setPopUp(null);
      }
    };

    fetchDataPopup();
  }, []);
  useEffect(() => {
    const hasNewsletter = hasNewsletterCookie(CAMPAIGN);
    setClose(hasNewsletter);
  }, [setClose]);
  const setNoneAndClose = useCallback(() => {
    addNewsletterCookie(CAMPAIGN, "no-email");
    setClose(true);
  }, [setClose]);
  if (popUp) {
    return (
      !close &&
      !!slug &&
      slug === "/home" && (
        <Wrapper>
          <PopupWrapper>
            <CloseButton onClick={setNoneAndClose}>
              <AtIcon name="Close" size={22} />
            </CloseButton>
            <a href={popUp.url}>
              <Image src={popUp.image} />
            </a>
          </PopupWrapper>
        </Wrapper>
      )
    );
  }

  return <></>;
};
