import styled from "styled-components";
import { Colors, Fonts } from "../../../theme";
import AtIcon from "../../AtIcon/AtIcon";
import { rgba } from "polished";

export const Wrapper = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.9);
  max-width: 310px;
  padding: 20px 40px 40px;
  text-align: right;
  @media screen and (max-width: 767px) {
    margin-right: 0px;
    margin-bottom: 20px;
    max-width: unset;
    width: 76%;
  }
`;

export const ImageLogo = styled.img`
  max-height: 36px;
  display: inline-block;
  margin: 0 0 10px 0;
`;

export const ImagePodcast = styled.img`
  max-width: 240px;
  display: block;
  @media screen and (min-width: 768px) {
    min-width: 240px;
    min-height: 240px;
  }
`;

export const MetadataContainer = styled.div`
  width: 100%;
  display: block;
  text-align: center;
  max-width: 240px;
`;

export const Category = styled(Fonts.infoHoverCategory)`
  margin: 5px 0 0;
  font-size: 22px;
`;

export const Title = styled(Fonts.infoHoverTitle)`
  margin-top: 5px;
  font-size: 26px;
  line-height: 33px;
  height: 66px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const Synopsis = styled(Fonts.infoFooterMicroSynopsis)`
  min-height: 48px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 18px;
  margin: 20px 0 3px 0;
`;

export const IconPodcast = styled(AtIcon)`
  position: absolute;
  filter: drop-shadow(0px 1px 2px ${rgba(Colors.black, 0.4)});
  pointer-events: none;
  opacity: 0.5;
  margin-top: -40px;
  right: 50px;
`;

export const Link = styled.a`
  display: inline-block;
  text-decoration: none;
`;
