import { useState, useEffect } from "react";
import { useLoadMore } from "../../../contexts/LoadMoreProvider";

export const LoadMoreWrapper = ({ loadMore = false, children }) => {
  const { isOpen, setLoading } = useLoadMore();
  const [render, setRender] = useState(false);

  useEffect(
    () => {
      setRender(!loadMore || isOpen);
      setLoading(false);
    },
    [loadMore, isOpen]
  );

  return render && children;
};
