import React from "react";
import FacebookLogin from "react-facebook-login";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import md5 from "md5";

import { loginUser } from "../../../../store/actions/auth";
import { getKey, hasKey } from "../../../utils/helpers";
import { FacebookWrapper } from "./styles";
import { signUp } from "../utils";
import { getRefererContent } from "../../../../store/utils/helpers";

function ButtonLogin({ loginUser }) {
  // return null;
  // TODO: Renable when the app id is the correct.

  const responseFacebook = async response => {
    if (hasKey(response, "signedRequest")) {
      const data = {
        ...response,
        email: `fb-${response.email}`,
        pass: md5(`fbrtvcplay_${response.userID}`),
        image: getKey(response, "picture.data.url")
      };
      const { email, pass } = data;
      const result = await loginUser(email, pass);
      if (getKey(result, "error") === "invalid_credentials") {
        await signUp(data, async () => await loginUser(email, pass));
        await loginUser(email, pass);
      }
      window.location.href = getRefererContent() || "/";
    }
  };

  return (
    <FacebookWrapper>
      <FacebookLogin
        //appId="782666265600760"
        appId="1082639022143712"
        cookie={true}
        fields="name,email,picture"
        callback={responseFacebook}
        icon="fa-facebook"
        textButton="Iniciar Sesión"
        cssClass={"ButtonFacebook"}
      />
    </FacebookWrapper>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ loginUser }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(ButtonLogin);
