import React from "react";

import { Link, Span, IconCircle } from "./AtCloseButton";

const AtCloseButton = ({ onClick, text }) => {
  return (
    <Link onClick={onClick}>
      <Span>{text || "Cerrar"}</Span>
      <IconCircle name="Close" size={22} />
    </Link>
  );
};

export default AtCloseButton;
