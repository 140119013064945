import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getChaptersMyList,
  getSeriesMyList
} from "../../../../store/actions/my-list";
import OrMyList from "../../../components/OrMyList";
import Page from "../";
import { searchFooter } from "../../../../store/actions/content";
import { getCurrentUser } from "../../../../store/utils/helpers";
import NotLoggedIn from "../notLoggedIn";

class MyList extends Component {
  render() {
    let currentUser = getCurrentUser();
    if (currentUser === null) {
      return <NotLoggedIn {...this.props} />;
    } else {
      return (
        <Page
          id="MyList"
          title="Mi lista"
          description="Mi lista de reproducción"
        >
          <Fragment>
            <OrMyList {...this.props} page />
          </Fragment>
        </Page>
      );
    }
  }
}

const mapStateToProps = state => ({
  myListChapters: state.myList.chapters,
  myListSeries: state.myList.series
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getChaptersMyList, getSeriesMyList, searchFooter },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyList);
