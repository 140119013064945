import React from "react";
import Swiper from "react-id-swiper";
import "react-id-swiper/src/styles/scss/swiper.scss";
import { isMobile } from "react-device-detect";

import { InnerWrapper, Wrapper, TitleBlock, CinemaList } from "./OrCineWidget";

import CineItem from "./CineItem";

export default function OrPodcastWidget({
  title: widgetTitle,
  showTitle,
  contents,
  streaming_provider
}) {
  const params = {
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true
    },
    slidesPerView: isMobile ? 1 : 2,
    allowTouchMove: isMobile,
    spaceBetween: 50
  };
  return (
    <Wrapper>
      <InnerWrapper>
        <TitleBlock title={widgetTitle} showTitle={showTitle} animateTitle />
        <CinemaList>
          <Swiper {...params}>
            {contents.map((itm, indx) => (
              <div key={`itemcinec-${indx}`}>
                <CineItem
                  streaming_provider={streaming_provider}
                  index={indx}
                  {...itm}
                />
              </div>
            ))}
          </Swiper>
        </CinemaList>
      </InnerWrapper>
    </Wrapper>
  );
}
