import React from "react";
import "react-id-swiper/src/styles/scss/swiper.scss";

import {
  InnerWrapper,
  Description,
  Wrapper,
  MetatagContainer,
  ImagePodcast,
  WrapperWidget,
  WrapperPodsctas,
  WrapperProduction,
  ListenNowContainer,
  PlayIconescuchar,
  EscucharContainer,
  WrapperAuthor,
  LinkWrapper,
  SocialButtonsWrapper,
  InnerWrapperPodsctas
} from "./PodcastSingleCategoryPromote";

import SocialButtons from "../MolShareButton";
import AtTitle from "../AtTitle";
import { getKey } from "../../utils/helpers";

export default function OrSeriesInterviewsWidget({
  contents,
  description,
  slug,
  title
}) {
  return (
    <Wrapper>
      <InnerWrapper>
        <MetatagContainer>
          <AtTitle isWidgetTitle>{title}</AtTitle>
          <WrapperWidget>
            <Description>{description}</Description>
            <SocialButtonsWrapper>
              <SocialButtons
                label="COMPARTIR"
                title={title}
                text={description}
                size={32}
                sizeButtons={18}
                uri={slug}
              />
            </SocialButtonsWrapper>
          </WrapperWidget>
          <WrapperPodsctas>
            <InnerWrapperPodsctas>
              {contents.map(({ url, image, description }, index) => (
                <WrapperProduction key={`podcastSingleCategoryItem${index}`}>
                  <LinkWrapper className="link" href={url}>
                    <ImagePodcast src={getKey(image, "cover.path")} />
                    <ListenNowContainer>
                      <EscucharContainer>
                        <PlayIconescuchar
                          className="play-button"
                          name="PlayCircle"
                          size={25}
                        />
                        ESCUCHAR
                      </EscucharContainer>
                      {description && (
                        <WrapperAuthor>{description}</WrapperAuthor>
                      )}
                    </ListenNowContainer>
                  </LinkWrapper>
                </WrapperProduction>
              ))}
            </InnerWrapperPodsctas>
          </WrapperPodsctas>
        </MetatagContainer>
      </InnerWrapper>
    </Wrapper>
  );
}
