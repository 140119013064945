import React, { Component } from "react";
import styled from "styled-components";

import AtIcon from "../AtIcon/AtIcon";

import { Colors } from "../../theme";

const Button = styled.button`
  border: none;
  background: ${props => props.color || "none"};
  outline: none;
  padding: 8px;
  margin: 0;
  cursor: pointer;
`;
class AtIconButton extends Component {
  static defaultProps = {
    name: "star",
    color: Colors.black,
    iconColor: Colors.white,
    iconSize: 48,
    onClick: () => {},
    onTouchStart: () => {},
    type: "button"
  };
  render() {
    const {
      color,
      name,
      iconColor,
      iconSize,
      onClick,
      onMouseOver,
      onMouseOut,
      onTouchStart,
      className,
      type
    } = this.props;
    return (
      <Button
        color={color}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onTouchStart={onTouchStart}
        className={className}
        type={type}
      >
        <AtIcon name={name} size={iconSize} color={iconColor} />
      </Button>
    );
  }
}

export default AtIconButton;
