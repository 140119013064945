import React from "react";
import styled from "styled-components";
import { Colors, Wp } from "../../theme";

const Container = styled(Wp.Page)`
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  background-color: ${({ isActive }) =>
    isActive ? Colors.cerulean : "#2e2e2e"};
  border-bottom: ${({ isActive }) => (isActive ? Colors.gray : "#2e2e2e")};
  border-bottom: 1px solid ${Colors.gray};
  width: calc(100% - 2.5rem);
  display: flex;

  @media screen and (max-width: 767px) {
    margin: 0;
    padding: 0;
    width: 100%;
  }
`;
const ContainerImg = styled.div`
  @media screen and (max-width: 767px) {
    width: 114px;
    min-width: 114px;
    overflow: hidden;
    height: 64px;
    img {
      max-height: 64px;
      width: auto;
    }
  }
`;
const Hour = styled["span"]`
  color: ${Colors.white};
  background-color: ${Colors.cerulean};
  font-family: "Dosis", sans-serif;
  font-size: 1.25em;
  display: inline-flex;
  align-items: center;
  width: 90px;
  margin-left: -1.25rem;
  padding-left: 35px;

  @media screen and (max-width: 767px) {
    margin: 0;
    padding: 0;
    min-width: 67px;
    font-size: 14px;
    width: auto;
    display: grid;
    text-align: center;
    max-height: 64px;
  }
`;
const Programa = styled["span"]`
  color: ${({ isActive }) => (isActive ? Colors.white : Colors.gray)};
  font-family: "Dosis", sans-serif;
  font-size: 1.25em;
  display: inline-flex;
  align-items: center;
  padding-left: 15px;

  @media screen and (max-width: 767px) {
    font-size: 16px;
    color: white;
  }
`;

class AtProgram extends React.Component {
  render() {
    return (
      <Container isActive={this.props.activo}>
        <Hour>{this.props.hora}</Hour>
        <ContainerImg>
          <img
            alt="No activo"
            height="130px"
            width="280px"
            src={this.props.imagen}
          />
        </ContainerImg>
        <Programa isActive={this.props.activo}>{this.props.programa}</Programa>
      </Container>
    );
  }
}

export default AtProgram;
