import fetch from "isomorphic-fetch";
import config from "../config";
import { getCurrentUser, getEnvContent } from "../../store/utils/helpers";
import { decrypt } from "../../app/utils/helpers/crypto";
import { KEY_AUTH } from "../../store/actions/auth";
import { hasKey, logInfo } from "../../app/utils/helpers";

export async function fetchApi(
  id,
  translatePath = true,
  params = { _format: "json" },
) {
  const domain = `${config.cms.environments[getEnvContent()]}${
    config.api.endpoints.request
  }`;
  const params2string = Object.keys(params)
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join("&");

  const currentUser = getCurrentUser();
  const bearer =
    currentUser && hasKey(currentUser, "tokenData.access_token")
      ? `Bearer ${currentUser.tokenData.access_token}`
      : null;
  const currentURI = encodeURIComponent(`${id}`);
  const routerid = `routerContent`;
  const requestId = `resolveResource${id}`;
  const data = [
    {
      requestId: routerid,
      action: "view",
      uri: translatePath
        ? `/router/translate-path?path=/${currentURI}&_random=${1 +
            Math.random() * 1000}`
        : id,
    },
    {
      requestId,
      action: "view",
      uri: `/api/v1/{{router.body@$.entity.type}}/{{router.body@$.entity.bundle}}/{{router.body@$.entity.id}}`,
      headers: {
        Accept: "application/json",
      },
      waitFor: [routerid],
    },
    {
      requestId: "resolveHeaderFooter",
      action: "view",
      uri: "/api/v1/ott_services/cross_blocks",
      headers: {
        Accept: "application/json",
        Authorization: bearer,
      },
      waitFor: [requestId],
    },
    {
      requestId: "resolveProviderVideo",
      action: "view",
      uri: "/api/v1/app-mobile-config",
      headers: {
        Accept: "application/json",
      },
      waitFor: ["resolveHeaderFooter"],
    },
  ];
  const headers = new Headers({
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: bearer,
    "Cache-Control": "no-cache",
  });

  return fetch(`${domain}?${params2string}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers,
  })
    .then(res => res.json())
    .then(res => res)
    .catch(reason => ({ error: reason }))
    .then(res => {
      let resolveProviderVideo =
        res && res["resolveProviderVideo"] && res["resolveProviderVideo"].body
          ? JSON.parse(res["resolveProviderVideo"].body)
          : {};
      let resolveHeaderFooter =
        res && res["resolveHeaderFooter"] && res["resolveHeaderFooter"].body
          ? JSON.parse(res["resolveHeaderFooter"].body)
          : {};
      const resolveResurceContent = `${requestId}#uri{0}`;
      let resolveResourceContent = {};
      try {
        resolveResourceContent =
          res && res[resolveResurceContent] && res[resolveResurceContent].body
            ? JSON.parse(res[resolveResurceContent].body)
            : {
                error: res,
                text: "Error trying to resolve the content: fetchApi:91",
              };
      } catch (e) {
        resolveResourceContent = {
          error: {
            ...res,
            stack: e.stack,
          },
          text: "Exception catched trying to resolve the content: fetchApi:96",
        };
      }
      let resolveResourceSearchContent =
        res && res[routerid] && res[routerid].body
          ? JSON.parse(res[routerid].body)
          : {};

      const allData = {
        currentContent: {
          ...resolveResourceContent,
          ...resolveProviderVideo,
        },
        searchContent: resolveResourceSearchContent,
        fetched: {
          domain,
          data,
          headers,
        },
        ...resolveHeaderFooter,
      };
      logInfo({ allData });
      return allData;
    });
}

export async function fetchAuthTokenApi(username, password) {
  const domain = `${config.cms.environments[getEnvContent()]}${
    config.api.auth.paths.token.uri
  }`;
  const data = {
    ...config.api.auth.paths.token.body,
    client_id: config.api.auth.client_id,
    client_secret: config.api.auth.client_secret,
    username,
    password,
  };
  const formData = new FormData();

  for (const name in data) {
    formData.append(name, data[name]);
  }
  return fetch(domain, {
    method: "POST",
    body: formData,
  })
    .then(res => res.json())
    .catch(reason => ({ error: reason }));
}

export async function fetchRefreshAuthTokenApi(refresh_token) {
  const domain = `${config.cms.environments[getEnvContent()]}${
    config.api.auth.paths.token.uri
  }`;
  const data = {
    ...config.api.auth.paths.token.body_refresh_token,
    client_id: config.api.auth.client_id,
    client_secret: config.api.auth.client_secret,
    refresh_token,
  };
  const formData = new FormData();

  for (const name in data) {
    formData.append(name, data[name]);
  }
  return fetch(domain, {
    method: "POST",
    body: formData,
  })
    .then(res => res.json())
    .catch(reason => ({ error: reason }));
}

export async function fetchAuthMeApi(token) {
  const domain = `${config.cms.environments[getEnvContent()]}${
    config.api.auth.paths.me.uri
  }`;
  return fetch(domain, {
    method: "GET",
    headers: {
      Authorization: token
        ? config.api.auth.paths.me.body.Authorization.replace("{token}", token)
        : null,
    },
  })
    .then(res => res.json())
    .catch(reason => ({ error: reason }));
}

export async function fetchGeneralPreferences() {
  const domain = `${config.cms.environments[getEnvContent()]}${
    config.api.auth.paths.preferences.uri
  }`;
  return fetch(domain, { method: "GET" })
    .then(res => res.json())
    .catch(reason => ({ error: reason }));
}

export async function pushNewUser(newUser) {
  const domain = `${config.cms.environments[getEnvContent()]}${
    config.api.auth.paths.signUp.uri
  }`;

  return fetch(domain, {
    method: "POST",
    headers: config.api.auth.paths.signUp.headers,
    body: JSON.stringify(newUser),
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      } else {
        return throwError(res);
      }
    })
    .catch(reason => {
      return { error: reason };
    });
}

export async function fetchUpdateUser(infoUser, currentUser) {
  const domain = `${
    config.cms.environments[getEnvContent()]
  }${config.api.auth.paths.update.uri.replace("{uid}", currentUser.id)}`;
  const headers = config.api.auth.paths.update.headers;
  headers.Authorization = hasKey(currentUser, "tokenData.access_token")
    ? headers.Authorization.replace(
        "{token}",
        currentUser.tokenData.access_token,
      )
    : null;
  if (infoUser.pass !== undefined) {
    infoUser.pass[0]["existing"] = decrypt(currentUser.password, KEY_AUTH);
  }
  return await fetch(domain, {
    method: "PATCH",
    headers,
    body: JSON.stringify(infoUser),
  }).then(res => {
    if (res.ok) {
      return res.json();
    } else {
      return throwError(res);
    }
  });
}

function throwError(result) {
  try {
    return result.json().then(body => {
      return { error: body.message, detail: result };
    });
  } catch (e) {
    return { error: "Error indefinido", detail: result };
  }
}

export async function pushImageNewUser(file) {
  const domain = `${config.cms.environments[getEnvContent()]}${
    config.api.auth.paths.imageUpload.uri
  }`;
  const headers = config.api.auth.paths.imageUpload.headers;
  const currentUsr = getCurrentUser();
  headers["Content-Disposition"] = headers["Content-Disposition"].replace(
    "{nameFile}",
    file.name,
  );
  headers["Authorization"] = hasKey(currentUsr, "tokenData.access_token")
    ? headers["Authorization"].replace(
        "{token}",
        currentUsr.tokenData.access_token,
      )
    : null;
  return fetch(domain, {
    method: "POST",
    headers,
    body: file,
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      } else {
        return throwError(res);
      }
    })
    .catch(reason => {
      return { error: reason };
    });
}

export async function fetchForgotPassword(mail) {
  const domain = `${config.cms.environments[getEnvContent()]}${
    config.api.auth.paths.forgotPassword.uri
  }`;
  return fetch(domain, {
    method: "POST",
    headers: config.api.auth.paths.forgotPassword.headers,
    body: JSON.stringify({ mail: { value: mail } }),
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      } else {
        return throwError(res);
      }
    })
    .catch(reason => {
      return { error: reason };
    });
}

export async function fetchUpdatePassword({ uid, hash, timestamp, pass }) {
  const domain = `${config.cms.environments[getEnvContent()]}${
    config.api.auth.paths.updatePassword.uri
  }`;
  return fetch(domain, {
    method: "POST",
    headers: config.api.auth.paths.updatePassword.headers,
    body: JSON.stringify({
      uid: { value: uid },
      timestamp: { value: timestamp },
      hash: { value: hash },
      pass: { value: pass },
    }),
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      } else {
        return throwError(res);
      }
    })
    .catch(reason => {
      return { error: reason };
    });
}

export async function fetchGrill(fecha, canal) {
  // const url = `https://parrilla.rtvc.gov.co/parrillageneral/views/parrillageneral?field_channel_nid=${canal}&field_date_value[value][date]=${fecha}`;
  const url = `https://parrilla.rtvc.gov.co/services/parrilla-programacion?start=${fecha}T00:00:00&end=${fecha}T23:59:59&field_channel_nid=${canal}`;
  return await fetch(url)
    .then(res => {
      if (res.ok) {
        return res.json();
      } else {
        return throwError(res);
      }
    })
    .catch(reason => {
      return { error: reason };
    });
}

export async function fetchCurrentCountry() {
  return await fetch(config.api.country.ipService)
    .then(res => {
      if (res.ok) {
        return res.json();
      } else {
        return throwError(res);
      }
    })
    .catch(reason => {
      return { error: reason };
    });
}

export function fetchDataRecommender(
  user_Id,
  item_Id,
  TIMESTAMP,
  EVENT_TYPE,
  EVENT_VALUE,
) {
  const domain = config.api.dataevent.uri;
  return fetch(domain, {
    method: "POST",
    // headers:
    body: JSON.stringify({
      user_Id,
      item_Id,
      TIMESTAMP,
      EVENT_TYPE,
      EVENT_VALUE,
    }),
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      } else {
        return throwError(res);
      }
    })
    .catch(reason => {
      return { error: reason };
    });
}

export function fetchDataEvent(
  user_Id,
  item_Id,
  TIMESTAMP,
  EVENT_TYPE,
  EVENT_VALUE,
) {
  const domain = config.api.dataputevent.uri;
  return fetch(domain, {
    method: "POST",
    // headers:
    body: JSON.stringify({
      user_Id,
      item_Id,
      TIMESTAMP,
      EVENT_TYPE,
      EVENT_VALUE,
    }),
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      } else {
        return throwError(res);
      }
    })
    .catch(reason => {
      return { error: reason };
    });
}

export async function fetchNewsletter(email, campaignId, categoryId) {
  const domain = `${config.cms.environments[getEnvContent()]}${
    config.api.auth.paths.newsletter.uri
  }`;
  const data = {
    email,
    title: `Entrada newsletter: ${email}`,
    campaignId,
    categoryId,
  };
  return fetch(domain, {
    method: "POST",
    headers: config.api.auth.paths.newsletter.headers,
    body: JSON.stringify(data),
  })
    .then(res => res.json())
    .catch(reason => ({ error: reason }));
}

export async function fetchPopup() {
  let date = new Date();
  const datehoy =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date
      .getDate()
      .toString()
      .padStart(2, "0");
  const url = `${
    config.cms.environments[getEnvContent()]
  }/jsonapi/node/pop_up_home?
  include=field_cover_bg,field_cover_bg_mobile&filter[datefilter][condition][path]=field_date&
  filter[datefilter][condition][operator]=%3C=&filter[datefilter][condition][value]=${datehoy}&
  filter[dateendfilter][condition][path]=field_date_end_popup&
  filter[dateendfilter][condition][operator]=%3E=&filter[dateendfilter][condition][value]=${datehoy}`;
  return fetch(url)
    .then(res => res.json())
    .catch(reason => ({ error: reason }));
}
