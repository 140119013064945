import styled from "styled-components";
import { Metrics, Wp } from "../../../theme";

export const Wrapper = styled(Wp.Page)`
  margin-top: ${Metrics.size.minHeaderHeight};
  margin-left: auto;
  margin-right: auto;
  max-width: 1240px;
  width: calc(100% - 2.5rem);
  @media ${Metrics.mediaQ.tablet} {
    margin-top: ${Metrics.size.maxHeaderHeight};
  }
`;

export const InnerWrapper = styled(Wp.Container)`
  padding: 30px 0 20px;
`;

export const Image = styled.img`
  width: 100%;
  margin: 0 auto;
`;
