import React from "react";
import { hasUserFeaturesEnable } from "../../utils/helpers";
import MolMyListButton from "../MolMyListButton";
import { AddMyListButton } from "./AddMyListButton";

const MolAddMyListButton = ({ size, label, id }) => {
  return !hasUserFeaturesEnable() ? null : (
    <AddMyListButton>
      <MolMyListButton size={size || 32} label={label || "MI LISTA"} id={id} />
    </AddMyListButton>
  );
};
export default MolAddMyListButton;
