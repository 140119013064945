import React, { Component } from "react";
import styled from "styled-components";
import Modal from "react-awesome-modal";
import { Colors, Fonts } from "../../theme";
import { setCookie, getCookie } from "../../utils/helpers";
import "./OrAdultPopup.scss";

const ContentAdultPopup = styled.div`
  position: relative;
  max-width: 500px;
  width: 100%;

  color: ${Colors.white};
  text-align: center;
  & > div > div > div:first-child {
    background-color: transparent !important;
    padding: 10px;
    border-radius: 0px !important;
  }
  img {
    width: 100%;
  }
`;

const Button = styled.button`
  background: ${Colors.cerulean};
  color: ${Colors.white};
  border: none;
  font-size: 1.375em;
  font-family: "Dosis", sans-serif;
  line-height: 0.864;
  padding: 0;
  margin: 0 auto;
  width: 224px;
  height: 59px;
  outline: none;
  cursor: pointer;
  &:hover {
    background: ${Colors.uglyblue};
  }
  &[disabled] {
    background: #ccc;
    color: #888;
    box-shadow: inset 0px 0px 5px 0px #000;
    cursor: progress;
  }
`;
const Buttonhoverdisable = styled.button`
  background: rgb(0 0 0/0.5);
  color: ${Colors.white};
  border: none;
  font-size: 1.375em;
  font-family: "Dosis", sans-serif;
  line-height: 0.864;
  padding: 0;
  margin: 0 auto;
  width: 224px;
  height: 59px;
  outline: none;
  cursor: default;
  &:hover {
    background: rgb(0 0 0/0.5);
  }
  &[disabled] {
    background: rgb(0 0 0/0.5);
    cursor: progress;
  }
`;
const Input = styled(Fonts.input)`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;
const Title = styled.h1`
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  margin: 0 auto;
  font-size: 20px;
  padding: 0px;
  width: 100%;
  text-align: center;
  width: 100%;
  filter: drop-shadow(1px 2px 3px black);
`;
const Pagraph = styled.p`
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  margin: 0px;
  padding: 0px;
  text-align: center;
  width: 100%;
`;
const Warning = styled.p`
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  margin: 0 auto;
  padding: 0px;
  color: #cc9933;
  text-align: center;
  width: 100%;
  font-weight: bold;
`;
const Img = styled.img`
  width: 55% !important;
  height: auto;
`;
const ImgDes = styled.img`
  width: 20% !important;
  height: auto;
  vertical-align: 25%;
`;
const Containerlandscape = styled.div`
  @media (max-width: 850px) and (orientation: landscape) {
    max-height: 93vh;
    margin-top: 66px;
    overflow: scroll;
  }
`;
const Terms = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  width: 95%;
  border-radius: 10px;
  border: 1px solid #cacaca;
  background-color: ${Colors.bg1};
  color: ${Colors.white};
  padding: 5px;
  padding-right: 10px;
  height: 100px;
  overflow: hidden;
  overflow-y: auto;
  text-align: left;

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: #000;
    margin: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${Colors.cerulean};
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${Colors.uglyblue};
  }
`;

class AdultPopup extends Component {
  constructor(props) {
    super(props);
    const acceptedCookie = getCookie("acceptAdult");
    this.state = {
      hasErrors: false,
      modalVisible:
        this.props.isVisible === true
          ? acceptedCookie !== null
            ? acceptedCookie !== undefined && acceptedCookie !== ""
              ? acceptedCookie !== "true"
              : true
            : false
          : false,

      loading: false
    };
  }
  openModal() {
    this.setState({
      modalVisible: true
    });
  }

  closeModal() {
    if (document.querySelector("#checkboxAdultAceptTerms").checked) {
      this.setState({
        modalVisible: false
      });
      setCookie("acceptAdult", "true", 365);
    }
  }

  componentDidMount() {
    if (document.querySelector("#checkboxAdultAceptTerms") != null) {
      document
        .querySelector("#checkboxAdultAceptTerms")
        .addEventListener("change", e => {
          if (!e.target.checked) {
            document
              .querySelector("#buttonAdultSeeNow")
              .setAttribute("disabled", "disabled");
          } else {
            document
              .querySelector("#buttonAdultSeeNow")
              .removeAttribute("disabled");
          }
        });

      document
        .querySelector("#buttonAdultSeeNow")
        .addEventListener("click", () => {
          this.closeModal();
        });
    }
  }

  render() {
    return !this.state.modalVisible ? null : (
      <ContentAdultPopup className="zindex-adultpopup">
        <Modal
          visible={this.state.modalVisible}
          width="500"
          height="530"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <Containerlandscape>
            <div>
              <Title>
                <ImgDes src="https://s3.amazonaws.com/rtvcplay-v2/s3fs-public/field/logo/production/RTCPlay%2B18%20assets/RTVCPlay_destellos-single.png" />
                CONTENIDO PARA
                <ImgDes src="https://s3.amazonaws.com/rtvcplay-v2/s3fs-public/field/logo/production/RTCPlay%2B18%20assets/RTVCPlay_destellos-single.png" />
              </Title>
              <Img src="https://s3.amazonaws.com/rtvcplay-v2/s3fs-public/field/logo/production/RTCPlay%2B18%20assets/RTVCPlay_titulo%2B18-completo.png" />
              <Pagraph>
                Este contenido es apto para una audiencia mayor de 18 años.
                puede contener lenguaje ofensivo, escenas de sexo y violencia.{" "}
              </Pagraph>
              <br />
              <Warning>Padres, se recomienda discreción.</Warning>
              <Terms>
                Al presionar el botón “continuar”, el usuario manifiesta que es
                mayor de edad y accede a este contenido de forma voluntaria,
                declarando que no lo encuentra ofensivo u objetable de ver, leer
                y/o escuchar debido a la posible presencia de desnudez,
                actividad sexual, violencia o vocabulario que pueda considerarse
                ofensivo.
                <br /> <br />
                El usuario se compromete a no facilitar el acceso a esta página
                web a ninguna persona menor de 18 años.
                <br /> <br />
                El usuario entiende que <b>RTVCPlay.co</b> no tiene la
                obligación legal de borrar material que pueda ser considerado
                objetable u ofensivo.
                <br /> <br />
                El usuario entiende que <b>RTVCPlay.co</b> no autoriza el uso de
                sus signos distintivos, ni la copia, publicación, distribución o
                difusión del material contenido en este sitio web (incluyendo
                audio, video, imágenes, texto o cualquier obra protegida por
                derecho de autor). Cualquier infracción a los derechos de
                propiedad intelectual derivados del contenido de este sitio web,
                dará lugar a las reclamaciones, acciones legales y/o sanciones
                que disponga la ley.
              </Terms>
              <Buttonhoverdisable>
                <Input
                  id="checkboxAdultAceptTerms"
                  ref="inputSearch"
                  type="checkbox"
                />
                He leído y acepto.
              </Buttonhoverdisable>

              <br />
              <br />
              <Button id="buttonAdultSeeNow" disabled="disabled">
                VER
              </Button>
            </div>
          </Containerlandscape>
        </Modal>
      </ContentAdultPopup>
    );
  }
}

export default AdultPopup;
