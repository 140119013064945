import styled from "styled-components";
import { Colors, Fonts } from "../../theme";

export const ContentLogoutForm = styled.div`
  position: relative;
  max-width: 440px;
  background-color: ${Colors.bg1};
  padding: 30px;
`;

export const Title = styled(Fonts.h1)`
  color: ${Colors.cerulean};
`;
export const SubTitle = styled(Fonts.slideLabel)``;
