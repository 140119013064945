import { keyframes } from "styled-components";
import { rgba } from "polished";

import Colors from "./Colors";

const FadeInFromLeft = keyframes`
	from {
        opacity: 0;
        transform: translatex(-300px);
    }
    to {
        opacity: 1;
        transform: translatex(0);
    }
`;

const Rotate = keyframes`
	from { 
        transform: rotate(0deg); 
    } to { 
        transform: rotate(360deg);
    }
`;

const Blinker = keyframes`
	50% {
		opacity: 0;
	}
`;

const TextShadowBlinker = keyframes`
  0% {
    text-shadow: none;
  } 50% {
    text-shadow: 0px 0px 10px ${rgba(Colors.white, 0.6)};
  } 100% {
    text-shadow: none;
  }
`;

const Animations = {
  FadeInFromLeft,
  Rotate,
  Blinker,
  TextShadowBlinker
};
export default Animations;
