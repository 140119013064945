import React, { Component, Fragment } from "react";
import AtIcon from "../../../components/AtIcon/AtIcon";

import "./BotonesOriginales.scss";

class BotonesOriginales extends Component {
  scrollTop() {
    if (typeof window !== "undefined") {
      window.document.body.scrollTop = 0;
    }
  }

  render() {
    const customStyles = `.contentTrailer { background: #1c1c1c; padding-bottom: 4em; border-bottom: none;}`;
    return (
      <Fragment>
        <style>{customStyles}</style>
        <div className={`contentBotonesOriginales`}>
          <div className="links">
            <ul>
              <li>
                <div className="links_decoration" />
                <a
                  href={"#top"}
                  onClick={this.scrollTop}
                  className="firstEpisodeButton"
                >
                  <AtIcon name="PlayCircle" size={32} /> Ver Serie
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default BotonesOriginales;
