import React from "react";
import Swiper from "react-id-swiper";
import "react-id-swiper/src/styles/scss/swiper.scss";

import {
  InnerWrapper,
  Wrapper,
  TitleBlock,
  PodcastList,
  IconButton,
  ContainerMainPodcastMobile,
  ContainerList
} from "./PodcastWidget";

import PodcastMainItem from "./PodcastMainItem";
import PodcastItem from "./PodcastItem";
import AtIcon from "../AtIcon/AtIcon";
import { isValidArray } from "../../utils/helpers";

export default function OrPodcastWidget({
  title: widgetTitle,
  showTitle,
  contents
}) {
  const params = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    renderPrevButton: () => (
      <IconButton className="swiper-button-prev">
        <AtIcon className="icon" name="KeyboardArrowLeft" size={25} />
      </IconButton>
    ),
    renderNextButton: () => (
      <IconButton className="swiper-button-next">
        <AtIcon className="icon" name="KeyboardArrowRight" size={25} />
      </IconButton>
    ),
    breakpoints: {
      3000: {
        slidesPerView: "auto",
        spaceBetween: 30
      },
      767: {
        slidesPerView: 1.15,
        spaceBetween: 15,
        allowTouchMove: true
      }
    }
  };

  const firstContent = isValidArray(contents) ? { ...contents[0] } : null;
  return (
    <Wrapper>
      <TitleBlock title={widgetTitle} showTitle={showTitle} animateTitle />
      <InnerWrapper>
        <PodcastList className="podcastWidgetContainer">
          {!!firstContent && (
            <ContainerMainPodcastMobile>
              <PodcastMainItem {...firstContent} />
            </ContainerMainPodcastMobile>
          )}
          <ContainerList>
            <Swiper {...params}>
              {contents.map((itm, index) => (
                <div key={`podcast-${index}`}>
                  {index === 0 ? (
                    <PodcastMainItem {...itm} />
                  ) : (
                    <PodcastItem {...itm} />
                  )}
                </div>
              ))}
            </Swiper>
          </ContainerList>
        </PodcastList>
      </InnerWrapper>
    </Wrapper>
  );
}
