import React, { Component } from "react";
import styled from "styled-components";

import { Wp } from "../../theme";

const Container = styled(Wp.Container)`
  position: relative;
`;
const IframeContainer = styled(Wp.Container)`
  position: relative;
  overflow: hidden;
  padding-top: ${props => props.ratio}%;
`;
const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;
class AtIframe extends Component {
  static defaultProps = {
    aspectRatio: "16-9"
  };
  render() {
    const { className, title, src, aspectRatio } = this.props;
    const aspectRatioValues = aspectRatio.split("-");
    return (
      <Container className={className}>
        <IframeContainer
          ratio={(aspectRatioValues[1] / aspectRatioValues[0]) * 100}
        >
          <Iframe
            sandbox
            title={title}
            src={src}
            allowfullscreen=""
            webkitallowfullscreen=""
            mozallowfullscreen=""
          />
        </IframeContainer>
      </Container>
    );
  }
}

export default AtIframe;
