import React from "react";
import styled, { css } from "styled-components";
import { getUniqKey } from "../../../utils/helpers";
import { Wp } from "../../../theme";
import HorizontalList from "../../../components/OrHorizontalList";

const Entrevistas = ({
  id,
  is_widget_section,
  isEducation,
  isNinos,
  streaming_provider,
  titulo_paragraph,
  videos
}) => (
  <ContentEntrevistas isWidgetSection={is_widget_section}>
    <Wp.Page>
      {!is_widget_section && <Title>{titulo_paragraph}</Title>}
      <WidgetContainer>
        <HorizontalList
          key={getUniqKey(id)}
          contents={videos}
          initialSlide={-1}
          view="video"
          isNinos={isNinos}
          isEducation={isEducation}
          isVideo={true}
          streaming_provider={streaming_provider}
          showTitle={!!is_widget_section}
          title={titulo_paragraph}
          className={`contentEntrevistas_video`}
          styles={
            !is_widget_section
              ? css`
                  padding-top: 0px !important;
                `
              : css`
                  background-color: black;
                  .swiper-button-next:before {
                    background: linear-gradient(
                      to right,
                      rgba(0, 0, 0, 0) 0%,
                      rgba(0, 0, 0, 1) 100%
                    ) !important;
                  }
                  .swiper-button-prev:before {
                    background: linear-gradient(
                      to left,
                      rgba(0, 0, 0, 0) 0%,
                      rgba(0, 0, 0, 1) 100%
                    ) !important;
                  }
                `
          }
        />
      </WidgetContainer>
    </Wp.Page>
  </ContentEntrevistas>
);

const ContentEntrevistas = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  padding-top: 20px;
  background: #1c1c1c;
  padding-bottom: 4em;
  border-bottom: none;

  ${({ isWidgetSection }) =>
    isWidgetSection &&
    css`
      background-color: black;
      border-top: 1px solid #7c7c7c;
      padding-bottom: 45px;
    `};
`;

const Title = styled.h2`
  font-family: "BebasNeue Regular", sans-serif;
  text-transform: uppercase;
  font-size: 28px;
  color: rgb(217, 217, 217);
  line-height: 1.2;
  font-weight: 400;
  margin: 0px;
  margin-bottom: 5px;
`;

const WidgetContainer = styled.div`
  .BlockTitle {
    margin-bottom: 35px;
  }
  .block-body {
    padding-top: 0;
  }
  .inner-container {
    padding: 0px;
  }
  .swiper-slide {
    &.video {
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      @media screen and (max-width: 835px) {
        display: block;
        height: auto !important;
      }
      @media screen and (max-width: 768px) {
        max-width: 80%;
      }
    }
  }
`;

export default Entrevistas;
