import styled from "styled-components";
import { Colors } from "../../theme";

export const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 9999;
`;

export const PopupWrapper = styled.div`
  position: relative;
  width: 90%;
  margin: 50% auto 0;
  font-family: "NexaBold", "Dosis", serif;
  max-width: 400px;

  @media screen and (min-width: 768px) {
    margin: 15% auto 0;
  }
`;

export const Image = styled.img`
  position: absolute;
  max-width: 400px;
  top: -57px;
  width: 100%;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: -45px;
  right: 0;
  border: 2px solid ${Colors.white};
  border-radius: 50%;
  padding: 4px;
  z-index: 1;
  cursor: pointer;
`;
