import React, { Component } from "react";
import styled from "styled-components";

import { Colors, Fonts, Icons } from "../../theme";
import DropDown from "./DropDown";

// const FontAwesome = () => <div />;

const DROPDOWN_WIDTH = 222;
const DROPDOWN_HEIGHT = 36;
const DROPDOWN_MAX_HEIGHT = 215;

const Wrapper = styled.div`
  user-select: none;
  position: relative;
  width: ${props => props.width}px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  border: 0;
  background-color: rgba(0, 0, 0, 0.45);
  .icon {
    position: absolute;
    right: 11px;
    top: 11px;
    opacity: 0.6;
  }
  .title {
    position: relative;
    margin-left: ${DROPDOWN_HEIGHT / 2}px;
    margin-right: ${DROPDOWN_HEIGHT}px;
    line-height: ${DROPDOWN_HEIGHT}px;
    width: 100%;
  }
`;
const Title = styled(Fonts.span)`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1.25rem;
  font-weight: 600;
`;
const List = styled.ul`
  z-index: 10;
  position: absolute;
  width: 100%;
  border: 0;
  background-color: black;
  margin: 0;
  padding: 0;
  max-height: ${DROPDOWN_MAX_HEIGHT}px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  @media screen and (min-width: 768px) {
    padding: ${DROPDOWN_HEIGHT / 2}px 0;
  }
`;
const Item = styled.li`
  padding: 0;
  padding-left: ${DROPDOWN_HEIGHT / 2}px;
  margin: 0;
  line-height: 1.6rem;
  cursor: pointer;
  box-sizing: border-box;
  .item-text {
    line-height: ${DROPDOWN_HEIGHT}px;
    width: 100%;
  }
  :hover {
    background-color: rgba(0, 0, 0, 0.6);
    .item-text {
      font-weight: bold;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 7px;
    padding-left: 18px;
    margin: 0;
    line-height: 1.6rem;
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 1px solid #707070;
  }
`;
const ItemText = styled(Fonts.span)`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 22px;
  font-weight: 400;
`;

class AtDropdown extends Component {
  static defaultProps = {
    title: "",
    list: [],
    onItemClick: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      headerTitle: this.props.title
    };
    this.close = this.close.bind(this);

    this.icon = styled(Icons[props.iconName || "chevronBottomB"])`
      pointer-events: none;
      transition: transform 200ms ease-in-out;
      &.icon-open {
        transform: rotate(0deg);
      }
      &.icon-close {
        transform: rotate(-90deg);
      }
    `;
  }

  componentDidUpdate() {
    const { listOpen } = this.state;
    setTimeout(() => {
      if (listOpen) {
        window.addEventListener("click", this.close);
      } else {
        window.removeEventListener("click", this.close);
      }
    }, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.close);
  }

  close() {
    this.setState({
      listOpen: false
    });
  }

  selectItem(item, index) {
    this.setState(
      {
        headerTitle: item.title,
        listOpen: false
      },
      this.props.onItemClick(item, index)
    );
  }

  toggleList() {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }));
  }

  render() {
    const { list, className, width, version } = this.props;
    if (version === 2) {
      return <DropDown {...this.props} />;
    }
    const { listOpen, headerTitle } = this.state;
    const Icon = this.icon;
    return (
      <Wrapper className={className} width={width || DROPDOWN_WIDTH}>
        <Header onClick={() => this.toggleList()} className="dd-header">
          <Title className="title">{headerTitle}</Title>
          <Icon
            className={`icon ${listOpen ? "icon-open" : "icon-close"}`}
            color={Colors.white}
            size={14}
          />
        </Header>
        {listOpen && (
          <List onClick={e => e.stopPropagation()} className="dd-list">
            {list.map((item, index) => (
              <Item
                key={index}
                onClick={() => this.selectItem(item, index)}
                className="dd-list-item"
              >
                <ItemText className="item-text">{item.title}</ItemText>
              </Item>
            ))}
          </List>
        )}
      </Wrapper>
    );
  }
}

export default AtDropdown;
