import React from "react";

import {
  InnerWrapper,
  Wrapper,
  TitleWrapper,
  PlayerWrapper,
  ContentWrapper,
  MetadataWrapper,
  ShareButtonWrapper,
  DescriptionWrapper
} from "./PromoteWidget";
import AtTitle from "../AtTitle";
import AtDescription from "../AtDescription";
import MolShareButton from "../MolShareButton";
import AtVideo from "../AtVideo";
import { getKey } from "../../utils/helpers";
import PromoteList from "./PromoteList";
import AtImg from "../AtImg/AtImg";

export default function OrPromoteWidget({
  contents,
  description,
  image,
  streaming_provider,
  title,
  title_related_content,
  video
}) {
  const videoData = getKey(video, "trailer");
  const imagePth = getKey(image, "logo.path");
  return (
    <Wrapper>
      <InnerWrapper>
        <TitleWrapper>
          <AtTitle>{title}</AtTitle>
          <AtDescription>{description}</AtDescription>
        </TitleWrapper>
        {videoData && (
          <ContentWrapper>
            <PlayerWrapper>
              <AtVideo
                video={videoData}
                streaming_provider={streaming_provider}
              />
            </PlayerWrapper>
            <MetadataWrapper>
              {imagePth && <AtImg src={imagePth} />}
              <AtTitle>{videoData.title}</AtTitle>
              <DescriptionWrapper>
                <AtDescription>{videoData.synopsis}</AtDescription>
              </DescriptionWrapper>
              <ShareButtonWrapper>
                <MolShareButton label="COMPARTIR" size={26} />
              </ShareButtonWrapper>
            </MetadataWrapper>
          </ContentWrapper>
        )}
      </InnerWrapper>
      <PromoteList
        contents={contents}
        streaming_provider={streaming_provider}
        title_related_content={title_related_content}
      />
    </Wrapper>
  );
}
