import styled from "styled-components";

export const Wrapper = styled.div`
  @media screen and (min-width: 768px) {
    margin: 30px 0;
    & > div:first-child {
      border-right: 1px solid white;
    }
  }
`;
