import React from "react";
import get from "lodash.get";
import config, { globalComponents } from "../../../server/config";
import { PODCAST_VIEW } from "../../constants";
import { getEnvContent } from "../../../store/utils/helpers";

/**
 * Helper function to determine if a given property exists
 * @param {Object} property Property being tested
 * @returns {boolean}
 */
export function exists(property) {
  if (typeof property !== "undefined" && property) {
    return true;
  }
  return false;
}
/**
 * Capitalise first letter of string ('hello' -> 'Hello')
 * @param {string} str to transform
 * @returns {string}
 */
export function capFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Animation easing function
 * @param {number} currentTime current time
 * @param {number} startValue start value
 * @param {number} change in value (delta)
 * @param {number} duration duration (ms)
 * @returns {number}
 */
export function easeInOutQuad(currentTime, startValue, change, duration) {
  let time = currentTime;

  time /= duration / 2;

  if (time < 1) {
    return (change / 2) * time * time + startValue;
  }
  time -= 1;
  return (-change / 2) * (time * (time - 2) - 1) + startValue;
}

/**
 * Helper to determine if array is valid and not valid
 * @param {array} array to be checked
 * @returns {boolean}
 */
export function isValidArray(array) {
  return Array.isArray(array) && array.length > 0;
}

/**
 * Checks to see if given url is relative (i.e., without a host/protocol)
 * @param {string} url to test
 * @returns {bool} if url is relative
 */
export function isRelativeUrl(url) {
  return url.search(/^(?:[a-z]+:)?\/\//i) < 0;
}

/**
 * Checks to see if given url is ABSOLUTE (i.e., with a host/protocol)
 * @param {string} url to test
 * @returns {bool} if url is ABSOLUTE, not relative
 */
export function isAbsoluteUrl(url) {
  return !isRelativeUrl(url);
}
/**
 * Converts an absolute URL to a relative one
 * @param {string} absoluteUrl Url to be converted
 * @returns {string}
 */
export function toRelativeUrl(absoluteUrl) {
  const noDomainUrlRegexp = /^.*\/\/[^/]+/;
  if (typeof absoluteUrl === "string") {
    return absoluteUrl.replace(noDomainUrlRegexp, "");
  }
  return null;
}
/**
 * Converts a relative URL to an absolute one
 * @param {string} uri Potential relative URL that needs converting
 * @param {string} domain Domain to use on the absolute URL
 * @returns {string}
 */
export function toAbsoluteUrl(uri, domain) {
  if (typeof uri === "string" && typeof domain === "string") {
    // Only make absolute a relative url
    if (exists(uri) && exists(domain) && isRelativeUrl(uri)) {
      if (uri.startsWith("/")) {
        return `${domain}${uri}`;
      }
      return `${domain}/${uri}`;
    }
    return uri;
  }
  return null;
}
/**
 * Convert string to camel case
 * @param {string} value an text/value to convert
 * @returns {string}
 */
export function camelCase(value) {
  if (typeof value !== "string" || value === null) {
    return value;
  }

  const rsLower = "[a-z\\xdf-\\xf6\\xf8-\\xff]";
  const rsUpper = "[A-Z\\xc0-\\xd6\\xd8-\\xde]";
  const rsOptContrLower = "(?:['’](?:d|ll|m|re|s|t|ve))?";
  const rsAscii = `${rsUpper}?${rsLower}+${rsOptContrLower}(?=${rsUpper}|$)`;
  const reAsciiWord = RegExp(
    `${rsAscii}|[^\x00-\x2f\x3a-\x40\x5b-\x60\x7b-\x7f]+`,
    "g",
  );
  const words = value.match(reAsciiWord);

  if (!isValidArray(words)) {
    return value;
  }

  return words.reduce((result, val, index) => {
    const word = val.toLowerCase();
    return result + (index ? capFirstLetter(word) : word);
  }, "");
}

/**
 * Helper function to check if key is not null and defined
 * @param {Object} obj to te tested
 * @param {string} key to be checked
 * @returns {boolean}
 */
export function hasKey(obj, key) {
  let tempObj = obj;
  if (typeof key !== "string") {
    return false;
  }
  return key.split(".").every(x => {
    if (
      typeof tempObj !== "object" ||
      tempObj === null ||
      !(x in tempObj) ||
      !tempObj[x]
    ) {
      return false;
    }
    tempObj = tempObj[x];
    return true;
  });
}

/**
 * Helper function to get key if exist otherwise returns undefined
 * @param {Object} obj The object to query
 * @param {(Array|string)} path The path of the property to get
 * @param {*} [defaultValue] The value returned for `undefined` resolved values.
 * @returns {*}
 */
export const getKey = get;

/**
 * Method to remove all falsy values: undefined, null, 0, false, NaN and "" from array
 * @param {Array} actual of the page
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i += 1) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * Get the URL without domain and /* on the end
 * @param {string} url - the URL to clean
 * @access public
 * @returns {string}
 */
export function cleanUrl(url) {
  const cleanUrlRegexp = /(\/|\*|\/\*)$/;
  if (typeof url === "string") {
    return toRelativeUrl(url.replace(cleanUrlRegexp, ""));
  }
  return url;
}

/**
 * Returns a cookie by name from document.cookie
 * @param {string} name cookie name
 * @returns {string}
 */
export function getCookie(name) {
  if (getKey(global, "window.document")) {
    const cookies = `; ${document.cookie}`;
    const parts = cookies.split(`; ${name}=`);
    let cookie = "";
    if (parts.length === 2) {
      cookie = parts
        .pop()
        .split(";")
        .shift();
    }
    return cookie;
  }
  return null;
}

/**
 * sets a cookie by name for document.cookie
 * @param {string} name cookie name
 * @param {string} value for cookie
 * @param {string} days how many days before expire

 */
export function setCookie(name, value, days, hours = 24) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * hours * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  if (getKey(global, "window.document")) {
    document.cookie = `${name}=${value || ""}${expires}; path=/`;
  }
}

/**
 * Returns the first element which is present in both arrays.
 * @param {Array} firstArray First array to iterate
 * @param {Array} secondArray Second array to iterate
 * @returns {*} the first element which is present in both arrays, if any.
 */
export function getFirstMatch(firstArray, secondArray) {
  if (Array.isArray(firstArray) && Array.isArray(secondArray)) {
    return firstArray.find(expectedElement =>
      secondArray.some(element => expectedElement === element),
    );
  }
  return null;
}

/**
 * Simple unique key generator
 * @param {*} prefix - the identifier prefix to the key
 * @returns {string}
 */
export const getUniqKey = prefix => {
  return `${prefix || "key"}${Date.now()}${Math.random()
    .toString(36)
    .substr(2, 10)}`;
};

/**
 * Performs comparison between two values to determine if they are
 * equivalent, if 'partial' is true performs compare no deep in objects/arrays.
 * @param {*} value The value to compare.
 * @param {*} other The other value to compare.
 * @param {boolean} shallow If true make a non-deep comparation in objects/arrays.
 * @returns {boolean} Returns `true` if the values are equivalent, else `false`.
 */
export function isEqual(value, other, shallow = false) {
  const dateTag = "[object Date]";
  const errorTag = "[object Error]";
  const self = {
    // For similar behavior of _.eq
    equal: (valA, valB) => {
      if (valA === valB) {
        return true;
      }
      return (
        typeof valA === "number" &&
        Number.isNaN(valA) &&
        typeof valB === "number" &&
        Number.isNaN(valB)
      );
    },
    getTag: (valA, valB) => {
      const tagA = Object.prototype.toString.call(valA);
      const tagB = Object.prototype.toString.call(valB);
      if (tagA !== tagB) {
        return false;
      }
      return tagA;
    },
    equalByTag: (valA, valB) => {
      const tag = self.getTag(valA, valB);
      if (!tag) {
        return false;
      }
      switch (tag) {
        case dateTag:
          // Coerce dates to milliseconds.
          // Invalid dates are coerced to `NaN`.
          return self.equal(+valA, +valB);
        case errorTag:
          return valA.name === valB.name && valA.message === valB.message;
        default:
          return true;
      }
    },
  };

  /* eslint-disable no-else-return */
  if (self.equal(value, other)) {
    return true;
  } else if (!self.equalByTag(value, other)) {
    return false;
  } else if (
    value == null ||
    other == null ||
    typeof value !== "object" ||
    typeof other !== "object"
  ) {
    return false;
  }

  const keysValue = Object.keys(value);
  const keysOther = Object.keys(other);

  if (keysValue.length !== keysOther.length) {
    return false;
  }

  for (let i = 0; i < keysValue.length; i += 1) {
    if (!Object.prototype.hasOwnProperty.call(other, keysValue[i])) {
      return false;
    }
    const valA = value[keysValue[i]];
    const valB = other[keysValue[i]];

    if (!self.equalByTag(valA, valB)) {
      return false;
    } else if (
      !shallow &&
      typeof valA === "object" &&
      typeof valB === "object"
    ) {
      if (!isEqual(valA, valB, shallow)) {
        return false;
      }
    } else if (!self.equal(valA, valB)) {
      return false;
    }
  }
  return true;
}

/**
 * Tests if an element is present in a given array
 * @param {Object} element to search in the array
 * @param {Array} array of elements
 * @returns {boolean} true if the element is in the array
 */
export function isInArray(element, array) {
  return exists(array) && array.indexOf(element) >= 0;
}

/**
 * Returns true if the element is in the viewport
 * @param {Node} element DOM element to check
 * @param {number} bottom position of the element to check against in the viewport
 * @returns {boolean}
 */
export function isInViewport(element, bottom = 0) {
  if (!exists(global.window)) {
    return false;
  }
  const bounds = element.getBoundingClientRect();
  return bounds.top < window.innerHeight && bounds.bottom > bottom;
}

/**
 * scrolls an element to a given position (in px),
 * will animate if duration > 0
 * @param {Node} element to scroll
 * @param {number} to scroll destination
 * @param {number} duration of animation
 * @param {number} increment step value for scrolling
 */
export function scrollTo(element, to, duration = 400, increment = 20) {
  const localElement = element;
  const start = localElement.scrollTop;
  const change = to - start;
  let currentTime = 0;

  if (!duration) {
    localElement.scrollTop = to;
  } else {
    /**
     * animates scrolling, requires local vars
     */
    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);

      localElement.scrollTop = val;
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    animateScroll();
  }
}

/**
 * Helper function to convert string to safe class name for css
 * @param {string} name as string
 * @returns {string} classname
 */
export const safeClassName = name => {
  const from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç";
  const to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc";
  const mapping = {};

  for (let i = 0, j = from.length; i < j; i += 1) {
    mapping[from.charAt(i)] = to.charAt(i);
  }

  const ret = [];
  for (let i = 0, j = name.length; i < j; i += 1) {
    const c = name.charAt(i);
    if (Object.prototype.hasOwnProperty.call(mapping, name.charAt(i))) {
      ret.push(mapping[c]);
    } else {
      ret.push(c);
    }
  }

  return ret
    .join("")
    .toLowerCase()
    .replace(/[^a-z0-9]/g, s => {
      const c = s.charCodeAt(0);
      if (c === 32) return "-";
      return `__${`000${c.toString(16)}`.slice(-4)}`;
    });
};

/**
 * Returns a truncated string With an ellipsis attached
 * @param {string} str String to be truncated
 * @param {string} maxChars Max length in characters for truncated string
 * @param {string} append What should be appended to the truncated string
 * @param {bool} onlyFullWords Should crop in the middle of a word or not
 * @returns {string}
 */
export const truncateString = (
  str,
  maxChars = globalComponents.truncate.description,
  append = "…",
  onlyFullWords = true,
) => {
  if (!exists(str) || typeof str !== "string") {
    return str;
  }
  // include one extra char for appended char if `onlyFullWords` is true
  const charLimit = onlyFullWords ? maxChars + 1 : maxChars;
  if (str.length <= charLimit) {
    return str;
  }

  let sub = str;
  sub = sub.substr(0, charLimit - append.length);
  // grab whole words if onlyFullWord is true
  sub = onlyFullWords ? sub.substr(0, sub.lastIndexOf(" ")) : sub;
  // ensure last char is not a space
  sub =
    sub.charAt(sub.length - 1) === " " ? sub.substr(0, sub.length - 1) : sub;
  return sub + append;
};

/**
 * add an external script to the page
 * @param {Object} params params to add
 */
export function loadExternalScript(params = {}) {
  const script = document.createElement("script");
  const selector = params.elId ? `[id='${params.elId}']` : "body";
  script.async = true;
  script.id = params.id;
  script.type = "text/javascript";
  script.onload = params.onLoad;
  if (params.src) {
    script.src = params.src;
  }
  if (params.text) {
    script.text = params.text;
  }
  if (exists(global.window) && document.querySelector(selector)) {
    document.querySelector(selector).appendChild(script);
  }
}

/**
 * get taxonomy specified
 * @param {Array} taxonomy
 * @param {Integer} vid
 */
export function getTaxonomy(taxonomy, vid) {
  if (exists(taxonomy)) {
    for (let index = 0; index < taxonomy.length; index++) {
      const element = taxonomy[index];
      if (element.vid === vid) return element;
    }
  }
  return null;
}

/**
 * verify is the content is niños type
 * @param {Object} production
 */
export function isNinos(production) {
  const type = getKey(production, "type", "");
  if (type === "category") {
    const ninosCategories = [
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "2760",
      "2653",
    ];
    const categoriesSiblings = Object.keys(
      getKey(production, "another_categories", {}),
    );
    const checker = (arr, target) => target.every(v => arr.includes(v));
    return (
      ninosCategories.includes(production.id) ||
      checker(categoriesSiblings, ninosCategories)
    );
  }
  return type === "production-kids" || get(production, "slug") === "/ninos";
}
/**
 * verify is the content is niños type
 * @param {Object} production
 */

export function isEducation(production) {
  const productionType = getKey(production, "type");
  if (
    productionType !== "production-learn" &&
    !getKey(config, "section.education", []).find(
      ({ id, slug } = {}) => production.id === id || production.slug === slug,
    )
  ) {
    const taxonomies = getKey(production, "taxonomy", []);
    const educationIds = [3399, 2728, 2727, 2726, 5056];
    return (
      isValidArray(taxonomies) &&
      taxonomies.map(tax => tax.tid).filter(tid => educationIds.includes(tid))
        .length > 0
    );
  }
  return true;
}
/**
 * verify is the show is a podcast type
 * @param {Object} production
 */
export function isPodcast(production) {
  return getKey(production, "type") === "production-audio";
}

/**
 * verify is the object it's empty
 * @param {Object} object to validate
 */
export function isObjectEmpty(object) {
  return Object.entries(object).length === 0 && object.constructor === Object;
}

/**
 * verify is the content is new poster style by taxonomy checkbox (field_new_poster_view service para escuchar) and taxonomy tid ('2' para escuchar) type
 * @param {Object} new_poster_view
 */
export function isNewPosterStyle({ new_poster_view } = {}) {
  if (new_poster_view) {
    return PODCAST_VIEW;
  }
  return "poster";
}
/**
 * replace all url in a string to html link
 * @param {Srting} content to linkfy
 */
export function linkfyString(content) {
  const processString = require("react-process-string");

  let config = [
    {
      // eslint-disable-next-line
      regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
      fn: (key, result) => (
        <span key={key}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}
          >
            {result[2]}.{result[3]}
            {result[4]}
          </a>
          {result[5]}
        </span>
      ),
    },
    {
      // eslint-disable-next-line
      regex: /(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim,
      fn: (key, result) => (
        <span key={key}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`http://${result[1]}.${result[2]}${result[3]}`}
          >
            {result[1]}.{result[2]}
            {result[3]}
          </a>
          {result[4]}
        </span>
      ),
    },
  ];
  return processString(config)(content);
}

export function setLocalStorage(key, value) {
  if (typeof localStorage !== "undefined") {
    localStorage.setItem(key, value);
  }
}

export function getLocalStorage(key) {
  if (typeof localStorage !== "undefined") {
    localStorage.getItem(key);
  }
}

export function removeLocalStorage(key) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(key);
  }
}

export function hasUserFeaturesEnable() {
  return getKey(config, "enableUserFeatures", false);
}

export const isPodcastCategory = data =>
  getKey(config, "categories.podcasts", []).some(
    ({ id, slug } = {}) => data.id === id || data.slug === slug,
  );

export const isUserPath = path =>
  /(^\/login.*$)|(^\/registro.*$)|(^\/bienvenido.*$)|(^\/logout.*$)/.test(path);

export const searchToObject = search => {
  if (!search) {
    return {};
  }
  const pairs = search.substring(1).split("&");
  const obj = {};

  for (const i in pairs) {
    if (pairs[i] === "") continue;

    const pair = pairs[i].split("=");
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }

  return obj;
};

export const logInfo = data => {
  getEnvContent() === "local" && console.log(data);
};

export const getReCaptchaKey = () => config.reCaptchaKey;

export const getDocumentariesData = () => getKey(config, "documentaries", {});

export function removeHTMLBlankSpaces(htmlString) {
  let result = htmlString.replace(/<br\s*\/?>/gi, "");
  result = result.replace(/&nbsp;/gi, "");

  return result;
}

export const formatAudioPlayerTime = timeInSeconds => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

export function copyURLToClipboard(url) {
  navigator.clipboard.writeText(url).then(
    function() {
      console.log("Copying to clipboard was successful!");
    },
    function(err) {
      console.error("Could not copy text: ", err);
    },
  );
}
