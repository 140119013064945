import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { getKey, getTaxonomy } from "../../../../utils/helpers";
import SocialButtons from "../../../../components/MolShareButton";
import Rating from "../../../../components/AtRating/AtRating";
import MolTrailerPlayer from "../../../../components/MolTrailerPlayer";

import {
  BrandLogo,
  Category,
  WrapperOpening,
  ContentOpening,
  WrapperMetadata,
  InnerWrapperMetadata,
  MetaData,
  LongDescriptionWrapper,
  TrailerPlayerContainer,
  TitleContainer,
  Title,
  ProductionLogo,
  RatingContainer,
  SocialButtonsContainer
} from "./CommingSoon";

export default function Index({
  title,
  taxonomy,
  resource,
  description,
  longDescription,
  trailer,
  slug,
  rating,
  streaming_provider
}) {
  const [coverImage, setCoverImage] = useState(isMobile ? "mobile" : "desktop");
  const brand = getTaxonomy(taxonomy, "brands");
  const category = getTaxonomy(taxonomy, "categories");
  const brand_logo_negative = getKey(brand, "image.logo_negative.path");

  useEffect(() => {
    let landscape = window.innerWidth > window.innerHeight;
    setCoverImage(isMobile && !landscape ? "mobile" : "desktop");
    window.onresize = () => {
      landscape = window.innerWidth > window.innerHeight;
      setCoverImage(isMobile && !landscape ? "mobile" : "desktop");
    };
  });

  const loadResource = resource => {
    let poster = getKey(resource, "image.cover_" + coverImage + ".path", null);
    if (poster === undefined) return <div />;
    return <img src={poster} alt={title} title={title} />;
  };
  const logoProduction = getKey(resource, "logo.path");
  return (
    <WrapperOpening>
      <ContentOpening>
        {loadResource(resource)}
        <WrapperMetadata>
          <TitleContainer>
            <Title>Proximamente</Title>
          </TitleContainer>
          <InnerWrapperMetadata>
            <TrailerPlayerContainer>
              <MolTrailerPlayer
                streaming_provider={streaming_provider}
                title={title}
                trailer={trailer}
              />
            </TrailerPlayerContainer>
            <MetaData>
              <div>
                {brand_logo_negative && <BrandLogo src={brand_logo_negative} />}
                {logoProduction ? (
                  <ProductionLogo
                    src={logoProduction}
                    alt={title}
                    title={title}
                  />
                ) : (
                  <h1>{title}</h1>
                )}
                <Category>{getKey(category, "value", "")}</Category>
                {rating && (
                  <RatingContainer>
                    <Rating data={rating.value.split(",")} />
                  </RatingContainer>
                )}
                <LongDescriptionWrapper
                  dangerouslySetInnerHTML={{ __html: longDescription }}
                />
                <SocialButtonsContainer>
                  <SocialButtons
                    title={title}
                    text={description}
                    size={32}
                    sizeButtons={20}
                    uri={slug}
                    renderAllButtons
                  />
                </SocialButtonsContainer>
              </div>
            </MetaData>
          </InnerWrapperMetadata>
        </WrapperMetadata>
      </ContentOpening>
    </WrapperOpening>
  );
}
