import styled from "styled-components";
import { Colors, Fonts } from "../../../theme";
import AtIcon from "../../AtIcon/AtIcon";
import AtIconButton from "../../AtIconButton/AtIconButton";

export const ContentLoginButton = styled.div`
  position: relative;
  max-width: 440px;
  color: ${Colors.bg1};
  display: flex;
  flex-basis: 240px;
  border-left: 1px solid #333;
  padding-left: 10px;
  margin-left: 10px;
`;

export const Icon = styled(AtIcon)``;

export const IconButton = styled(AtIconButton)`
  border-radius: 50%;
  padding: 8px 8px 6px 8px;
`;

export const Span = styled(Fonts.span)`
  vertical-align: text-top;
  max-width: 50px;
  overflow: hidden;
  display: inline-flex;
  text-overflow: ellipsis;
  height: 24px;
  word-break: break-word;
  margin-left: 5px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Link = styled(Fonts.a)`
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  &.closeModalButton {
    display: flex;
    float: right;
  }
`;

export const WrapperUserImage = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #fff;
  overflow: hidden;
  outline: none;
  display: inline-flex;
  margin-right: 5px;
  margin-top: 6px;
  img {
    max-width: 100%;
  }
  button {
    padding: 0px;
  }
`;
