import React, { useEffect } from "react";
import AtDate from "../AtDate";
import { Container, InnerContainer, ListItem } from "./MolCalendar";

export const MolCalendar = ({
  activar,
  dateSelected,
  fechas,
  canal,
  fetchDataGrill
}) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const molCalendarItemSelected = document.querySelector(
        ".MolCalendarItemSelected"
      );
      !!molCalendarItemSelected &&
        molCalendarItemSelected.scrollIntoViewIfNeeded(true);
    }
  });

  return (
    activar && (
      <Container>
        <InnerContainer>
          {fechas.map(dia => {
            const fechahoy = new Date();
            const semana = ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"];
            var calend = dia.value;
            let fecha = calend.split("/");
            let dateS = new Date(fecha[2], fecha[1] - 1, fecha[0]);
            let sem = dateS.getDay();
            let diasem = semana[sem];
            if (
              fechahoy.getFullYear() === dateS.getFullYear() &&
              fechahoy.getMonth() === dateS.getMonth() &&
              fechahoy.getDate() === dateS.getDate()
            ) {
              diasem = "HOY";
            }
            const isActive = dateSelected === dia.valueForRequest;
            return (
              <ListItem className={isActive && "MolCalendarItemSelected"}>
                <AtDate
                  dia={diasem}
                  num={fecha[0]}
                  fecha={dia.value}
                  date={dia.valueForRequest}
                  active={isActive}
                  canal={canal}
                  fetchDataGrill={fetchDataGrill}
                />
              </ListItem>
            );
          })}
        </InnerContainer>
      </Container>
    )
  );
};

export default MolCalendar;
