import React, { Component, Fragment } from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { rgba } from "polished";
import "./OrVerticalList.scss";
import MolHorizontalListView from "../MolHorizontalListView/MolHorizontalListView";
import TplTitleBlock from "../TplTitleBlock/TplTitleBlock";
import AtDropdown from "../AtDropdown/AtDropdown";
import Pager from "../MolPager";
import OrModalWelcome from "../OrModalWelcome";
import { Colors, Metrics, Wp, Fonts } from "../../theme";
import { getKey, isValidArray } from "../../utils/helpers";
import { PODCAST_VIEW } from "../../constants";
import AtIcon from "../AtIcon/AtIcon";

const MOBILE_M = parseInt(Metrics.size.mobileM, 10);
const MOBILE_L = parseInt(Metrics.size.mobileL, 10);
const TABLET = parseInt(Metrics.size.tablet, 10);
const TABLET_L = parseInt(Metrics.size.tabletL, 10);

const Header = styled(Wp.Container)`
  margin-top: ${Metrics.size.minHeaderHeight};
  min-height: 60px;
  padding-top: 8px;
  @media ${Metrics.mediaQ.tablet} {
    padding-top: 96px;
    margin-top: 0px;
  }
  border-bottom: ${props => props.borderBottom};
`;

const Container = styled(Wp.Page)`
  @media screen and (max-width: 767px) {
    padding: 10px;
  }
  @media ${Metrics.mediaQ.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  gap: 5px;
`;
const BackLink = styled.a`
  border: 2px solid white;
  border-radius: 50%;
  height: fit-content;
  margin: auto 0;
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled(Fonts.h1)`
  font-family: "Dosis", sans-serif;
  font-size: 28px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: initial;
`;
const Vocabulary = styled(Fonts.h4)`
  font-family: "Dosis", sans-serif;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: initial;
`;
const Dropdown = styled(AtDropdown)`
  @media screen and (max-width: 767px) {
    width: 100%;
    border: 1px solid #707070;
    margin: 10px 0 0;
  }
`;

const TitleBlock = styled(TplTitleBlock)`
  background-color: ${props => props.backgroundColor};
  padding-bottom: 40px;
  border-top: ${props => props.borderTop};
  .block-body {
    padding-top: 1em;
  }
  .inner-container {
    padding: 0px 10px;
  }
  ${props => props.customStyles || ""};
`;

const List = styled(Wp.Container)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 374px) {
    zoom: 0.85;
  }

  ${props => {
    return `
      &.marginaudio{
        max-width: 1100px;
        justify-content: space-around;
        padding: 0 15px;
        @media ${Metrics.mediaQ.tablet} {
          padding: 0;
        }
      }
      @media ${Metrics.mediaQ.laptop} {
        max-width: ${(props.view.width + 10) * 4}px;
        margin: 0 auto;
      }        
      .vertical-list {
        position: relative;            
        margin: 5px;
        &.marginaudio {
          margin: 0;
        }
      }
    `;
  }};
`;

const MetaDescription = styled.h2`
  display: none;
`;

class OrVerticalList extends Component {
  state = { animateTitleBlock: false, containerWidth: null };
  static defaultProps = {
    view: "poster",
    title: "",
    contents: [],
    another_categories: null,
    styles: "",
    page: false
  };
  _calcScale = (width, cover = false) => {
    const { containerWidth } = this.state;
    let scale = 1;
    if (containerWidth) {
      scale = (containerWidth / (cover ? 1 : 2) - 10) / width;
      if (containerWidth >= TABLET_L - 20) {
        let calc = (containerWidth / 4 - 10) / width;
        scale = calc < 1 ? calc : 1;
      } else if (containerWidth >= TABLET - 20) {
        scale = (containerWidth / 3 - 10) / width;
      } else if (containerWidth >= MOBILE_L - 20) {
        scale = (containerWidth / 3 - 10) / width;
      } else if (containerWidth >= MOBILE_M - 20) {
        scale = (containerWidth / 2 - 10) / width;
      }
    }
    return cover ? scale : scale < 1 ? scale : 1;
  };
  _scroll = ev => {
    if (this.refs.titleBlock && this.refs.titleBlock.refs.blockTitle) {
      let blockTitleElTop = this.refs.titleBlock.refs.blockTitle.getBoundingClientRect()
        .top;
      if (
        blockTitleElTop < window.innerHeight - 250 &&
        !this.state.animateTitleBlock
      ) {
        this.setState({
          animateTitleBlock: true
        });
      }
    }
  };
  _resize = () => {
    const containerWidthEl = document.getElementById("blockBodyContainer");
    if (containerWidthEl) {
      this.setState({
        containerWidth: containerWidthEl.offsetWidth
      });
    }
  };
  componentDidMount() {
    window.addEventListener("resize", this._resize);
    document.addEventListener("scroll", this._scroll);
    const containerWidthEl = document.getElementById("blockBodyContainer");
    if (containerWidthEl) {
      this.setState({
        containerWidth: containerWidthEl.offsetWidth
      });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this._resize);
    document.removeEventListener("scroll", this._scroll);
  }
  render() {
    const {
      idKey,
      title,
      shortTitle,
      description,
      styles,
      view,
      contents,
      page,
      metadata,
      searchContent,
      brands,
      is_it_education,
      isNinos,
      isGridMode,
      pluralText,
      meta_tags,
      vocabulary,
      imagen_opening_banner
    } = this.props;
    const metaDescription = getKey(
      meta_tags,
      "description['#attributes'].content"
    );
    const isNewAudioPoster = view === PODCAST_VIEW;
    const views = {
      poster: {
        className: "poster",
        imageKey: "poster",
        backgroundColor: is_it_education
          ? Colors.educacionBackground
          : isNinos
            ? Colors.ninosBackgroundCover
            : Colors.bg2,
        width: 220,
        height: 330,
        scale: this._calcScale(220, true),
        spaceBetween: 20,
        infoHover: {
          customStyles: `
	          	border: 1px solid ${Colors.white};
	          	background: radial-gradient(circle at center 43%, ${rgba(
                Colors.rating,
                0.85
              )} 0%,rgba(0,0,0,.9) 60%,rgba(0,0,0,1) 100%);
					.play-button {
						top: 15%;
					}
				`
        }
      },
      cover: {
        className: "cover",
        imageKey: "cover",
        backgroundColor: isNinos ? Colors.ninosBackgroundCover : Colors.bg2,
        width: 265,
        height: 149,
        scale: this._calcScale(265, true),
        spaceBetween: 20,
        infoFooter: {
          height: 56,
          customStyles: ``
        },
        contentTypeIcon: true
      },
      bigCover: {
        className: "cover",
        imageKey: "cover",
        backgroundColor: isNinos ? Colors.ninosBackgroundCover : Colors.bg2,
        width: 410,
        height: 231,
        scale: this._calcScale(isMobile ? 410 : 205, true),
        spaceBetween: 20,
        contentTypeIcon: false
      },
      [PODCAST_VIEW]: {
        className: PODCAST_VIEW,
        isNewAudioPoster,
        imageKey: "cover_podcast",
        backgroundColor: is_it_education
          ? Colors.educacionBackground
          : isNinos
            ? Colors.ninosBackgroundCover
            : Colors.bg2,
        width: 244,
        height: 277,

        scale: this._calcScale(265),
        spaceBetween: 20,
        infoHover: {
          customStyles: `
              height: 244px;
	          border: 1px solid ${Colors.white};
	          background: rgba(9, 62,71,0.9);
              .play-button {
                  top: 15%;
              }
          `
        }
      }
    };

    const View = views[view];
    let dropdownList = null;
    if (brands) {
      let keys = Object.keys(brands);
      dropdownList = keys.map(k => {
        return { ...brands[k], ID: k };
      });
    }
    return (
      <Fragment>
        {page && (
          <Header
            className={is_it_education ? `educationView` : ``}
            borderBottom={
              is_it_education
                ? "1px solid #695d5f"
                : "1px solid rgba(255, 255, 255, 0.2)"
            }
          >
            <Container>
              <TitleContainer>
                {!!vocabulary.url_section && (
                  <BackLink href={vocabulary.url_section}>
                    <AtIcon name="ArrowBack" size={24} />
                  </BackLink>
                )}
                <Text>
                  <Vocabulary>{vocabulary.name}</Vocabulary>
                  <Title>{title}</Title>
                </Text>
              </TitleContainer>
              {metaDescription && (
                <MetaDescription>{metaDescription}</MetaDescription>
              )}
              {dropdownList &&
                !is_it_education && (
                  <Dropdown
                    title="Filtrar por:"
                    list={dropdownList}
                    onItemClick={(itm, index) =>
                      (document.location.href = itm.slug)
                    }
                  />
                )}
            </Container>
          </Header>
        )}
        <TitleBlock
          id={idKey}
          ref="titleBlock"
          title={title}
          description={description}
          customStyles={styles}
          backgroundColor={View.backgroundColor}
          animateTitle={this.state.animateTitleBlock}
          showTitle={!page}
          isGridMode={isGridMode}
          textRight={
            isGridMode
              ? `${contents.length} ${
                  contents.length > 1
                    ? pluralText || "contenidos"
                    : shortTitle || "contenido"
                }`
              : null
          }
        >
          {
            imagen_opening_banner && imagen_opening_banner.image && (
              <div
                style={{
                  margin: "-1em auto 0",
                  maxWidth: "1076px",
                  width: "100%"
                }}
              >
                <img
                  alt={title}
                  style={{ width: "100%" }}
                  src={imagen_opening_banner.image.path}
                />
              </div>
            )
          }
          <List
            containerWidth={this.state.containerWidth}
            view={View}
            className={isNewAudioPoster && "vertical-list marginaudio"}
          >
            {this.state.containerWidth &&
              isValidArray(contents) &&
              contents.map((content, index) => (
                <MolHorizontalListView
                  key={index}
                  content={{
                    ...content,
                    title: isGridMode ? shortTitle : getKey(content, "title")
                  }}
                  view={View}
                  index={index}
                  className={
                    isNewAudioPoster
                      ? "vertical-list marginaudio"
                      : "vertical-list"
                  }
                />
              ))}
          </List>
          {metadata && (
            <Pager
              {...metadata}
              uri={searchContent && "/buscar/" + searchContent.q}
            />
          )}
        </TitleBlock>
        {is_it_education ? <OrModalWelcome /> : ""}
      </Fragment>
    );
  }
}

export default OrVerticalList;
