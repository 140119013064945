import { formatAudioPlayerTime } from "../../../utils/helpers";
import { AudioPlayerProgress } from "./AudioPlayerProgress";
import React, { useState } from "react";
import { AudioPlayerContainer, AudioPlayerType, Metadata } from "./index";
import styled from "styled-components";

export const AudioPlayerSimple = ({
  audioRef,
  audio,
  handleSeek,
  handleEnded,
  progress,
}) => {
  const [currentTime, setCurrentTime] = useState(0);
  return (
    <AudioPlayerContainer>
      <audio
        ref={audioRef}
        src={audio.file}
        onEnded={handleEnded}
        onTimeUpdate={e => setCurrentTime(e.target.currentTime)}
      />
      <Container>
        <CurrentTimer>
          {formatAudioPlayerTime(currentTime)} /
          {audioRef.current && !isNaN(audioRef.current.duration)
            ? formatAudioPlayerTime(audioRef.current.duration)
            : audio.duration}
        </CurrentTimer>
        <Metadata>
          <AudioPlayerProgress
            progress={progress}
            handleSeek={handleSeek}
            audioPlayerType={AudioPlayerType.SIMPLE}
          />
        </Metadata>
      </Container>
    </AudioPlayerContainer>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const CurrentTimer = styled.div`
  position: absolute;
  right: 0;
  top: -47px;
  font-family: Dosis, sans-serif;
  font-size: 18px;
  line-height: 20px;
`;
