import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "reactstrap";
import styled from "styled-components";

import { Colors } from "../../../theme";
import { getKey } from "../../../utils/helpers";
import { Close } from "../../AtButton";

const REGISTRATE_TEXT = "REGISTRATE";
const INICIA_SESION_TEXT = "INICIA SESIÓN";

const MyListToolTip = ({ currentId, isOpen, setOpen }) => {
  const toggleButton = e => {
    if (e.type === "focusout") {
      const relatedTargetInnerText = getKey(e, "relatedTarget.innerText");

      if (relatedTargetInnerText === REGISTRATE_TEXT) {
        goSignUp();
      }
      if (relatedTargetInnerText === INICIA_SESION_TEXT) {
        goLogin();
      }

      setTimeout(() => setOpen && setOpen(!isOpen), 100);
    }
  };

  const goSignUp = () => {
    window.location.href = "/registro";
  };
  const goLogin = () => {
    document
      .querySelector("div[class^=Button__ContentLoginButton] button")
      .click();
  };
  return (
    <Tooltip
      className="MyListToolTip"
      placement="top"
      isOpen={isOpen}
      target={currentId}
      toggle={toggleButton}
    >
      <Close onClick={() => setOpen && setOpen(false)} />
      <ToolTipMessage>Regístrate gratis y accede a esta función</ToolTipMessage>
      <Button onClick={goSignUp} style={{ zIndex: 9999 }}>
        {REGISTRATE_TEXT}
      </Button>
      <ToolTipLogin>
        <Link to={_ => {}} onClick={goLogin} className="lnk-login">
          {INICIA_SESION_TEXT}
        </Link>
      </ToolTipLogin>
    </Tooltip>
  );
};

export const Button = styled.button`
  background: ${Colors.cerulean};
  color: ${Colors.white};
  border: none;
  font-size: 1em;
  font-family: "Dosis", sans-serif;
  line-height: 0.864;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 34px;
  outline: none;
  cursor: pointer;
`;

export const ToolTipMessage = styled.div`
  color: #fff;
  font-size: 18px;
  padding: 10px 15px;
  text-align: center;
  font-family: "Dosis", serif;
`;

export const ToolTipLogin = styled.div`
  text-align: center;
  a {
    display: block;
    font-family: "Dosis", serif;
    font-weight: 500;
    color: #009bdb;
    margin: 10px;
  }
`;

export default MyListToolTip;
