import styled from "styled-components";

export const GoogleWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  height: 45px;
  & button {
    width: 100%;
    height: 100% !important;
    outline: none;
    font-family: Roboto, arial, sans-serif;
    font-size: 16px;
    & > span {
      padding: 0 !important;
    }
  }
`;
