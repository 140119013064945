import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";

class Heartbeat extends Component {
  render() {
    return (
      <Fragment>
        <Helmet
          script={[
            {
              type: "text/javascript",
              src: "/scripts/heartbeat.js"
            }
          ]}
        />
      </Fragment>
    );
  }
}

export default Heartbeat;
