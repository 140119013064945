import React from "react";
import { isMobile } from "react-device-detect";

import {
  Category,
  Label,
  MetadataContainer,
  Wrapper,
  ImagePoster,
  ContentImageCover,
  ProductionTitle,
  DescriptionWrapper,
  RatingContainer,
  MetadataInnerContainer,
  BackGradient,
  ButtonsContainer,
  ExpanedView,
  CollapseView,
  ImagePosterInactive,
  ImagePosterInactiveReflected,
  ContainerReflection,
  LayerGradientReflection,
  Link,
  Player
} from "./OriginalItem";

import { getKey } from "../../../utils/helpers";
import Rating from "../../AtRating/AtRating";

import AddMyListButton from "../../MolAddMyListButton";

import ShowTrailerButton from "../../MolShowTrailerButton";
import { LitlePlayIcon } from "../../OrPodcastWidget/PodcastMainItem/PodcastMainItem";
import { ListenNow } from "../../OrCineWidget/CineItem/CineItem";

export default function OriginalItem({
  id,
  category,
  image,
  label,
  label_button,
  micro_synopsis,
  slug,
  title,
  trailer,
  key,
  rating: { value: ratingName },
  streaming_provider,
  video
}) {
  if (!slug && !image) {
    return null;
  }
  const path_cover = getKey(
    image,
    `${isMobile ? "cover_mobile" : "poster_full_hd_desktop"}.path`
  );
  const path_poster = getKey(image, "poster.path");

  const videoAttr = video => {
    return {
      config: {
        file: {
          attributes: {
            autoPlay: true,
            poster: "",
            loop: true,
            controls: false,
            muted: true
          }
        }
      },
      url: [
        {
          src: video.video_desktop,
          preload: "auto"
        }
      ]
    };
  };

  const ToggleTransform = () => {
    if (typeof window !== "undefined") {
      try {
        const swiperContainer = document.querySelector(`.wrapperOrg${id}`)
          .parentElement.parentElement;
        swiperContainer.classList.toggle("withoutTransform");
      } catch (e) {}
    }
  };

  return (
    <Wrapper key={key} className={`wrapperOriginalItem wrapperOrg${id}`}>
      <Link href={slug}>
        <ExpanedView className="expanedView">
          <BackGradient />
          <ContentImageCover>
            <img alt={title} src={path_cover} />
            {video && (
              <Player
                coverOverflow
                showAudioButton={false}
                showProgressBar={false}
                {...videoAttr(video)}
                loop
                isBackgroundVideo
              />
            )}
          </ContentImageCover>
          <Label color="#009DE4" el="h3">
            {label}
          </Label>
          <MetadataContainer>
            <ImagePoster src={path_poster} />
            <MetadataInnerContainer>
              <Category>{category}</Category>
              <ProductionTitle>{title}</ProductionTitle>
              <RatingContainer>
                <Rating data={[ratingName]} />
              </RatingContainer>
              <DescriptionWrapper
                dangerouslySetInnerHTML={{ __html: micro_synopsis }}
              />
              <ButtonsContainer>
                {trailer && (
                  <ShowTrailerButton
                    streaming_provider={streaming_provider}
                    title={title}
                    trailer={trailer}
                    onOpened={ToggleTransform}
                    onClosed={ToggleTransform}
                  />
                )}
                <ListenNow>
                  <LitlePlayIcon
                    className="play-icon"
                    name="PlayCircle"
                    size={30}
                  />
                  <span>
                    {label_button ? label_button.toUpperCase() : "VER SERIE"}
                  </span>
                </ListenNow>
                <AddMyListButton id={id} />
              </ButtonsContainer>
            </MetadataInnerContainer>
          </MetadataContainer>
        </ExpanedView>
        <CollapseView className="collapseView">
          <ImagePosterInactive src={path_poster} />
          <ContainerReflection>
            <LayerGradientReflection />
            <ImagePosterInactiveReflected src={path_poster} />
          </ContainerReflection>
        </CollapseView>
      </Link>
    </Wrapper>
  );
}
