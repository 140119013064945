import React, { Component } from "react";
import { rgba } from "polished";

import LightBox from "../MolLightbox/MolLightbox";
import MolHorizontalListView from "../MolHorizontalListView/MolHorizontalListView";

import { Colors } from "../../theme";
import { PODCAST_VIEW } from "../../constants";

import { TitleBlock, Swiper } from "./OrHorizontalList";

class HorizontalList extends Component {
  constructor(props) {
    super(props);
    this.openLightBox = this.openLightBox.bind(this);
    this.closeLightBox = this.closeLightBox.bind(this);
    this.state = {
      isMobile: false
    };
  }

  state = { animateTitleBlock: false, lightBoxIndex: 0, lightBoxRender: false };
  static defaultProps = {
    view: "poster",
    title: "",
    contents: [],
    showTitle: true
  };
  _scroll = ev => {
    if (this.refs.titleBlock && this.refs.titleBlock.refs.blockTitle) {
      let blockTitleElTop = this.refs.titleBlock.refs.blockTitle.getBoundingClientRect()
        .top;
      if (
        blockTitleElTop < window.innerHeight - 250 &&
        !this.state.animateTitleBlock
      ) {
        this.setState({ animateTitleBlock: true });
      }
    }
  };
  componentDidMount() {
    document.addEventListener("scroll", this._scroll);
    if (typeof window !== "undefined" && !this.state.isMobile) {
      this.setState({
        isMobile: window.outerWidth < 768
      });
    }
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this._scroll);
  }

  openLightBox(e, index, isLightBox) {
    if (isLightBox) {
      e.preventDefault();
      global.window.document
        .querySelector("body")
        .classList.toggle("disableScroll");
      this.setState({
        lightBoxIndex: index + 1,
        lightBoxRender: true
      });
    }
  }

  closeLightBox() {
    global.window.document
      .querySelector("body")
      .classList.toggle("disableScroll");
    this.setState({
      lightBoxRender: false
    });
  }

  render() {
    const {
      id,
      title,
      contents,
      view,
      styles,
      showTitle,
      isNinos,
      isEducation,
      isShow,
      isVideo,
      showModal,
      isLightBox,
      isPodcast,
      streaming_provider,
      initialSlide,
      description,
      isCineSection
    } = this.props;
    const isReallyShow = !!isShow;
    const hideMetaInfo = !isReallyShow && this.state.isMobile && !isCineSection;
    const views = {
      poster: {
        className: "poster",
        imageKey: "poster",
        backgroundColor: isEducation ? Colors.educacionBackground : Colors.bg2,
        width: hideMetaInfo ? 200 : isCineSection ? 401 : 265,
        height: hideMetaInfo ? 300 : isCineSection ? 597 : 399,
        spaceBetween: hideMetaInfo ? 5 : isCineSection ? 30 : 10,
        infoHover: {
          customStyles: `
						border: 1px solid ${Colors.white};
						background: radial-gradient(circle at center 43%, ${rgba(
              Colors.rating,
              0.85
            )} 0%,rgba(0,0,0,.9) 60%,rgba(0,0,0,1) 100%);
						.play-button {
							top: 15%;
						}
					`
        }
      },
      posterLarge: {
        className: "poster-large",
        imageKey: "poster",
        backgroundColor: isEducation ? Colors.educacionBackground : Colors.bg2,
        width: 265,
        height: 399,
        spaceBetween: 10,
        firstSlideWidth: 709,
        firstImageKey: "poster_large",
        infoHover: {
          customStyles: `
						border: 1px solid ${Colors.white};
						background: radial-gradient(circle at center 43%, ${rgba(
              Colors.rating,
              0.85
            )} 0%,rgba(0,0,0,.9) 60%,rgba(0,0,0,1) 100%);
						.play-button {
							top: 15%;
						}
					`
        }
      },
      posterBig: {
        className: "poster",
        imageKey: "poster",
        backgroundColor: Colors.bg2,
        width: 265,
        height: 399,
        spaceBetween: 10,
        infoHover: {
          customStyles: `
						border: 1px solid ${Colors.white};
						background: radial-gradient(circle at center 43%, ${rgba(
              Colors.rating,
              0.85
            )} 0%,rgba(0,0,0,.9) 60%,rgba(0,0,0,1) 100%);
						.play-button {
							top: 15%;
						}
					`
        }
      },
      live: {
        className: "live",
        imageKey: "cover",
        backgroundColor: Colors.bg2,
        width: 309,
        height: 232,
        spaceBetween: 10,
        infoHover: {
          customStyles: `
            border: 1px solid ${Colors.white};
						background: radial-gradient(circle, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 70%,rgba(0,0,0,1) 100%);
						.info-body,
						.info-actions {
							display: none;
						}
					`
        },
        linkContainer: true
      },
      cover: {
        className: "cover",
        imageKey: "cover",
        backgroundColor: Colors.bg2,
        width: 383,
        height: hideMetaInfo ? 160 : 215,
        spaceBetween: 5,
        infoFooter: {
          height: 56,
          customStyles: `
          @media screen and (max-width: 767px) { 
            ${hideMetaInfo && `display: none !important`};
          }`
        },
        contentTypeIcon: true
      },
      cover_nocolor: {
        className: "cover",
        imageKey: "cover",
        backgroundColor: Colors.transparent,
        width: 265,
        height: 149,
        spaceBetween: 10,
        infoFooter: {
          height: 56,
          customStyles: ``
        },
        contentTypeIcon: true
      },
      cover_colorbg1: {
        className: "cover",
        imageKey: "cover",
        backgroundColor: Colors.bg1,
        width: 265,
        height: 149,
        spaceBetween: 10,
        infoFooter: {
          height: 56,
          customStyles: ``
        },
        contentTypeIcon: true
      },
      cover_originales: {
        className: "cover",
        imageKey: "cover",
        backgroundColor: Colors.originales,
        width: 383,
        height: hideMetaInfo ? 160 : 215,
        spaceBetween: 5,
        infoFooter: {
          height: 56,
          customStyles: `
          @media screen and (max-width: 767px) { 
            ${hideMetaInfo && `display: none !important`};
          }`
        },
        contentTypeIcon: true
      },
      kids: {
        className: "cover",
        imageKey: "cover",
        backgroundColor: Colors.bg2,
        scaleMobile: 0.6,
        width: 1240 / 3,
        height: 242,
        spaceBetween: 15,
        infoFooter: {
          height: 0,
          customStyles: ``
        },
        contentTypeIcon: false
      },
      video: {
        className: "video",
        imageKey: "video",
        backgroundColor: Colors.originales,
        width: 950,
        height: 330,
        spaceBetween: 10,
        scaleMobile: 0.4,
        contentTypeIcon: true
      },
      podcast: {
        className: "podcast",
        imageKey: "podcast",
        backgroundColor: Colors.originales,
        scaleMobile: 0.9,
        width: 265,
        height: 265,
        spaceBetween: 10,
        infoFooter: {
          height: 75,
          customStyles: ``
        },
        contentTypeIcon: true
      },
      [PODCAST_VIEW]: {
        className: PODCAST_VIEW,
        isNewAudioPoster: true,
        imageKey: "poster",
        backgroundColor: isNinos ? Colors.ninosBackgroundCover : Colors.bg2,
        width: 210,
        height: 264,
        spaceBetween: 20,
        infoHover: {
          customStyles: `
              height: 225px;
	          	border: 1px solid ${Colors.white};
	          	background: rgba(9, 62,71,0.9);
					.play-button {
						top: 15%;
					}
				`
        }
      },
      personaje: {
        name: "personaje",
        className: "video",
        imageKey: "image_mobile.cover_chapter",
        imageKeyFallback: "cover.cover_desktop",
        backgroundColor: Colors.originales,
        width: 300,
        height: 399,
        spaceBetween: 10,
        contentTypeIcon: true
      }
    };
    const View = views[view] || views.cover;

    let lightBoxData = [];

    if (isLightBox) {
      contents.map(content => {
        if (content.video) {
          lightBoxData.push({
            url: `${content.video.uri_embed}`,
            type: "video",
            altTag: `${content.video.title}`
          });
        } else if (content.image) {
          lightBoxData.push({
            url: `${content.image.originales.path}`,
            type: "photo",
            altTag: `${content.image.originales.title}`
          });
        }
        return null;
      });
    }
    return (
      <TitleBlock
        id={id}
        ref="titleBlock"
        title={title}
        description={description}
        customStyles={styles}
        isNinos={isNinos}
        isEducation={isEducation}
        backgroundColor={View.backgroundColor}
        borderTop={View.borderTop}
        showTitle={showTitle}
        animateTitle={this.state.animateTitleBlock}
      >
        <Swiper
          backgroundColor={View.backgroundColor}
          responsive={false}
          swiperParams={{
            spaceBetween: View.spaceBetween,
            pagination: { el: null, type: null },
            slidesPerView: "auto",
            initialSlide,
            allowTouchMove: false,
            breakpoints: {
              767: {
                allowTouchMove: true
              }
            }
          }}
        >
          {contents.map((content, index) => (
            <MolHorizontalListView
              key={index}
              content={content}
              view={View}
              index={index}
              isVideo={isVideo}
              hideMetaInfo={hideMetaInfo}
              streaming_provider={streaming_provider}
              styles={styles}
              showModal={showModal}
              isLightBox={isLightBox}
              isPodcast={isPodcast}
              openLightBox={this.openLightBox}
              isCineSection={isCineSection}
            />
          ))}
        </Swiper>
        {isLightBox &&
          this.state.lightBoxRender && (
            <LightBox
              lightBoxData={lightBoxData}
              lightBoxIndex={this.state.lightBoxIndex}
              closeLightBox={this.closeLightBox}
            />
          )}
      </TitleBlock>
    );
  }
}

export default HorizontalList;
