import styled from "styled-components";
import { Colors, Wp } from "../../theme";
import TplTitleBlock from "../TplTitleBlock/TplTitleBlock";
import { rgba } from "polished";

export const Wrapper = styled(Wp.Container)`
  .swiper-slide {
    width: 310px;

    &:first-child {
      width: 494px;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    @media screen and (min-width: 768px) {
      margin-right: 20px;
    }
  }
  .swiper-button-prev {
    &::before {
      content: "";
      top: calc(15px - 18em);
      left: -12px;
      position: absolute;
      width: 40px;
      height: 37em;
      background: linear-gradient(
        to left,
        ${rgba(Colors.bg2, 0)} 0%,
        ${rgba(Colors.bg2, 1)} 100%
      );
    }
  }

  .swiper-button-next {
    &::before {
      content: "";
      top: calc(15px - 18em);
      right: -12px;
      position: absolute;
      width: 40px;
      height: 37em;
      background: linear-gradient(
        to right,
        ${rgba(Colors.bg2, 0)} 0%,
        ${rgba(Colors.bg2, 1)} 100%
      );
    }
  }
  .swiper-button-disabled {
    opacity: 0 !important;
  }
`;

export const InnerWrapper = styled(Wp.Page)`
  position: relative;
  min-height: 420px;
  padding: 0;
`;
export const PodcastList = styled(Wp.Page)`
  padding: 0;
  @media screen and (min-width: 768px) {
  }
`;

export const TitleBlock = styled(TplTitleBlock)`
  padding-top: ${props => (props.showTitle ? 0.75 : 0)}em;
  padding-bottom: ${props => (props.showTitle || props.isNinos ? 1 : 0)}em;

  ${props => props.customStyles || ""};
`;

export const IconButton = styled.button`
  background: none !important;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  height: 29px;
  width: 29px;
  top: calc(50% - 14px);
  .icon {
    border: 2px solid ${Colors.white};
    border-radius: 50%;
    filter: drop-shadow(0px 1px 2px ${rgba(Colors.black, 0.4)});
  }
`;

export const ContainerMainPodcastMobile = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
export const ContainerMainPodcastDesktop = styled.div`
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const ContainerList = styled.div`
  @media screen and (max-width: 767px) {
    margin: 0 0 0 15px;
  }
`;
