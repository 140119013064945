import React, { Component, Fragment } from "react";
import classnames from "classnames";
import { isMobileOnly, isMobile } from "react-device-detect";

import "./opening.scss";
import { getKey, getTaxonomy } from "../../../utils/helpers";
import AtIcon from "../../../components/AtIcon/AtIcon";
import SocialButtons from "../../../components/MolShareButton";
import ShowTrailerButton from "../../../components/MolShowTrailerButton";
import VideoOpening from "../../../components/AtVideoOpening";
import * as pageCategory from "../../../constants/pageType";
import MolAudio from "../../../components/MolAudio";
import Rating from "../../../components/AtRating/AtRating";
import AtTimeLeft from "../../../components/AtTimeLeft";
import CommingSoon from "./CommingSoon/index";
import AddMyListButton from "../../../components/MolAddMyListButton";
import styled from "styled-components";

class Opening extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: false,
      mobileViewPort: null,
      coverImage: null
    };
  }
  componentDidMount() {
    let landscape = window.innerWidth > window.innerHeight;
    this.setState({
      mobileViewPort: isMobileOnly, //window.innerWidth < 835 && !landscape,
      coverImage: isMobile && !landscape ? "mobile" : "desktop"
    });
    window.onresize = () => {
      landscape = window.innerWidth > window.innerHeight;
      this.setState({
        mobileViewPort: isMobileOnly, //window.innerWidth < 835 && !landscape,
        coverImage: isMobile && !landscape ? "mobile" : "desktop"
      });
    };
  }
  loadResource(resource) {
    let poster = getKey(
      resource,
      "image.cover_" + this.state.coverImage + ".path",
      null
    );
    if (resource.video) {
      let data = {
        type: "video",
        player: getKey(resource, "video.video_" + this.state.coverImage, null),
        videoAttr: `autoplay poster="${poster ||
          ""}" loop muted playsinline preload="auto"`
      };
      return <VideoOpening {...data} className="videoOpening" />;
    }
    if (poster === undefined) return <div />;
    return <img src={poster} alt={this.props.title} title={this.props.title} />;
  }

  infoDetail(e) {
    e.preventDefault();
    global.window.document
      .querySelector(".contentDetailInfo")
      .classList.toggle("display");
  }
  render() {
    const {
      id,
      type,
      audios,
      title,
      genre,
      taxonomy,
      resource,
      description,
      longDescription,
      trailer,
      isCommingSoon,
      infoAdditional,
      isNinos,
      isEducation,
      firstEpisode,
      slug,
      rating,
      availability,
      streaming_provider
    } = this.props;
    const brand = getTaxonomy(taxonomy, "brands");
    const category = getTaxonomy(taxonomy, "categories");
    const brand_logo_negative = getKey(brand, "image.logo_negative.path");
    const isPodcast = type === pageCategory.SHOWAUDIO;
    return isCommingSoon ? (
      <CommingSoon {...this.props} />
    ) : (
      <Fragment>
        <div
          className={`wrapperOpening ${isNinos && "ninosView"} ${isPodcast &&
            "podcastContainer"} ${isEducation && "educationView"} `}
        >
          <div className="contentOpening">
            {firstEpisode && (
              <a className="playFirstEpisode" href={firstEpisode}>
                <AtIcon className="play-icon" name="PlayCircle" size={100} />
              </a>
            )}
            <div className="wrapperAdditionalInfo">
              {!isPodcast &&
                brand_logo_negative && (
                  <img
                    alt={title}
                    src={brand_logo_negative}
                    className="wrapOpeningCategoryLogo"
                  />
                )}
              {!isPodcast &&
                rating && (
                  <Rating
                    className="wrapOpeningRating"
                    data={rating.value.split(",")}
                  />
                )}
              {!isPodcast &&
                availability && (
                  <AtTimeLeft
                    className="wrapOpeningTimeLeft opening"
                    type="slider"
                    text={availability}
                  />
                )}
            </div>
            {this.state.coverImage !== null ? (
              this.loadResource(resource)
            ) : (
              <div style={{ height: "400px" }} />
            )}
            <div className="wrapperMetaData">
              <div className="metaData">
                <p className="category">{getKey(category, "value", "")}</p>
                <h2 className="openingTitle">{title}</h2>
                {genre && <h3>{genre}</h3>}
                <div className="longDescriptionWrapper">
                  <span dangerouslySetInnerHTML={{ __html: longDescription }} />
                </div>
                <div className="links">
                  <ul>
                    {trailer && (
                      <li>
                        <ShowTrailerButton
                          streaming_provider={streaming_provider}
                          title={title}
                          trailer={trailer}
                        />
                      </li>
                    )}
                    {infoAdditional && (
                      <li>
                        <div
                          className="infoButton"
                          onClick={e => this.infoDetail(e)}
                        >
                          <AtIcon name="InfoCustom" size={32} />{" "}
                          <span>INFO</span>
                        </div>
                      </li>
                    )}
                    <li>
                      {!isPodcast && (
                        <a href={firstEpisode} className="firstEpisodeButton">
                          <AtIcon name="PlayCircle" size={32} /> Ver Serie
                        </a>
                      )}
                    </li>
                    <li>
                      <SocialButtonsStyled>
                        <SocialButtons
                          label="COMPARTIR"
                          title={title}
                          text={description}
                          size={32}
                          sizeButtons={18}
                          uri={slug}
                        />
                      </SocialButtonsStyled>
                    </li>
                    <li>
                      <AddMyListButton size={30} id={id} />
                    </li>
                  </ul>
                </div>
              </div>
              {isPodcast && (
                <div className="contentAudioPlaylist">
                  <MolAudio
                    audios={audios}
                    taxonomy={taxonomy}
                    titleProduction={title}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={classnames("contentDetailInfo", {
            ninosView: isNinos,
            contentDetailInfoEducation: isEducation
          })}
        >
          {infoAdditional &&
            this.state.mobileViewPort !== null && (
              <ul>
                {infoAdditional.map((info, index) => (
                  <li
                    key={index}
                    style={{
                      width:
                        (this.state.mobileViewPort
                          ? 100
                          : Math.round(100 / infoAdditional.length)) + "%"
                    }}
                    dangerouslySetInnerHTML={{
                      __html: info.value || "<p></p>"
                    }}
                  />
                ))}
              </ul>
            )}
          <div className="contentCloseInfoButton">
            <div onClick={e => this.infoDetail(e)}>
              <AtIcon name="ArrowDropUp" size={34} />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const SocialButtonsStyled = styled.div`
  & > .generalContainerShareButtons {
    svg {
      margin: 0 !important;
    }
  }
`;

export default Opening;
