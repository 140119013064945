import React, { useMemo } from "react";
import styled from "styled-components";
import { Button } from "../../../components/AtButton";
import AtIcon from "../../../components/AtIcon/AtIcon";
import { useLoadMore } from "../../../contexts/LoadMoreProvider";

export const LoadMoreButton = () => {
  const { isOpen, isLoading, setLoading, setOpen, hasContents } = useLoadMore();
  const loadMoreContentHandler = e => {
    e.preventDefault();
    setOpen(!isOpen);
    setLoading(true);
  };
  const getText = useMemo(
    () => {
      if (isLoading) {
        return "Cargando...";
      }
      return !isOpen ? "Ver más contenido" : "Ver menos contenido";
    },
    [isLoading, isOpen]
  );

  return !hasContents ? null : (
    <Wrapper>
      <LMButton onClick={loadMoreContentHandler}>
        {!isLoading && <ArrowDownIconWrapper className={isOpen ? "up" : ""} />}
        <span>{getText}</span>
      </LMButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 30px 0;
`;

const LMButton = styled(Button)`
  font-family: "Dosis", sans-serif;
  background-color: rgba(0, 155, 219, 0.3);
  border-radius: 24px;
  padding: 12px 43px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  gap: 10px;

  &:hover {
    background-color: #00effc;
    color: #000;
    svg {
      filter: invert(100%);
    }
  }

  &:active {
    background-color: #009fdc;
    color: #000;
  }
`;

const ArrowDownIconWrapper = styled(AtIcon).attrs({
  name: "ArrowDownIcon",
  size: 18
})`
  &.up {
    transform: rotate(180deg);
  }
`;
