import styled from "styled-components";
import AtTitle from "../../AtTitle";

export const Wrapper = styled.div`
  img {
    width: 100%;
    min-height: 188px;

    @media screen and (min-width: 768px) {
      width: 490px;
    }
  }

  @media screen and (min-width: 768px) {
    width: 920px;
    display: inline-flex;
  }
`;

export const ContentMetadata = styled.div`
  max-width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Separator = styled.div`
  margin: 10px 0 20px;
  width: 60px;
  height: 2px;
  background: #009bdb;
`;

export const Link = styled.a`
  text-decoration: none;

  @media screen and (min-width: 768px) {
    display: inline-flex;
    gap: 26px;
  }
`;

export const Description = styled.div`
  & > div {
    margin: 5px 0;
  }
`;

export const PlayerContainer = styled.div`
  position: relative;
  @media screen and (min-width: 768px) {
    width: 507px;
  }
`;

export const MetadataContainerItem = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
`;

export const TitleContainer = styled(AtTitle)`
  max-width: 320px;
`;
