import React, { Component } from "react";
import MolPortada from "../../../components/MolPortada";
import MolTrailer from "../../../components/MolTrailer";

export default class Premiere extends Component {
  render() {
    const {
      label,
      content: { category, micro_synopsis, slug, image, trailer, title, video },
      director,
      elenco,
      gallery,
      subtitulo,
      streaming_provider,
      label_button
    } = this.props;
    const resource = {
      logo: {
        alt: "",
        path: "",
        title: ""
      },
      image: image,
      video: video
    };
    const data = {
      etiqueta: label,
      subtitulo: category,
      titular: title,
      path: slug,
      imageMobile: image.cover_mobile.path,
      imageDesktop: image.cover_desktop.path,
      trailer: trailer,
      sinopsis: micro_synopsis,
      elenco: elenco,
      director: director,
      subTrailer: subtitulo,
      reproductor: streaming_provider,
      videoDesktop: video !== undefined ? video.video_desktop : null,
      videoMobile: video !== undefined ? video.video_mobile : null
    };
    return (
      <>
        <MolPortada
          imageDesktop={data.imageDesktop}
          imageMobile={data.imageMobile}
          etiqueta={data.etiqueta.toUpperCase()}
          subtitulo={data.subtitulo}
          titular={data.titular.toUpperCase()}
          path={data.path}
          videoDesktop={data.videoDesktop}
          videoMobile={data.videoMobile}
          resource={resource}
          label_button={label_button}
        />
        <MolTrailer
          trailer={data.trailer}
          sinopsis={data.sinopsis}
          elenco={data.elenco}
          director={data.director}
          titulo={data.titular}
          subtitulo={data.subTrailer}
          gallery={gallery}
          path={data.path}
          reproductor={data.reproductor}
        />
      </>
    );
  }
}
