import React from "react";
import { Colors, Icons } from "../../theme";

const AtIcon = ({
  name = "star",
  size = 48,
  color = Colors.white,
  className,
}) => {
  const StyledIcon = Icons[name];
  return (
    <StyledIcon
      id={`Icon${name}`}
      size={size}
      color={color}
      className={className}
    />
  );
};

export default AtIcon;
