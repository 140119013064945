import React, { Component } from "react";
import styled, { css } from "styled-components";
import { Colors, Wp, Fonts } from "../../theme";

const Wrapper = styled(Wp.Container)`
  position: relative;
`;

const ErrorMessage = styled.div`
  width: 96%;
  color: white;
  background-color: #fb0700;
  padding: 8px;
  position: relative;
  margin-top: 10px;
  font-weight: bold;
`;
const ArrowUp = styled.div`
  border-bottom: 11px solid #fb0700;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  height: 0;
  position: absolute;
  left: 22px;
  top: -11px;
  width: 0;
`;
const Label = styled.label`
  color: ${Colors.snow};
`;
const Input = styled(Fonts.input)`
  color: ${Colors.darkbluegrey};
  padding: 12px;
  width: 100%;
  box-sizing: inherit;
  border: 0px;
  max-height: 43px;

  &[disabled] {
    background: #ccc;
    color: #888;
    box-shadow: inset 0px 0px 5px 0px #000;
    cursor: progress;
  }
  &[type="checkbox"] + label span {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0px 10px 0 0;
    cursor: pointer;
    border: 1px solid #ccc;
  }
  &[type="checkbox"]:checked + label span {
    background-color: #ccc;
  }

  ${props =>
    props.hasErrors &&
    css`
      border-color: red !important;
      color: red !important;

      &::-webkit-datetime-edit,
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: red !important;
        opacity: 1; /* Firefox */
      }
    `};
`;

const Select = styled.select`
  width: 100%;
  box-sizing: inherit;
  background: #555;
  border: 1px solid #ccc;
  color: #ccc;
  max-height: 43px;
  padding: 12px;
  height: 43px;
`;

const List = styled.ul`
  margin: 0px;
  padding: 0px;
  list-style: none;
  width: 100%;
  display: block;
  position: relative;
`;
const ListItem = styled.li`
  display: inline-block;
  vertical-align: top;
`;
const CheckBoxContainer = styled.div`
  display: flex;
  input[type="checkbox"] {
    width: 10%;
  }
`;

class AtField extends Component {
  constructor(props) {
    super(props);
    const { selected } = props;
    this.state = {
      value: props.value,
      value_selected:
        selected !== undefined
          ? this.propsToArray(selected).map(check => ({
              ...check,
              checked: check.selected
            }))
          : undefined
    };
  }

  propsToArray(object) {
    let arr = [];
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        arr.push(object[key]);
      }
    }
    return arr;
  }
  isSelected(id, data) {
    return data[id] !== undefined ? data[id].selected : false;
  }
  handleChange(event) {
    if (this.props.selected === undefined) {
      return false;
    }
    this.setState({ value: event.target.value });
  }
  handleChangeChecks(index) {
    if (this.props.selected === undefined) {
      return false;
    }
    const value_selected = this.state.value_selected;
    if (value_selected.length === 0) return;

    value_selected[index].checked = !value_selected[index].checked;
    this.setState({ value_selected });
  }
  onlyCharDetect(e) {
    var key = e.keyCode || e.which;
    if ((key >= 48 && key <= 57) || key === 46) {
      e.preventDefault();
      e.returnValue = false;
    }
  }

  render() {
    const {
      className,
      name,
      type,
      label,
      checks,
      children,
      onlyChar,
      hasErrors,
      errorMessage
    } = this.props;
    let fieldComponent = null;
    switch (type) {
      case "listCheckBox":
        fieldComponent = (
          <List>
            {(this.state.value_selected || checks).map((val, index) => (
              <ListItem key={index}>
                <Input
                  type="checkbox"
                  id={`field_check_${index}`}
                  name={`field_check_${index}`}
                  defaultValue={val.id}
                  checked={val.checked}
                  onChange={_ => this.handleChangeChecks(index)}
                />{" "}
                <Label htmlFor={`field_check_${index}`}>
                  <span />
                  {val.name}
                </Label>
              </ListItem>
            ))}
          </List>
        );
        break;
      case "select":
        fieldComponent = (
          <Select
            {...this.props}
            id={`field_${name}`}
            name={`field_${name}`}
            onChange={e => this.handleChange(e)}
          >
            {children}
          </Select>
        );
        break;
      default:
        const { value, ...rest } = this.props;
        fieldComponent = (
          <Input
            {...rest}
            onKeyDown={e => (onlyChar ? this.onlyCharDetect(e) : null)}
            defaultValue={value}
            id={`field_${name}`}
            name={`field_${name}`}
            type={type}
            onChange={e => this.handleChange(e)}
          />
        );
        break;
    }

    const isCheckBox = type === "checkbox";
    const dangerouslySetInnerHTMLData =
      typeof label === "object"
        ? {}
        : { dangerouslySetInnerHTML: { __html: label } };
    const LabelComponent = (
      <Label htmlFor={`field_${name}`} {...dangerouslySetInnerHTMLData}>
        {typeof label === "object" ? label : null}
      </Label>
    );
    return (
      <Wrapper className={className} hasErrors={hasErrors}>
        {isCheckBox ? (
          <CheckBoxContainer>
            {fieldComponent}
            {LabelComponent}
            <span className="checkmark" />
          </CheckBoxContainer>
        ) : (
          <>
            {LabelComponent}
            {fieldComponent}
          </>
        )}
        {hasErrors && (
          <ErrorMessage>
            {errorMessage}
            <ArrowUp />
          </ErrorMessage>
        )}
      </Wrapper>
    );
  }
}

export default AtField;
