import CryptoJS from "crypto-js";

export function encrypt(message, key) {
  return CryptoJS.AES.encrypt(message, key).toString();
}
export function decrypt(messageEncrypted, key) {
  return CryptoJS.AES.decrypt(messageEncrypted, key).toString(
    CryptoJS.enc.Utf8
  );
}
