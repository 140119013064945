import React, { Component } from "react";
import styled from "styled-components";

import AtImg from "../AtImg/AtImg";
import AtIframe from "../AtIframe/AtIframe";
import MolShareButton from "../MolShareButton";

import { Colors, Wp, Fonts, Metrics } from "../../theme";

const Container = styled(Wp.Page)`
  margin-top: ${Metrics.size.minHeaderHeight};
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  width: calc(100% - 2.5rem);
  @media ${Metrics.mediaQ.tablet} {
    margin-top: ${Metrics.size.maxHeaderHeight};
  }
`;

const Row = styled(Wp.Container)`
  display: flex;
  flex-direction: row;
  &.first-row {
    padding-top: 10px;
    .brand-container {
      max-width: 110px;
      justify-content: flex-end;
      padding-bottom: 1em;
      @media ${Metrics.mediaQ.tablet} {
        max-width: 137px;
      }
    }
  }
`;

const IFrame = styled(AtIframe)`
  background: ${Colors.black};
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin: 0px -50vw;
  @media ${Metrics.mediaQ.tabletL} {
    margin: 0px;
    width: 100%;
    left: initial;
    right: initial;
  }
`;

const Column = styled(Wp.Container)`
  display: flex;
  flex-direction: column;
  &.share-container {
    max-width: 8rem;
    margin-top: 18px;
    align-items: flex-end;
  }
  .share {
    > a {
      > span {
        font-size: 1.125rem;
        line-height: 1.5;
        margin-left: 1px;
      }
    }
  }
`;

const Label = styled(Fonts.liveStreamingLabel)`
  color: ${Colors.livered};
  margin: 0px;
  line-height: 1.2;
`;

const Brand = styled(AtImg)`
  max-width: 100%;
  width: 100%;
`;

const Title = styled(Fonts.liveStreamingTitle)`
  margin-top: 10px;
  line-height: 1;
`;

const SubTitle = styled(Fonts.liveStreamingSubtitle)`
  margin-top: 18px;
  margin-bottom: 22px;
`;

const Summary = styled(Wp.Container)`
  border-top: 1px solid ${Colors.bunker};
  display: block;
  width: 100%;
  padding-top: 22px;
  margin-top: 0;
  margin-bottom: 32px;
  font-family: "Dosis", sans-serif;
  font-size: 1.125rem;
  color: ${Colors.snow};
  p,
  span,
  a {
    font-family: "Dosis", sans-serif;
    font-size: 1.125rem;
    color: ${Colors.snow};
    margin: 0;
    padding: 0;
  }
`;

const ShareButton = styled(MolShareButton)``;

class OrLivestreamingEvent extends Component {
  render() {
    const {
      data: { label, title, brand, subtitle, description, player, slug }
    } = this.props;

    return (
      <Container>
        <Row className="first-row">
          <Column>
            <Label>{label}</Label>
            <Title>{title}</Title>
          </Column>
          {brand && (
            <Column className="brand-container">
              <Brand src={brand.image.logo_negative.path} />
            </Column>
          )}
        </Row>
        <Row>
          <IFrame aspectRatio="16-9" title={title} src={player} />
        </Row>
        <Row>
          <Column>
            <SubTitle>{subtitle}</SubTitle>
          </Column>
          <Column className="share-container">
            <div className="share">
              <ShareButton
                label="COMPARTIR"
                title={title}
                size={26}
                uri={slug}
                sizeButtons={18}
                text={description}
              />
            </div>
          </Column>
        </Row>
        <Row>
          {description && (
            <Summary dangerouslySetInnerHTML={{ __html: description }} />
          )}
        </Row>
      </Container>
    );
  }
}

export default OrLivestreamingEvent;
