import styled from "styled-components";
import { Colors, Metrics } from "../../theme";

export const Wrapper = styled.div`
  max-width: ${Metrics.size.maxPageWidth};
  width: 100%;

  @media ${Metrics.mediaQ.tablet} {
    margin: 0 0 30px 0;
  }
`;
export const Container = styled.div`
  color: ${Colors.white};
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #000;
`;
export const Superior = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 3;
  @media ${Metrics.mediaQ.mobileS} {
    flex-direction: column;
  }
  @media ${Metrics.mediaQ.tablet} {
    flex-direction: column;
  }
  @media ${Metrics.mediaQ.laptop} {
    flex-direction: row;
  }
`;
export const Trailer = styled.div`
  display: inline-block;
  @media ${Metrics.mediaQ.mobileS} {
    width: 90%;
  }
  @media ${Metrics.mediaQ.tablet} {
    width: 90%;
  }
  @media ${Metrics.mediaQ.laptop} {
    width: 45%;
  }
`;
export const Acercade = styled.div`
  display: "flex";
  flex-direction: "column";
  font-size: "3rem";
  z-index: 4;
  @media ${Metrics.mediaQ.mobileS} {
    margin-top: 10px;
    width: 90%;
  }
  @media ${Metrics.mediaQ.tablet} {
    width: 90%;
  }
  @media ${Metrics.mediaQ.laptop} {
    width: 45%;
  }
`;
export const DirectorContainer = styled.div`
  display: flex;
  justify-content: start;
  padding-top: 20px;
  flex-direction: row;
  margin-bottom: 20px;
  @media ${Metrics.mediaQ.mobileS} {
    margin-left: 5%;
  }
  @media ${Metrics.mediaQ.tablet} {
    margin-left: 5%;
  }
  @media ${Metrics.mediaQ.laptop} {
    margin-left: 7.5%;
  }
`;
export const TextDirector = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
export const Subtitle = styled.h3`
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  font-family: "Bebas Neue", "BebasNeue Book", sans-serif;
`;
export const SubSubtitle = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  margin: 0 0 10px 0;
`;
export const Sinopsys = styled.p`
  margin: 5px 0 15px;
  text-align: justify;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
`;

export const OnlyDesktop = styled.p`
  display: none;
  @media ${Metrics.mediaQ.tablet} {
    display: initial;
  }
`;
