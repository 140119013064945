import React, { Component } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Colors } from "../../theme";
import Cookies from "js-cookie";
const ImgClose = styled.img`
  position: absolute;
  right: 112px;
  top: 62px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    right: 50px;
    top: 30px;
  }
`;
const ImgWelcome = styled.img`
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

const BtnAceptar = styled.a`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 160px;
  padding: 8px;
  background-color: #1c1c1c;
  color: ${Colors.white};
  text-align: center;
  font-weight: 100;
  text-decoration: none;
  top: 47%;
  @media screen and (min-width: 768px) {
    top: 350px;
  }
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    background: "none"
  },
  overlay: {
    zIndex: "2147483646",
    backgroundColor: "rgba(0, 0, 0, 0.722)"
  }
};
Modal.setAppElement("#root");

class OrModalWelcome extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    let primerIngreso = Cookies.getJSON("PRIMER_INGRESO_EDUCACION");
    if (!primerIngreso) {
      this.openModal();
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
    let primerIngreso = true;
    Cookies.set("PRIMER_INGRESO_EDUCACION", primerIngreso);
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <ImgClose
          src="https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/assets/CLOSE_WELCOME_EDU.png"
          onClick={this.closeModal}
        />
        <BtnAceptar href="#" onClick={this.closeModal}>
          ACEPTAR
        </BtnAceptar>
        <ImgWelcome src="https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/field/image/banners/educacion-intro-2020-04-28.png" />
      </Modal>
    );
  }
}

export default OrModalWelcome;
