import React, { useEffect, useState } from "react";
import { Metrics } from "../../theme";
import AtIcon from "../AtIcon/AtIcon";
import AtImg from "../AtImg/AtImg";
import VideoOpening from "../AtVideoOpening";
import "./MolPortada.scss";
import {
  Button,
  ButtonText,
  Etiqueta,
  GeneralContainer,
  ImgContainer,
  ImgContainerVideo,
  Information,
  Subtitulo,
  Titular
} from "./MolPortada";

const MolPortada = ({
  imageDesktop,
  imageMobile,
  etiqueta,
  subtitulo,
  titular,
  path,
  videoDesktop,
  videoMobile,
  label_button
}) => {
  let image = imageDesktop;
  let video = videoDesktop;
  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const currentWidth = document.body.clientWidth;
      setWidth(currentWidth);

      window.addEventListener("resize", () => {
        const currentWidth = document.body.clientWidth;
        setWidth(currentWidth);
      });
    }
  });

  if (width <= parseInt(Metrics.size.tablet.replace("px", "")) && width > 0) {
    image = imageMobile;
    video = videoMobile;
  }

  if (videoDesktop != null) {
    let videoUrl = video.replace(/\s/g, "%20");
    let data = {
      player: videoUrl,
      type: "video",
      videoAttr:
        "autoplay poster='" + image + "' loop muted playsinline preload='auto'"
    };
    return (
      <GeneralContainer href={path} style={styles.links}>
        <ImgContainerVideo>
          <VideoOpening {...data} className="videoOpening" />
        </ImgContainerVideo>
        <Information>
          <Etiqueta>{etiqueta}</Etiqueta>
          <Subtitulo>{subtitulo}</Subtitulo>
          <Titular>{titular}</Titular>
          <Button>
            <AtIcon
              name={"PlayCircle"}
              size={width < 768 ? 22 : 30}
              color={"#FFF"}
            />
            <ButtonText>{label_button || "VER AHORA"}</ButtonText>
          </Button>
        </Information>
      </GeneralContainer>
    );
  } else {
    return (
      <GeneralContainer href={path} style={styles.links}>
        <div style={styles.imgContainer}>
          <ImgContainer>
            <AtImg src={image} alt={titular} withLazyLoad={true} />
          </ImgContainer>
        </div>
        <Information>
          <Etiqueta>{etiqueta}</Etiqueta>
          <Subtitulo>{subtitulo}</Subtitulo>
          <Titular>{titular}</Titular>
          <Button>
            <AtIcon
              name={"PlayCircle"}
              size={width < 768 ? 22 : 30}
              color={"#FFF"}
            />
            <ButtonText>{label_button || "VER AHORA"}</ButtonText>
          </Button>
        </Information>
      </GeneralContainer>
    );
  }
};

export default MolPortada;

MolPortada.propTypes = {};

const styles = {
  container: {
    flex: 1
  },
  imgContainer: {
    position: "relative"
  }
};
