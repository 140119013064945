import { SET_CHAPTERS, SET_SERIES } from "../constants/action-types";
import { getCurrentUser, getEnvContent } from "../utils/helpers";
import config from "../../server/config";
import { getKey } from "../../app/utils/helpers";

export const getChaptersMyList = (page, pageSize) => dispatch => {
  return fetch(
    `${
      config.cms.environments[getEnvContent()]
    }/api/v1/my_list/chapters?page=${page}&page_size=${pageSize}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getKey(
          getCurrentUser(),
          "tokenData.access_token"
        )}`
      }
    }
  )
    .then(response => response.json())
    .then(content => {
      return dispatch({ type: SET_CHAPTERS, content: content });
    });
};

export const getSeriesMyList = (page, pageSize) => dispatch => {
  return fetch(
    `${
      config.cms.environments[getEnvContent()]
    }/api/v1/my_list/series?page=${page}&page_size=${pageSize}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getKey(
          getCurrentUser(),
          "tokenData.access_token"
        )}`
      }
    }
  )
    .then(response => response.json())
    .then(content => {
      return dispatch({ type: SET_SERIES, content: content });
    });
};
