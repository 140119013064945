import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Routes from "../server/routes";
import OrHeader from "./components/OrHeader/OrHeader";
import OrFooter from "./components/OrFooter/OrFooter";
import AtCookieAlert from "./components/AtCookieAlert/AtCookieAlert";
import OrBannerAppMovil from "./components/OrBannerAppMovil";
import { Fonts, Wp } from "./theme";

import "./index.scss";

const Container = styled(Wp.Container)``;

const CookieAlert = styled(AtCookieAlert)``;
class App extends Component {
  componentDidMount() {
    const { reactPixel } = this.props;
    reactPixel.track("ViewContent", {
      content_name: "Ingresa al sitio y ve el contenido",
      content_category: "Apparel & Accessories > Shoes",
      content_ids: ["1234"],
      content_type: "product",
      value: 0.0,
      currency: "USD"
    });

    /*********** CHATBOT **************/
    //load external files
    const scriptlex = document.createElement("script");
    scriptlex.src =
      process.env.PUBLIC_URL + "/scripts/chatbot/lex-web-ui-loader.min.js";
    scriptlex.async = true;
    document.body.appendChild(scriptlex);

    const scriptarrive = document.createElement("script");
    scriptarrive.src = process.env.PUBLIC_URL + "/scripts/chatbot/arrive.js";
    scriptarrive.async = true;
    document.body.appendChild(scriptarrive);

    setTimeout(function() {
      const scriptmain = document.createElement("script");
      scriptmain.src =
        process.env.PUBLIC_URL + "/scripts/chatbot/chatbot_main.js";
      scriptmain.async = true;
      document.body.appendChild(scriptmain);
    }, 1000);
  }

  render() {
    const { location, headerContent, footerContent } = this.props;
    return (
      <Container id="app">
        <OrBannerAppMovil />
        <Fonts.global />
        <OrHeader current={location.pathname} data={headerContent} />
        <Wp.Container id="content">
          <Routes />
        </Wp.Container>
        <OrFooter current={location.pathname} data={footerContent} />
        <CookieAlert />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user,
  headerContent: state.content.headerContent,
  footerContent: state.content.footerContent
});

export default withRouter(connect(mapStateToProps)(App));
