import React, { Component } from "react";
import PropTypes from "prop-types";
import Swiper from "react-id-swiper";
import "react-id-swiper/src/styles/scss/swiper.scss";

import { exists, hasKey } from "../../../utils/helpers";
import AtIcon from "../../../components/AtIcon/AtIcon";

import {
  Container,
  ContentBanner,
  PageWrapper,
  IconButton,
  ImageBanner
} from "./Banner";

class Banner extends Component {
  render() {
    const { contents, styles } = this.props;
    const params = {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      allowTouchMove: true,
      autoplay: { delay: 8000 },
      renderPrevButton: () => (
        <IconButton className="swiper-button-prev">
          <AtIcon className="icon" name="KeyboardArrowLeft" size={25} />
        </IconButton>
      ),
      renderNextButton: () => (
        <IconButton className="swiper-button-next">
          <AtIcon className="icon" name="KeyboardArrowRight" size={25} />
        </IconButton>
      )
    };
    return (
      <Container>
        <PageWrapper>
          <ContentBanner customStyles={styles}>
            <Swiper {...params}>
              {contents.map(({ image, slug, target_blank }, index) => (
                <div key={`banner-${index}`}>
                  <div
                    key={index}
                    className={`banner-button ${
                      exists(slug) && slug !== "" ? "" : "noLink"
                    }`}
                    onClick={() => {
                      if (exists(slug) && slug !== "") {
                        window.location.href = slug;
                      }
                    }}
                    target={target_blank ? "_blank" : "_self"}
                  >
                    {hasKey(image, "banner.path") && (
                      <ImageBanner
                        src={image.banner.path}
                        alt={image.banner.alt}
                        title={image.banner.title}
                        className={`isDesktop${
                          !hasKey(image, "banner_mobile.path")
                            ? " isMobile"
                            : ""
                        }`}
                        withLazyLoad={true}
                      />
                    )}
                    {hasKey(image, "banner_mobile.path") && (
                      <ImageBanner
                        src={image.banner_mobile.path}
                        alt={image.banner_mobile.alt}
                        title={image.banner_mobile.title}
                        className="isMobile"
                        withLazyLoad={true}
                      />
                    )}
                  </div>
                </div>
              ))}
            </Swiper>
          </ContentBanner>
        </PageWrapper>
      </Container>
    );
  }
}
Banner.propTypes = {
  image: PropTypes.object
};
export default Banner;
