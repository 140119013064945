import styled from "styled-components";

export const Wrapper = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  background-image: url(https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/field/field_cover_bg/slider/2020-03-19-La-De-Troya-1920X823.jpg);
  height: 100vh;

  input {
    border: 0;
    background-color: transparent;
    border-bottom: 1px solid white;
    color: white !important;
  }
  ::-webkit-input-placeholder {
    color: white;
  }

  :-ms-input-placeholder {
    color: white;
  }

  ::placeholder {
    color: white;
  }

  @media screen and (min-width: 768px) {
    padding-top: 50px;
  }
`;

export const ContainerCloseButton = styled.div`
  position: absolute;
  right: 10%;
  top: 5%;
`;
