import React, { Component, Fragment } from "react";
import styled, { css } from "styled-components";
import { isMobile } from "react-device-detect";
import { rgba } from "polished";
import { UncontrolledTooltip } from "reactstrap";

import AtImg from "../AtImg/AtImg";
import AtBadge from "../AtBadge/AtBadge";
import AtIcon from "../AtIcon/AtIcon";
import AtInputSlider from "../AtInputSlider/AtInputSlider";
import MolBrandContainer from "../MolBrandContainer/MolBrandContainer";
import MolShareButton from "../MolShareButton";
import MolModalVideo from "../MolModalVideo";
import TrailerPlayer from "../MolTrailerPlayer";

import { Wp, Colors, Fonts, Metrics } from "../../theme";
import { CHAPTER_VIEW_GRID } from "../../constants/widgetType";
import { getCurrentUser, removeFromMyList } from "../../../store/utils/helpers";
import { getKey } from "../../utils/helpers";
import { SHOWAUDIO } from "../../constants/pageType";
import MetaInfo from "../MolMetaInfo";

const Slide = styled(Wp.Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  &.vertical-list,
  &.swiper-slide {
    width: ${props =>
      props.width *
      (props.scale || props.scaleMobile || Metrics.scale.mobile)}px;
    height: ${props =>
      props.height *
      (props.scale || props.scaleMobile || Metrics.scale.mobile)}px;
    .info-footer-container {
      height: ${props =>
        props.infoFooterHeight * (props.scale || Metrics.scale.mobile)}px;
      bottom: 4px;
    }

    .content-type-icon {
      right: 0.5em;
      bottom: calc(
        ${props =>
            props.infoFooterHeight * (props.scale || Metrics.scale.mobile)}px +
          0.5em
      );
      ${({ hideMetaInfo }) =>
        hideMetaInfo &&
        css`
          bottom: 10px;
          @media ${Metrics.mediaQ.tablet} {
            bottom: calc(
              ${props =>
                  props.infoFooterHeight *
                  (props.scale || Metrics.scale.mobile)}px + 0.5em
            );
          }
        `};
    }
    .content-type-icon-prod {
      right: 0.5em;
      bottom: calc(
        ${props =>
            props.infoFooterHeight * (props.scale || Metrics.scale.desktop)}px +
          -2.5em
      );
    }
    @media ${Metrics.mediaQ.tablet} {
      width: ${props =>
        (props.index === 0 && props.firstSlideWidth
          ? props.firstSlideWidth
          : props.width) * (props.scale || Metrics.scale.desktop)}px;
      height: ${props =>
        props.height * (props.scale || Metrics.scale.desktop)}px;
      .info-footer-container {
        height: ${props =>
          props.infoFooterHeight * (props.scale || Metrics.scale.desktop)}px;
      }
      .content-type-icon {
        right: 0.5em;
        bottom: calc(
          ${props =>
              props.infoFooterHeight *
              (props.scale || Metrics.scale.desktop)}px + 0.5em
        );
      }
      .content-type-icon-prod {
        right: 0.5em;
        bottom: calc(
          ${props =>
              props.infoFooterHeight *
              (props.scale || Metrics.scale.desktop)}px + -2.85em
        );
      }
    }
  }
  &.isActive:after {
    content: " ";
    display: block;
    width: 100%;
    height: 2px;
    background: #4c86c1;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
  & > .link {
    & > span {
      width: 100% !important;
      height: 100% !important;
    }
  }
`;
const Img = styled(AtImg)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  img {
    width: 100%;
  }
`;
const InfoHoverContainer = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  opacity: 0;
  @media ${Metrics.mediaQ.laptop} {
    :hover {
      opacity: 1;
    }
  }
  transition: opacity 100ms ease-in;
  ${props => props.customStyles || ""};
`;
const InfoHoverBody = styled.a`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 8em;
  width: calc(100% - 2em);
  height: calc(100% - ${isMobile ? "8em" : "12em"});
  overflow: hidden;
  text-decoration: none;
  &.hoveraudio {
    height: 15em;
    padding-top: 1.5em;
    @media (max-width: ${Metrics.size.tabletL}) {
      height: 52%;
    }
  }
  @media ${Metrics.mediaQ.tablet} {
    bottom: 3em;
    padding-left: 10%;
    padding-right: 10%;
    width: 80%;
  }
`;
const InfoHoverActions = styled.div`
  position: absolute;
  left: 0.5em;
  bottom: 0;
  width: calc(100% - 1em);
  height: 3em;
  border-top: 1px solid ${Colors.gray};
  padding: 0 0.5em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  &.info-actions-audio {
    border-top: 0px;
  }
`;
const InfoHoverCategory = styled(Fonts.infoHoverCategory)`
  margin: 0;
`;
const InfoHoverTitle = styled(Fonts.infoHoverTitle)`
  margin: 0;
  height: 50px;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
const InfoHoverSynopsis = styled(Fonts.infoHoverSynopsis)`
  margin: 0.5em 0;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ isNewAudioPoster }) =>
    !isNewAudioPoster &&
    css`
      height: 80px;
      display: -webkit-box;
      line-clamp: 4;
      -webkit-line-clamp: 4;
      box-orient: vertical;
      -webkit-box-orient: vertical;
    `};
`;
const InfoHoverChapters = styled(Fonts.infoHoverChapters)`
  margin-bottom: 1em;
`;
const InfoFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  ${props => {
    if (props.scale === 1) {
      return `
        font-size: 14px;
        @media ${Metrics.mediaQ.tablet} {
          font-size: 16px;
        }
      `;
    }
    return `font-size: ${16 * props.scale}px;`;
  }};

  ${({ customStyles }) => customStyles};
`;
const InfoFooterTitle = styled(Fonts.infoFooterTitle)`
  display: block;
  line-height: initial;
  width: 100%;
  margin-top: 0.3em;
  .link {
    display: block;
    width: ${props => props.width};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-decoration: none;
    color: ${Colors.white};
    font-size: inherit;
    font-weight: inherit;
    :active,
    :visited {
      text-decoration: none;
      color: ${Colors.white};
    }
    :hover {
      opacity: 0.8;
      text-decoration: none;
    }
    transition: opacity 200ms ease-in;
  }
`;
const InfoFooterSubTitle = styled(Fonts.infoFooterSubTitle)`
  display: block;
  width: 100%;
  .link {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.25rem;
    display: block;
    height: 1.5rem;
    text-decoration: none;
    color: ${Colors.white};
    font-size: inherit;
    font-weight: inherit;
    :active,
    :visited {
      text-decoration: none;
      color: ${Colors.white};
    }
    :hover {
      opacity: 0.8;
      text-decoration: none;
    }
    transition: opacity 200ms ease-in;
  }
`;
const InfoFooterMicroSynopsis = styled(Fonts.infoFooterMicroSynopsis)`
  display: block;
  width: 100%;

  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1rem;
  height: 4rem;
  text-decoration: none;
  color: ${Colors.white};
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;
const InfoFooterDuration = styled(Fonts.infoFooterSubTitle)`
  position: absolute;
  top: 0.1875em;
  right: 0;
  color: ${Colors.gray};
  span {
    font-size: 0.8em;
  }
`;
const BrandContainer = styled.div`
  position: absolute;
  width: 30%;
  max-width: 100px;
  top: 0.6em;
  right: 0.5em;
  .brand {
    width: 100%;
    opacity: 0.8;
  }
`;
const BrandContainerLive = styled(MolBrandContainer)`
  position: absolute;
  width: 100%;
  padding: 10px 0 10px 10px;
  left: 0;
  bottom: 0;
  .brand {
    width: 125px;
    padding-right: 0.5em;
    margin-right: 0.5em;
    img {
      max-width: 100px;
    }
  }
  :before {
    content: "";
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    background: linear-gradient(
      to bottom,
      ${rgba(Colors.black, 0)} 0%,
      ${rgba(Colors.black, 1)} 100%
    );
    width: 100%;
    height: calc(100% + 1em);
  }
`;
const Title = styled(Fonts.h2)`
  color: ${Colors.white};
  font-size: 1.9375em;
  line-height: 1;
  margin-top: 0.25em;
`;
const PlayIcon = styled(AtIcon)`
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  filter: drop-shadow(0px 1px 2px ${rgba(Colors.black, 0.4)});
  pointer-events: none;
`;
const PlayIconescuchar = styled(AtIcon)`
  display: inline-block;
  vertical-align: top;
  margin-right: 11px;
  filter: drop-shadow(0px 1px 2px ${rgba(Colors.black, 0.4)});
  pointer-events: none;
`;
const EscucharContainer = styled.div`
  display: inline-block;
  text-decoration: none;
  font-family: "Dosis", sans-serif;
  color: ${Colors.white};
  font-size: 1em;
  font-weight: 600;
  line-height: 2em;
  text-transform: uppercase;
`;
const PlayIcon150percent = styled(AtIcon)`
  position: absolute;
  top: calc(50% - 48px - 37px);
  left: calc(50% - 48px);
  filter: drop-shadow(0px 1px 2px ${rgba(Colors.black, 0.4)});
  pointer-events: none;
`;
const PodcastDuration = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  color: white;
`;
const PodcastTitle = styled(Fonts.podcastTitle)`
  position: absolute;
  bottom: 85px;
  left: 15px;

  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.725rem;
  height: 3.51rem;
  text-decoration: none;
  color: ${Colors.white};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 768px) {
    height: 1.825rem;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;
const ContentTypeIcon = styled(AtIcon)`
  position: absolute;
  filter: drop-shadow(0px 1px 2px ${rgba(Colors.black, 0.4)});
  pointer-events: none;
  opacity: 0.5;
`;
const LinkContainer = styled.a`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  background: ${Colors.transparent};
  text-decoration: none;
`;
const ContainerRemoveMyListButton = styled.div`
  position: absolute;
  right: 0;
  ${props =>
    props.isCover &&
    css`
      right: 0.5em;
      top: 5px;
    `};
`;
const RemoveFromMyListButton = styled.a`
  display: inline-block;
  color: white;
  text-decoration: none;
`;
const DarkUnconrolledToooltip = styled.div`
  font-family: Dosis, sans-serif;
  color: ${Colors.white};
  font-size: 12px;
  font-weight: 600;
  line-height: 2em;
  margin-left: 2px;
  padding: 3px;
  background-color: ${Colors.cerulean};
  border-radius: 5px;
  border: 1px solid #555555;
  border-bottom: 0px;
`;
const UncontrolledTooltipCustom = styled(UncontrolledTooltip)`
  & .arrow::before,
  & .arrow::after {
    border-top-color: ${Colors.cerulean};
  }
`;
const VideoVideoContainer = styled.div`
  display: block;
  width: 60%;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
    // & > div::after { content: ""; background: transparent; width: 100%; height: 100%; min-height: 1px; position: absolute; top: 0; }
  }
`;

// Personaje view

const PersonajeSlide = styled(Slide)`
  display: block;
`;

const PersonajeImageContainer = styled.div`
  display: block;
  min-height: 173px;
  img {
    position: relative;
  }
  width: 100%;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
const PersonajeMetadataContainer = styled.div`
  display: block;
  width: 100%;
  color: white;
  margin-left: 0px;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;
const PersonajeDecoration = styled.div`
  background-color: #40b1d3;
  height: 3px;
  width: 66px;
  margin-bottom: 5px;
  opacity: 1;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;
const PersonajeTitle = styled.h3`
  text-transform: uppercase;
  font-size: 27px;
  line-height: 22px;
  margin: 10px 0 0;
  font-weight: normal;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;
const PersonajeSubTitle = styled.h3`
  text-transform: uppercase;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 20px;
  font-weight: normal;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;
const PersonajeDescription = styled.p`
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

class MolHorizontalListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldUnMount: false,
      isPlaying: false,
      played: 0
    };
  }
  async removeMyList(event) {
    event.preventDefault();
    const { onRemoveFromMyList } = this.props;
    let currentUser = getCurrentUser();

    if (currentUser !== null) {
      const {
        content: { id }
      } = this.props;
      const result = await removeFromMyList(id);
      if (result.type === "CHANGE_STATE_ADD_MY_LIST_BUTTON") {
        if (onRemoveFromMyList !== undefined) {
          onRemoveFromMyList(undefined);
        }
        this.setState({ shouldUnMount: true });
      }
    }
  }

  run(e, index, isLightBox, isPodcast, podcastId, player) {
    if (isLightBox) {
      e.preventDefault();
      this.props.openLightBox(e, index, isLightBox);
    }
    if (isPodcast) {
      e.preventDefault();
      player = document.getElementById(podcastId);
      if (this.state.isPlaying) {
        player.pause();
        this.setState({
          isPlaying: !this.state.isPlaying
        });
      } else {
        player.play();
        this.setState({
          isPlaying: !this.state.isPlaying
        });
      }
    }
  }

  handleTime(e) {
    this.setState({
      played: e.target.currentTime / e.target.duration
    });
  }

  handleEnded(e) {
    this.setState({
      played: 0,
      isPlaying: false
    });
  }

  handleSeekChange = (e, podcastId, player) => {
    player = document.getElementById(podcastId);
    player.currentTime = e.target.value * player.duration;
    if (this.state.isPlaying) {
      this.setState({
        played: e.target.value
      });
    } else {
      player.play();
      this.setState({
        isPlaying: !this.state.isPlaying,
        played: e.target.value
      });
    }
  };

  render() {
    if (this.state.shouldUnMount) return null;

    const {
      content: {
        id,
        brand,
        chapter_number,
        chapter_view,
        content_type,
        image,
        poster_audio,
        micro_synopsis,
        slug,
        title,
        subtitle,
        subtitle_slug,
        category,
        active,
        duration,
        video,
        audio,
        chapters_audios,
        chapters_videos,
        type,
        is_unitary
      },
      view,
      index,
      miLista,
      isVideo,
      hideMetaInfo,
      showModal,
      isLightBox,
      isPodcast,
      streaming_provider,
      className,
      isCineSection
    } = this.props;
    let title_without_whitespace = "";
    let tId = "";
    let podcastId = "";

    if (title) {
      title_without_whitespace = title
        .replace(/\s/g, "")
        .replace(/[^0-9a-z]/gi, "");

      tId = `placeholder-${index}-${title_without_whitespace}`;
      podcastId = `podcast-${index}-${title_without_whitespace}`;
    } else {
      tId = `placeholder-${index}`;
      podcastId = `podcast-${index}`;
    }

    const pack = {
      video,
      title,
      slug,
      duration,
      streaming_provider,
      tId
    };

    const { isNewAudioPoster } = view;

    let imageSource =
      image && image[view.imageKey] && image[view.imageKey].path
        ? image[view.imageKey].path
        : null;
    let imageTitle =
      image && image[view.imageKey] && image[view.imageKey].title
        ? image[view.imageKey].title
        : "";
    let imageAlt =
      image && image[view.imageKey] && image[view.imageKey].alt
        ? image[view.imageKey].alt
        : "";
    let imageResponsive = false;

    const imageSrcAudio = getKey(poster_audio, `${view.imageKey}.path`);

    if (index === 0 && view.firstImageKey) {
      imageResponsive = true;
      imageSource = [
        {
          "": image[view.imageKey].path
        },
        {
          [Metrics.mediaQ.tablet]: image[view.firstImageKey].path
        }
      ];
    }
    let slideHeight =
      (view.infoFooter && title ? view.infoFooter.height : 0) + view.height;
    const isActiveClass = active ? "isActive" : "";

    const slide_info = {
      id,
      imageSource,
      imageResponsive,
      imageAlt,
      imageTitle,
      view,
      brand,
      chapter_number,
      content_type,
      image,
      micro_synopsis,
      slug,
      title,
      subtitle,
      subtitle_slug,
      category,
      active,
      duration,
      video,
      isActiveClass
    };
    if (isVideo) {
      if (view.name === "personaje") {
        const { content } = this.props;
        const imageSource = getKey(
          content,
          `${view.imageKey}.path`,
          getKey(content, `${view.imageKeyFallback}.path`)
        );

        return (
          <PersonajeSlide
            index={index}
            height={slideHeight}
            className={`${className} ${view.className} ${isActiveClass}`}
            scaleMobile={1}
            width={view.width}
            hideMetaInfo={hideMetaInfo}
          >
            <PersonajeImageContainer>
              {imageSource ? (
                <Img src={imageSource} alt={imageAlt} title={imageTitle} />
              ) : (
                <AtIcon className="broken-image" name="Image" size={35} />
              )}
            </PersonajeImageContainer>
            <PersonajeMetadataContainer>
              <PersonajeDecoration />
              <PersonajeTitle>
                {getKey(this.props, "content.nombre_personaje")}
              </PersonajeTitle>
              <PersonajeSubTitle>
                {getKey(this.props, "content.nombre_real")}
              </PersonajeSubTitle>
              <PersonajeDescription
                dangerouslySetInnerHTML={{
                  __html: getKey(this.props, "content.descripcion")
                }}
              />
            </PersonajeMetadataContainer>
          </PersonajeSlide>
        );
      }

      return (
        <Slide
          index={index}
          height={slideHeight}
          className={`${className} ${view.className} ${isActiveClass}`}
          scale={view.scale || null}
          scaleMobile={view.scaleMobile || null}
          width={view.width}
          firstSlideWidth={view.firstSlideWidth}
          hideMetaInfo={hideMetaInfo}
        >
          <VideoVideoContainer>
            <TrailerPlayer
              title={title_without_whitespace}
              trailer={video}
              streaming_provider={streaming_provider}
              index={index}
            />
          </VideoVideoContainer>
          <MetaInfo title={title} micro_synopsis={micro_synopsis} />
        </Slide>
      );
    }

    if (showModal) {
      return (
        <Slide
          index={index}
          scale={view.scale || null}
          scaleMobile={view.scaleMobile || null}
          width={view.width}
          firstSlideWidth={view.firstSlideWidth}
          height={slideHeight}
          className={`${className} ${view.className} ${isActiveClass}`}
          infoFooterHeight={view.infoFooter ? view.infoFooter.height : 0}
          hideMetaInfo={hideMetaInfo}
        >
          <MolModalVideo
            streaming_provider={streaming_provider}
            title_video={title}
            video={video}
            tId={tId}
            pack={pack}
            slide_info={slide_info}
          />
        </Slide>
      );
    }

    let chapterInfo = "";

    if (
      (isNewAudioPoster || isPodcast || type === SHOWAUDIO) &&
      chapters_audios
    ) {
      chapterInfo = `${chapters_audios} audios`;
    } else if (chapters_videos) {
      chapterInfo = `${chapters_videos} capítulos`;
    } else {
      chapterInfo = chapter_number;
    }

    return (
      <Slide
        index={index}
        scale={view.scale || null}
        scaleMobile={view.scaleMobile || null}
        width={view.width}
        firstSlideWidth={view.firstSlideWidth}
        height={slideHeight}
        className={`${className} ${view.className} ${isActiveClass}`}
        infoFooterHeight={view.infoFooter ? view.infoFooter.height : 0}
        hideMetaInfo={hideMetaInfo}
      >
        <a
          className="link"
          href={!isCineSection && slug}
          onClick={e => this.run(e, index, isLightBox, isPodcast, podcastId)}
        >
          {imageSource || imageSrcAudio ? (
            <Img
              responsive={imageResponsive}
              src={
                isNewAudioPoster && !!imageSrcAudio
                  ? imageSrcAudio
                  : imageSource
              }
              alt={imageAlt}
              title={imageTitle}
            />
          ) : (
            <AtIcon className="broken-image" name="Image" size={35} />
          )}

          {miLista &&
            view.imageKey === "cover" && (
              <ContainerRemoveMyListButton isCover>
                <UncontrolledTooltipCustom
                  placement="top"
                  target={`remove-from-my-list-${id}`}
                >
                  <DarkUnconrolledToooltip>
                    ELIMINAR DE MI LISTA
                  </DarkUnconrolledToooltip>
                </UncontrolledTooltipCustom>
                <RemoveFromMyListButton
                  id={`remove-from-my-list-${id}`}
                  href={_ => {}}
                  onClick={e => this.removeMyList(e)}
                >
                  <AtIcon name="MinusCircle" size={28} />
                </RemoveFromMyListButton>
              </ContainerRemoveMyListButton>
            )}
          {view.contentTypeIcon &&
            content_type && (
              <>
                {title ? (
                  <ContentTypeIcon
                    className="content-type-icon"
                    name={
                      content_type === "audio" ? "SpeakerNew" : "PlayCircle"
                    }
                    size={28}
                    color={Colors.white}
                  />
                ) : (
                  <ContentTypeIcon
                    className="content-type-icon-prod"
                    name={
                      content_type === "audio" ? "SpeakerNew" : "PlayCircle"
                    }
                    size={28}
                    color={Colors.white}
                  />
                )}
              </>
            )}
          {image &&
            image.logo && (
              <BrandContainerLive
                brand={image.logo.path}
                brandAlt={image.logo.alt}
                brandTitle={image.logo.title}
              >
                <AtBadge className={brand.class} fontSize="0.857em">
                  online
                </AtBadge>
                <Title>{title}</Title>
              </BrandContainerLive>
            )}
        </a>
        {isPodcast && (
          <>
            {audio && (
              <audio
                src={`${audio}`}
                id={`podcast-${index}-${title_without_whitespace}`}
                onTimeUpdate={e => {
                  this.handleTime(e);
                }}
                onEnded={e => {
                  this.handleEnded(e);
                }}
              />
            )}
            {this.state.isPlaying ? (
              <PlayIcon150percent
                className="pause-button"
                name="PauseCircle"
                color={Colors.white}
                size={96}
              />
            ) : (
              <PlayIcon150percent
                className="play-button"
                name="PlayFaCircle"
                color={Colors.white}
                size={96}
              />
            )}
            <PodcastDuration>{`${duration}m`}</PodcastDuration>
            <PodcastTitle>{title}</PodcastTitle>
            {this.state.isPlaying && (
              <AtInputSlider
                type="range"
                min={0}
                max={1}
                step="any"
                value={this.state.played}
                onChange={e => this.handleSeekChange(e, podcastId)}
                height="100%"
                width="100%"
              />
            )}
          </>
        )}
        {view.infoHover && (
          <InfoHoverContainer customStyles={view.infoHover.customStyles}>
            {!isNewAudioPoster &&
              !isCineSection && (
                <PlayIcon
                  className="play-button"
                  name="PlayCircle"
                  color={Colors.white}
                  size={64}
                />
              )}

            <InfoHoverBody
              className={
                isNewAudioPoster ? "info-body hoveraudio" : "info-body"
              }
              href={!isCineSection && slug}
            >
              {category &&
                !isNewAudioPoster && (
                  <InfoHoverCategory>{category}</InfoHoverCategory>
                )}
              {title &&
                !isNewAudioPoster && (
                  <InfoHoverTitle title={title} alt={title}>
                    {title}
                  </InfoHoverTitle>
                )}
              {micro_synopsis && (
                <InfoHoverSynopsis isNewAudioPoster={isNewAudioPoster}>
                  {micro_synopsis}
                </InfoHoverSynopsis>
              )}
              {(chapter_number || chapters_videos || chapters_audios) &&
                chapter_view !== CHAPTER_VIEW_GRID &&
                !is_unitary && (
                  <InfoHoverChapters>{chapterInfo}</InfoHoverChapters>
                )}
            </InfoHoverBody>
            <InfoHoverActions
              className={
                isNewAudioPoster
                  ? "info-actions info-actions-audio"
                  : "info-actions"
              }
            >
              {!isMobile && !isNewAudioPoster && !isCineSection ? (
                <MolShareButton
                  size={24}
                  label="COMPARTIR"
                  title={title}
                  text={micro_synopsis}
                  uri={slug}
                />
              ) : (
                !isCineSection && (
                  <a
                    className="link"
                    href={slug}
                    onClick={e =>
                      this.run(e, index, isLightBox, isPodcast, podcastId)
                    }
                  >
                    <EscucharContainer>
                      <PlayIconescuchar
                        className="play-button"
                        name="PlayCircle"
                        color={Colors.white}
                        size={30}
                      />
                      ESCUCHAR
                    </EscucharContainer>
                  </a>
                )
              )}
              {miLista && (
                <ContainerRemoveMyListButton>
                  <UncontrolledTooltipCustom
                    placement="top-start"
                    target={`remove-from-my-list-${id}`}
                  >
                    <DarkUnconrolledToooltip>
                      ELIMINAR DE MI LISTA
                    </DarkUnconrolledToooltip>
                  </UncontrolledTooltipCustom>
                  <RemoveFromMyListButton
                    id={`remove-from-my-list-${id}`}
                    href={_ => {}}
                    onClick={e => this.removeMyList(e)}
                  >
                    <AtIcon name="MinusCircle" size={24} />
                    <Fonts.shareButton className="white">
                      MI LISTA
                    </Fonts.shareButton>
                  </RemoveFromMyListButton>
                </ContainerRemoveMyListButton>
              )}
            </InfoHoverActions>
            {brand &&
              brand.image && (
                <BrandContainer>
                  <AtImg
                    className="brand"
                    src={brand.image.logo_negative.path}
                    title={brand.image.logo_negative.title}
                    alt={brand.image.logo_negative.alt}
                  />
                </BrandContainer>
              )}
            {view.linkContainer && <LinkContainer href={slug} />}
          </InfoHoverContainer>
        )}
        {view.infoFooter &&
          title &&
          !isPodcast && (
            <Fragment>
              <InfoFooterContainer
                customStyles={view.infoFooter.customStyles}
                className="info-footer-container"
                scale={view.scale || 1}
              >
                {title && (
                  <InfoFooterTitle
                    width={duration ? `calc(100% - 2em)` : `100%`}
                  >
                    <a className="link" href={slug}>
                      {title}
                    </a>
                  </InfoFooterTitle>
                )}
                {subtitle && (
                  <InfoFooterSubTitle className={isActiveClass}>
                    <a className="link" href={subtitle_slug}>
                      {subtitle}
                    </a>
                  </InfoFooterSubTitle>
                )}
                {duration && (
                  <InfoFooterDuration>
                    <span>{duration}</span>
                  </InfoFooterDuration>
                )}
              </InfoFooterContainer>
            </Fragment>
          )}
        {view.infoFooter &&
          title &&
          isPodcast && (
            <Fragment>
              <InfoFooterContainer scale={view.scale || 1}>
                {micro_synopsis && (
                  <InfoFooterMicroSynopsis className={isActiveClass}>
                    {micro_synopsis}
                  </InfoFooterMicroSynopsis>
                )}
              </InfoFooterContainer>
            </Fragment>
          )}
      </Slide>
    );
  }
}

export default MolHorizontalListView;
