import styled from "styled-components";
import { Fonts } from "../../theme";
import AtIcon from "../AtIcon/AtIcon";

export const Span = styled(Fonts.span)`
  display: inline-flex;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const Link = styled(Fonts.a)`
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  &.closeModalButton {
    display: flex;
    float: right;
  }
`;
export const IconCircle = styled(AtIcon)`
  margin-left: 5px;
  border: 2px solid #fff;
  border-radius: 50%;
`;
