import React, { Component } from "react";
import styled from "styled-components";

import AtIcon from "../AtIcon/AtIcon";

import { Fonts } from "../../theme";

const Link = styled(Fonts.a)`
  display: inline-block;
  text-transform: ${props => props.textTransform || "uppercase"};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const IconLeft = styled.span`
  display: inline-block;
  margin-right: 10px;
  pointer-events: none;
`;
const IconRight = styled.span`
  display: inline-block;
  margin-left: 10px;
  pointer-events: none;
`;

class AtTextLink extends Component {
  static defaultProps = {
    el: null
  };
  _renderTextLink = () => {
    const {
      href,
      text,
      textTransform,
      iconLeft,
      iconRight,
      iconSize,
      iconColor,
      className,
      onClick,
      onMouseOver,
      onMouseOut,
      onTouchStart,
      el
    } = this.props;
    const hrefFormatted = !!href ? { href: href } : {};
    return (
      <Link
        {...hrefFormatted}
        title={text}
        textTransform={textTransform}
        className={el ? "link" : className}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onTouchStart={onTouchStart}
      >
        {iconLeft && (
          <IconLeft>
            <AtIcon name={iconLeft} size={iconSize} color={iconColor} />
          </IconLeft>
        )}
        {text}
        {iconRight && (
          <IconRight>
            <AtIcon name={iconRight} size={iconSize} color={iconColor} />
          </IconRight>
        )}
      </Link>
    );
  };
  render() {
    const { className, el } = this.props;
    const Wrapper = styled(Fonts[el || "span"])`
      .link {
        margin: 0;
        padding: 0;
        font-size: inherit;
        color: inherit;
        font-weight: inherit;
      }
    `;
    if (el) {
      return <Wrapper className={className}>{this._renderTextLink()}</Wrapper>;
    }

    return this._renderTextLink();
  }
}

export default AtTextLink;
