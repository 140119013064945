import React, { Component, Fragment } from "react";
import "./AtTimeLeft.scss";
import AtIcon from "../AtIcon/AtIcon";
import { exists } from "../../utils/helpers";

class TimeLeft extends Component {
  render() {
    const { date, text, type, className } = this.props;
    let countDays = null;
    if (exists(date)) {
      var ONEDAY = 1000 * 60 * 60 * 24;
      var date1_ms = new Date(date).getTime();
      var date2_ms = new Date().getTime();
      var difference_ms = Math.abs(date1_ms - date2_ms);
      countDays = Math.round(difference_ms / ONEDAY) + " DIAS";
    }
    return (
      <Fragment>
        <div className={`timeLeftWrapper ${type} ${className}`}>
          <ul>
            <li>
              <AtIcon name="CalendarCircle" size={32} />
            </li>
            <li>
              <span>DISPONIBLE HASTA</span>
              <div>{countDays || text}</div>
            </li>
          </ul>
        </div>
        <div className={`triangleTopLeft ${type}`} />
      </Fragment>
    );
  }
}
export default TimeLeft;
