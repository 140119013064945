import React from "react";
import styled from "styled-components";

import Metrics from "./Metrics";
import Colors from "./Colors";
import Fonts from "./Fonts";
import { getKey } from "../utils/helpers";

const StoryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background-color: ${Colors.black};
`;
const StoryContainerGlobal = props => {
  return (
    <StoryContainer>
      <Fonts.global />
      {props.children}
    </StoryContainer>
  );
};
export default {
  StoryContainer: StoryContainerGlobal,
  Page: styled.div`
    display: block;
    position: relative;
    box-sizing: content-box;
    max-width: ${Metrics.size.maxPageWidth};
    margin: 0 auto;
    padding: 0 1.25rem;
    ${props =>
      getKey(props, "showAfterHeader", false)
        ? `
      padding-top: 66px;
      @media screen and (min-width: 768px) {
        padding-top: 90px;
      }`
        : ""};
  `,
  Container: styled.div`
    display: block;
    position: relative;
    box-sizing: border-box;
    width: 100%;
  `,
  Header: styled.header`
    display: flex;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9998;
  `,
  Footer: styled.footer`
    display: flex;
    align-items: center;
    width: 100%;
  `,
  Navbar: styled.nav`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  `,
  Section: styled.section``
};
