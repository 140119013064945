import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import screenfull from "screenfull";
import ReactPlayer from "react-player";

import AtImg from "../AtImg/AtImg";
import MolPlayerBackground from "./MolPlayerBackground";

import { Colors } from "../../theme";

import {
  Container,
  InnerContainer,
  PlayPauseButton,
  VolumeButton,
  LoadingIcon,
  AltText,
  ProgressBarContainer,
  ProgressBarLoaded,
  ProgressBarPlayed,
  Cover
} from "./MolPlayerStyles";
import { logInfo } from "../../utils/helpers";

class MolPlayer extends Component {
  static defaultProps = {
    aspectRatio: "16-9",
    pip: false,
    coverOverflow: false,
    coverResposive: false,
    showAudioButton: true,
    showProgressBar: true,
    showLoading: true,
    loop: false,
    onEnded: () => {},
    width: "100%",
    height: "100%"
  };
  state = {
    ready: false,
    playing: true,
    volume: 0.8,
    muted: true,
    played: 0,
    loaded: -1,
    duration: 0,
    playbackRate: 1.0,
    loop: false
  };
  playPause = () => {
    this.setState({ playing: !this.state.playing });
  };
  pip = () => {
    this.setState({ pip: !this.state.pip });
  };
  stop = () => {
    this.setState({ url: null, playing: false });
  };
  toggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  };
  setVolume = e => {
    this.setState({ volume: parseFloat(e.target.value) });
  };
  toggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };
  setPlaybackRate = e => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };
  onReady = () => {
    this.setState({ ready: true });
  };
  onPlay = () => {
    this.setState({ playing: true, ready: true });
  };
  onPause = () => {
    this.setState({ playing: false });
  };
  onSeekMouseDown = e => {
    this.setState({ seeking: true });
  };
  onSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) });
  };
  onSeekMouseUp = e => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };
  onProgress = state => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
  };
  onEnded = () => {
    this.setState({ playing: this.state.loop });
    this.props.onEnded();
  };
  onDuration = duration => {
    this.setState({ duration });
  };
  onClickFullscreen = () => {
    screenfull.request(findDOMNode(this.player));
  };
  ref = player => {
    this.player = player;
  };
  componentDidMount() {
    this.setState({ loop: this.props.loop });
  }
  render() {
    const { isBackgroundVideo } = this.props;
    if (isBackgroundVideo) {
      return <MolPlayerBackground {...this.props} />;
    }
    const {
      className,
      aspectRatio,
      url,
      pip,
      altText,
      cover,
      coverOverflow,
      coverResposive,
      children,
      showAudioButton,
      showProgressBar,
      showLoading,
      config,
      width,
      height
    } = this.props;
    const {
      playing,
      volume,
      muted,
      loop,
      playbackRate,
      played,
      loaded,
      ready
    } = this.state;
    const aspectRatioValues = aspectRatio.split("-");
    return (
      <Container
        className={className}
        ratio={(aspectRatioValues[1] / aspectRatioValues[0]) * 100}
        coverOverflow={coverOverflow}
      >
        {cover && (
          <Cover className="cover">
            <AtImg src={cover} responsive={coverResposive} />
          </Cover>
        )}
        <ReactPlayer
          ref={this.ref}
          className="react-player"
          width={width}
          height={height}
          url={url}
          pip={pip}
          playing={playing}
          loop={loop}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          onReady={this.onReady}
          onPlay={this.onPlay}
          onEnablePIP={this.onEnablePIP}
          onDisablePIP={this.onDisablePIP}
          onPause={this.onPause}
          onEnded={this.onEnded}
          onError={e =>
            logInfo({
              source: "MolPlayer",
              message: "player error:",
              e
            })
          }
          onProgress={this.onProgress}
          onDuration={this.onDuration}
          config={config}
        />
        {ready && (
          <PlayPauseButton
            color={Colors.transparent}
            name={playing ? "PauseCircle" : "PlayCircle"}
            iconColor={Colors.white}
            iconSize={48}
            onClick={() => this.playPause()}
            onMouseOver={() => {}}
            onMouseOut={() => {}}
            hasInnerContent={!!children}
          />
        )}
        {ready &&
          playing &&
          showAudioButton && (
            <VolumeButton
              color={Colors.transparent}
              name={muted ? "VolumeOff" : "VolumeUp"}
              iconColor={Colors.white}
              iconSize={64}
              onClick={() => this.toggleMuted()}
              onMouseOver={() => {}}
              onMouseOut={() => {}}
              hasInnerContent={children ? true : false}
            />
          )}
        {showLoading &&
          !ready && (
            <LoadingIcon
              name="Spinner"
              color={Colors.white}
              size={32}
              hasInnerContent={children ? true : false}
            />
          )}
        {ready &&
          playing &&
          showAudioButton &&
          altText && (
            <AltText hasInnerContent={children ? true : false}>
              {muted ? "Escuchar Ahora" : "Silenciar"}
            </AltText>
          )}
        {!ready &&
          showLoading &&
          altText && (
            <AltText hasInnerContent={children ? true : false}>
              {"Cargando..."}
            </AltText>
          )}
        {children && (
          <InnerContainer className="inner-container">
            {children}
          </InnerContainer>
        )}
        {showProgressBar && (
          <ProgressBarContainer>
            <ProgressBarLoaded value={loaded} />
            <ProgressBarPlayed value={played} />
          </ProgressBarContainer>
        )}
      </Container>
    );
  }
}
export default MolPlayer;
