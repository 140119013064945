import React, { useCallback, useEffect } from "react";
import { getKey, searchToObject } from "../../../utils/helpers";
import { logOut, setRefererContent } from "../../../../store/utils/helpers";
import Page from "../index";
import { ContainerCloseButton, ContentLoginPage } from "../login/Login";
import OrLogout from "../../../components/OrLogout";
import AtCloseButton from "../../../components/AtCloseButton";

const Logout = ({ location: { search } }) => {
  const refer = searchToObject(search);
  setRefererContent(refer);
  useEffect(
    () => {
      logOut();
      if (typeof window !== "undefined") {
        window.location.href = getKey(refer, "ref", "/");
      }
    },
    [logOut]
  );
  const goBack = useCallback(
    () => {
      window.location.href = getKey(refer, "ref", "/");
    },
    [refer]
  );
  return (
    <Page
      id="login"
      title="Inicio de sesión"
      description="Página de inicio de sesión de RTVCPlay"
    >
      <ContentLoginPage>
        <OrLogout />
        <ContainerCloseButton>
          <AtCloseButton onClick={goBack} />
        </ContainerCloseButton>
      </ContentLoginPage>
    </Page>
  );
};
export default Logout;
