import styled from "styled-components";
import { Colors, Wp } from "../../../theme";
import TplTitleBlock from "../../TplTitleBlock/TplTitleBlock";
import { rgba } from "polished";

export const PromoteListContainer = styled.div`
  margin: 40px 0 0;
`;

export const InnerWrapper = styled(Wp.Page)`
  position: relative;
  .swiper-button-prev {
    &::before {
      content: "";
      top: calc(15px - 18em);
      left: -12px;
      position: absolute;
      width: 40px;
      height: 37em;
      background: linear-gradient(
        to left,
        ${rgba(Colors.bg2, 0)} 0%,
        ${rgba(Colors.bg2, 1)} 100%
      );
    }
  }

  .swiper-button-next {
    &::before {
      content: "";
      top: calc(15px - 18em);
      right: -12px;
      position: absolute;
      width: 40px;
      height: 37em;
      background: linear-gradient(
        to right,
        ${rgba(Colors.bg2, 0)} 0%,
        ${rgba(Colors.bg2, 1)} 100%
      );
    }
  }
  .swiper-button-disabled {
    opacity: 0 !important;
  }
`;
export const TitleRelated = styled(TplTitleBlock)`
  padding-top: ${props => (props.showTitle ? 0.75 : 0)}em;
  padding-bottom: ${props => (props.showTitle || props.isNinos ? 1 : 0)}em;
  margin-bottom: 10px;

  ${props => props.customStyles || ""};
`;
