import { createStore, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { createBrowserHistory, createMemoryHistory } from "history";
import rootReducer from "./actions";

export const isServer = !(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export default () => {
  const history = isServer ? createMemoryHistory() : createBrowserHistory();
  const enhancers = [];

  if (process.env.NODE_ENV !== "production" && !isServer) {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (typeof devToolsExtension === "function") {
      enhancers.push(devToolsExtension());
    }
  }

  const middleware = [thunk, routerMiddleware(history)];
  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  const initialState = !isServer ? window.__RTVCPLAY_STATE__ : {};

  const store = createStore(
    connectRouter(history)(rootReducer),
    initialState,
    composedEnhancers
  );
  return {
    store,
    history
  };
};
