import { AudioPlayerProgress } from "./AudioPlayerProgress";
import { removeHTMLBlankSpaces } from "../../../utils/helpers";
import { AudioTools } from "./AudioPlayerTools";
import React from "react";
import { AudioDescription, AudioPlayerContainer, Metadata } from "./index";
import styled from "styled-components";
import { Colors, Fonts } from "../../../theme";

export const AudioPlayerFull = ({
  isSelected,
  audioRef,
  audio,
  handleEnded,
  coverPodcast,
  handleSeek,
  progress,
  handleClick,
  playing,
}) => {
  return (
    <AudioPlayerContainer isSelected={isSelected}>
      <audio ref={audioRef} src={audio.file} onEnded={handleEnded} />
      <Container>
        {(audio.thumbnail || coverPodcast) && (
          <Thumbnail
            src={
              audio.thumbnail && !!audio.thumbnail.cover_podcast
                ? audio.thumbnail.cover_podcast.path
                : coverPodcast
            }
            alt={audio.title}
          />
        )}
        <Metadata>
          <HeaderData>
            <AudioLink href={audio.slug}>
              <Title>{audio.title}</Title>
            </AudioLink>
            {audio.chapter && <ChapterNumber>{audio.chapter}</ChapterNumber>}
          </HeaderData>
          <AudioPlayerProgress progress={progress} handleSeek={handleSeek} />
          <AudioInfoContainer onClick={handleClick}>
            <AudioDescription
              dangerouslySetInnerHTML={{
                __html: removeHTMLBlankSpaces(audio.description),
              }}
            />
            <AudioTools playing={playing} audio={audio} />
          </AudioInfoContainer>
        </Metadata>
      </Container>
    </AudioPlayerContainer>
  );
};

const Container = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
`;

const Thumbnail = styled.img`
  width: 70px;
  height: 70px;
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
`;

const HeaderData = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AudioLink = styled.a`
  text-decoration: none;
  color: ${Colors.white};
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
`;

const Title = styled(Fonts.audioTitle)``;

const ChapterNumber = styled(Fonts.audioTitle)`
  color: ${Colors.fedora};
`;

const AudioInfoContainer = styled.div`
  display: flex;
  gap: 33px;

  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;
