import { hasKey } from "./index";

export const getVideoPlayer = ({
  video,
  streaming_provider,
  tId,
  uniqueId
}) => {
  if (video === null) return null;

  const { id, ovp_id, player, videoAttr, uri_embed, thumbnail } = video;

  const idRender = uniqueId ? uniqueId : getUniqueIdPlayer();
  const ovpId = ovp_id || id;
  let placeHolderPlayer = "";

  switch (streaming_provider) {
    case "multistream":
      placeHolderPlayer = `<iframe sandbox id="${idRender}"
                 src="https://playerssl.cdnmedia.tv/embed/${player}/${ovpId}/?title=false"
                 style="width: 100%; border: none; margin: 0; padding: 0; height: 100%;"
                 allowfullscreen></iframe>`;
      break;
    case "cg_producciones_hls":
      placeHolderPlayer = `<div class="player-placeholder" id="${idRender}"></div>`;
      break;
    case "cg_producciones_hls_embed":
      if (uri_embed) {
        placeHolderPlayer = `<iframe sandbox id="iframePlayerCustom"
                     src="${uri_embed}"
                     style="width: 100%; border: none; margin: 0; padding: 0; height: 100%;"
                     allowfullscreen></iframe>`;
      } else {
        placeHolderPlayer = `<h3 class="hls-not-found">Video no disponible</h3><div class="player-placeholder" id="${idRender}"></div>`;
      }
      break;
    case "video":
      placeHolderPlayer = `<video id="${idRender}" src=${player} ${videoAttr} />`;
      break;
    case "amazon_backup":
      placeHolderPlayer = `<div class="player-placeholder" id="${idRender}"></div>`;
      break;
    default:
      placeHolderPlayer = `<div class="player-placeholder" id="${idRender}"></div>`;
      break;
  }

  if (hasKey(thumbnail, "path") && false) {
    //Se desactiva thumbnail sobre videos
    placeHolderPlayer = `
        <div class="thumbnailVideoPlayer" id="thumb-${tId}" >
            <img src="${thumbnail.path}" />
            <div class="containerButtonPlay">
              <div class="backgroundShadow"></div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 623.87 623.87" width="100" height="100" focusable="false" aria-hidden="true" color="#ffffff" class="play-icon">
                <path fill="#ffffff" d="M311.93,0C139.66,0,0,139.66,0,311.93S139.66,623.87,311.93,623.87,623.87,484.21,623.87,311.93,484.21,0,311.93,0Zm0,585.34c-151,0-273.4-122.41-273.4-273.41s122.41-273.4,273.4-273.4,273.41,122.41,273.41,273.4S462.93,585.34,311.93,585.34Z"/><path fill="#ffffff" d="M418.47,326.83,278,414.61a17.56,17.56,0,0,1-26.86-14.89V224.16A17.55,17.55,0,0,1,278,209.27l140.46,87.78a17.56,17.56,0,0,1,0,29.78Z"></path>
              </svg>
              <div class="textCTA">VER TRAILER</div>
            </div>
        </div>
        ${placeHolderPlayer}`;
  }
  return placeHolderPlayer;
};

export const isKaltura = streaming_provider => {
  return (
    streaming_provider !== "multistream" &&
    streaming_provider !== "video" &&
    streaming_provider !== "amazon_backup"
  );
};
export const isAmazon = streaming_provider => {
  return streaming_provider === "amazon_backup";
};

export const getURLVideo = ({ streaming_provider, video }) => {
  if (video === null) return null;
  const { assetid, uri } = video;
  switch (streaming_provider) {
    case "amazon_backup":
      return `https://streaming.rtvc.gov.co/RTVCPlay-vod/smil:${assetid}.smil/playlist.m3u8`;
    default:
      return uri;
  }
};

export const getUniqueIdPlayer = () => {
  return `player-placeholder-${parseInt(1000 + Math.random() * 1000000)}`;
};
