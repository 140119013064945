import React, { Component, Fragment } from "react";
import { isMobile } from "react-device-detect";
import "./MolPager.scss";

class Pager extends Component {
  printLinks() {
    const { page, uri } = this.props;
    const max_diff = isMobile ? 3 : 5;
    let { pages } = this.props;

    pages = pages > 0 ? pages - 1 : pages;
    let items = [];
    const startAt = page - max_diff < 0 ? 0 : page - max_diff;
    const endAt = page + max_diff > pages ? pages : page + max_diff;

    const prevIndex = startAt > 0 ? startAt - 1 : -1;
    const nextIndex = endAt < pages ? endAt + 1 : -1;

    if (prevIndex !== -1) {
      items.push(
        <li className="firstItem">
          <a href={uri}>{"Primer"}</a>
        </li>
      );
      items.push(
        <li className="prevItem">
          <a href={uri + "/" + (prevIndex + 1)}>{"<"}</a>
        </li>
      );
    }

    for (let i = startAt; i < endAt; i++) {
      items.push(
        <li>
          {i + 1 === page ? (
            <span>{i + 1}</span>
          ) : (
            <a href={uri + "/" + (i + 1)}>{i + 1}</a>
          )}
        </li>
      );
    }
    if (nextIndex !== -1) {
      items.push(
        <li className="nextItem">
          <a href={uri + "/" + nextIndex}>{">"}</a>
        </li>
      );
      items.push(
        <li className="lastItem">
          <a href={uri + "/" + pages}>{"Último"}</a>
        </li>
      );
    }
    return items;
  }
  render() {
    return (
      <Fragment>
        <div className="contentPager">
          <ul>{this.printLinks()}</ul>
        </div>
      </Fragment>
    );
  }
}
export default Pager;
