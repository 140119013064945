import React from "react";

import { VideoContainer } from "./MolTrailerPlayer";

const TrailerPlayer = ({ trailer }) => {
  return (
    trailer && (
      <VideoContainer>
        <iframe
          title="Trailer"
          className="iframePlayerTrailer"
          src={`/embed/${trailer.uuid}`}
        />
      </VideoContainer>
    )
  );
};

export default TrailerPlayer;
