import React from "react";
import Page from "../";
import OrWelcome from "../../../components/OrWelcome";
import { setRefererContent } from "../../../../store/utils/helpers";
import { searchToObject } from "../../../utils/helpers";

import { ContentLoginPage } from "./Welcome";

const Welcome = ({ location: { search } }) => {
  setRefererContent(searchToObject(search));
  return (
    <Page
      id="welcome"
      title="Bienvenido"
      description="Página de bienvenida de RTVCPlay"
    >
      <ContentLoginPage>
        <OrWelcome />
      </ContentLoginPage>
    </Page>
  );
};
export default Welcome;
