import styled, { css } from "styled-components";
import { Wp } from "../../theme";
import { Button } from "../OrLogin/Form/LoginForm";
import TplTitleBlock from "../TplTitleBlock/TplTitleBlock";

export const Wrapper = styled(Wp.Container)``;

export const InnerWrapper = styled(Wp.Page)`
  position: relative;
  min-height: 420px;
  padding: 0;

  h3 {
    font-size: 25px;
    font-family: "BebasNeue Regular", sans-serif;
    color: white;
    line-height: 1.2;
    text-align: left;
    font-weight: 400;
    margin: 0;
    padding: 0;

    @media screen and (min-width: 768px) {
      font-size: 35.99px;
      margin-bottom: 5px;
    }
  }

  padding-top: 50px;
  @media screen and (min-width: 768px) {
    padding-top: 0;
    display: flex;
    min-height: 640px;
  }
`;

export const BackgroundLayer = styled.div`
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  margin-left: -50vw;
  margin-right: -50vw;
  left: 50%;
  right: 50%;
  width: 100vw;
  background-position: center center;
  background-size: cover;

  ${props =>
    props.backgroundImage &&
    css`
      background-image: url('${props.backgroundImage}');
    `};
`;

export const OpaqueLayer = styled.div`
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

export const TrailerContainer = styled.div`
  position: relative;
  width: 85%;
  margin: 0 auto 10px;
  z-index: 0;

  @media screen and (min-width: 768px) {
    display: inline-flex;
    width: 65%;
  }
`;

export const Trailer = styled.div`
  width: 100%;

  > div {
    border: 1px solid #187b68;
  }

  @media screen and (min-width: 768px) {
    max-width: 768px;
    margin-top: 100px;
    margin-left: 30px;
  }
`;

export const MetadataContainer = styled.div`
  position: relative;
  width: 85%;
  margin: 0 auto;
  z-index: 1;
  align-items: center;

  @media screen and (min-width: 768px) {
    display: inline-flex;
    width: 35%;
  }
`;
export const MetadataInnerContainer = styled.div`
  max-height: 295px;
  max-width: 410px;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 767px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;
export const SeeNow = styled(Button)`
  display: inline-flex;
  width: 168px;
  margin-right: 5px;
  padding: 15px 10px;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  > svg {
    margin-right: 10px;
  }
`;

export const TitleBlock = styled(TplTitleBlock)`
  position: absolute;
  left: 0;
  top: 0;
  background-color: ${props => props.backgroundColor};
  padding-top: ${props => (props.showTitle ? 0.75 : 0)}em;
  padding-bottom: ${props => (props.showTitle || props.isNinos ? 0.75 : 0)}em;
  border-top: ${props => props.borderTop};
  .block-body {
    padding-top: 1em;
  }
  ${props => props.customStyles || ""};

  @media screen and (min-width: 768px) {
    top: 35px;
    & > .inner-container {
      padding-left: 0;
    }
  }
`;
