import Cookies from "js-cookie";
import Store from "../../../store/store";
import {
  setCookiesAccepted,
  setBannerAppsAccepted
} from "../../../store/actions/intro";
import { setGeoCountryLocation } from "../../../store/actions/country";
import { setEnvContent } from "../../../store/utils/helpers";

import {
  removeUser,
  loadUserFromCookies,
  USER_NEWSLETTER
} from "../../../store/actions/auth";

const LOCATION_NO_DETECTED = { country: "UNKNOWN" };

export function dispatchCookiesToStore(req = null) {
  Store.dispatch(removeUser());
  if (req !== null) {
    setEnvContent(process.env.NODE_ENV);
    // SSR
    // Cookies accept
    const cloudfrontViewerCountry =
      req.headers["cloudfront-viewer-country"] || LOCATION_NO_DETECTED;
    Store.dispatch(setGeoCountryLocation(cloudfrontViewerCountry));

    Store.dispatch(setCookiesAccepted("isCookiesAccepted" in req.cookies));
    Store.dispatch(
      setBannerAppsAccepted("isBannerAppsAccepted" in req.cookies)
    );
    Store.dispatch(loadUserFromCookies(req));
  } else {
    if (process.env.NODE_ENV === "local") {
      setEnvContent(process.env.NODE_ENV);
    }
    // Client
    // Cookies accept
    Store.dispatch(setGeoCountryLocation(LOCATION_NO_DETECTED));

    const isCookiesAccepted = Cookies.get("isCookiesAccepted");
    const isBannerAppsAccepted = Cookies.get("isBannerAppsAccepted");
    Store.dispatch(
      setCookiesAccepted(
        isCookiesAccepted !== undefined ? isCookiesAccepted : false
      )
    );
    Store.dispatch(
      setBannerAppsAccepted(
        isBannerAppsAccepted !== undefined ? isBannerAppsAccepted : false
      )
    );
    Store.dispatch(loadUserFromCookies());
  }
}

export const hasNewsletterCookie = title => {
  const userNewsletterCookie = Cookies.get(USER_NEWSLETTER);
  let users = [];
  if (userNewsletterCookie) {
    users = JSON.parse(userNewsletterCookie);
  }
  return users.some(e => e.title === title);
};
export const addNewsletterCookie = (title, email) => {
  const userNewsletterCookie = Cookies.get(USER_NEWSLETTER);
  let users = [];
  if (userNewsletterCookie) {
    users = JSON.parse(userNewsletterCookie);
  }
  const isUserExists = users.some(e => e.title === title && e.email === email);
  if (!isUserExists) {
    users.push({ title, email });
    Cookies.set(USER_NEWSLETTER, users);
  }
};
