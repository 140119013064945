import React from "react";

import {
  Category,
  MetadataContainer,
  Wrapper,
  ImageLogo,
  ImagePodcast,
  Synopsis,
  Title,
  IconPodcast,
  Link
} from "./PodcastItem";
import { getKey } from "../../../utils/helpers";
import { Colors } from "../../../theme";

export default function PodcastItem({
  brand,
  category,
  image,
  micro_synopsis,
  slug,
  title,
  key
}) {
  const {
    cover_mobile: { path }
  } = image;
  const { image: imageBrand } = brand;
  return (
    <Wrapper key={key}>
      <Link href={slug}>
        {imageBrand && (
          <ImageLogo src={getKey(imageBrand, "logo_negative.path")} />
        )}
        <ImagePodcast src={path} />
        <IconPodcast
          className="content-type-icon-prod"
          name="SpeakerNew"
          size={28}
          color={Colors.white}
        />
        <MetadataContainer>
          <Category>{category}</Category>
          <Title>{title}</Title>
          <Synopsis>{micro_synopsis}</Synopsis>
        </MetadataContainer>
      </Link>
    </Wrapper>
  );
}
