import React from "react";
import SmartBanner from "react-smartbanner";
import "react-smartbanner/dist/main.css";

const props = {
  price: {
    ios: "Gratis",
    android: "Gratis",
    windows: "Gratis",
    kindle: "Gratis"
  },
  storeText: {
    ios: "En la App Store",
    android: "En Google Play",
    windows: "En Windows store",
    kindle: "En Amazon Appstore"
  },
  button: "Ver",
  title: "RTVCPlay"
};

export default function OrBannerAppMovil() {
  return <SmartBanner {...props} />;
}
