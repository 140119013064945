import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { rgba } from "polished";
import Pagination from "react-js-pagination";
import MolHorizontalListView from "../MolHorizontalListView/MolHorizontalListView";
import TplTitleBlock from "../TplTitleBlock/TplTitleBlock";

import "./OrMyList.scss";

import { Colors, Metrics, Wp, Fonts } from "../../theme";

const MOBILE_M = parseInt(Metrics.size.mobileM, 10);
const MOBILE_L = parseInt(Metrics.size.mobileL, 10);
const TABLET = parseInt(Metrics.size.tablet, 10);
const TABLET_L = parseInt(Metrics.size.tabletL, 10);

const Header = styled(Wp.Container)`
  margin-top: ${Metrics.size.minHeaderHeight};
  height: 60px;
  padding-top: 8px;
  @media ${Metrics.mediaQ.tablet} {
    height: 70px;
    padding-top: 16px;
    margin-top: ${Metrics.size.maxHeaderHeight};
  }
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

const ContainerInfo = styled(Wp.Container)`
  height: 60px;
  @media ${Metrics.mediaQ.tablet} {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

const Container = styled(Wp.Page)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Info = styled(Wp.Page)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled(Fonts.h1)`
  font-family: "Dosis", sans-serif;
  font-size: 1.75rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LabelInfo = styled(Fonts.h1)`
  font-family: "Dosis", sans-serif;
  font-size: 2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TitleBlock = styled(TplTitleBlock)`
  background-color: ${props => props.backgroundColor};
  padding-bottom: 40px;
  border-top: ${props => props.borderTop};
  .block-body {
    padding-top: 1em;
  }
  .inner-container {
    padding: 0px 10px;
  }
  ${props => props.customStyles || ""};
`;

const List = styled(Wp.Container)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  ${props => {
    return `    
      @media ${Metrics.mediaQ.laptop} {
        max-width: ${(props.view.width + 10) * 4}px;
        margin: 0 auto;
      }        
      .vertical-list {
        position: relative;            
        margin: 5px;
      }
    `;
  }};
`;

class OrMyList extends Component {
  static defaultProps = {
    myListChapters: {},
    myListSeries: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      animateTitleBlock: false,
      containerWidth: null,
      totalItemsCountChapters: 0,
      pageRangeDisplayedChapters: 10,
      pageRangeDisplayedSeries: 10,
      selectPageChapters: 1,
      perPageChapters: 8,
      totalItemsCountSeries: 0,
      selectPageSeries: 1,
      perPageSeries: 8,
      showFirstChapters: "hide",
      showFirstSeries: "hide",
      showLastChapters: "hide",
      showLastSeries: "hide",
      showPrevItemChapters: "hide",
      showNextItemChapters: "hide",
      showPrevItemSeries: "hide",
      showNextItemSeries: "hide"
    };
  }

  _calcScale = (width, cover = false) => {
    const { containerWidth } = this.state;
    let scale = 1;
    if (containerWidth) {
      scale = (containerWidth / (cover ? 1 : 2) - 10) / width;
      if (containerWidth >= TABLET_L - 20) {
        let calc = (containerWidth / 4 - 10) / width;
        scale = calc < 1 ? calc : 1;
      } else if (containerWidth >= TABLET - 20) {
        scale = (containerWidth / 3 - 10) / width;
      } else if (containerWidth >= MOBILE_L - 20) {
        scale = (containerWidth / 3 - 10) / width;
      } else if (containerWidth >= MOBILE_M - 20) {
        scale = (containerWidth / 2 - 10) / width;
      }
    }
    return cover ? scale : scale < 1 ? scale : 1;
  };

  _scroll = ev => {
    if (this.refs.titleBlock && this.refs.titleBlock.refs.blockTitle) {
      let blockTitleElTop = this.refs.titleBlock.refs.blockTitle.getBoundingClientRect()
        .top;
      if (
        blockTitleElTop < window.innerHeight - 250 &&
        !this.state.animateTitleBlock
      ) {
        this.setState({
          animateTitleBlock: true
        });
      }
    }
  };
  _resize = () => {
    const containerWidthEl = document.getElementById("blockBodyContainer");
    if (containerWidthEl) {
      this.setState({
        containerWidth: containerWidthEl.offsetWidth
      });
    }
  };
  componentDidMount() {
    window.addEventListener("resize", this._resize);
    document.addEventListener("scroll", this._scroll);
    const containerWidthEl = document.getElementById("blockBodyContainer");
    if (containerWidthEl) {
      this.setState({
        containerWidth: containerWidthEl.offsetWidth
      });
    }
    let promise = this.loadPageRecordsChapters(this.state.selectPageChapters);
    promise = promise.then(
      this.loadPageRecordsSeries(this.state.selectPageSeries)
    );
    promise.then(this.props.searchFooter());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._resize);
    document.removeEventListener("scroll", this._scroll);
  }

  loadPageRecordsChapters(selectPageNumber) {
    if (selectPageNumber === undefined) {
      const currentPage = this.state.selectPageChapters;
      const countleftContent =
        this.state.totalItemsCountChapters -
        (currentPage - 1) * this.state.perPageChapters -
        1;
      selectPageNumber = currentPage - (countleftContent > 0 ? 0 : 1);
      selectPageNumber = selectPageNumber <= 0 ? 1 : selectPageNumber;
    }
    let promise = this.props.getChaptersMyList(
      selectPageNumber - 1,
      this.state.perPageChapters
    );
    promise = promise.then(data => {
      //show or hide first button
      let showFirstChapters =
        selectPageNumber <= Math.ceil(this.state.pageRangeDisplayedChapters / 2)
          ? "hide"
          : "";
      //show or hide last button
      let numberPages = data.content.widgets
        ? Math.ceil(
            data.content.widgets[0].metadata.count / this.state.perPageChapters
          )
        : 1;
      let showLastChapters =
        selectPageNumber <
        numberPages - Math.floor(this.state.pageRangeDisplayedChapters / 2)
          ? ""
          : "hide";
      let showPrevItemChapters = selectPageNumber === 1 ? "hide" : "";
      let showNextItemChapters = selectPageNumber === numberPages ? "hide" : "";
      this.setState({
        selectPageChapters: selectPageNumber,
        totalItemsCountChapters: data.content.widgets
          ? data.content.widgets[0].metadata.count
          : 0,
        showFirstChapters: showFirstChapters,
        showLastChapters: showLastChapters,
        showPrevItemChapters: showPrevItemChapters,
        showNextItemChapters: showNextItemChapters
      });
    });
    return promise;
  }

  loadPageRecordsSeries(selectPageNumber) {
    if (selectPageNumber === undefined) {
      const currentPage = this.state.selectPageSeries;
      const countleftContent =
        this.state.totalItemsCountSeries -
        (currentPage - 1) * this.state.perPageSeries -
        1;
      selectPageNumber = currentPage - (countleftContent > 0 ? 0 : 1);
      selectPageNumber = selectPageNumber <= 0 ? 1 : selectPageNumber;
    }
    let promise = this.props.getSeriesMyList(
      selectPageNumber - 1,
      this.state.perPageSeries
    );
    promise = promise.then(data => {
      //show or hide first button
      let showFirstSeries =
        selectPageNumber <= Math.ceil(this.state.pageRangeDisplayedSeries / 2)
          ? "hide"
          : "";
      //show or hide last button
      let numberPages = data.content.widgets
        ? Math.ceil(
            data.content.widgets[0].metadata.count / this.state.perPageSeries
          )
        : 1;
      let showLastSeries =
        selectPageNumber <
        numberPages - Math.floor(this.state.pageRangeDisplayedSeries / 2)
          ? ""
          : "hide";
      let showPrevItemSeries = selectPageNumber === 1 ? "hide" : "";
      let showNextItemSeries = selectPageNumber === numberPages ? "hide" : "";
      this.setState({
        selectPageSeries: selectPageNumber,
        totalItemsCountSeries: data.content.widgets
          ? data.content.widgets[0].metadata.count
          : 0,
        showFirstSeries: showFirstSeries,
        showLastSeries: showLastSeries,
        showPrevItemSeries: showPrevItemSeries,
        showNextItemSeries: showNextItemSeries
      });
    });
    return promise;
  }

  handlePageClickChapters = selectPageNumber => {
    this.loadPageRecordsChapters(selectPageNumber);
  };

  handlePageClickSeries = selectPageNumber => {
    this.loadPageRecordsSeries(selectPageNumber);
  };

  render() {
    const { myListChapters, myListSeries } = this.props;
    const views = {
      poster: {
        className: "poster",
        imageKey: "poster",
        backgroundColor: Colors.bg2,
        width: 265,
        height: 399,
        scale: this._calcScale(265),
        spaceBetween: 20,
        infoHover: {
          customStyles: `
	          	border: 1px solid ${Colors.white};
	          	background: radial-gradient(circle at center 43%, ${rgba(
                Colors.rating,
                0.85
              )} 0%,rgba(0,0,0,.9) 60%,rgba(0,0,0,1) 100%);
					.play-button {
						top: 15%;
					}
				`
        }
      },
      cover: {
        className: "cover",
        imageKey: "cover",
        backgroundColor: Colors.bg2,
        width: 265,
        height: 149,
        scale: this._calcScale(265, true),
        spaceBetween: 20,
        infoFooter: {
          height: 56,
          customStyles: ``
        },
        contentTypeIcon: true
      }
    };
    if (myListSeries && myListChapters) {
      if (myListChapters.widgets || myListSeries.widgets) {
        return (
          <Fragment>
            <Header>
              <Container>
                <Title>{myListChapters.title}</Title>
              </Container>
            </Header>
            <TitleBlock
              ref="titleBlock"
              backgroundColor={
                myListChapters.widgets
                  ? views[myListChapters.widgets[0].view].backgroundColor
                  : Colors.bg2
              }
              /* animateTitle={this.state.animateTitleBlock} */
              showTitle={false}
            >
              <div className={`contentMyListList`}>
                <div className="navMyList">
                  <div className="titleWiget">
                    <h3 className="title">
                      {myListChapters.widgets &&
                      myListChapters.widgets.length > 0
                        ? myListChapters.widgets[0].title
                        : null}
                    </h3>
                  </div>
                </div>
                <List
                  containerWidth={this.state.containerWidth}
                  view={
                    myListChapters.widgets
                      ? views[myListChapters.widgets[0].view]
                      : views["cover"]
                  }
                >
                  {myListChapters.widgets &&
                    myListChapters.widgets[0].contents.map((content, index) => (
                      <MolHorizontalListView
                        key={index}
                        content={content}
                        view={views["cover"]}
                        index={index}
                        className="vertical-list"
                        miLista={true}
                        onRemoveFromMyList={this.handlePageClickChapters}
                      />
                    ))}
                </List>
                <div className={"contentPager"}>
                  <Pagination
                    firstPageText={"PRIMER"}
                    lastPageText={"ÚLTIMO"}
                    prevPageText={"<"}
                    nextPageText={">"}
                    activePage={this.state.selectPageChapters}
                    itemsCountPerPage={this.state.perPageChapters}
                    totalItemsCount={this.state.totalItemsCountChapters}
                    pageRangeDisplayed={this.state.pageRangeDisplayedChapters}
                    onChange={this.handlePageClickChapters}
                    itemClassFirst={"firstItem " + this.state.showFirstChapters}
                    itemClassPrev={
                      "prevItem " + this.state.showPrevItemChapters
                    }
                    itemClassNext={
                      "nextItem " + this.state.showNextItemChapters
                    }
                    itemClassLast={"lastItem " + this.state.showLastChapters}
                    activeClass={"active"}
                    activeLinkClass={"active"}
                    itemClass={"page-item"}
                    linkClass={"page-link"}
                  />
                </div>
              </div>
              <div className={`contentMyListList`}>
                <div className="navMyList">
                  <div className="titleWiget">
                    <h3 className="title">
                      {myListSeries.widgets && myListSeries.widgets.length > 0
                        ? myListSeries.widgets[0].title
                        : null}
                    </h3>
                  </div>
                </div>
                <List
                  containerWidth={this.state.containerWidth}
                  view={views["poster"]}
                >
                  {myListSeries.widgets &&
                    myListSeries.widgets[0].contents.map((content, index) => (
                      <MolHorizontalListView
                        key={index}
                        content={content}
                        view={views["poster"]}
                        index={index}
                        className="vertical-list"
                        miLista={true}
                        onRemoveFromMyList={this.handlePageClickSeries}
                      />
                    ))}
                </List>
                <div className={"contentPager"}>
                  <Pagination
                    firstPageText={"PRIMER"}
                    lastPageText={"ÚLTIMO"}
                    prevPageText={"<"}
                    nextPageText={">"}
                    activePage={this.state.selectPageSeries}
                    itemsCountPerPage={this.state.perPageSeries}
                    totalItemsCount={this.state.totalItemsCountSeries}
                    pageRangeDisplayed={this.state.pageRangeDisplayedSeries}
                    onChange={this.handlePageClickSeries}
                    itemClassFirst={"firstItem " + this.state.showFirstSeries}
                    itemClassPrev={"prevItem " + this.state.showPrevItemSeries}
                    itemClassNext={"nextItem " + this.state.showNextItemSeries}
                    itemClassLast={"lastItem " + this.state.showLastSeries}
                    activeClass={"active"}
                    activeLinkClass={"active"}
                    itemClass={"page-item"}
                    linkClass={"page-link"}
                  />
                </div>
              </div>
            </TitleBlock>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Header>
              <Container>
                <Title>Mi lista</Title>
              </Container>
            </Header>
            <ContainerInfo>
              <Info>
                <LabelInfo>Aún no has agregado contenido a la lista.</LabelInfo>
              </Info>
            </ContainerInfo>
          </Fragment>
        );
      }
    } else {
      return <Fragment />;
    }
  }
}

export default OrMyList;
