import React from "react";
import styled, { css } from "styled-components";
import { Colors } from "../../../theme";
import { AudioPlayerType } from "./index";

export const AudioPlayerProgress = ({
  progress,
  handleSeek,
  audioPlayerType = AudioPlayerType.FULL,
}) => {
  return (
    <Progress
      type="range"
      min="0"
      max="100"
      value={progress}
      onChange={handleSeek}
      audioPlayerType={audioPlayerType}
      style={{ "--progress": progress }}
    />
  );
};

const Progress = styled.input`
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  margin: 10px 0 15px;

  &::-webkit-slider-runnable-track {
    background: linear-gradient(
      90deg,
      ${Colors.orangeDark} calc(var(--progress) * 1%),
      ${Colors.orangeOpaque} 0%
    );
    height: 2px;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    background: ${Colors.orangeBright};
    border-radius: 50%;
    cursor: pointer;
    border: none;
    margin-top: -4px;
  }

  &::-moz-range-track {
    background: linear-gradient(
      90deg,
      ${Colors.orangeDark} calc(var(--progress) * 1%),
      ${Colors.orangeOpaque} 0%
    );
    height: 2px;
  }
  &::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background: ${Colors.orangeBright};
    border-radius: 50%;
    cursor: pointer;
    border: none;
    margin-top: -4px;
  }

  ${props =>
    props.audioPlayerType === AudioPlayerType.SIMPLE &&
    css`
      &::-webkit-slider-runnable-track {
        background: linear-gradient(
          90deg,
          ${Colors.orangeBright} calc(var(--progress) * 1%),
          #464646 0%
        );
        height: 5px;
      }

      &::-webkit-slider-thumb {
        width: 13px;
        height: 13px;
        background: ${Colors.white};
      }

      &::-moz-range-track {
        background: linear-gradient(
          90deg,
          ${Colors.orangeBright} calc(var(--progress) * 1%),
          #464646 0%
        );
        height: 5px;
      }
      &::-moz-range-thumb {
        width: 13px;
        height: 13px;
        background: ${Colors.white};
      }
    `}
`;
