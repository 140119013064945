import styled from "styled-components";

import AtImg from "../AtImg/AtImg";
import AtIframe from "../AtIframe/AtIframe";
import MolShareButton from "../MolShareButton";

import { Colors, Wp, Fonts, Metrics } from "../../theme";

export const Container = styled(Wp.Page)`
  margin-top: ${Metrics.size.minHeaderHeight};
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  width: calc(100% - 2.5rem);
  @media ${Metrics.mediaQ.tablet} {
    margin-top: ${Metrics.size.maxHeaderHeight};
    padding: 0;
  }
`;

export const Row = styled(Wp.Container)`
  display: flex;
  flex-direction: row;
  &.first-row {
    padding: 10px 0;
    .brand-container {
      max-width: 100px;
      justify-content: flex-end;
      padding-bottom: 5px;
    }
  }
  &.ItemCentered {
    align-items: center;
  }
`;

export const IFrame = styled(AtIframe)`
  background: ${Colors.black};
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin: 0px -50vw;
  @media ${Metrics.mediaQ.tabletL} {
    margin: 0px;
    width: 100%;
    left: initial;
    right: initial;
  }
`;

export const Column = styled(Wp.Container)`
  display: flex;
  flex-direction: column;
  &.share {
    align-items: flex-end;
    max-width: 8rem;
    > a {
      > span {
        font-size: 1.125rem;
        line-height: 1.5;
        margin-left: 1px;
      }
    }

    @media screen and (max-width: 767px) {
      margin-top: 10;
    }
  }
  .generalContainerShareButtons {
    > a {
      > span {
        @media screen and (max-width: 767px) {
          font-size: 14px;
          font-weight: normal;
          line-height: 28px;
        }
      }
    }
  }
`;

export const Label = styled(Fonts.liveStreamingLabel)`
  color: ${Colors.red};
  margin: 0;
  line-height: 1.2;
  @media screen and (max-width: 767px) {
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
  }
`;

export const Brand = styled(AtImg)`
  max-height: 34px;
  max-width: 100%;
  align-self: flex-end;
`;

export const Title = styled(Fonts.liveStreamingTitle)`
  font-family: "BebasNeue Regular", sans-serif;
  font-weight: 400;
  font-size: 36px;
  margin-top: 10px;
  line-height: 1;
  @media ${Metrics.mediaQ.tablet} {
    font-size: 2.25rem;
  }
`;

export const SubTitle = styled(Fonts.liveStreamingSubtitle)`
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 767px) {
    font-size: 22px;
    font-weight: normal;
  }
`;

export const Summary = styled(Wp.Container)`
  border-top: 1px solid ${Colors.bunker};
  display: block;
  width: 100%;
  margin-top: 0;
  text-transform: uppercase;
  background-color: #2e2e2e;
  padding: 0.6rem 0rem 0.6rem 0.5rem;
  p,
  span,
  a {
    font-family: "Dosis", sans-serif;
    font-size: 1.125rem;
    color: ${Colors.snow};
    margin: 0;
    padding: 0;
  }
  @media screen and (max-width: 767px) {
    p,
    span,
    a {
      font-size: 14px;
      line-height: 17px;
    }
  }
`;
export const WrapperPlayer = styled(Wp.Container)`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
  }
`;
export const KalturaPlayer = styled.div`
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  margin: auto !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
`;

export const ShareButton = styled(MolShareButton)``;
