import React, { Component } from "react";
import styled from "styled-components";

import { Wp, Metrics, Fonts } from "../../theme";

const Container = styled(Wp.Page)`
  margin-top: ${Metrics.size.minHeaderHeight};
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  width: calc(100% - 2.5rem);
  @media ${Metrics.mediaQ.tablet} {
    margin-top: ${Metrics.size.maxHeaderHeight};
  }
`;

const Title = styled(Fonts.h1)``;

const Row = styled(Wp.Container)`
  display: flex;
  flex-direction: row;
`;

const Column = styled(Wp.Container)`
  display: flex;
  flex-direction: column;
  &.title {
    margin-top: 2em;
  }
  &.content {
    span {
      ${Fonts.span.componentStyle.rules.join("")};
    }
    a {
      ${Fonts.a.componentStyle.rules.join("")};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }
    h2 {
      ${Fonts.h2.componentStyle.rules.join("")};
      margin-top: 1em;
      margin-bottom: 0.5em;
    }
    p {
      ${Fonts.p.componentStyle.rules.join("")};
    }
    img {
      max-width: 100%;
      height: initial;
    }
  }
`;

class OrBasicPage extends Component {
  render() {
    const { content, title } = this.props;
    return (
      <Container>
        <Row>
          <Column className="title">
            <Title>{title}</Title>
          </Column>
        </Row>
        <Row>
          <Column
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Row>
      </Container>
    );
  }
}

export default OrBasicPage;
