import React, { Component, Fragment } from "react";
import {
  getUniqueIdPlayer,
  getURLVideo,
  getVideoPlayer,
  isAmazon
} from "../../utils/helpers/video";
import { setDataRecommender } from "../../../app/utils/helpers/recommender";
import "./AtVideo.scss";
import { getKey } from "../../utils/helpers";

class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqueId: getKey(props, "video.playerId", getUniqueIdPlayer())
    };  
  }

  componentDidMount() {
    let firstTimePlayed = false;
    if (isAmazon(this.props.streaming_provider)) {
      if (typeof window !== "undefined") {
        try {
          const player = window.KalturaPlayer.setup({
            targetId: this.state.uniqueId,
            provider: {},
            //logLevel: "DEBUG",
            adobe: {
              name: this.props.title,
              player: "rtvcplay",
              production: this.props.title,
              type: "vod",
              duration: this.props.d || 0
            },
            rtvcplay: {
              isLive: false,
              useservice: true,
              assetid: this.props.video.assetid
            },
            sources: {
              hls: [
                {
                  mimetype: "application/x-mpegurl",
                  url: getURLVideo(this.props)
                }
              ]
            }
          });

          player.addEventListener(player.Event.PLAYER_STATE_CHANGED, event => {
            const newState = event.payload.newState;
            if (newState.type === player.State.PLAYING && !firstTimePlayed) {
              setDataRecommender(
                this.props.production.id,
                new Date().getTime(),
                "VISTO",
                1
              );
              firstTimePlayed = true;
            }
          });
        } catch (e) {
          console.error({
            errorFromAtVideo: e
          });
        }
      }
    }
  }

  render() {
    const className = getKey(this.props, "video.className");
    const videoPlayer = getVideoPlayer({
      ...this.props,
      uniqueId: this.state.uniqueId
    });

    return (
      <Fragment>
        <div
          className={`videoWrapper ${className}`}
          dangerouslySetInnerHTML={{ __html: videoPlayer }}
        />
      </Fragment>
    );
  }
}
export default Video;
