import styled from "styled-components";

export const Container = styled.ul`
  padding: 0;
  margin: 0;
  @media screen and (max-width: 767px) {
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
  }
`;
export const InnerContainer = styled.ul`
  list-style: none;
  margin: 10px 0;
  padding: 0;
  display: flex;
`;
export const ListItem = styled.li`
  display: inline-flex;
`;
