import React, { Component } from "react";
import styled from "styled-components";

import AtImg from "../AtImg/AtImg";

import { Colors, Wp, Images, Metrics } from "../../theme";

const Container = styled(Wp.Container)`
  display: flex;
  flex-direction: row;
`;
const Column = styled(Wp.Container)`
  display: inline-block;
  font-size: 10px;
  @media ${Metrics.mediaQ.tablet} {
    padding-right: 10px;
    font-size: 13px;

    :first-child {
      border-right: 1px solid ${Colors.white};
      padding-right: 1.25em;
    }
    :last-child {
      padding-left: 1.25em;
    }
  }
  @media ${Metrics.mediaQ.laptop} {
    font-size: initial;
  }
`;
const Img = styled(AtImg)`
  width: auto;
  max-height: 50px;
  float: right;
`;

class MolBrandContainer extends Component {
  static defaultProps = {
    brand: Images.radionica,
    brandTitle: "Radiónica",
    brandAlt: "Radiónica",
    brandRetina: false,
    hideLogo: false
  };
  render() {
    const {
      className,
      brand,
      brandTitle,
      brandAlt,
      brandRetina,
      children,
      isSliderKids,
      hideLogo
    } = this.props;
    return (
      <Container className={className}>
        {isSliderKids ? (
          <Column className="brand content">
            {!hideLogo && (
              <Img
                src={brand}
                title={brandTitle}
                alt={brandAlt}
                retina={brandRetina}
                withLazyLoad={false}
              />
            )}
            {children}
          </Column>
        ) : (
          <>
            {!hideLogo && (
              <Column className="brand">
                <Img
                  src={brand}
                  title={brandTitle}
                  alt={brandAlt}
                  retina={brandRetina}
                  withLazyLoad={false}
                />
              </Column>
            )}
            <Column className="content">{children}</Column>
          </>
        )}
      </Container>
    );
  }
}

export default MolBrandContainer;
