import React, { createContext, useState, useContext } from "react";
const AudioPageContext = createContext({
  isPlaying: false,
  togglePlay: false,
  toggle: () => {},
  currentAudio: {},
  setCurrentAudio: () => {},
  setIsPlaying: () => {},
  brand: null,
  coverPodcast: null,
  infoAdditional: null,
  title: null,
  audioCredit: null,
  currentIndex: -1,
  setCurrentIndex: () => {},
});

export const AudioPageProvider = ({
  brand,
  coverPodcast,
  infoAdditional,
  title,
  children,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [togglePlay, setTogglePlay] = useState(1);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [audioCredit, setAudioCredit] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const openAudioCreditModal = audio => {
    setAudioCredit({
      ...audio,
      open: audioCredit && audioCredit.open ? audioCredit.open + 1 : 1,
    });
  };

  const toggle = () => {
    setTogglePlay(togglePlay + 1);
  };

  return (
    <AudioPageContext.Provider
      value={{
        isPlaying,
        togglePlay,
        setIsPlaying,
        currentAudio,
        toggle,
        setCurrentAudio,
        brand,
        coverPodcast,
        infoAdditional,
        title,
        audioCredit,
        openAudioCreditModal,
        currentIndex,
        setCurrentIndex,
      }}
    >
      {children}
    </AudioPageContext.Provider>
  );
};

export const useAudioPlayer = () => useContext(AudioPageContext);

export default AudioPageContext;
