import React, { Component, Fragment } from "react";
import { Colors, Metrics, Images } from "../../theme";

import AtBadge from "../AtBadge/AtBadge";
import AtImg from "../AtImg/AtImg";
import AtTimeLeft from "../../components/AtTimeLeft";
import SocialButtons from "../MolShareButton";
import { getCurrentFrontEndEnvironmentUrl } from "../../../store/utils/helpers";
import AddMyListButton from "../MolAddMyListButton";
import Metadata from "./Metadata";
import { isValidArray } from "../../utils/helpers";

import {
  BarsIconButton,
  BrandContainer,
  GradientBg,
  Icon,
  IconNinos,
  ImageSelect,
  ImageSlider,
  ImgContainer,
  ImgContainerKids,
  LabelContainer,
  LinkContainer,
  LinkNinos,
  LitlePlayIcon,
  MyListcontainer,
  Player,
  Rating,
  Slide,
  SliderContainer,
  SliderKids,
  SocialBtns,
  Summary,
  Swiper,
  TextContainer,
  Title,
  TitleBlock,
  ToolbarButtons
} from "./OrMainSlider";
import { CategoryTreeFilter } from "./CategoryTreeFilter";

class MainSlider extends Component {
  state = { animateTitleBlock: false, windowWidth: null, windowHeight: null };
  static defaultProps = {
    contents: [],
    title: "",
    aspectRatio: "12-13",
    showTitle: true
  };
  _scroll = ev => {
    if (this.refs.titleBlock && this.refs.titleBlock.refs.blockTitle) {
      let blockTitleElTop = this.refs.titleBlock.refs.blockTitle.getBoundingClientRect()
        .top;
      if (
        blockTitleElTop < window.innerHeight - 250 &&
        !this.state.animateTitleBlock
      ) {
        this.setState({ animateTitleBlock: true });
      }
    }
  };

  _resize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
  };
  _imageAttr = image => {
    if (image.cover_mobile && image.cover_desktop) {
      return {
        title: image.cover_mobile.title || image.cover_desktop.title || "",
        alt: image.cover_mobile.alt || image.cover_desktop.alt || "",
        src: [
          {
            "": image.cover_mobile.path
          },
          {
            [Metrics.mediaQ.tabletL]: image.cover_desktop.path
          },
          {
            [`(max-width: ${
              Metrics.size.tabletL
            }) and (orientation: landscape)`]: image.cover_desktop.path
          }
        ],
        responsive: true
      };
    }
    return { src: Images.coverTest };
  };
  _videoAttr = (video, poster) => {
    const landscape = this.state.windowWidth > this.state.windowHeight;
    if (video.video_mobile && this.state.windowWidth < 835) {
      return {
        config: {
          file: {
            attributes: {
              autoPlay: true,
              poster: poster.cover_mobile
                ? landscape
                  ? poster.cover_desktop.path
                  : poster.cover_mobile.path || ""
                : poster.cover_desktop.path || "",
              loop: true,
              controls: false,
              muted: true,
              playsInline: true
            }
          }
        },
        url: [
          {
            src: landscape ? video.video_desktop : video.video_mobile,
            preload: "auto"
          }
        ]
      };
    }
    return {
      config: {
        file: {
          attributes: {
            autoPlay: true,
            poster: poster.cover_desktop.path || "",
            loop: true,
            controls: false,
            muted: true
          }
        }
      },
      url: [
        {
          src: video.video_desktop,
          preload: "auto"
        }
      ]
    };
  };
  _Sliderkids = (contents, index) => {
    return (
      <>
        <SliderContainer className="sliderkidscontainer">
          <ImgContainerKids>
            {contents.map(({ miniatura }, index1) => (
              <>
                {index <= index1 && (
                  <ImageSelect
                    key={`imgKids1${index1}`}
                    className="content_slider_kids"
                    data-slide={index1 + 1}
                  >
                    <ImageSlider src={miniatura.cover_chapter.path} />
                  </ImageSelect>
                )}
              </>
            ))}
            {contents.map(({ miniatura }, index1) => (
              <>
                {index > index1 && (
                  <ImageSelect
                    key={`imgKids2${index1}`}
                    className="content_slider_kids"
                    data-slide={index1 + 1}
                  >
                    <ImageSlider src={miniatura.cover_chapter.path} />
                  </ImageSelect>
                )}
              </>
            ))}
          </ImgContainerKids>
        </SliderContainer>
      </>
    );
  };

  _renderImage = (
    id,
    slug,
    image,
    category,
    title,
    micro_synopsis,
    brand,
    rating,
    availability,
    label,
    related_content,
    isSliderKids,
    contents,
    color,
    index,
    isEducation,
    align_left,
    cta,
    noContentKids
  ) => {
    const environmentUrl = getCurrentFrontEndEnvironmentUrl();
    if (!related_content || (!related_content.id && !related_content.slug)) {
      return (
        <Fragment>
          <ImgContainer>
            <AtImg {...this._imageAttr(image)} />
          </ImgContainer>
          {isSliderKids && (
            <>
              {!noContentKids && (
                <IconNinos className="rigthiconninos">
                  <LinkNinos href={"/ninos"}>
                    Mundo Infantil
                    <BarsIconButton
                      name="IconNinos"
                      iconSize={70}
                      color={Colors.transparent}
                      classname="iconninos"
                    />
                  </LinkNinos>
                </IconNinos>
              )}
              <SliderKids>{this._Sliderkids(contents, index)}</SliderKids>
            </>
          )}
          {rating && <Rating data={rating.split(",")} />}
          {availability && <AtTimeLeft type="slider" text={availability} />}
          <GradientBg
            className={isEducation ? "isEducation" : "SectionDefault"}
          />
          {align_left ? (
            !!brand && (
              <Metadata
                brand={brand}
                title={title}
                micro_synopsis={micro_synopsis}
                label={label}
                category={category}
                slug={slug}
              />
            )
          ) : (
            <BrandContainer
              brand={!!brand && brand.image.logo.path}
              brandAlt={!!brand && brand.image.logo.alt}
              brandTitle={!!brand && brand.image.logo.title}
              isSliderKids={isSliderKids}
              noContentKids={noContentKids}
              className={isSliderKids ? "brandslider" : ""}
            >
              <TextContainer>
                <AtBadge
                  color={isSliderKids ? color : !!brand && brand.color_label}
                  el="h2"
                >
                  {isSliderKids ? label : category}
                </AtBadge>
                <Title className={isSliderKids ? "titlesliderkids" : "title"}>
                  {title}
                </Title>
                <Summary className={isSliderKids && "sumarysliderkids"}>
                  {micro_synopsis}
                </Summary>
                <ToolbarButtons isSliderKids={isSliderKids}>
                  {label && (
                    <LabelContainer
                      className={isSliderKids && "play-icon-sliderkids"}
                    >
                      <LitlePlayIcon
                        className="play-icon"
                        name="PlayCircle"
                        size={32}
                      />{" "}
                      <span>{isSliderKids ? cta || "VER SERIE" : label}</span>
                    </LabelContainer>
                  )}
                </ToolbarButtons>
              </TextContainer>
            </BrandContainer>
          )}
          <LinkContainer href={slug}>
            <Icon
              className={isSliderKids ? "playiconsliderkids" : "play-icon"}
              name="PlayCircle"
              size={100}
            />
          </LinkContainer>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <ImgContainer>
          <AtImg {...this._imageAttr(image)} />
        </ImgContainer>
        {rating && <Rating data={rating.split(",")} />}
        {availability && <AtTimeLeft type="slider" text={availability} />}
        <GradientBg
          className={isEducation ? "isEducation" : "SectionDefault"}
        />

        {align_left ? (
          <Metadata
            brand={brand}
            title={title}
            micro_synopsis={micro_synopsis}
            label={label}
            category={category}
            slug={slug}
          />
        ) : (
          <BrandContainer
            brand={brand.image.logo.path}
            brandAlt={brand.image.logo.alt}
            brandTitle={brand.image.logo.title}
          >
            <TextContainer>
              <AtBadge color={brand.color_label} el="h2">
                {category}
              </AtBadge>
              <Title className="title">{title}</Title>
              <Summary>{micro_synopsis}</Summary>
              <ToolbarButtons>
                {label && (
                  <LabelContainer>
                    <LitlePlayIcon
                      className="play-icon"
                      name="PlayCircle"
                      size={30}
                    />{" "}
                    <span>{label}</span>
                  </LabelContainer>
                )}
                <SocialBtns>
                  <SocialButtons
                    label="COMPARTIR"
                    title={title}
                    text={micro_synopsis}
                    size={32}
                    sizeButtons={18}
                    uri={environmentUrl + related_content.slug}
                  />
                </SocialBtns>
                <MyListcontainer>
                  <AddMyListButton size={32} id={related_content.id} />
                </MyListcontainer>
              </ToolbarButtons>
            </TextContainer>
          </BrandContainer>
        )}
        <LinkContainer href={environmentUrl + related_content.slug}>
          <Icon className="play-icon" name="PlayCircle" size={100} />
        </LinkContainer>
      </Fragment>
    );
  };
  _renderVideo = (
    slug,
    aspectRatio,
    image,
    video,
    category,
    title,
    micro_synopsis,
    brand,
    rating,
    availability,
    label,
    isSliderKids,
    contents,
    color,
    index,
    isEducation,
    align_left,
    noContentKids
  ) => {
    return (
      <Fragment>
        {this.state.windowWidth &&
          this.state.windowHeight && (
            <Player
              aspectRatio={aspectRatio}
              coverOverflow
              showAudioButton={false}
              showProgressBar={false}
              {...this._videoAttr(video, image)}
              loop
              isBackgroundVideo
            />
          )}
        {rating && <Rating data={rating.split(",")} />}
        {availability && <AtTimeLeft type="slider" text={availability} />}
        {isSliderKids && (
          <>
            {!noContentKids && (
              <IconNinos className="rigthiconninos">
                <LinkNinos href={"/ninos"}>
                  Mundo Infantil
                  <BarsIconButton
                    name="IconNinos"
                    iconSize={70}
                    color={Colors.transparent}
                    classname="iconninos"
                  />
                </LinkNinos>
              </IconNinos>
            )}
            <SliderKids>{this._Sliderkids(contents, index)}</SliderKids>
          </>
        )}
        <GradientBg
          className={isEducation ? "isEducation" : "SectionDefault"}
        />
        {align_left ? (
          <Metadata
            brand={brand}
            title={title}
            micro_synopsis={micro_synopsis}
            label={label}
            category={category}
            slug={slug}
          />
        ) : (
          <BrandContainer
            brand={brand.image.logo.path}
            brandAlt={brand.image.logo.alt}
            brandTitle={brand.image.logo.title}
            isSliderKids={isSliderKids}
            className={isSliderKids ? "brandslider" : ""}
          >
            <TextContainer>
              <AtBadge
                className={brand.class}
                color={isSliderKids ? color : brand.color_label}
                el="h2"
              >
                {isSliderKids ? label : category}
              </AtBadge>
              <Title className={isSliderKids ? "titlesliderkids" : "title"}>
                {title}
              </Title>
              <Summary className={isSliderKids && "sumarysliderkids"}>
                {micro_synopsis}
              </Summary>
              {label && (
                <LabelContainer
                  className={isSliderKids && "play-icon-sliderkids"}
                >
                  <LitlePlayIcon
                    className="play-icon"
                    name="PlayCircle"
                    size={30}
                  />{" "}
                  <span>{isSliderKids ? "VER SERIE" : label}</span>
                </LabelContainer>
              )}
            </TextContainer>
          </BrandContainer>
        )}
        <LinkContainer href={slug}>
          <Icon
            className={isSliderKids ? "playiconsliderkids" : "play-icon"}
            name="PlayCircle"
            size={100}
          />
        </LinkContainer>
      </Fragment>
    );
  };

  componentDidMount() {
    window.addEventListener("resize", this._resize);
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
    document.addEventListener("scroll", this._scroll);
    let container = document.querySelector(".resizecontainer");
    let container_left_sliderkids = document.querySelectorAll(".brandslider");
    let container_right_sliderkids = document.querySelectorAll(
      ".sliderkidscontainer,.rigthiconninos"
    );
    let container_arrow_next_sliderkids = document.querySelectorAll(
      ".swipersliderkids .swiper-button-next"
    );
    let container_arrow_prev_sliderkids = document.querySelectorAll(
      ".swipersliderkids .swiper-button-prev"
    );
    let positionleftkids = 0;
    container_left_sliderkids.forEach(element => {
      if (window.outerWidth > 768) {
        element.style.left = container.offsetLeft + "px";
      }
    });
    container_right_sliderkids.forEach(element => {
      if (window.outerWidth > 768) {
        element.style.right = container.offsetLeft + "px";
      }
    });
    container_arrow_prev_sliderkids.forEach(element => {
      if (window.outerWidth > 1024) {
        positionleftkids = container.offsetLeft + 657;
        element.style.left = positionleftkids + "px";
      }
    });
    container_arrow_next_sliderkids.forEach(element => {
      if (window.outerWidth > 1024) {
        positionleftkids = container.offsetLeft + 730;
        element.style.left = positionleftkids + "px";
      }
    });
  }
  componentDidUpdate() {
    let container = document.querySelector(".resizecontainer");
    let container_left_sliderkids = document.querySelectorAll(".brandslider");
    let container_right_sliderkids = document.querySelectorAll(
      ".sliderkidscontainer,.rigthiconninos"
    );
    let container_arrow_next_sliderkids = document.querySelectorAll(
      ".swipersliderkids .swiper-button-next"
    );
    let container_arrow_prev_sliderkids = document.querySelectorAll(
      ".swipersliderkids .swiper-button-prev"
    );
    let positionleftkids = 0;
    container_left_sliderkids.forEach(element => {
      if (window.outerWidth > 768) {
        element.style.left = container.offsetLeft + "px";
      }
    });
    container_right_sliderkids.forEach(element => {
      if (window.outerWidth > 768) {
        element.style.right = container.offsetLeft + "px";
      }
    });
    container_arrow_prev_sliderkids.forEach(element => {
      if (window.outerWidth > 1024) {
        positionleftkids = container.offsetLeft + 657;
        element.style.left = positionleftkids + "px";
      }
    });
    container_arrow_next_sliderkids.forEach(element => {
      if (window.outerWidth > 1024) {
        positionleftkids = container.offsetLeft + 730;
        element.style.left = positionleftkids + "px";
      }
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this._resize);
    document.removeEventListener("scroll", this._scroll);
  }

  render() {
    const {
      align_left,
      aspectRatio,
      category_tree,
      contents,
      isEducation,
      isNinos,
      isSliderKids,
      showTitle,
      styles,
      title,
      noContentKids
    } = this.props;
    let loopSliderKids = false;
    let keyboardSliderKids = false;
    if (isSliderKids) {
      loopSliderKids = true;
      keyboardSliderKids = true;
    }
    return (
      <>
        {title && (
          <TitleBlock
            ref="titleBlock"
            title={title}
            customStyles={styles}
            isNinos={isNinos}
            isEducation={isEducation}
            showTitle={showTitle}
            animateTitle={this.state.animateTitleBlock}
          />
        )}
        {!!category_tree && <CategoryTreeFilter data={category_tree} />}
        <Swiper
          aspectRatio={aspectRatio}
          nextPrevButtonSize={isSliderKids ? 35 : 45}
          className={isSliderKids && "swipersliderkids"}
          showPagination={isValidArray(contents) && contents.length > 1}
          swiperParams={{
            autoplay: { delay: 5000 },
            loop: loopSliderKids,
            keyboard: keyboardSliderKids,
            on: {
              init: function() {
                if (isSliderKids) {
                  let swiper = this;

                  document
                    .querySelectorAll(".content_slider_kids")
                    .forEach(element => {
                      element.addEventListener("click", function() {
                        swiper.slideTo(element.dataset.slide);
                      });
                    });
                }
              },
              slideChange: function() {
                if (isSliderKids) {
                  if (this.activeIndex === 0) {
                    this.slideTo(this.slides.length - 2);
                  } else if (this.activeIndex === this.slides.length - 1) {
                    this.slideTo(1);
                  }
                }
              }
            }
          }}
        >
          {contents.map(
            (
              {
                id,
                slug,
                category,
                brand,
                title,
                micro_synopsis,
                rating,
                image,
                video,
                availability,
                label,
                related_content,
                color,
                cta
              },
              index
            ) => (
              <Slide key={`sld${index}`}>
                {image &&
                  !video &&
                  this._renderImage(
                    id,
                    slug,
                    image,
                    category,
                    title,
                    micro_synopsis,
                    brand,
                    rating,
                    availability,
                    label,
                    related_content,
                    isSliderKids,
                    contents,
                    color,
                    index,
                    isEducation,
                    align_left,
                    cta,
                    noContentKids
                  )}
                {video &&
                  this._renderVideo(
                    slug,
                    aspectRatio,
                    image,
                    video,
                    category,
                    title,
                    micro_synopsis,
                    brand,
                    rating,
                    availability,
                    label,
                    isSliderKids,
                    contents,
                    color,
                    index,
                    isEducation,
                    align_left,
                    noContentKids
                  )}
              </Slide>
            )
          )}
        </Swiper>
      </>
    );
  }
}

export default MainSlider;
