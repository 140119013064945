import React, { Component } from "react";
import styled from "styled-components";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3";

import { updatePassword } from "../../../../store/actions/auth";

import { Colors, Fonts } from "../../../theme";
import AtField from "../../AtField";
import { getKey, exists, getReCaptchaKey } from "../../../utils/helpers";
import AtIcon from "../../AtIcon/AtIcon";

const ContentUpdatePasswordForm = styled.div`
  position: relative;
  max-width: 440px;
  margin: 10px auto;
  color: ${Colors.bg1};
`;
const Title = styled(Fonts.h1)`
  color: ${Colors.cerulean};
`;
const SubTitle = styled(Fonts.slideLabel)``;
const FieldContainer = styled.div`
  user-select: none;
  position: relative;
  margin-top: 5px;
  text-align: ${props => getKey(props, "align", "inherits")};
`;

const Field = styled(AtField)`
  &.hasErrors,
  &.hasErrors:invalid {
    border-color: red;
  }
`;

const Button = styled.button`
  background: ${Colors.cerulean};
  color: ${Colors.white};
  border: none;
  font-size: 1.375em;
  font-family: "Dosis", sans-serif;
  line-height: 0.864;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 59px;
  outline: none;
  cursor: pointer;

  &:hover {
    background: ${Colors.uglyblue};
  }
  &[disabled] {
    background: #ccc;
    color: #888;
    box-shadow: inset 0px 0px 5px 0px #000;
    cursor: progress;
  }
`;

const ModalContainer = styled.div`
  & > div > div > div:first-child {
    background-color: ${Colors.bg1} !important;
    color: ${Colors.white} !important;
    padding: 10px;
    border-radius: 0px !important;
    button {
      height: 50px;
      font-size: 17px;
    }
  }
`;
const ErrorWrapper = styled.div`
  display: ${props => (props.hasErrors ? "flex" : "none")};
  margin-top: 5px;
  margin-bottom: 5px;
  svg {
    display: inline-flex;
  }
`;
const TextError = styled.span`
  display: inline-flex;
  color: ${Colors.orange};
  font-size: 20px;
  width: 300px;
  margin-left: 10px;
`;

class FormUpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      hasErrors: false,
      modalVisible: false,
      loading: false,
      googleToken: null
    };
  }
  async handleSubmit(event) {
    event.preventDefault();
    const { uid, hash, timestamp } = this.props;
    if (
      event.target.checkValidity() &&
      (exists(uid) && exists(hash) && exists(timestamp)) &&
      this.state.googleToken !== undefined
    ) {
      this.setState({
        loading: true
      });
      const result = await this.props.updatePassword({
        uid,
        timestamp,
        hash,
        pass: event.target.field_p.value
      });
      if (!result.error) {
        this.setState({
          hasErrors: true,
          modalVisible: false,
          modalMessage: result.message,
          loading: false
        });
        setTimeout(_ => {
          window.location.href = "/login";
        }, 5000);
      } else {
        this.setState({
          hasErrors: true,
          modalVisible: false,
          modalMessage:
            result.error ||
            "Hubo un problema interno, intente de nuevo más tarde.",
          loading: false
        });
      }
    } else {
      if (
        event.target.field_p.value !== "" &&
        !event.target.field_p.checkValidity()
      ) {
        this.setState({
          hasErrors: true,
          modalVisible: false,
          modalMessage: "Ingrese un email válido."
        });
      } else if (!(exists(uid) && exists(hash) && exists(timestamp))) {
        this.setState({
          hasErrors: true,
          modalVisible: false,
          modalMessage: "La URL no es valida o ha expirado."
        });
      } else {
        this.setState({
          hasErrors: true,
          modalVisible: false,
          modalMessage: "Todos los campos son necesarios."
        });
      }
    }
  }
  openModal() {
    this.setState({
      modalVisible: false
    });
  }

  closeModal() {
    this.setState({
      modalVisible: false
    });
  }
  setGoogleToken(token) {
    this.setState({ googleToken: token });
  }
  render() {
    const { hasErrors } = this.state;
    return (
      <ContentUpdatePasswordForm>
        <Title>ACTUALIZAR CONTRASEÑA</Title>
        <ErrorWrapper hasErrors={hasErrors}>
          <AtIcon name="ErrorOutline" size={48} color={Colors.orange} />
          <TextError>{this.state.modalMessage}</TextError>
        </ErrorWrapper>
        <SubTitle>Ingresa tu nueva contraseña</SubTitle>
        <form onSubmit={this.handleSubmit} noValidate>
          <FieldContainer>
            <Field
              name="p"
              type="password"
              label=""
              disabled={this.state.loading && "disabled"}
              required
              className={hasErrors ? "hasErrors" : ""}
            />
          </FieldContainer>
          <FieldContainer>
            <Button disabled={this.state.loading && "disabled"}>
              Actualizar contraseña
            </Button>
          </FieldContainer>
        </form>
        <ModalContainer>
          <Modal
            visible={this.state.modalVisible}
            width="370"
            height="142"
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <div>
              <h1>Error</h1>
              <p>{this.state.modalMessage}</p>
              <GoogleReCaptchaProvider reCaptchaKey={getReCaptchaKey()}>
                <GoogleReCaptcha
                  onVerify={token => this.setGoogleToken(token)}
                />
              </GoogleReCaptchaProvider>
              <Button onClick={() => this.closeModal()}>CERRAR</Button>
            </div>
          </Modal>
        </ModalContainer>
      </ContentUpdatePasswordForm>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updatePassword }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(FormUpdatePassword);
