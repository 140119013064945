import React, { Component } from "react";
import styled from "styled-components";
import Page from "../";
import widgetLoader from "../../widgets";
import {
  getUniqKey,
  getTaxonomy,
  isNinos,
  isEducation
} from "../../../utils/helpers";
import "./show.scss";

import { Wp } from "../../../theme";
const Container = styled(Wp.Container)``;

class Show extends Component {
  getMainSliderInfo() {
    const {
      title,
      id,
      resource,
      description,
      taxonomy,
      slug
    } = this.props.currentContent;
    const { image, video } = resource;
    const category = getTaxonomy(taxonomy, "category");

    return [
      {
        id,
        slug,
        category: {
          label: category && category.value,
          link: category && category.uri
        },
        title,
        summary: description,
        image,
        video
      }
    ];
  }
  render() {
    const {
      title,
      id,
      description,
      type,
      tags,
      widgets,
      meta_tags
    } = this.props.currentContent;
    const ninosFallback = isNinos(this.props.currentContent);
    const education = isEducation(this.props.currentContent);
    return (
      <Page
        id={type + "-" + id}
        meta_tags={meta_tags}
        title={title}
        description={description}
        tags={tags}
      >
        <Container>
          {
            <widgetLoader.opening
              {...this.props.currentContent}
              type={type}
              isCommingSoon
            />
          }
          <div className={`${ninosFallback && "ninosView"} wrapperShow`}>
            {widgets &&
              widgets.map((widget, idx) => {
                if (widgetLoader[widget.type] !== undefined) {
                  let LoadableWidgetComponent = widgetLoader[widget.type];
                  return (
                    <LoadableWidgetComponent
                      key={getUniqKey(idx)}
                      {...widget}
                      isNinos={ninosFallback}
                      isEducation={education}
                    />
                  );
                }
                return <div key={getUniqKey(idx)} />;
              })}
          </div>
        </Container>
      </Page>
    );
  }
}

export default Show;
