import React from "react";
import styled from "styled-components";

import "./AtInputSlider.scss";

const sliderStyles = props => `
    background: linear-gradient(90deg, #019cdf 0%, #019cdf ${props.value *
      100}%, #000000 ${props.value * 100}%, #000000 100%);
`;

const Styles = styled.div`
  .inputSlider {
    ${props => sliderStyles(props)};
  }
`;

export default class AtInputSlider extends React.Component {
  render() {
    return (
      <Styles className="inputSlidertStyles" value={this.props.value}>
        <input
          type={this.props.type}
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
          value={this.props.value}
          onChange={this.props.onChange}
          onMouseDown={this.props.onMouseDown}
          onMouseUp={this.props.onMouseUp}
          className="inputSlider"
        />
      </Styles>
    );
  }
}
