import React, { Component } from "react";
import classnames from "classnames";
import styled from "styled-components";
import Video from "../AtVideo";
import SocialButtons from "../MolShareButton";
import "./OrVideo.scss";
import AtTimeLeft from "../../components/AtTimeLeft";
import { getTaxonomy, getKey, exists, hasKey } from "../../utils/helpers";
import { Wp } from "../../theme";
import AtIcon from "../AtIcon/AtIcon";
import LikeButton from "../MolLikeButton";
import MyListButton from "../MolMyListButton";
import AdultPopup from "../OrAdultPopup";
import ShowTrailerButton from "../MolShowTrailerButton";
import { setDataRecommender } from "../../utils/helpers/recommender";
import { isMobile } from "react-device-detect";

const Container = styled(Wp.Container)``;

class OrVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readMoreOpened: false
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      setDataRecommender(this.props.id, new Date().getTime(), "CLICK", 0);
    }
  }

  infoDetail(e) {
    e.preventDefault();
    global.window.document
      .querySelector(".additionalInfo")
      .classList.toggle("display");
    this.setState({
      readMoreOpened: global.window.document
        .querySelector(".additionalInfo")
        .classList.contains("display")
    });
  }
  infoDetailvideo(e) {
    e.preventDefault();
    global.window.document
      .querySelector(".contentDetailInfo")
      .classList.toggle("display");
  }

  getIndexChapter() {
    const { id, seasonList } = this.props;
    if (exists(seasonList) && Array.isArray(seasonList)) {
      let titIndex = "";
      seasonList.forEach(widget => {
        widget.contents.forEach(season => {
          season.contents.forEach(content => {
            if (content.id === id) {
              titIndex = content.title;
            }
          });
        });
      });
      return titIndex;
    }

    return "";
  }
  render() {
    const {
      id,
      video,
      title,
      taxonomy,
      production,
      description,
      longDescription,
      anio,
      isNinos,
      unitary,
      availability,
      infoAdditional,
      slug,
      duration,
      rating,
      streaming_provider = "amazon_backup",
      season,
      trailer,
      isEducation,
      widgets
    } = this.props;

    const hastWidgetTrailer =
      widgets && widgets.find(e => e.type === "trailer") !== undefined;
    const d = this.props.duration;
    const pack = {
      video,
      title,
      slug,
      d,
      production,
      streaming_provider
    };
    const medio = getTaxonomy(taxonomy, "brands");
    const medioValue = getKey(medio, "value", "");
    const medioUri = getKey(medio, "uri", null);
    const medioImage = getKey(medio, "image.logo_negative.path", null);
    const category = getTaxonomy(taxonomy, "categories");
    const isGridMode = getKey(production, "chapter_view") === "grid";
    const indexChapter = this.getIndexChapter();

    return (
      <Container>
        <div
          className={`${isNinos ? "ninosView " : ""}${
            isGridMode ? "gridMode " : ""
          }contentOrVideo`}
        >
          <div className="contentVideo">
            <div className="contentUpper">
              <div className="title">
                <div className="prodMedioDistribution">
                  {exists(production) && (
                    <a href={production.slug}>{production.name}</a>
                  )}
                  {medio &&
                    medioImage && (
                      <a href={medioUri} className="closeButton">
                        <img
                          src={medioImage}
                          alt={medioValue}
                          title={medioValue}
                          className="logoBrand"
                        />
                      </a>
                    )}
                </div>
                <h1>{title}</h1>
              </div>
            </div>
            <div className="videoContainerWrapper">
              <Video {...pack} />
            </div>
          </div>
          <div className="info">
            <ul>
              {anio && <li>{anio}</li>}
              <li>{`${getKey(category, "value", "")} - ${getKey(
                rating,
                "value",
                ""
              )}`}</li>
              {(duration || getKey(video, "duration")) && (
                <li>
                  {!isGridMode && indexChapter !== "" ? (
                    <b>{indexChapter}</b>
                  ) : null}
                  {isGridMode && hasKey(season, "value") ? (
                    <b>{season.value}</b>
                  ) : null}
                  <span className="durationTime">
                    {duration || video.duration}
                  </span>
                </li>
              )}
              <li className="right myListBeforeDescription">
                <MyListButton size={26} label="MI LISTA" id={id} />
              </li>
              {!unitary && (
                <li className="right">
                  <SocialButtons
                    label="COMPARTIR"
                    title={title}
                    size={26}
                    sizeButtons={18}
                    text={description}
                    uri={slug}
                  />
                </li>
              )}
              <li className="right like-buttons">
                <li className="like">
                  <LikeButton
                    size={isMobile ? 36 : 26}
                    idVideo={id}
                    label="ME GUSTA"
                  />
                </li>
                <li className="like">
                  <LikeButton
                    size={isMobile ? 36 : 26}
                    idVideo={id}
                    label="NO ME GUSTA"
                    isLike={false}
                  />
                </li>
              </li>
            </ul>
          </div>
          <ul className={`${unitary && "unitary"}`}>
            <li>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: longDescription || description
                }}
              />
            </li>
            {unitary && (
              <li>{availability && <AtTimeLeft text={availability} />}</li>
            )}
          </ul>
          <ul>
            <li>
              {unitary && (
                <div className="links unitaryShareButtons">
                  <ul>
                    {trailer &&
                      !hastWidgetTrailer && (
                        <li>
                          <ShowTrailerButton
                            streaming_provider={streaming_provider}
                            title={title}
                            trailer={trailer}
                            tId="video_trailer_placeholder"
                          />
                        </li>
                      )}
                    {infoAdditional && (
                      <li>
                        <a
                          href={_ => {}}
                          className="infoButton"
                          onClick={e => this.infoDetailvideo(e)}
                        >
                          <AtIcon name="InfoCustom" size={32} />
                          <span>info</span>
                        </a>
                      </li>
                    )}
                    <li>
                      <SocialButtons
                        label="COMPARTIR"
                        title={title}
                        size={32}
                        sizeButtons={18}
                        text={description}
                        uri={slug}
                      />
                    </li>
                    <li className="myListAfterDescription">
                      <MyListButton size={32} label="MI LISTA" idVideo={id} />
                    </li>
                  </ul>
                </div>
              )}
            </li>
          </ul>
          <div
            className={classnames("contentDetailInfo", {
              ninosView: isNinos,
              contentDetailInfoEducation: isEducation
            })}
          >
            {infoAdditional &&
              this.state.mobileViewPort !== null && (
                <ul>
                  {infoAdditional.map((info, index) => (
                    <li
                      key={index}
                      style={{
                        width:
                          (this.state.mobileViewPort
                            ? 100
                            : Math.round(100 / infoAdditional.length)) + "%"
                      }}
                      dangerouslySetInnerHTML={{
                        __html: info.value || "<p></p>"
                      }}
                    />
                  ))}
                </ul>
              )}
            <div className="contentCloseInfoButton">
              <a href={_ => {}} onClick={e => this.infoDetailvideo(e)}>
                <AtIcon name="ArrowDropUp" size={34} />
              </a>
            </div>
          </div>
        </div>
        {<AdultPopup isVisible={getKey(rating, "tid") === 24} />}
      </Container>
    );
  }
}
export default OrVideo;
