import * as widgetType from "../../constants/widgetType";
import Opening from "./Opening";
import SeasonList from "./SeasonList";
import HorizontalList from "./HorizontalList";
import Banner from "./Banner";
import Slider from "./Slider";
import OutStandingTwoCover from "./OutStandingTwoCover";
import VerticalList from "./VerticalList";
import OpeningKids from "./OpeningKids";
import Unitary from "./Unitary";
import Trailer from "./Trailer";
import Elenco from "./Elenco";
import Entrevistas from "./Entrevistas";
import Podcasts from "./Podcasts";
import DetrasDeCamaras from "./DetrasDeCamaras";
import BotonesOriginales from "./BotonesOriginales";
import Premiere from "./Premiere";
import ProdInfoTrailer from "./ProdInfoTrailer";
import Highlight from "./Highlight";
import PodcastWidget from "./PodcastWidget";
import Cinema from "./Cinema";
import SliderKids from "./SliderKids";
import OriginalesWidget from "./OriginalesWidget";
import OpeningPodcast from "./OpeningPodcast";
import PromoteWidget from "./Promote";
import PodcastCategoryPromote from "./PodcastCategoryPromote";
import PodcastSingleCategoryPromoteWidget from "./PodcastSingleCategoryPromote";
import CinemaPromotion from "./CinemaPromotion";
import PodcastSingle from "./PodcastSingle";

const globalWidgets = {
  /**
   * Widget used as fallback
   * @constructor
   */
  [widgetType.HORIZONTAL_LIST]: HorizontalList,
  [widgetType.SEASON_LIST]: SeasonList,
  [widgetType.BANNER]: Banner,
  [widgetType.SLIDER]: Slider,
  [widgetType.OUT_STANDING_TWO_COVER]: OutStandingTwoCover,
  [widgetType.VERTICAL_LIST]: VerticalList,
  [widgetType.OPENING]: Opening,
  [widgetType.OPENING_KIDS]: OpeningKids,
  [widgetType.UNITARY]: Unitary,
  [widgetType.HIGHLIGHT]: Highlight,
  [widgetType.TRAILER]: Trailer,
  [widgetType.ELENCO]: Elenco,
  [widgetType.ENTREVISTAS]: Entrevistas,
  [widgetType.PODCASTS]: Podcasts,
  [widgetType.DETRAS_DE_CAMARAS]: DetrasDeCamaras,
  [widgetType.BOTONES_ORIGINALES]: BotonesOriginales,
  [widgetType.PREMIERE_WIDGET]: Premiere,
  [widgetType.PRODINFOTRA_WIDGET]: ProdInfoTrailer,
  [widgetType.PODCAST_WIDGET]: PodcastWidget,
  [widgetType.CINEMA_WIDGET]: Cinema,
  [widgetType.SLIDERKIDS_WIDGET]: SliderKids,
  [widgetType.ORIGINALES_WIDGET]: OriginalesWidget,
  [widgetType.OPENING_PODCAST_WIDGET]: OpeningPodcast,
  [widgetType.PROMOTE_WIDGET]: PromoteWidget,
  [widgetType.OPENING_PODCAST_WIDGET]: OpeningPodcast,
  [widgetType.PODCAST_CATEGORY_PROMOTE]: PodcastCategoryPromote,
  [widgetType.PODCAST_SINGLE_CATEGORY_PROMOTE]: PodcastSingleCategoryPromoteWidget,
  [widgetType.CINEMA_PROMOTION_WIDGET]: CinemaPromotion,
  [widgetType.PODCAST_SINGLE_WIDGET]: PodcastSingle
};

export default globalWidgets;
