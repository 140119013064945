import React, { Component } from "react";
import styled from "styled-components";
import { Wp } from "../../theme";
import Colors from "../../theme/Colors";
import { hasUserFeaturesEnable } from "../../utils/helpers";

const Container = styled(Wp.Container)`
  position: relative;
  margin-top: -20px;
  & > div {
    padding: 15px;
    img {
      width: 100%;
      max-width: 286px;
    }
  }
`;

const ContainerHeader = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 10px;
  & h2 {
    color: ${Colors.goldenPoppy};
    display: inline-flex;
    font-size: 18px;
    margin: 0;
    font-weight: normal;
  }
  & a {
    color: ${Colors.goldenPoppy};
    display: inline-flex;
    align-items: center;
    margin-left: 116px;
    text-decoration: none;
    span {
      margin-left: 2px;
    }
  }
`;

class AtBadge extends Component {
  render() {
    const { cover_chapter, status, url, title } =
      this.props.data !== null ? this.props.data : {};
    return !hasUserFeaturesEnable()
      ? null
      : this.props.data &&
          !!cover_chapter &&
          status && (
            <Container {...this.props}>
              <div>
                <ContainerHeader>
                  <h2>{title || "Proximamente"}</h2>
                </ContainerHeader>
                <a href={url}>
                  <img
                    alt={cover_chapter.alt}
                    title={cover_chapter.title}
                    src={cover_chapter.path}
                  />
                </a>
              </div>
            </Container>
          );
  }
}

export default AtBadge;
