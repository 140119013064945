import React, { useCallback } from "react";
import Page from "../";
import SignupForm from "../../../components/OrSignUp/Signup";
import { setRefererContent } from "../../../../store/utils/helpers";

import { ContainerCloseButton, Wrapper } from "./Register";
import { getKey, searchToObject } from "../../../utils/helpers";
import AtCloseButton from "../../../components/AtCloseButton";

const SignUp = ({ location: { search } }) => {
  const refer = searchToObject(search);
  setRefererContent(refer);
  const goBack = useCallback(
    () => {
      window.location.href = getKey(refer, "ref", "/");
    },
    [refer]
  );
  return (
    <Page
      id="register"
      title="Registro"
      description="Página de Registro de RTVCPlay"
    >
      <Wrapper>
        <SignupForm />
        <ContainerCloseButton>
          <AtCloseButton onClick={goBack} />
        </ContainerCloseButton>
      </Wrapper>
    </Page>
  );
};
export default SignUp;
