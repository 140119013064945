import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Colors, Metrics, Wp } from "../../theme";
import TplTitleBlock from "../TplTitleBlock/TplTitleBlock";
import MetaInfo from "../MolMetaInfo";
import PodcastExternalPlatform from "../MolPodcastExternalPlatform";
import Player from "../MolAudio/AudioPlayerWidget";
import {
  ListenNowContainer,
  LitlePlayIcon,
} from "../OrPodcastWidget/PodcastMainItem/PodcastMainItem";
import AtIcon from "../AtIcon/AtIcon";

export default function OrPodcastSingle({
  episode,
  production,
  slug,
  title,
  title_admin,
  id,
  showSubscriptionButton,
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [shotDescriptionEpisode] = episode
    ? (episode.description || "").match(/<p>(.*?)<\/p>/gs)
    : [];
  const onTogglePlay = playing => {
    setIsPlaying(playing);
  };
  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };
  return (
    !!episode && (
      <Wrapper>
        <Wp.Page>
          <TitleBlock
            id={id}
            title={title || title_admin}
            isNinos={false}
            isEducation={false}
            backgroundColor={Colors.black}
            showTitle
            animateTitle
          >
            <InnerContainer>
              <PlayerWrapper>
                <CoverContainer
                  onClick={togglePlay}
                  background={production.image.cover_audio.path}
                >
                  <PlayPauseIcon
                    name={isPlaying ? "Pause" : "PlayCircle"}
                    fill={"none"}
                    size={isPlaying ? 85 : 94}
                    className={isPlaying ? "PauseBorder" : ""}
                  />
                </CoverContainer>
                <MetaInfoWrapper>
                  <Link>
                    <MetaInfo
                      title={episode.title}
                      micro_synopsis={shotDescriptionEpisode}
                    />
                  </Link>
                  <PlayerContainer>
                    <Link onClick={togglePlay}>
                      <ListenNowContainer>
                        <LitlePlayIcon
                          className="play-icon"
                          name="PlayCircle"
                          size={30}
                        />{" "}
                        <span>ESCUCHAR AHORA</span>
                      </ListenNowContainer>
                    </Link>
                    <Player
                      {...episode}
                      slugPodcast={slug}
                      onTogglePlay={onTogglePlay}
                      hideControls
                      toggle={isPlaying}
                    />
                  </PlayerContainer>
                </MetaInfoWrapper>
              </PlayerWrapper>
              {showSubscriptionButton && (
                <AvailablePlaformWrapper>
                  <PodcastExternalPlatform
                    url_external_podcasts={production.url_external_podcasts}
                    isWidget
                  />
                </AvailablePlaformWrapper>
              )}
            </InnerContainer>
          </TitleBlock>
        </Wp.Page>
      </Wrapper>
    )
  );
}

const Wrapper = styled.div`
  background-color: ${Colors.black};
  border-bottom: 1px solid #7c7c7c;
  padding: 40px 0;
  @media screen and (min-width: ${Metrics.size.tablet}) {
  }
`;
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${Metrics.size.tablet}) {
    flex-direction: row;
  }
`;

const PlayerWrapper = styled.div`
  display: flex;
  max-width: 790px;
  flex-direction: column;

  @media screen and (min-width: ${Metrics.size.tablet}) {
    flex-direction: row;
    min-width: 70%;
  }
`;
const CoverContainer = styled.div`
  background-size: contain;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 265px;
  justify-content: center;
  min-height: 265px;
  min-width: 265px;
  width: 265px;
  margin: 0 auto 30px;

  ${({ background }) =>
    background &&
    css`
      background-image: url('${background}');
    `};

  @media screen and (min-width: ${Metrics.size.tablet}) {
    margin: inherit;
  }
`;
const MetaInfoWrapper = styled.div`
  & > a > div:first-child {
    width: auto;
  }
`;

const AvailablePlaformWrapper = styled.div`
  @media screen and (min-width: ${Metrics.size.tablet}) {
    margin-left: 40px;
    padding-left: 40px;
    border-left: 1px solid ${Colors.white};
  }
`;

const PlayerContainer = styled.div`
  display: block;
  width: 100%;
  margin: 10px 0 0;
  @media screen and (min-width: ${Metrics.size.tablet}) {
    width: auto;
    margin-left: 20px;
  }
`;

const Link = styled.a`
  text-decoration: none;
`;

const PlayPauseIcon = styled(AtIcon)`
  margin: 0 auto;
  &.PauseBorder {
    border: 5px solid white;
    border-radius: 50%;
  }
`;

const TitleBlock = styled(TplTitleBlock)`
  @media screen and (max-width: ${Metrics.size.tablet}) {
    .inner-container {
      padding: 0;
    }
  }
`;
