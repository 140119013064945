import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Colors, Fonts } from "../../../theme";
import { AudioPlayerSimple } from "./AudioPlayerSimple";
import { AudioPlayerFull } from "./AudioPlayerFull";
import { useAudioPlayer } from "../../../contexts/AudioPageContext";

export const AudioPlayerType = {
  SIMPLE: "simple",
  FULL: "full",
};

export const AudioPlayer = ({
  coverPodcast,
  audio,
  onAudioSelect,
  isSelected,
  keepCurrentTime = false,
  audios,
  type = AudioPlayerType.FULL,
}) => {
  const { togglePlay, setCurrentAudio, setIsPlaying } = useAudioPlayer();
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = useRef(null);

  useEffect(() => {
    if (isSelected) {
      setPlaying(true);
      setCurrentAudio(audio);
    } else {
      setPlaying(false);
      if (!keepCurrentTime) {
        setProgress(0);
        audioRef.current.currentTime = 0;
      }
    }
  }, [isSelected]);

  useEffect(() => {
    if (playing) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    setIsPlaying(playing);
  }, [playing]);

  useEffect(() => {
    if (togglePlay && isSelected) {
      setPlaying(!playing);
    }
  }, [togglePlay]);

  useEffect(() => {
    const handleTimeUpdate = () => {
      setProgress(
        (audioRef.current.currentTime / audioRef.current.duration) * 100,
      );
    };

    const audioElement = audioRef.current;
    audioElement.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      audioElement.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  const handleClick = () => {
    if (!isSelected) {
      onAudioSelect && onAudioSelect(audio.id);
    } else {
      setPlaying(!playing);
    }
  };

  const handleEnded = () => {
    const currentIndex = audios.findIndex(a => a.id === audio.id);
    const nextAudioId = (currentIndex + 1) % audios.length;
    onAudioSelect && onAudioSelect(audios[nextAudioId].id);
  };

  const handleSeek = e => {
    if (!isSelected) {
      onAudioSelect && onAudioSelect(audio.id);
    }

    const audioElement = audioRef.current;
    audioElement.currentTime = (e.target.value * audioElement.duration) / 100;
    setPlaying(true);
  };

  if (type === AudioPlayerType.SIMPLE) {
    return (
      <AudioPlayerSimple
        audio={audio}
        progress={progress}
        audioRef={audioRef}
        handleEnded={handleEnded}
        handleSeek={handleSeek}
      />
    );
  }

  return (
    <AudioPlayerFull
      audio={audio}
      progress={progress}
      audioRef={audioRef}
      handleEnded={handleEnded}
      handleSeek={handleSeek}
      isSelected={isSelected}
      coverPodcast={coverPodcast}
      handleClick={handleClick}
      playing={playing}
    />
  );
};

export const AudioPlayerContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${Colors.white};
  width: 100%;
`;

export const Metadata = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AudioDescription = styled(Fonts.audioDescription)`
  & > p {
    margin: 0;
  }
  p {
    @media screen and (min-width: 768px) {
      font-size: 18px;
    }
  }
  a {
    color: ${Colors.orangeBright};
  }
`;
