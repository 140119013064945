import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { Wp } from "../../../theme";

import { ElencoMobile } from "./Mobile";

import OrElencoSlider from "../../../components/OrElencoSlider/OrElencoSlider";

import "./Elenco.scss";

const PageWrapper = styled(Wp.Page)`
  @media screen and (max-width: 767px) {
    margin: 0 auto 30px;
  }
`;

class Elenco extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    let container = document.querySelector(".content_Elenco_container");
    let container_personaje = document.querySelectorAll(
      ".contentElenco_personaje"
    );
    let container_offset = 0;
    container_personaje.forEach(element => {
      if (window.outerWidth > 768) {
        container_offset = container.offsetLeft + 80;
      } else {
        container_offset = container.offsetLeft + 16;
      }
      element.style.left = container_offset + "px";
    });
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentDidUpdate() {
    let container = document.querySelector(".content_Elenco_container");
    let container_personaje = document.querySelectorAll(
      ".contentElenco_personaje"
    );
    let container_offset = 0;
    container_personaje.forEach(element => {
      if (this.state.width > 768) {
        container_offset = container.offsetLeft + 80;
      } else {
        container_offset = container.offsetLeft + 16;
      }
      element.style.left = container_offset + "px";
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.outerWidth, height: window.innerHeight });
  }

  render() {
    const { titulo_paragraph, dirigido_por } = this.props;

    return isMobile ? (
      <div className={`contentElenco`}>
        <div className={`contentElenco_title`}>
          <h2 className={`title`}>{titulo_paragraph}</h2>
        </div>
        <ElencoMobile {...this.props} />
      </div>
    ) : (
      <div className={`contentElenco`}>
        <PageWrapper className="content_Elenco_container">
          <div className={`contentElenco_title`}>
            <h2 className={`title`}>{titulo_paragraph}</h2>
            {dirigido_por && (
              <div className={`directed_by`}>{dirigido_por}</div>
            )}
          </div>
          <div className={`widgetContainer`}>
            <OrElencoSlider {...this.props} />
          </div>
        </PageWrapper>
      </div>
    );
  }
}

export default Elenco;
