import React, { Component, Fragment } from "react";
import styled from "styled-components";

import Video from "../AtVideo";
import SocialButtons from "../MolShareButton";
import FileSaver from "file-saver";
import "./OrVideoEducation.scss";

import { getTaxonomy, getKey, exists } from "../../utils/helpers";
import { Wp } from "../../theme";
import { setDataRecommender } from "../../utils/helpers/recommender";
const Container = styled(Wp.Container)``;

class OrVideoEducation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readMoreOpened: false,
    };
    this.downloadGuia = this.downloadGuia.bind(this);
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      setDataRecommender(this.props.id, new Date().getTime(), "CLICK", 0);
    }
  }

  infoDetail(e) {
    e.preventDefault();
    global.window.document
      .querySelector(".additionalInfo")
      .classList.toggle("display");
    this.setState({
      readMoreOpened: global.window.document
        .querySelector(".additionalInfo")
        .classList.contains("display"),
    });
  }

  getIndexChapter() {
    const { id, seasonList } = this.props;
    if (exists(seasonList) && Array.isArray(seasonList)) {
      let titIndex = "";
      seasonList.forEach(widget => {
        widget.contents.forEach(season => {
          season.contents.forEach(content => {
            if (content.id === id) {
              titIndex = content.title;
            }
          });
        });
      });
      return titIndex;
    }

    return "";
  }

  downloadGuia(url) {
    FileSaver.saveAs(url, url.replace("/^.*[\\\\\\/]/", ""));
  }

  render() {
    const {
      video,
      title,
      taxonomy,
      production,
      description,
      anio,
      isNinos,
      slug,
      duration,
      rating,
      streaming_provider,
      usageSuggestion,
      downloadable,
    } = this.props;
    const d = this.props.duration;
    const pack = {
      video,
      title,
      slug,
      d,
      production,
      streaming_provider,
    };

    const medio = getTaxonomy(taxonomy, "brands");
    const medioValue = getKey(medio, "value", "");
    const medioUri = getKey(medio, "uri", null);
    const medioImage = getKey(medio, "image.logo_negative.path", null);
    const category = getTaxonomy(taxonomy, "categories");
    return (
      <Container>
        <div
          className={`${
            isNinos ? "ninosView" : ""
          } educationView contentOrVideoEducacion`}
        >
          <table className="tbVideo">
            <tbody>
              <tr>
                <td colSpan="2">
                  <div className="contentVideo">
                    <div className="contentUpper">
                      <div className="title">
                        <div className="prodMedioDistribution">
                          {exists(production) ? (
                            <a href={production.slug}>{production.name}</a>
                          ) : (
                            <span />
                          )}
                          {medio && medioImage && (
                            <a href={medioUri} className="closeButton">
                              <img
                                src={medioImage}
                                alt={medioValue}
                                title={medioValue}
                                className="logoBrand"
                              />
                            </a>
                          )}
                        </div>
                        <h1>{title}</h1>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="colTbVideo">
                  <div className="videoContainer">
                    <div className="contentVideo">
                      <div className="videoContainerWrapper">
                        <Video tId="player-placeholder1" {...pack} />
                      </div>
                    </div>
                    <div className="info">
                      <ul>
                        {anio && <li>{anio}</li>}
                        <li>{`${getKey(category, "value", "")} - ${getKey(
                          rating,
                          "value",
                          "",
                        )}`}</li>
                        {(duration || video.duration) && (
                          <li>
                            <b>{this.getIndexChapter()}</b>
                            <span className="durationTime">
                              {duration || video.duration}
                            </span>
                          </li>
                        )}
                        <li>
                          <SocialButtons
                            label="COMPARTIR"
                            title={title}
                            size={26}
                            sizeButtons={18}
                            text={description}
                            uri={slug}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="divInfo">
                    <div className="titleSinopsis">Sinopsis</div>
                    <div
                      className="textSinopsis"
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                    <div className="uso">
                      {downloadable && (
                        <div className="secGuia">
                          <div className="lbGuia">
                            ¿Quieres la guía pedagógica?{" "}
                          </div>
                          <div
                            className="contentBtnDescargarPdf"
                            onClick={() => this.downloadGuia(downloadable)}
                          >
                            <div className="btnDescargarPdf">
                              <img
                                alt="PDF"
                                className="imgPdf"
                                src="https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/assets/asset_pdf.png"
                              />
                              <span className="lbDescarga">DESCÁRGALA AQUÍ</span>
                              <img
                                alt="Descarga"
                                className="imgDownload"
                                src="https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/assets/asset_descarga.png"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {usageSuggestion && (
                        <Fragment>
                          <div className="divisorSugerenciaUsoGuia" />
                          <div className="secSuger">
                            <div className="titleSugerenciaUso">
                              Sugerencia de uso
                            </div>
                            <div
                              className="textSugerenciaUso"
                              dangerouslySetInnerHTML={{
                                __html: usageSuggestion,
                              }}
                            />
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Container>
    );
  }
}
export default OrVideoEducation;
