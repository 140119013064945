import React from "react";
import Swiper from "react-id-swiper";
import "react-id-swiper/src/styles/scss/swiper.scss";

import {
  InnerWrapper,
  Wrapper,
  TitleBlock,
  List,
  IconButton
} from "./OriginalWidget";
import OriginalItem from "./OriginalItem";
import AtIcon from "../AtIcon/AtIcon";

export default function OrPodcastWidget({
  title: widgetTitle,
  showTitle,
  contents,
  streaming_provider
}) {
  const params = {
    allowTouchMove: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    breakpoints: {
      3000: {
        effect: "coverflow",
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: true,
        initialSlide: 1,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 20,
          modifier: 1,
          slideShadows: false
        }
      },
      767: {
        slidesPerView: 1,
        centeredSlides: false,
        spaceBetween: 10,
        allowTouchMove: true
      }
    },
    renderPrevButton: () => (
      <IconButton className="swiper-button-prev">
        <AtIcon className="icon" name="KeyboardArrowLeft" size={25} />
      </IconButton>
    ),
    renderNextButton: () => (
      <IconButton className="swiper-button-next">
        <AtIcon className="icon" name="KeyboardArrowRight" size={25} />
      </IconButton>
    )
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <TitleBlock title={widgetTitle} showTitle={showTitle} animateTitle />
        <List>
          <div>
            <Swiper {...params} activeSlideKey="1">
              {contents.map((itm, index) => (
                <div key={index}>
                  <OriginalItem
                    key={index}
                    streaming_provider={streaming_provider}
                    {...itm}
                  />
                </div>
              ))}
            </Swiper>
          </div>
        </List>
      </InnerWrapper>
    </Wrapper>
  );
}
