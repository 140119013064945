import {
  CHANGE_STATE_ADD_MY_LIST_BUTTON,
  CHANGE_STATE_ACTION_LIKE
} from "../constants/action-types";
import config from "../../server/config";
import { setDataRecommender } from "../../app/utils/helpers/recommender";
import { getCurrentUser, getEnvContent } from "../utils/helpers";
import { getKey } from "../../app/utils/helpers";

export const addPlayerMyList = idVideo => {
  return dispatch => {
    return fetch(
      `${
        config.cms.environments[getEnvContent()]
      }/api/v1/flag/my_list/${idVideo}?_format=json`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getKey(
            getCurrentUser(),
            "tokenData.access_token"
          )}`,
          "Content-Type": "application/json"
        }
      }
    )
      .then(response => response.json())
      .then(content => {
        return dispatch({
          type: CHANGE_STATE_ADD_MY_LIST_BUTTON,
          content: true
        });
      });
  };
};

export const removePlayerMyList = idVideo => {
  return dispatch => {
    return fetch(
      `${
        config.cms.environments[getEnvContent()]
      }/api/v1/flag/my_list/${idVideo}?_format=json`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${getKey(
            getCurrentUser(),
            "tokenData.access_token"
          )}`,
          "Content-Type": "application/json"
        }
      }
    ).then(_ => {
      return dispatch({
        type: CHANGE_STATE_ADD_MY_LIST_BUTTON,
        content: false
      });
    });
  };
};

export const giveLike = idVideo => {
  return dispatch => {
    return fetch(
      `${
        config.cms.environments[getEnvContent()]
      }/api/v1/rate/like/like/${idVideo}?_format=json`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getKey(
            getCurrentUser(),
            "tokenData.access_token"
          )}`,
          "Content-Type": "application/json"
        }
      }
    ).then(data => {
      setDataRecommender(idVideo, new Date().getTime(), "ME GUSTA", 1);
      return dispatch({ type: CHANGE_STATE_ACTION_LIKE, content: true });
    });
  };
};

export const giveDislike = idVideo => {
  return dispatch => {
    return fetch(
      `${
        config.cms.environments[getEnvContent()]
      }/api/v1/rate/like/dislike/${idVideo}?_format=json`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getKey(
            getCurrentUser(),
            "tokenData.access_token"
          )}`,
          "Content-Type": "application/json"
        }
      }
    ).then(data => {
      setDataRecommender(idVideo, new Date().getTime(), "NO ME GUSTA", 0);
      return dispatch({ type: CHANGE_STATE_ACTION_LIKE, content: false });
    });
  };
};

export const undoVote = idVideo => {
  return dispatch => {
    return fetch(
      `${
        config.cms.environments[getEnvContent()]
      }/api/v1/rate/like/${idVideo}?_format=json`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${getKey(
            getCurrentUser(),
            "tokenData.access_token"
          )}`,
          "Content-Type": "application/json"
        }
      }
    ).then(data => {
      return dispatch({ type: CHANGE_STATE_ACTION_LIKE, content: null });
    });
  };
};
