import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { lighten } from "polished";

import AtImgLink from "../AtImgLink/AtImgLink";

import { Colors, Wp, Images } from "../../theme";
import { getKey } from "../../utils/helpers";

const Container = styled(Wp.Container)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
const blink = keyframes`
  0% { background-color: ${lighten(0, Colors.onyx)}; }
  50% { background-color: ${lighten(0.1, Colors.onyx)}; }
  100% { background-color: ${lighten(0, Colors.onyx)}; }
`;
const ImgLink = styled(AtImgLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 270px;
  height: 124px;
  background-color: ${Colors.onyx};
  margin: 10px;
  :hover {
    animation: 1s ${blink} ease infinite;
  }
  img {
    width: 60%;
  }
`;
class MolMenuLS extends Component {
  static defaultProps = {
    opacity: 1,
    data: [
      {
        image: {
          source: Images.canalInstitucional,
          alt: "Canal Institucional"
        },
        link: "#"
      },
      {
        image: {
          source: Images.senalColombia,
          alt: "Señal Colombia"
        },
        link: "#"
      },
      {
        image: {
          source: Images.radioNacional,
          alt: "Radio Nacional"
        },
        link: "#"
      },
      {
        image: {
          source: Images.radionica,
          alt: "Radiónica"
        },
        link: "#"
      }
    ]
  };
  render() {
    const { data, className, opacity } = this.props;
    return (
      <Container className={className}>
        {data.map((itm, index) => (
          <ImgLink
            key={index}
            href={itm.link}
            imageSource={getKey(itm.image, "source", null)}
            imageAlt={getKey(itm.image, "alt", null)}
            imageBackground={
              // eslint-disable-next-line
              !itm.link.match(/(\/en\-vivo\/)/gim) &&
              getKey(itm.image_bg, "source", null)
            }
            withLazyLoad={false}
            opacity={opacity}
          />
        ))}
      </Container>
    );
  }
}

export default MolMenuLS;
