import Cookies from "js-cookie";
import { getCurrentUser } from "../../../store/utils/helpers";
import {
  fetchDataRecommender,
  fetchDataEvent
} from "../../../server/api/fetchApi";

export function setDataRecommender(
  item_Id,
  TIMESTAMP,
  EVENT_TYPE,
  EVENT_VALUE
) {
  const currentUser = getCurrentUser();
  const usercookies = Cookies();
  if ((currentUser === null || currentUser.id === null) && usercookies !== undefined) {
    fetchDataRecommender(
      usercookies._gid,
      item_Id,
      TIMESTAMP,
      EVENT_TYPE,
      EVENT_VALUE
    );
    fetchDataEvent(
      usercookies._gid,
      item_Id,
      TIMESTAMP,
      EVENT_TYPE,
      EVENT_VALUE
    );
  } else if(currentUser !== null && currentUser.id !== null) {
    fetchDataRecommender(
      currentUser.id,
      item_Id,
      TIMESTAMP,
      EVENT_TYPE,
      EVENT_VALUE
    );
    fetchDataEvent(currentUser.id, item_Id, TIMESTAMP, EVENT_TYPE, EVENT_VALUE);
  }
}
