import React from "react";

import {
  Label,
  MetadataContainer,
  Wrapper,
  IconPodcast,
  ImageLogo,
  ImagePodcast,
  EpisodeTitle,
  PlayerContainer,
  Footer,
  ListenNowContainer,
  LitlePlayIcon,
  Link
} from "./PodcastMainItem";

import { getKey, isValidArray } from "../../../utils/helpers";
import { Colors } from "../../../theme";
import Player from "../../MolAudio/AudioPlayerWidget";

export default function PodcastMainItem({
  brand,
  episodes,
  image,
  label,
  slug
}) {
  if (!slug && !image) {
    return null;
  }
  let path = getKey(image, "cover_desktop.path");
  const { image: imageBrand } = brand;
  const [firstEpisode] = isValidArray(episodes) ? episodes : [];
  return (
    <Wrapper>
      {label && (
        <Label color="#009DE4" el="h3">
          {label}
        </Label>
      )}
      <ImagePodcast src={path} />
      <IconPodcast
        className="content-type-icon-prod"
        name="SpeakerNew"
        size={28}
        color={Colors.white}
      />
      <MetadataContainer>
        {imageBrand && (
          <ImageLogo src={getKey(imageBrand, "logo_negative.path")} />
        )}
        <PlayerContainer>
          <EpisodeTitle>{firstEpisode && firstEpisode.title}</EpisodeTitle>
          <Player {...firstEpisode} slugPodcast={slug} />
        </PlayerContainer>
      </MetadataContainer>
      <Footer>
        <Link href={slug}>
          <ListenNowContainer>
            <LitlePlayIcon className="play-icon" name="PlayCircle" size={30} />{" "}
            <span>ESCUCHAR AHORA</span>
          </ListenNowContainer>
        </Link>
      </Footer>
    </Wrapper>
  );
}
