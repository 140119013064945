import React, { Component } from "react";
import AtIcon from "../../../app/components/AtIcon/AtIcon";
import { getUniqKey, exists } from "../../utils/helpers";

import { Fonts } from "../../theme";

import "./MolShareButton.scss";
import { isMobile } from "react-device-detect";

class SocialButtons extends Component {
  showButtons(e) {
    e.preventDefault();
    if (exists(this.buttonsContainer)) {
      global.window.document
        .querySelector("#" + this.buttonsContainer.id)
        .classList.toggle("display");
    }
  }
  componentDidMount() {
    !isMobile &&
      document.addEventListener("mouseup", this.removeAllFloating, false);
  }
  componentWillUnmount() {
    !isMobile &&
      document.removeEventListener("mouseup", this.removeAllFloating, false);
  }
  removeAllFloating(e) {
    document.querySelector(".contentFloatingButtons") &&
      document
        .querySelector(".contentFloatingButtons")
        .classList.remove("display");
  }
  getButtons(className) {
    const { title, text, uri } = this.props;
    let { sizeButtons } = this.props;
    if (sizeButtons === undefined) {
      sizeButtons = isMobile ? 18 : 22;
    }
    let url = "/";
    if (uri !== undefined) {
      url = uri.startsWith("http") ? uri : "https://www.rtvcplay.co" + uri;
    } else if (typeof window !== "undefined") {
      url = `https://www.rtvcplay.co${window.location.pathname}`;
    } else if (this.props.serverRequest) {
      url = this.props.serverRequest.headers.host;
    }
    return (
      <ul>
        <li>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${url}&t=${title ||
              ""}`}
            target="_blank"
            onClick={e => setTimeout(() => this.showButtons(e), 800)}
            rel="noopener noreferrer"
            className={className}
          >
            <AtIcon name="FacebookF" size={sizeButtons} />
          </a>
        </li>
        <li>
          <a
            href={
              "https://twitter.com/intent/tweet?source=" +
              url +
              "&text=" +
              (title || "") +
              " " +
              url
            }
            target="_blank"
            onClick={e => setTimeout(() => this.showButtons(e), 800)}
            rel="noopener noreferrer"
            className={className}
          >
            <AtIcon name="X" size={sizeButtons} />
          </a>
        </li>
        <li>
          <a
            href={"whatsapp://send?text=" + (title || "") + " " + url}
            onClick={e => setTimeout(() => this.showButtons(e), 800)}
            className={className}
          >
            <AtIcon name="Whatsapp" size={sizeButtons} />
          </a>
        </li>
        <li>
          <a
            href={
              "mailto:?subject=" +
              (title || "") +
              "&body=" +
              (title || "") +
              ", " +
              (text || "") +
              " " +
              url
            }
            onClick={e => setTimeout(() => this.showButtons(e), 800)}
            className={className}
          >
            <AtIcon name="Email" size={sizeButtons} />
          </a>
        </li>
      </ul>
    );
  }

  render() {
    const { label, size, widthButtons, renderAllButtons } = this.props;
    return renderAllButtons ? (
      <div className="generalContainerShareButtons">
        {this.getButtons("circle")}
      </div>
    ) : (
      <div className="generalContainerShareButtons">
        <div
          id={getUniqKey()}
          className="contentFloatingButtons"
          style={{
            width: widthButtons !== undefined ? widthButtons + "px" : "auto"
          }}
          ref={buttonsContainer => (this.buttonsContainer = buttonsContainer)}
        >
          {this.getButtons()}
        </div>
        <div
          onClick={e => this.showButtons(e)}
          className="rounded share-button "
          ref={buttonContainer => (this.buttonContainer = buttonContainer)}
        >
          <AtIcon name="ShareCustom" size={size || 20} />
          <Fonts.shareButton>{label || ""}</Fonts.shareButton>
        </div>
      </div>
    );
  }
}
export default SocialButtons;
