import styled from "styled-components";
import { Colors } from "../../../theme";

export const FacebookWrapper = styled.div`
  background: #0178f6;
  color: ${Colors.white};
  font-size: 1.275em;
  line-height: 0.864;
  padding: 0;
  width: 100%;
  height: 35px;
  outline: none;
  cursor: pointer;
  display: inline-flex;

  @media screen and (min-width: 768px) {
    height: 40px;
  }

  & > span {
    width: 100%;
  }

  & button {
    background: transparent;
    color: #ffffff;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }

  & i {
    margin: 5px;
    color: #1877f2;
    background: #fff;
    border-radius: 50%;
    padding: 15px;
    position: relative;
    &::before {
      top: 7px;
      position: absolute;
      font-size: 28px;
      left: 6px;
      font-weight: 400;
    }
  }
`;
