import React from "react";
import styled from "styled-components";
import { isValidArray } from "../../utils/helpers";
import AtPlayNow from "../AtPlayNow";
import { THEME } from ".";
import { Metrics, Wp } from "../../theme";
import { getKey } from "../../utils/helpers";
import AtDescription from "../AtDescription";

export default function CinemaPromItem({
  additional_info,
  availability,
  category,
  color,
  is_unitary,
  theme,
  url_production,
  url_video,
  video
}) {
  const slug = is_unitary ? url_production : url_video;
  const technicalInfo = additional_info.match(/<ul>(.*?)<\/ul>/gs);
  return (
    <Wrapper>
      <Link href={slug}>
        <Thumbnail video={video} />
      </Link>
      <Metadata>
        <MetadataPlayNow>
          <Link href={slug}>
            <AtPlayNow slug={slug} backgroundColor={color} />
          </Link>
        </MetadataPlayNow>
        <MetadataInfo theme={theme}>
          {isValidArray(technicalInfo) && (
            <DescriptionInfo
              theme={theme}
              dangerouslySetInnerHTML={{
                __html: technicalInfo[0].replace(/&nbsp;/g, "")
              }}
            />
          )}
          {!availability && (
            <MetadataCategory theme={THEME.DARK}>{category}</MetadataCategory>
          )}
          <Divider color={color} />
          {availability && (
            <MetadataAvailability>
              <DescriptionInfo theme={THEME.LIGHT}>
                DISPONIBLE HASTA
              </DescriptionInfo>
              <MetadataAvailabilityDate color={color}>
                {availability}
              </MetadataAvailabilityDate>
            </MetadataAvailability>
          )}
        </MetadataInfo>
      </Metadata>
    </Wrapper>
  );
}

const Wrapper = styled(Wp.Container)`
  display: inline-block;
  width: 100%;
  @media screen and (min-width: 768px) {
  }
`;

const Link = styled.a`
  display: block;
  text-decoration: none;
`;

const Thumbnail = styled.img.attrs({
  src: ({ video }) =>
    getKey(
      video,
      "trailer.thumbnail_md.path",
      getKey(video, "trailer.thumbnail.path")
    )
})`
  max-width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 400px;
  }
`;

const Metadata = styled.div`
  display: flex;
  margin: 10px 0 0;
  flex-direction: column;
  max-width: 321px;
  @media screen and (min-width: ${Metrics.size.tablet}) {
    flex-direction: row;
    max-width: 400px;
  }
`;

const MetadataPlayNow = styled.div`
  min-width: 162px;
`;
const MetadataInfo = styled.div`
  border-left: 1px solid white;
  margin-top: 10px;
  margin-left: 15px;
  padding: 0px 15px 0 15px;

  @media screen and (min-width: ${Metrics.size.tablet}) {
    margin-top: 0;
  }
`;

const DescriptionInfo = styled(AtDescription)`
  color: ${({ theme }) => (theme === THEME.DARK ? "black" : "white")};
  margin: 0;
  font-size: 16px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      font-size: 16px;
      strong {
        font-size: 16px;
        text-transform: uppercase;
        display: block;
      }
      &:nth-child(n + 4) {
        display: none;
      }

      @media screen and (min-width: ${Metrics.size.tablet}) {
        font-size: 22px;
        strong {
          font-size: 22px;
        }
      }
    }
  }

  @media screen and (min-width: ${Metrics.size.tablet}) {
    font-size: 22px;
    strong {
      font-size: 22px;
    }
  }
`;

const Divider = styled.div`
  background-color: ${({ color }) => color};
  margin: 15px 0 10px;
  height: 3px;
  width: 26px;
`;

const MetadataAvailability = styled.div``;

const MetadataAvailabilityDate = styled(DescriptionInfo)`
  color: ${({ color }) => color};
  text-transform: uppercase;
  font-weight: bold;
`;

const MetadataCategory = styled(DescriptionInfo)`
  color: white;
  text-transform: uppercase;
  margin: 15px 0 0;
`;
