import React, { Component, Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import { getUniqKey, getKey } from "../../../utils/helpers";
import "./SeasonList.scss";
import HorizontalList from "../../../components/OrHorizontalList";
import { Wp } from "../../../theme";

const PageWrapper = styled(Wp.Page)``;

class SeasonList extends Component {
  constructor(props) {
    super(props);
    const { contents } = props;
    let currentSeason = contents.length > 0 ? contents[0].season : -1;
    let indexVideo = -1;
    var seasonActive = 99;
    for (var i = 0; i < contents.length; i++) {
      let season = contents[i];
      currentSeason = season.season;
      for (var j = 0; j < season.contents.length; j++) {
        let video = season.contents[j];
        if (video.active) {
          seasonActive = i;
          indexVideo = j;
          break;
        }
      }
      if (indexVideo !== -1) break;
    }
    if (currentSeason === -1) {
      currentSeason = 1;
    }
    if (seasonActive === 99) {
      seasonActive = contents.length - 1;
    }
    this.state = {
      season: currentSeason,
      contentLength:
        contents.length > 0 ? contents[seasonActive].contents.length : 0,
      indexVideo: indexVideo === -1 ? 0 : indexVideo
    };
  }
  showSeason(e, season, contentLength) {
    e.preventDefault();
    this.setState({ season: season, contentLength: contentLength });
  }
  render() {
    const { contents, isNinos, isEducation, styles } = this.props;
    let customStyles = `.contentSeasonList { ${getKey(
      this.props,
      "styles",
      null
    )} border-bottom: none;}`;
    return (
      <Fragment>
        <style>{customStyles}</style>
        <div
          className={`${isNinos && "ninosView"} ${isEducation &&
            "educationView"} contentSeasonList`}
        >
          <PageWrapper>
            <div className="navSeason">
              <ul>
                <li>
                  <h2 className="title">{isMobile ? "Temp." : "Temporadas"}</h2>
                </li>
                {contents.map((season, idx) => (
                  <li key={getUniqKey(idx)}>
                    <div
                      onClick={e =>
                        this.showSeason(
                          e,
                          season.season,
                          season.contents.length
                        )
                      }
                      className={`seasonSelector seasonSelector${
                        season.season
                      } ${this.state.season === season.season && "selected"}`}
                    >
                      {season.season}
                    </div>
                  </li>
                ))}
                <li className="numCaps">
                  {this.state.contentLength} {isMobile ? "Cap." : "Capí­tulos"}
                </li>
              </ul>
            </div>
          </PageWrapper>
          <div className="videosSeasonList">
            {contents.map((season, idx) => (
              <div
                key={getUniqKey(idx)}
                className={`seasonList ${this.state.season === season.season &&
                  "selected"}`}
              >
                <HorizontalList
                  key={getUniqKey(idx)}
                  contents={season.contents}
                  initialSlide={this.state.indexVideo}
                  view="cover"
                  isNinos={isNinos}
                  isShow
                  isEducation={isEducation}
                  showTitle={false}
                  styles={styles}
                  customStyles={`
                    padding-top: 0px !important
                  `}
                />
              </div>
            ))}
          </div>
        </div>
      </Fragment>
    );
  }
}
SeasonList.propTypes = {
  title: PropTypes.string,
  contents: PropTypes.array
};
export default SeasonList;
