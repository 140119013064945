import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import Page from "../";
import widgetLoader from "../../widgets";
import { LoadMoreWrapper } from "../../widgets/LoadMore/Wrapper";
import { LoadMoreButton } from "../../widgets/LoadMore/Button";
import {
  getUniqKey,
  isNinos,
  isValidArray,
  isEducation,
  getTaxonomy
} from "../../../utils/helpers";
import OrModalWelcome from "../../../components/OrModalWelcome";
import AtDropdown from "../../../components/AtDropdown/AtDropdown";

import "./section.scss";

import { getWidgetIndex } from "../../../utils/helpers/widget";
import { Wp, Metrics } from "../../../theme";
import { getCurrentUser } from "../../../../store/utils/helpers";
import { getChaptersMyList } from "../../../../store/actions/my-list";
import { bindActionCreators } from "redux";
import LoadMoreProvider, {
  useLoadMore
} from "../../../contexts/LoadMoreProvider";

const Section = props => {
  const {
    title,
    id,
    image,
    description,
    type,
    tags,
    widgets,
    meta_tags,
    categories,
    slug,
    streaming_provider,
    taxonomy
  } = props.currentContent;
  useLoadMore(widgets);
  const user = getCurrentUser();
  const myListIndex = getWidgetIndex(
    props.currentContent.widgets,
    "title_admin",
    TITLE_ADMIN_MY_LIST
  );

  if (user !== null && myListIndex.index !== -1) {
    props.getChaptersMyList(0, 20);
  }

  useEffect(() => {
    const { id, type, slug } = props.currentContent;

    const fallbackNinos = isNinos({ id, type, slug });

    if (fallbackNinos) {
      global.window.document.querySelector("body").classList.add("ninos");
    }
  }, []);

  const { myListChapters } = props;
  const fallbackNinos = isNinos({ id, type, slug });
  const fallbackeducation = type === "section" && slug === "/educacion";
  const fallbackisEducacion = isEducation({ id, type, slug });

  let dropdownList = null;
  if (categories) {
    let keys = Object.keys(categories);
    dropdownList = keys.map(k => {
      return { ...categories[k] };
    });
  }
  const indexMyList = myListIndex || {};

  if (
    myListChapters != null &&
    isValidArray(myListChapters.widgets) &&
    indexMyList.index !== -1
  ) {
    const currentMyListIndex = getWidgetIndex(
      widgets,
      "title_admin",
      TITLE_ADMIN_MY_LIST
    );
    const widget = myListChapters.widgets[0];
    if (currentMyListIndex.index !== -1) {
      widgets[indexMyList.index] = {
        ...indexMyList.data,
        contents: widget.contents
      };
    } else {
      widgets.splice(indexMyList.index, 0, {
        ...indexMyList.data,
        contents: widget.contents
      });
    }
  } else if (myListChapters === null && indexMyList.index !== -1) {
    widgets.splice(indexMyList.index, 1);
  }

  return (
    <LoadMoreProvider widgets={widgets}>
      <Page
        id={type + "-" + id}
        uid={id}
        category={getTaxonomy(taxonomy, "categories")}
        meta_tags={meta_tags}
        title={title}
        description={description}
        image={image}
        tags={tags}
      >
        <h1 className="hiddenTitle">{title}</h1>
        <Container>
          <div className={`wrapperContent ${fallbackNinos && isNinos}`}>
            {dropdownList && (
              <Header>
                <HeaderBody>
                  {dropdownList && (
                    <DropdownKids
                      title="Categorías niños:"
                      list={dropdownList}
                      width={300}
                      iconName="chevronBottomB"
                      onItemClick={(itm, index) =>
                        (document.location.href = itm.slug)
                      }
                    />
                  )}
                </HeaderBody>
              </Header>
            )}
            {widgets &&
              widgets.map((widget, idx) => {
                if (widgetLoader[widget.type] !== undefined) {
                  let LoadableWidgetComponent = widgetLoader[widget.type];
                  return (
                    <LoadMoreWrapper
                      key={`loadMoreWrapper${idx}`}
                      loadMore={widget.load_more}
                    >
                      <LoadableWidgetComponent
                        key={getUniqKey(idx)}
                        {...widget}
                        isNinos={fallbackNinos}
                        isEducation={fallbackisEducacion}
                        streaming_provider={streaming_provider}
                        showTitle={
                          !(
                            widget.type === "horizontalList" &&
                            widget.view === "kids" &&
                            fallbackNinos
                          )
                        }
                      />
                    </LoadMoreWrapper>
                  );
                }
                return <div key={getUniqKey(idx)} />;
              })}
            <LoadMoreButton />
          </div>
          {fallbackeducation ? <OrModalWelcome /> : ""}
        </Container>
      </Page>
    </LoadMoreProvider>
  );
};

const mapStateToProps = state => ({
  myListChapters: state.myList.chapters
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getChaptersMyList }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Section);

const TITLE_ADMIN_MY_LIST = "MI LISTA";

const Container = styled(Wp.Container)``;

const Header = styled(Wp.Container)`
  margin-top: ${Metrics.size.minHeaderHeight};
  padding-top: 0.5em;
  z-index: 1;
  @media ${Metrics.mediaQ.tablet} {
    padding-top: 1em;
    margin-top: ${Metrics.size.maxHeaderHeight};
  }
`;

const HeaderBody = styled(Wp.Page)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DropdownKids = styled(AtDropdown)`
  .dd-header,
  .dd-list {
    border: 0;
    background-color: rgba(0, 0, 0, 0.45);
    .title {
      font-size: 1.25rem;
      font-weight: 600;
    }
    .icon {
      opacity: 0.6;
    }
    .dd-list-item {
      .item-text {
        font-size: 1.25rem;
        font-weight: 600;
      }
      :hover {
        background-color: rgba(0, 0, 0, 0.6);
      }
    }
  }
`;
