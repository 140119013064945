import { fetchGrill } from "../../server/api/fetchApi";
import { SET_GRILL } from "../constants/action-types";

export const updateGrill = (date, channel_id) => dispatch =>
  fetchGrill(date, channel_id)
    .then(grill => {
      return dispatch({
        type: SET_GRILL,
        content: {
          grill,
          date,
          channel_id
        }
      });
    })
    .catch(reason => {
      console.error("***************************");
      console.error({ reason });
      console.error("***************************");
      return null;
    });
