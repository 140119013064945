export default {
  host: process.env.HOST || "0.0.0.0",
  port: process.env.PORT || 8081,
  api: {
    endpoints: {
      content: "/content",
      request: "/subrequests",
    },
    dataevent: {
      uri:
        "https://yztgcx4pse.execute-api.us-east-1.amazonaws.com/default/rtvc-personalize-data",
    },
    dataputevent: {
      uri:
        "https://ujnoe3wu5d.execute-api.us-east-1.amazonaws.com/default/rtvc-personalize-event",
    },
    auth: {
      paths: {
        token: {
          uri: "/oauth/token",
          body: {
            scope: "web_client",
            grant_type: "password",
          },
          body_refresh_token: {
            scope: "web_client",
            grant_type: "refresh_token",
          },
        },
        me: {
          uri: "/oauth/me",
          body: {
            Authorization: "Bearer {token}",
          },
        },
        signUp: {
          uri: "/api/v1/user/general/register?_format=json",
          headers: { "Content-Type": "application/json" },
        },
        forgotPassword: {
          uri: "/api/v1/user/forgot-password?_format=json",
          headers: { "Content-Type": "application/json" },
        },
        updatePassword: {
          uri: "/api/v1/user/reset-password?_format=json",
          headers: { "Content-Type": "application/json" },
        },
        update: {
          uri: "/user/{uid}?_format=json",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer {token}",
          },
        },
        imageUpload: {
          uri: "/api/v1/user/user/user_picture/upload?_format=json",
          headers: {
            "Content-Type": "application/octet-stream",
            "Content-Disposition": 'file; filename="{nameFile}"',
            Authorization: "Bearer {token}",
          },
        },
        preferences: {
          uri: "/api/v1/user/preferences",
        },
        newsletter: {
          uri: "/api/v1/user/newsletter?_format=json",
          headers: { "Content-Type": "application/json" },
        },
      },
      client_id: "01be246e-007a-426d-a7ba-7a29f0bf7cdd",
      client_secret: "54+yy@\\)ufAx8Z!G",
    },
    country: {
      ipService: "https://cms.rtvcplay.co/maxservice.php?time=false",
    },
  },
  cms: {
    environments: {
      local: process.env.REACT_APP_CMS_DOMAIN || "https://cms.rtvcplay.co",
      // local: "https://dev-rtvcplay-cms.rtvc.dev",
      development: "https://dev-rtvcplay-cms.rtvc.dev",
      usu: "https://dev-rtvcplay-cms.rtvc.dev",
      qa: "https://cal-rtvcplay-cms.rtvc.dev",
      production: "https://cms.rtvcplay.co",
      cubo: "https://cms.rtvcplay.co",
    },
  },
  frontent: {
    environments: {
      //local: "http://rtvcplay-fe.lndo.site",
      local: "http://localhost:3000",
      development: "https://dev-rtvcplay.rtvc.dev",
      usu: "https://dev-rtvcplay.rtvc.dev",
      qa: "https://cal-rtvcplay.rtvc.gov.co",
      production: "https://www.rtvcplay.co",
      cubo: "https://elcubo.rtvcplay.co",
    },
    domains: {
      //local: "rtvcplay-fe.lndo.site",
      local: "localhost",
      development: "rtvc.dev",
      usu: "rtvc.dev",
      qa: "rtvc.gov.co",
      production: "rtvcplay.co",
      cubo: "rtvcplay.co",
    },
  },
  routes: {
    proxy: {
      cached: "/proxy/api/cached",
      uncached: "/proxy/api/uncached",
    },
  },
  enableUserFeatures: true,
  categories: {
    podcasts: [
      { id: "10", slug: "/series-podcasts" },
      { id: "4074", slug: "/radionovelas-podcasts" },
      { id: "4075", slug: "/entrevistas-podcasts" },
      { id: "4885", slug: "/radioteatros-podcasts" },
    ],
  },
  section: {
    education: [{ id: "4068", slug: "/educacion" }],
  },
  // specify the country where the content can be viewed.
  contentGeoBlocked: [],
  vanitiesURL: [
    {
      "(.*)/innovadores/el-cubo(.*)": "https://elcubo.rtvcplay.co",
      /*"(.*)/innovadores/el-cubo-el-inquisidor-ii(.*)":
        "https://elcubo.rtvcplay.co/el-cubo/temporada-2"*/
    },
    { "(.*)/innovadores/el-cubo(.*)": "https://elcubo.rtvcplay.co" },
  ],
  reCaptchaKey: "6LcfZcMUAAAAACj6FRYVhq71E6gomTu0pEVavcKJ",
  documentaries: {
    id: "6618",
    url: "/gran-coleccion-documentales",
  },
};

// Default config values for global components
export const globalComponents = {
  widgets: {
    // List widgets
    list: {
      limit: 10,
    },
  },
  // Max length in characters for truncating strings
  truncate: {
    description: 140,
    title: 90,
  },
};
