import React, { Component, Fragment } from "react";
import styled from "styled-components";
import LazyLoad from "react-lazyload";

const Img = styled.img`
  border: 0;
`;

class AtImg extends Component {
  static defaultProps = {
    retina: false,
    responsive: false,
    withLazyLoad: true
  };
  get attr() {
    const { src } = this.props;
    return {
      srcSet: src
        .map(img => `${img[Object.keys(img)[0]]} ${Object.keys(img)[0]}`.trim())
        .join(", ")
    };
  }
  get sources() {
    const { src } = this.props;
    return src
      .filter(img => Object.keys(img)[0] !== "")
      .map((img, index) => (
        <source
          key={index}
          media={Object.keys(img)[0]}
          srcSet={img[Object.keys(img)[0]]}
        />
      ));
  }
  get src() {
    const { src } = this.props;
    let img = src.find(img => Object.keys(img)[0] === "");
    return img[Object.keys(img)[0]];
  }
  getImage() {
    const { src, alt, title, className, retina, responsive } = this.props;
    if (retina) {
      return (
        <Img className={className} {...this.attr} alt={alt} title={title} />
      );
    } else if (responsive) {
      return (
        <picture className={className}>
          {this.sources}
          <Img alt={alt} title={title} src={this.src} />
        </picture>
      );
    } else if (typeof src === "string") {
      return <Img className={className} src={src} alt={alt} title={title} />;
    } else {
      return <Img className={className} src={null} />;
    }
  }
  render() {
    const { withLazyLoad } = this.props;
    if (withLazyLoad) {
      return (
        <LazyLoad height={200} offset={100}>
          {this.getImage()}
        </LazyLoad>
      );
    }
    return <Fragment>{this.getImage()}</Fragment>;
  }
}

export default AtImg;
