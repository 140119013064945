import styled from "styled-components";
import { Colors, Wp } from "../../theme";
import TplTitleBlock from "../TplTitleBlock/TplTitleBlock";
import { rgba } from "polished";

export const Wrapper = styled(Wp.Container)`
  .withoutTransform {
    transform: none !important;
    -webkit-transform: none !important;
  }
  .swiper-button-disabled {
    display: none !important;
  }
  .swiper-slide {
    &:first-child {
      &.swiper-slide-active {
        @media screen and (min-width: 768px) {
          margin-left: -200px;
        }
      }
    }
    .wrapperOriginalItem {
      .collapseView {
        display: block;
      }
      .expanedView {
        display: none;
      }

      @media screen and (min-width: 768px) {
        width: 200px;
        min-width: 200px;

        .collapseView {
          display: block;
        }
        .expanedView {
          display: none;
        }
      }
    }

    &.swiper-slide-prev {
      @media screen and (min-width: 768px) {
        width: 200px !important;
        marign-left: 20px;
      }
    }

    @media screen and (min-width: 768px) {
      margin-top: 57px;
    }
  }
  .swiper-slide-active {
    @media screen and (min-width: 768px) {
      margin-top: inherit;
      width: 750px !important;
      .wrapperOriginalItem {
        min-width: 750px;
        width: 750px;
        .collapseView {
          display: none;
        }
        .expanedView {
          display: block;
        }
      }
    }
  }
  .swiper-button-prev {
    &::before {
      content: "";
      top: calc(15px - 18em);
      left: -12px;
      position: absolute;
      width: 40px;
      height: 37em;
      background: linear-gradient(
        to left,
        ${rgba(Colors.bg2, 0)} 0%,
        ${rgba(Colors.bg2, 1)} 100%
      );
    }
  }

  .swiper-button-next {
    &::before {
      content: "";
      top: calc(15px - 18em);
      right: -12px;
      position: absolute;
      width: 40px;
      height: 37em;
      background: linear-gradient(
        to right,
        ${rgba(Colors.bg2, 0)} 0%,
        ${rgba(Colors.bg2, 1)} 100%
      );
    }
  }
`;

export const InnerWrapper = styled(Wp.Page)`
  position: relative;
  min-height: 420px;
  padding: 0;
  padding-top: 50px;
  margin: 0 auto 30px;
`;
export const List = styled(Wp.Page)`
  position: unset;
  @media screen and (min-width: 768px) {
  }
`;

export const TitleBlock = styled(TplTitleBlock)`
  padding-top: ${props => (props.showTitle ? 0.75 : 0)}em;
  padding-bottom: ${props => (props.showTitle || props.isNinos ? 1 : 0)}em;

  ${props => props.customStyles || ""};
`;

export const IconButton = styled.button`
  background: none !important;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  height: 29px;
  width: 29px;
  top: calc(50% - 14px);
  .icon {
    border: 2px solid ${Colors.white};
    border-radius: 50%;
    filter: drop-shadow(0px 1px 2px ${rgba(Colors.black, 0.4)});
  }
`;
