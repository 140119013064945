import styled from "styled-components";
import { Colors, Metrics, Wp } from "../../theme";

export const GeneralContainer = styled.a`
  display: block;
  text-decoration: none;
  padding: 0 0 30px 0;
  background: black;
`;

export const Information = styled.div`
  font-family: "Dosis", sans-serif;
  font-size: 10px;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-top: -130px;

  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.01) 1%,
    rgba(0, 0, 0, 0.98) 72%,
    rgba(0, 0, 0, 0.98) 99%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.01) 1%,
    rgba(0, 0, 0, 0.98) 72%,
    rgba(0, 0, 0, 0.98) 99%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.01) 1%,
    rgba(0, 0, 0, 0.98) 72%,
    rgba(0, 0, 0, 0.98) 99%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#fa000000',GradientType=0 ); /* IE6-9 */

  @media ${Metrics.mediaQ.tablet} {
    margin-top: -240px;
  }
`;
export const Etiqueta = styled.h2`
  background: #ff0303;
  font-family: "Bebas Neue", "Dosis", sans-serif;
  font-size: 22px;
  color: #fff;
  padding: 5px 15px 0;
  font-weight: normal;
  margin: 0;
  @media ${Metrics.mediaQ.tablet} {
    margin: 30px 0 0;
  }
`;
export const Titular = styled.h3`
  font-size: 72px;
  color: #fff;
  font-weight: 400;
  font-family: "BebasNeue Regular", sans-serif;
  line-height: 0.8em;
  text-shadow: 2px 3px 5px #000;
  text-align: center;
  margin: 10px 0;

  @media ${Metrics.mediaQ.tablet} {
    font-size: 4rem;
    line-height: initial;
    margin: 0;
  }
  @media ${Metrics.mediaQ.laptop} {
    font-size: 6rem;
  }
`;
export const Subtitulo = styled.h2`
  font-size: 18px;
  color: #fff;
  text-shadow: 2px 3px 5px #000;
  text-align: center;
  font-family: "Dosis", sans-serif;
  font-weight: normal;
  margin: 0 0 5px;
  @media ${Metrics.mediaQ.tablet} {
    font-size: 22px;
  }
`;
export const Button = styled.button`
  border: 1px solid #707070;
  background: ${Colors.transparent};
  outline: none;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  :hover {
    background-color: #009bdb;
  }
  @media ${Metrics.mediaQ.tablet} {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  @media ${Metrics.mediaQ.laptop} {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const ButtonText = styled.div`
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  font-size: 18px;
  padding-left: 5px;
  @media ${Metrics.mediaQ.tablet} {
    font-size: 1.5rem;
    padding-left: 30px;
  }
`;
export const GradientBg = styled.div`
  position: relative;
  bottom: 0px;
  display: block;
  width: 100%;
  height: 150px;
  margin-top: -150px;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.01) 1%,
    rgba(0, 0, 0, 0.98) 72%,
    rgba(0, 0, 0, 0.98) 99%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.01) 1%,
    rgba(0, 0, 0, 0.98) 72%,
    rgba(0, 0, 0, 0.98) 99%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.01) 1%,
    rgba(0, 0, 0, 0.98) 72%,
    rgba(0, 0, 0, 0.98) 99%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#fa000000',GradientType=0 ); /* IE6-9 */
`;
export const GradientBgVideo = styled.div`
  position: relative;
  bottom: 0px;
  display: block;
  width: 100%;
  height: 150px;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.01) 1%,
    rgba(0, 0, 0, 0.98) 72%,
    rgba(0, 0, 0, 0.98) 99%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.01) 1%,
    rgba(0, 0, 0, 0.98) 72%,
    rgba(0, 0, 0, 0.98) 99%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.01) 1%,
    rgba(0, 0, 0, 0.98) 72%,
    rgba(0, 0, 0, 0.98) 99%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#fa000000',GradientType=0 ); /* IE6-9 */
`;
export const ImgContainer = styled(Wp.Container)`
  img,
  picture {
    width: 100%;
    max-width: 100%;
  }
`;
export const ImgContainerVideo = styled.div``;
