export const SET_GEO_COUNTRY = "geoLocation/SET_GEO_COUNTRY";

const initialState = {
  country: null
};

// Reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GEO_COUNTRY:
      return {
        ...state,
        country: action.payload
      };

    default:
      return state;
  }
};

export const setGeoCountryLocation = (value = null) => dispatch =>
  dispatch({ type: SET_GEO_COUNTRY, payload: value });
