import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { rgba } from "polished";

import AtLogo from "../AtLogo/AtLogo";
import AtLiveDisplay from "../AtLiveDisplay/AtLiveDisplay";
import AtComingSoonCover from "../AtComingSoonCover/AtComingSoonCover";
import AtIconButton from "../AtIconButton/AtIconButton";
import MolNavbar from "../MolNavbar/MolNavbar";
import MolInputSearch from "../MolInputSearch/MolInputSearch";
import MolMenuCOD from "../MolMenuCOD/MolMenuCOD";
import MolMenuLS from "../MolMenuLS/MolMenuLS";
import MolMenuMobile from "../MolMenuMobile/MolMenuMobile";
import ButtonLogin from "../OrLogin/Button";

import { Colors, Wp, Metrics } from "../../theme";
import { isUserPath } from "../../utils/helpers";

const MIN_HEADER_HEIGHT = parseInt(Metrics.size.minHeaderHeight, 10);
const MAX_HEADER_HEIGHT = parseInt(Metrics.size.maxHeaderHeight, 10);
const time = new Date().getTime() / 1000;

const Header = styled(Wp.Header)`
  background-color: ${rgba(Colors.black, 0.7)};
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  width: calc(100% - 40px);
  @media ${Metrics.mediaQ.tablet} {
    width: calc(100% - 60px);
    padding: 0px 30px;
  }
`;
const Border = styled(Wp.Container)`
  width: calc(100% + 60px);
  height: 1px;
  background-color: ${Colors.cerulean};
`;
const Menu = styled(Wp.Page)`
  display: flex;
  width: 100%;
  background-color: ${rgba(Colors.black, 0)};
  align-items: center;
  justify-content: space-between;
  height: ${Metrics.size.minHeaderHeight};
  @media ${Metrics.mediaQ.tablet} {
    height: ${props =>
      !!props.expanded
        ? Metrics.size.maxHeaderHeight
        : Metrics.size.minHeaderHeight};
  }
  transition: height 0.5s ease;
  padding: 0;
  .logo {
    img {
      width: 100%;
      max-width: 100%;
    }
    @media ${Metrics.mediaQ.tablet} {
      flex-basis: initial;
      img {
        width: auto;
        max-width: 100%;
      }
    }
  }
`;
const Navbar = styled(MolNavbar)`
  flex: 1 1 50%;
  display: none;
  margin: 0;
  @media ${Metrics.mediaQ.laptop} {
    display: flex;
    margin: 0 20px;
  }
`;
const LiveDisplay = styled(AtLiveDisplay)`
  display: none;
  @media ${Metrics.mediaQ.laptop} {
    display: block;
    position: fixed;
    right: 50px;
    top: 100px;
  }
`;
const ButtonIconGroup = styled.div`
  display: flex;
  .kids-button-wrapper {
    margin: auto;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    flex-basis: 132px;
  }
  .user-icon,
  .input-search {
    margin-right: 20px;
    flex-basis: 40px;
  }
  .bars-icon {
    display: block;
  }
  @media ${Metrics.mediaQ.laptop} {
    .bars-icon {
      display: none;
    }
    .user-icon,
    .input-search {
      display: flex;
    }
  }
`;
const SubMenu = styled(Wp.Page)`
  display: block;
  width: 100%;
  padding: 0;
  height: ${props => (props.visible ? "auto" : "0px")};
`;
const MenuCOD = styled(MolMenuCOD)`
  display: ${props => !props.visible && "none"};
  width: auto;
  padding-bottom: 20px;
`;
const MenuLS = styled(MolMenuLS)`
  margin-top: 30px;
  margin-bottom: 30px;
  display: ${props => (props.visible ? "flex" : "none")};
`;
const BarsIconButton = styled(AtIconButton)`
  border: 1px solid ${Colors.white};
  border-radius: 5px;
  padding: 8px 8px 6px 8px;
`;

const ComingSoonCover = styled(AtComingSoonCover)`
  display: none;
  @media ${Metrics.mediaQ.laptop} {
    display: block;
  }
`;

const MobileComingSoonCover = styled(AtComingSoonCover)`
  margin-bottom: 3em;
`;
const ContainerMenuMobile = styled(Wp.Container)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2147483638;
  display: ${props => (props.visible ? "block" : "none")};
  .dummy-link {
    display: block;
    position: inherit;
    width: inherit;
    height: inherit;
  }
`;
const MenuMobile = styled(MolMenuMobile)``;

class OrHeader extends Component {
  static defaultProps = {
    data: {}
  };
  state = {
    headerHeight: MAX_HEADER_HEIGHT,
    submenu: false,
    logoWidth: null,
    current: null,
    menuMobile: false,
    windowWidth: null,
    inputShow: false,
    clientRectSubMenu: null
  };
  _toggleMenuMobile = () => {
    if (this.state.menuMobile) {
      this.setState({ menuMobile: false });
    } else {
      this.setState({ menuMobile: true });
    }
  };
  _toggleMenuMobileFromContainer = ev => {
    ev.preventDefault();
    this.setState({ menuMobile: false });
  };
  _scroll = ev => {
    /*if (window.scrollY > MIN_HEADER_HEIGHT) {
      this.setState({ headerHeight: MIN_HEADER_HEIGHT });
    } else if (window.scrollY < MAX_HEADER_HEIGHT) {
      this.setState({ headerHeight: MAX_HEADER_HEIGHT });
    }*/
  };
  _showSubmenu = (current, clientRect) => {
    this.setState({
      submenu: true,
      current: current,
      clientRectSubMenu: clientRect
    });
  };
  _hideSubmenu = event => {
    if (
      this.refs.header &&
      !this.refs.header.contains(event.target) &&
      this.state.submenu
    ) {
      this.setState({ submenu: false, current: null });
    }
  };
  _resize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentDidMount() {
    document.addEventListener("scroll", this._scroll);
    document.addEventListener("mouseover", this._hideSubmenu);
    document.addEventListener("touchstart", this._hideSubmenu);
    window.addEventListener("resize", this._resize);
    this.setState({ windowWidth: window.innerWidth });
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this._scroll);
    document.removeEventListener("mouseover", this._hideSubmenu);
    document.removeEventListener("touchstart", this._hideSubmenu);
    window.removeEventListener("resize", this._resize);
  }
  render() {
    const { data, current } = this.props;

    if (isUserPath(current)) {
      return null;
    }

    let firstLevelData = [];
    let navbarData = [];
    if (data) {
      for (let i = 0; i < data.menu_main.length; i++) {
        let level1 = data.menu_main[i];
        let menuItemLevel1 = {
          data: [],
          type: null,
          id: i
        };

        firstLevelData.push({
          label: level1.link.title,
          link: level1.link.url,
          id: i,
          onMouseOver: ev => {
            this._showSubmenu(i, {
              ...ev.currentTarget.getBoundingClientRect(),
              offsetLeft: ev.currentTarget.offsetLeft
            });
          },
          onTouchStart: ev => {
            this._showSubmenu(i, {
              ...ev.currentTarget.getBoundingClientRect(),
              offsetLeft: ev.currentTarget.offsetLeft
            });
          },
          onMouseOut: ev => {}
        });
        if (level1.hasChildren) {
          for (let j = 0; j < level1.subtree.length; j++) {
            let level2 = level1.subtree[j];
            let menuItemLevel2 = {
              data: []
            };
            if (level2.link.image) {
              menuItemLevel2["image"] = {
                source: level2.link.image,
                alt: level2.link.title
              };
              menuItemLevel1["type"] = "live";
            } else {
              menuItemLevel1["type"] = "cod";
            }
            if (level2.link.image_bg) {
              menuItemLevel2["image_bg"] = {
                source: level2.link.image_bg,
                alt: level2.link.title
              };
              menuItemLevel1["type"] = "live";
            }
            if (level2.hasChildren) {
              for (let k = 0; k < level2.subtree.length; k++) {
                let level3 = level2.subtree[k];
                let menuItemLevel3 = {
                  data: []
                };
                menuItemLevel3["hasChildren"] = level3.hasChildren;
                menuItemLevel3["label"] = level3.link.title;
                menuItemLevel3["link"] = level3.link.url;
                menuItemLevel2["data"].push(menuItemLevel3);
              }
            }
            menuItemLevel2["hasChildren"] = level2.hasChildren;
            menuItemLevel2["label"] = level2.link.title;
            menuItemLevel2["link"] = level2.link.url;
            menuItemLevel1["data"].push(menuItemLevel2);
          }
        }
        menuItemLevel1["hasChildren"] = level1.hasChildren;
        menuItemLevel1["label"] = level1.link.title;
        menuItemLevel1["link"] = level1.link.url;
        navbarData.push(menuItemLevel1);
      }
    }
    const expanded = this.state.headerHeight !== MIN_HEADER_HEIGHT;
    const isTablet = this.state.windowWidth < 1024;

    let eventLive = null;
    if (data !== null && data.live !== null) {
      const itemsLive = data.live;
      Object.keys(itemsLive).map(function(item) {
        const itemTimeStart = itemsLive[item].time_start;
        const itemTimeEnd = itemsLive[item].time_end;
        if (itemTimeStart <= time && itemTimeEnd >= time) {
          eventLive = itemsLive[item];
        }
        return eventLive;
      });
    }

    return (
      <Fragment>
        <Header ref="header" expanded={true}>
          <Menu expanded={expanded} className="resizecontainer">
            <AtLogo className="logo" expanded={expanded} />
            <Navbar data={firstLevelData} expanded={expanded}>
              {data &&
                data.live !== null &&
                eventLive !== null && (
                  <LiveDisplay
                    text={eventLive.title}
                    link={eventLive.slug || "#"}
                  />
                )}
              <ButtonIconGroup>
                <MolInputSearch
                  className="input-search"
                  buttonLeft={!isTablet}
                  buttonRight={isTablet}
                  onInputShow={() => this.setState({ inputShow: true })}
                  onInputHide={() => this.setState({ inputShow: false })}
                />
                <BarsIconButton
                  className="bars-icon"
                  name="Bars"
                  iconSize={22}
                  color={Colors.transparent}
                  onClick={this._toggleMenuMobile}
                />
                <ButtonLogin />
              </ButtonIconGroup>
            </Navbar>
          </Menu>
          <Border />
          <SubMenu visible={this.state.submenu}>
            {navbarData.filter(itm => itm.type === "cod").map((itm, index) => (
              <MenuCOD
                key={index}
                data={itm.data}
                visible={this.state.current === itm.id}
                clientRect={this.state.clientRectSubMenu}
                rightComponent={
                  !!data &&
                  !!data.coming_soon && (
                    <ComingSoonCover data={data.coming_soon} />
                  )
                }
              />
            ))}
            {navbarData.filter(itm => itm.type === "live").map((itm, index) => (
              <MenuLS
                key={index}
                opacity={0.8}
                data={itm.data}
                visible={this.state.current === itm.id}
              />
            ))}
          </SubMenu>
        </Header>
        <ContainerMenuMobile visible={this.state.menuMobile}>
          <MenuMobile
            close={this._toggleMenuMobile}
            visible={this.state.menuMobile}
            liveDisplay={data && data.live !== null ? data.live : null}
            data={navbarData.map((lvl1, idx1) => {
              return {
                id: `root-${idx1}`,
                type: "root",
                label: JSON.stringify(lvl1.label),
                to: lvl1.link,
                content: lvl1.data.map((lvl2, idx2) => {
                  return {
                    id: `${lvl2.image ? "smlive" : "submenu"}-${idx1}-${idx2}`,
                    type: "submenu",
                    label: JSON.stringify(
                      lvl2.image && lvl2.image.source
                        ? { label: lvl2.label, image: lvl2.image.source }
                        : lvl2.label
                    ),
                    to: lvl2.link,
                    content: lvl2.data.map((lvl3, idx3) => {
                      return {
                        id: `link-${idx1}-${idx2}-${idx3}`,
                        type: "link",
                        label: JSON.stringify(lvl3.label),
                        to: lvl3.link
                      };
                    })
                  };
                })
              };
            })}
          >
            <MobileComingSoonCover data={data && data.coming_soon} />
          </MenuMobile>
          <a
            className="dummy-link"
            href="#toggleMenuMobile"
            onClick={this._toggleMenuMobileFromContainer}
          >
            <span />
          </a>
        </ContainerMenuMobile>
      </Fragment>
    );
  }
}
export default OrHeader;
