import React, { Component } from "react";
import Modal from "react-awesome-modal";
import { Colors, Fonts, Metrics } from "../../theme";
import styled from "styled-components";
import AtIconButton from "../AtIconButton/AtIconButton";
const Input = styled(Fonts.input)`
  background-color: #484848 !important;
  box-shadow: 0 0 30px 15px #121212;
  color: #ffffff;
  border-radius: 30px;
  border: 1px solid #707070;
  line-height: 34px;
  width: 90vw;
  padding: 15px 25px;
  padding-right: 50px;
  outline: none;
  margin-left: 5px;
  box-sizing: border-box;
  transition: all 0.5s ease;
  position: relative;
  font-family: "Dosis", sans-serif;
  font-size: 22px;
  font-weight: 100 !important;
  ::placeholder {
    color: #ffffff;
    font-family: "Dosis", sans-serif;
    font-size: 22px;
    @media (min-width: ${Metrics.size.tablet}) {
      font-size: 2rem;
      font-weight: 100 !important;
    }
  }
  @media (min-width: ${Metrics.size.laptop}) {
    height: 4vw;
    font-size: 2rem;
    width: 40vw;
  }
`;
const ContentSearch = styled.div`
  & > div > div > div:first-child {
    background-color: transparent !important;
    padding: 10px;
    border-radius: 0px !important;
    box-shadow: unset !important;
    position: absolute;
    top: 25%;
    @media (max-width: ${Metrics.size.mobileXL}) {
      top: 20%;
    }
  }
  position: relative;
`;
const AtIconButtonSearch = styled(AtIconButton)`
  position: absolute;
  right: 17px;
  top: 22%;
  @media (min-width: ${Metrics.size.tablet}) {
    top: 27%;
  }
`;
const AtIconButtonCloseModal = styled(AtIconButton)`
  position: absolute;
  top: -10px;

  @media (min-width: ${Metrics.size.tablet}) {
    left: 80px;
  }
`;
const ContentCloseModal = styled.div`
  position: absolute;
  color: white;
  z-index: 1;
  right: -7vw;
  top: -5vw;
  color: #ffffff;
  font-family: "Dosis", sans-serif;
  font-size: 2rem;
  font-weight: 200 !important;
  @media (max-width: ${Metrics.size.laptop}) {
    right: 6vw;
  }
  @media (max-width: ${Metrics.size.tabletL}) {
    right: 10vw;
    top: -12vw;
  }
  @media (max-width: ${Metrics.size.tablet}) {
    font-size: 22px;
    right: 15vw;
  }
  @media (max-width: ${Metrics.size.mobileL}) {
    top: -15vw;
  }
`;
class AtSearchFocus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasErrors: false,
      modalVisible: false,
      value: "",
      invalid: false,
      modal: true
    };
  }
  _handleChange = ev => {
    this.setState({ value: ev.target.value });
  };
  _handleSubmit = ev => {
    if (this.state.value !== "") {
      this.setState({ invalid: false });
      document.location.href = `/buscar/${this.state.value}`;
    } else {
      this.setState({ invalid: true });
    }
    ev.preventDefault();
  };
  render() {
    const { focus, close } = this.props;
    document.querySelector(".iconsearch").blur();
    //icono activo
    return !focus ? null : (
      <ContentSearch>
        <Modal visible={focus} effect="fadeInUp" onClickAway={close}>
          <ContentCloseModal>
            Cerrar
            <AtIconButtonCloseModal
              name="CloseCircle"
              iconSize={35}
              color={Colors.transparent}
              onClick={close}
            />
          </ContentCloseModal>

          <form onSubmit={this._handleSubmit}>
            <Input
              id="inputsearch"
              ref="inputSearch"
              type="text"
              value={this.state.value}
              onChange={this._handleChange}
              name="buscar"
              error={this.state.invalid}
              placeholder=" Escribe tu búsqueda"
              autofocus="true"
            />
            <AtIconButtonSearch
              name="search"
              iconSize={32}
              color={Colors.transparent}
              type="submit"
            />
          </form>
        </Modal>
      </ContentSearch>
    );
  }
}
export default AtSearchFocus;
