import styled from "styled-components";
import { rgba } from "polished";

import { Colors, Fonts, Wp } from "../../../theme";
import AtBadge from "../../AtBadge/AtBadge";
import AtIcon from "../../AtIcon/AtIcon";

export const Wrapper = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.3);
  min-width: 494px;
  text-align: right;
  margin-right: 20px;
  @media screen and (max-width: 767px) {
    width: 100%;
    display: inline-block;
    margin-right: 0;
    margin-bottom: 20px;
    min-width: unset;
  }
  .PauseBorder {
    border: 2px solid ${Colors.white};
    border-radius: 50%;
  }
`;

export const Label = styled(AtBadge)`
  position: absolute;
  left: 20px;
  top: 20px;
  @media screen and (max-width: 767px) {
    display: none !important;
  }
`;

export const ImagePodcast = styled.img`
  display: block;
  width: 100%;
  @media screen and (min-width: 768px) {
    max-height: 278px;
  }
`;

export const IconPodcast = styled(AtIcon)`
  position: absolute;
  filter: drop-shadow(0px 1px 2px ${rgba(Colors.black, 0.4)});
  pointer-events: none;
  opacity: 0.5;
  margin-top: -40px;
  right: 10px;
`;

export const MetadataContainer = styled.div`
  display: flex;
  text-align: left;
  margin: 24px;

  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 768px) {
    flex-direction: initial;
    align-items: initial;
    width: 100%;
  }
`;

export const ImageLogo = styled.img`
  height: fit-content;
  max-width: 90px;
  @media screen and (max-width: 767px) {
  }
`;

export const PlayerContainer = styled.div`
  display: block;
  width: 100%;
  margin: 10px 0 0;
  @media screen and (min-width: 768px) {
    width: 68%;
    border-left: 1px solid white;
    margin-left: 10px;
    padding-left: 20px;
  }
`;

export const EpisodeTitle = styled(Fonts.infoHoverTitle)`
  margin: 0;
  max-width: 237px;
  min-height: 50px;

  @media screen and (max-width: 767px) {
    margin: 0 auto;
    text-align: center;
  }
`;

export const Footer = styled.div`
  display: block;
  position: relative;
  text-align: center;
  margin: 18px;
  @media screen and (min-width: 768px) {
    margin: 35px 35px 25px;
    text-align: left;
    width: 100%;
  }
`;

export const ListenNowContainer = styled(Wp.Container)`
  width: auto;
  display: inline-flex;
  color: #ffffff;
  a {
    color: #ffffff;
  }
  span {
    margin-left: 5px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    margin-top: 5px;
  }
  margin-right: 20px;
  margin-bottom: 21px;
`;

export const LitlePlayIcon = styled(AtIcon)`
  opacity: 1;
`;

export const Link = styled.a`
  display: inline-block;
  text-decoration: none;
`;
