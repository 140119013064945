import React, { useEffect, useState } from "react";

import {
  BackGradient,
  InnerWrapper,
  Title,
  Description,
  Wrapper,
  MetatagContainer,
  CategoryList,
  CategoryListItem,
  Trailer,
  Player
} from "./OpeningPodcastWidget";

export default function OrOpeningPodcastWidget({
  contents,
  description,
  image,
  title,
  video_preview
}) {
  const [isMobile, setMobile] = useState(true);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setMobile(document.body.clientWidth < 768);
    }
  }, []);
  const videoAttr = ({ desktop, mobile }) => {
    return {
      config: {
        file: {
          attributes: {
            autoPlay: true,
            poster: "",
            loop: true,
            controls: false,
            muted: true
          }
        }
      },
      url: [{ src: isMobile ? mobile : desktop, preload: "auto" }]
    };
  };

  return (
    <Wrapper>
      <InnerWrapper back={image}>
        <Trailer>
          {video_preview && (
            <Player
              coverOverflow
              showAudioButton={false}
              showProgressBar={false}
              {...videoAttr(video_preview)}
              loop
              isBackgroundVideo
            />
          )}
        </Trailer>
        <BackGradient />
        <MetatagContainer>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <CategoryList>
            {contents.map(
              ({ id, slug, name, long_title, short_title }, index) => (
                <CategoryListItem key={`${id}-${index}`}>
                  <a href={slug}>{short_title || long_title || name}</a>
                </CategoryListItem>
              )
            )}
          </CategoryList>
        </MetatagContainer>
      </InnerWrapper>
    </Wrapper>
  );
}
