const Images = {
  test: require("../assets/images/logo.png"),
  coverTest: require("../assets/images/simona.jpg"),
  rtvc: require("../assets/images/rtvc.png"),
  canalInstitucional: require("../assets/images/canal-institucional.png"),
  senalColombia: require("../assets/images/senal-colombia.svg"),
  radioNacional: require("../assets/images/radionacional.svg"),
  radionica: require("../assets/images/radionica.svg"),
  senalMemoria: require("../assets/images/senal-memoria.svg"),
  kids: [
    {
      "": require("../assets/images/kids.png")
    },
    {
      "2x": require("../assets/images/kids@2x.png")
    }
  ],
  logo: require("../assets/logo-header.png"),
  responsiveTest: [
    {
      "": require("../assets/images/test768x832.jpg")
    },
    {
      "(min-width: 1024px)": require("../assets/images/test1920x823.jpg")
    }
  ]
};

export default Images;
