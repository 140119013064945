import React, { useCallback, useState } from "react";
import soundOff from "../../../assets/SOUND_OFF_White.svg";
import soundOn from "../../../assets/SOUND_ON_White.svg";

import {
  Wrapper,
  Image,
  Link,
  Label,
  HoverLayer,
  TrailerContainer,
  Trailer,
  ButtonsContainer,
  MetadataContainer,
  MetadataInnerContainer,
  Duration,
  ListenNow,
  DescriptionWrapper,
  ToggleSound,
  Player
} from "./CineItem";

import { LitlePlayIcon } from "../../OrPodcastWidget/PodcastMainItem/PodcastMainItem";
import SocialButtons from "../../MolShareButton";

export default function CineItem({
  duration,
  label,
  production,
  synopsis,
  title,
  video_preview,
  index
}) {
  const [muted, setMuted] = useState(true);
  let path = null;
  let slug = null;
  if (!!production) {
    slug = production.slug;
    const {
      image: { big_poster }
    } = production;
    path = big_poster.path;
  }
  const initialAxixX = index % 2 === 0 ? "left" : "right";

  const toggleAudio = useCallback(
    event => {
      event.preventDefault();
      setMuted(!muted);
    },
    [muted, setMuted]
  );

  const videoAttr = video => {
    return {
      config: {
        file: {
          attributes: {
            autoPlay: true,
            poster: "",
            loop: true,
            controls: false,
            muted
          }
        }
      },
      url: [
        {
          src: video,
          preload: "auto"
        }
      ]
    };
  };

  return (
    <Wrapper initialAxixX={initialAxixX}>
      <Label color={index % 2 === 0 ? "#00A99E" : "#FD0402"} el="h3">
        {label}
      </Label>
      {!!path && <Image src={path} />}
      <HoverLayer className={"containerHover"} initialAxixX={initialAxixX}>
        <TrailerContainer id={`trailerPlayerCinema${index}`}>
          <ToggleSound onClick={toggleAudio}>
            <img alt="Audio" src={muted ? soundOff : soundOn} />
          </ToggleSound>
          <Trailer>
            {video_preview && (
              <a href={slug}>
                <Player
                  coverOverflow
                  showAudioButton={false}
                  showProgressBar={false}
                  {...videoAttr(video_preview)}
                  loop
                  isBackgroundVideo
                />
              </a>
            )}
          </Trailer>
        </TrailerContainer>
        <MetadataContainer>
          <MetadataInnerContainer>
            <h3>{title}</h3>
            {duration && <Duration>{duration} min</Duration>}
            <DescriptionWrapper
              dangerouslySetInnerHTML={{ __html: synopsis }}
            />
            <ButtonsContainer>
              {slug && (
                <>
                  <Link href={slug}>
                    <ListenNow>
                      <LitlePlayIcon
                        className="play-icon"
                        name="PlayCircle"
                        size={30}
                      />{" "}
                      <span>VER AHORA</span>
                    </ListenNow>
                  </Link>
                  <SocialButtons
                    label="COMPARTIR"
                    title={title}
                    text={synopsis}
                    size={30}
                    sizeButtons={18}
                    uri={slug}
                  />
                </>
              )}
            </ButtonsContainer>
          </MetadataInnerContainer>
        </MetadataContainer>
      </HoverLayer>
    </Wrapper>
  );
}
