import React from "react";
import styled from "styled-components";

import { Colors } from "../../theme";

const btnClose = require("../../assets/images/btn-cerrar.svg");

export const Close = ({ onClick, label }) => {
  return (
    <CloseButton onClick={onClick}>
      {label || <img src={btnClose} alt="Cerrar" title="Cerrar" />}
    </CloseButton>
  );
};

export const Button = styled.button`
  color: ${Colors.white};
  background: ${Colors.celticBlue};
  border: 0;
  border-radius: 50px;
  padding: 2px 20px;
  font-family: "Dosis", sans-serif;
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;

  &:active {
    background: ${Colors.pacificBlue};
  }
`;

const CloseButton = styled.button`
  background: ${Colors.bg4};
  color: ${Colors.white};
  padding: 0;
  margin: 0;
  width: 24px;
  height: 24px;
  font-size: 14px;
  line-height: 0.864;
  outline: none;
  position: absolute;
  right: 10px;
  top: 14px;
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  &:active {
    background: ${Colors.bg1};
  }
`;
