import styled, { css } from "styled-components";
import { Colors, Wp } from "../../../theme";
import { rgba } from "polished";
import AtImg from "../../../components/AtImg/AtImg";

export const Container = styled(Wp.Container)`
  margin: 20px 0;
`;
export const PageWrapper = styled(Wp.Page)``;

export const IconButton = styled.button`
  background: none !important;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  height: 29px;
  width: 29px;
  top: calc(50% - 14px);
  .icon {
    border: 2px solid ${Colors.white};
    border-radius: 50%;
    filter: drop-shadow(0px 1px 2px ${rgba(Colors.black, 0.4)});
  }
`;
export const ContentBanner = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  text-align: center;

  .banner-button {
    cursor: pointer;
    display: inline-block;
    width: auto;
    margin: auto;
    &.noLink {
      cursor: auto;
    }
    img {
      width: auto;
      margin: auto;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    .banner-button {
      img {
        max-width: 100%;
      }
    }
  }
  .swiper-wrapper {
    align-items: center;
  }
  .swiper-button-disabled {
    display: none !important;
  }

  ${props =>
    props.customStyles &&
    props.customStyles !== "" &&
    css`
      ${props.customStyles};
    `};
`;

export const ImageBanner = styled(AtImg)`
  &.isMobile:not(.isDesktop) {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
  &.isDesktop:not(.isMobile) {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
`;
