import React from "react";
import Page from "../";
import styled from "styled-components";
import UpdatePassword from "../../../components/OrLogin/UpdatePassword";
import queryString from "query-string";
import { fetchContent } from "../../../../store/utils/helpers";

const WrapperUpdatePassword = styled.div`
  padding-bottom: 20px;
  padding-top: 66px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  @media screen and (min-width: 768px) {
    padding-top: 90px;
  }
  & > span {
    font-size: 12px;
    font-weight: bold;
  }
  & > h1 {
    padding: 0px;
    margin: 0px;
    margin-bottom: 10px;
  }
  & > img {
    max-width: 900px;
    width: 100%;
  }
  & > p {
    max-width: 900px;
    margin: auto;
    text-align: left;
    margin-top: 20px;
  }
`;

export default function Reset({ location: { search } }) {
  fetchContent("registro");
  return (
    <Page
      id="updatePass"
      title="Actualizar contraseña"
      description="Página de inicio de sesión de RTVCPlay"
    >
      <WrapperUpdatePassword>
        <UpdatePassword {...queryString.parse(search)} />
      </WrapperUpdatePassword>
    </Page>
  );
}
