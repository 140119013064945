import createStore from "./";

/**
 * StoreManager state.
 */
class State {
  /**
   * Constructor
   */
  constructor() {
    const { store, history } = createStore();
    this.Store = store;
    this.History = history;
  }

  /**
   * Return the redux store
   * @returns {*}
   * @constructor
   */
  getStore() {
    return this.Store;
  }
  getHistory() {
    return this.History;
  }
}

/**
 * Manager for the redux store.
 */
class StoreManager {
  /**
   * Create a fresh, new state (including a fresh, new store)
   * @returns {Object} StoreManager state
   */
  init() {
    this.state = new State();
    return this.state;
  }

  /**
   * Return the redux store
   * @returns {*}
   * @constructor
   */
  getStore() {
    if (!this.state) {
      this.init();
    }
    return this.state.getStore();
  }

  getHistory() {
    if (!this.state) {
      this.init();
    }
    return this.state.getHistory();
  }
}

/**
 * A singleton implementation with many states, each request can use
 * a different state to support a store-per-request scheme.
 */
export default new StoreManager();
