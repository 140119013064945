import { SET_CHAPTERS, SET_SERIES } from "../constants/action-types";
const initialState = {
  chapters: null,
  series: null
};
export default function myList(state = initialState, action) {
  switch (action.type) {
    case SET_CHAPTERS:
      return {
        ...state,
        chapters: action.content
      };
    case SET_SERIES:
      return {
        ...state,
        series: action.content
      };
    default:
      return state;
  }
}
