import styled, { css } from "styled-components";
import TplTitleBlock from "../TplTitleBlock/TplTitleBlock";
import TplSwiper from "../TplSwiper/TplSwiper";
import { Colors, Fonts, Metrics, Wp } from "../../theme";
import MolPlayer from "../MolPlayer/MolPlayer";
import AtRating from "../AtRating/AtRating";
import { rgba } from "polished";
import MolBrandContainer from "../MolBrandContainer/MolBrandContainer";
import AtIcon from "../AtIcon/AtIcon";
import AtIconButton from "../AtIconButton/AtIconButton";
import AtImg from "../AtImg/AtImg";

export const TitleBlock = styled(TplTitleBlock)`
  background-color: ${props => props.backgroundColor};
  padding-top: ${props => (props.showTitle ? 0.75 : 0)}em;
  padding-bottom: ${props => (props.showTitle || props.isNinos ? 0.75 : 0)}em;
  border-top: ${props => props.borderTop};
  .block-body {
    padding-top: 1em;
  }
  ${props => props.customStyles || ""};
`;

export const Swiper = styled(TplSwiper)`
  background: ${Colors.bg2};
  .swiper-container {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }
  @media (max-width: ${Metrics.size.tabletL}) and (orientation: landscape) {
    padding-top: ${100 / (21 / 9)}%;
  }
  @media ${Metrics.mediaQ.tabletL} {
    padding-top: ${100 / (21 / 9)}%;
    .swiper-container {
      .swiper-button-next,
      .swiper-button-prev {
        display: flex;
      }
    }
  }
  &.swipersliderkids {
    margin: auto;
    .swiper-container {
      .swiper-button-next,
      .swiper-button-prev {
        display: block;
        opacity: unset;
        bottom: 220px;
        top: unset;
        @media (max-width: ${Metrics.size.laptopM}) {
          bottom: 181px;
        }
      }
      .swiper-button-next {
        @media (max-width: ${Metrics.size.laptopM}) {
          right: 41%;
        }
        @media (max-width: ${Metrics.size.laptop}) {
          right: 50%;
        }
        @media (max-width: ${Metrics.size.tabletL}) {
          display: none;
        }
      }
      .swiper-button-prev {
        @media (max-width: ${Metrics.size.laptopM}) {
          left: 50%;
        }
        @media (max-width: ${Metrics.size.laptop}) {
          left: 39%;
        }
        @media (max-width: ${Metrics.size.tabletL}) {
          display: none;
        }
      }
      .swiper-pagination {
        top: 67%;
        text-align: right;
        width: 83.5%;
        height: 25px;
        z-index: 1;
        @media (min-width: 1950px) {
          display: none;
        }
        @media (max-width: ${Metrics.size.laptopL}) {
          top: 55%;
          width: 94.5%;
        }
        @media (max-width: ${Metrics.size.laptopM}) {
          top: 58%;
          width: 98.5%;
        }
        @media (max-width: ${Metrics.size.laptop}) {
          top: 47%;
        }
        @media (max-width: ${Metrics.size.tablet}) {
          top: unset;
          width: 100%;
          bottom: 5px;
          text-align: center;
        }
      }
    }
  }
`;
export const Player = styled(MolPlayer)`
  @media ${Metrics.mediaQ.tabletL} {
    padding-top: ${100 / (21 / 9)}%;
  }
  @media (max-width: ${Metrics.size.tabletL}) and (orientation: landscape) {
    padding-top: ${100 / (21 / 9)}%;
  }
`;

export const Rating = styled(AtRating)`
  position: absolute;
  top: 70px;
  right: 47px;
  @media ${Metrics.mediaQ.tablet} {
    top: 120px;
  }
`;

export const TextContainer = styled.div`
  max-width: 500px;
  font-size: 10px;
  @media ${Metrics.mediaQ.tablet} {
    font-size: 13px;
  }
  @media ${Metrics.mediaQ.laptop} {
    font-size: initial;
  }
`;
export const Title = styled(Fonts.h3)`
  color: ${Colors.white};
  font-size: 38px;
  font-weight: 400;
  line-height: 36px;
  font-family: "BebasNeue Regular", sans-serif;
  margin: 0.3em 0 0 0;
  text-decoration: none;
  white-space: normal;
  text-shadow: none;
  text-transform: uppercase;
  text-overflow: ellipsis;

  @media ${Metrics.mediaQ.tablet} {
    font-size: 2.875em;
    line-height: 41px;
  }
`;
export const Summary = styled(Fonts.slideSynopsis)`
  font-size: 1.25em;
  line-height: 1em;
  color: ${Colors.white};
  margin: 0;
  padding: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: none;
  font-weight: normal;
  @media ${Metrics.mediaQ.tablet} {
    white-space: normal;
    overflow: auto;
    display: block;
    margin: 0 0 10px 0;
  }
  &.sumarysliderkids {
    @media (max-width: ${Metrics.size.laptop}) {
      width: 63%;
    }
  }
`;
export const Slide = styled(Wp.Container)``;
export const GradientBg = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 50%;
  left: 0;
  bottom: -5px;
  &.isEducation {
    background: linear-gradient(
      to bottom,
      ${rgba(Colors.educacionBackground, 0)} 0%,
      ${rgba(Colors.educacionBackground, 1)} 100%
    );
  }
  &.SectionDefault {
    background: linear-gradient(
      to bottom,
      ${rgba(Colors.bg2, 0)} 0%,
      ${rgba(Colors.bg2, 1)} 100%
    );
  }
`;
export const BrandContainer = styled(MolBrandContainer)`
  position: absolute;
  width: 80%;
  left: 10%;
  bottom: 8%;
  flex-direction: column;
  text-align: center;
  @media ${Metrics.mediaQ.tablet} {
    text-align: initial;
    width: 100%;
    left: 0;
    right: 0;
    flex-direction: row;
  }
  .brand img {
    height: auto;
    width: 100%;
    max-width: 130px;
    margin: 10px auto;
    float: initial;
    @media ${Metrics.mediaQ.tablet} {
      float: ${({ noContentKids }) => (!!noContentKids ? "unset" : "right")};
      object-fit: contain;
      object-position: right;
      max-width: initial;
      margin: initial;
    }
  }
  &.brandslider {
    width: 90%;
    left: 4%;
    text-align: left;

    .brand {
      border-right: none;
    }
    .brand img {
      max-width: 115px;
      margin-bottom: 15px;
      object-position: left;
      @media (max-width: ${Metrics.size.tabletL}) {
        margin-bottom: 10px;
      }
    }
  }
`;

export const ImgContainer = styled(Wp.Container)`
  img,
  picture {
    width: 100%;
    max-width: 100%;
  }
`;

export const LabelContainer = styled(Wp.Container)`
  margin-top: 0;
  width: auto;
  display: inline-flex;
  color: #ffffff;

  @media (max-width: ${Metrics.size.tablet}) {
    flex-flow: column;
    text-transform: uppercase;
    align-items: center;
  }

  a {
    color: #ffffff;
  }
  &.play-icon-sliderkids {
    @media (max-width: ${Metrics.size.tabletL}) {
      margin-top: 0;
      span {
        @media (max-width: ${Metrics.size.mobileS}) {
          font-size: 14px;
          padding-top: 7px;
        }
      }
    }
  }

  @media ${Metrics.mediaQ.tablet} {
    span {
      font-size: 18px;
      line-height: 22px;
      margin: 0 0 0 5px;
      align-self: center;
      text-transform: uppercase;
    }
  }
`;

export const LinkContainer = styled.a`
  display: block;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${Colors.transparent};
  text-decoration: none;

  @media ${Metrics.mediaQ.tablet} {
    display: flex;

    :hover {
      .play-icon {
        opacity: 1;
        -webkit-animation: fadein 1s;
        -moz-animation: fadein 1s;
        -ms-animation: fadein 1s;
        -o-animation: fadein 1s;
        animation: fadein 1s;
        @media (max-width: ${Metrics.size.laptopM}) {
          width: 6%;
        }
        @media (max-width: ${Metrics.size.tablet}) {
          width: 18%;
          height: auto;
          position: absolute;
          top: 25%;
        }
      }
    }

    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @-moz-keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @-webkit-keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @-ms-keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @-o-keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
`;

export const Icon = styled(AtIcon)`
  opacity: 0;
  transition: opacity 100ms ease-out;
  filter: drop-shadow(0px 4px 5px ${rgba(Colors.black, 0.4)});
  &.playiconsliderkids {
    display: none;
  }
`;

export const LitlePlayIcon = styled(AtIcon)`
  opacity: 1;
`;
export const ToolbarButtons = styled.div`
  position: relative;
  display: flex;
  margin: 20px 0 0;
  column-gap: 20px;

  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    white-space: nowrap;
  }
  & > div {
    margin: 0;
    @media screen and (max-width: ${Metrics.size.tablet}) {
      flex: auto;
      column-gap: initial;
      a {
        flex-direction: column;
        align-items: center;
      }
      span {
        font-weight: 400;
        margin: 5px 0;
        font-size: 18px;
        line-height: 22px;
        white-space: nowrap;
      }

      ${({ isSliderKids }) =>
        isSliderKids &&
        css`
          column-gap: 5px;
          flex: inherit;
          flex-direction: row;
          a {
            flex-direction: row;
          }
        `};
    }
  }

  @media screen and (max-width: ${Metrics.size.tablet}) {
    ${({ isSliderKids }) =>
      isSliderKids &&
      css`
        margin: 5px 0 0;
      `};
  }
`;
export const SocialBtns = styled.div`
  margin-top: 15px;
  margin-left: 15px;
  display: flex;
  z-index: 9999;
`;
export const MyListcontainer = styled.div`
  margin-top: 15px;
  margin-left: 15px;
  display: flex;
  z-index: 9999;
`;
export const BarsIconButton = styled(AtIconButton)`
  position: relative;
  top: -12px;
  right: 0px;
  &.iconninos {
    padding: 0px 8px;
  }
`;
export const IconNinos = styled.div`
  border: 1px solid #ffffff;
  position: absolute;
  right: 7%;
  top: 15%;
  height: 30px;
  width: 65px;
  border-radius: 10px;
  padding: 6px 14px;
  @media (max-width: ${Metrics.size.tablet}) {
    top: 15%;
  }
`;
export const SliderKids = styled.div`
  @media (max-width: ${Metrics.size.tabletL}) {
    display: none;
  }
`;
export const SliderContainer = styled.div`
  width: 609px;
  height: 151px;
  overflow: hidden;
  position: absolute;
  bottom: 60px;
  z-index: 10;
  @media (max-width: ${Metrics.size.laptopM}) {
    bottom: 20px;
  }
`;
export const ImgContainerKids = styled(Wp.Container)`
  box-sizing: border-box;
  width: 827px;
  height: 151px;
  z-index: 1;
`;
export const ImageSlider = styled(AtImg)`
  border: 0;
  margin-right: 10px;
`;
export const ImageSelect = styled.div`
  display: inline-block;
  cursor: pointer;
  position: relative;
`;
export const LinkNinos = styled.a`
  z-index: 1;
  position: absolute;
  bottom: -36px;
  right: -2px;
  color: #ffffff;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
  color: #ffffff;
  display: block;
  background-color: transparent;
  font-size: 1em;
  border-radius: 50%;
  text-align: center;
  width: 94px;
  text-decoration: none;
`;
