import React, { Component } from "react";
import styled from "styled-components";
import { rgba } from "polished";

import AtTextLink from "../AtTextLink/AtTextLink";

import { Colors, Wp, Metrics, Fonts } from "../../theme";

const Navbar = styled(Wp.Navbar)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;
const TextLink = styled(AtTextLink)`
  flex-basis: calc(50% - 20px);
  margin: 0px;
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 2rem;
  color: ${Colors.gray};
  padding: 0 10px;
  background-color: ${Colors.transparent};
  text-align: center;
  @media ${Metrics.mediaQ.tablet} {
    flex-basis: calc(25% - 20px);
  }
  @media ${Metrics.mediaQ.laptop} {
    flex-basis: auto;
    background-color: rgba(0, 0, 0, 0);
    margin: 0px 10px;
    padding: 0px;
    :first-child {
      margin-left: 0px;
    }
    :last-child {
      margin-right: 0px;
    }
  }
`;
const AppStoresList = styled.ul`
  margin: 0 15px 0;
  padding: 0;
  height: 40px;
  list-style: none;
  display: flex;

  @media ${Metrics.mediaQ.laptop} {
    width: 100%;
    margin: 0;
  }
`;
const AppStoresListItem = styled.li`
  max-width: 131px;
  margin: 0 20px 0 0;
  img {
    width: 100%;
  }
`;
const DownloadAppText = styled(Fonts.infoFooterSubTitle)`
  margin: 0 15px 10px;
  @media ${Metrics.mediaQ.laptop} {
    width: 100%;
    margin: 0 0 10px;
  }
`;
const ContainerNavGeneral = styled.div`
  @media ${Metrics.mediaQ.laptop} {
    width: 49%;
    position: relative;
    &::after {
      content: " ";
      height: 54px;
      border-right: 1px solid ${rgba(Colors.gray, 0.5)};
      right: 0;
      position: absolute;
      top: 33%;
    }
  }
`;

class MolSecondaryNavbar extends Component {
  static defaultProps = {
    data: [
      {
        label: "Quienes Somos",
        link: "#"
      },
      {
        label: "Términos y Condiciones",
        link: "#"
      },
      {
        label: "Contactenos",
        link: "#"
      },
      {
        label: "Ayuda",
        link: "#"
      }
    ]
  };
  render() {
    const { data, className } = this.props;
    return (
      <ContainerNavGeneral>
        <DownloadAppText>Descarga la app</DownloadAppText>
        <AppStoresList>
          <AppStoresListItem>
            <a
              href="http://bit.ly/RTVCPlayApp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Rtvcplay App"
                src="https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/inline-images/app-store.png"
              />
            </a>
          </AppStoresListItem>
          <AppStoresListItem>
            <a
              href="http://bit.ly/RTVCPlayAndroid"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Rtvcpla Android"
                src="https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/inline-images/play-store.png"
              />
            </a>
          </AppStoresListItem>
          <AppStoresListItem>
            <a
              href="https://appgallery.cloud.huawei.com/ag/n/app/C103120993?locale=es_US&source=appshare&subsource=C103120993"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Rtvcplay Huawei"
                src="https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/inline-images/app-gallery.png"
              />
            </a>
          </AppStoresListItem>
        </AppStoresList>
        <Navbar className={className}>
          {data.map((itm, index) => (
            <TextLink
              key={index}
              href={itm.link}
              text={itm.label}
              textTransform="normal"
            />
          ))}
        </Navbar>
      </ContainerNavGeneral>
    );
  }
}
export default MolSecondaryNavbar;
