import React, { useCallback, useState } from "react";

import { getCurrentUser, logOut } from "../../../../store/utils/helpers";
import { getKey, hasKey, hasUserFeaturesEnable } from "../../../utils/helpers";

import ArrowTooltip from "./ButtonTooltip";

import {
  ContentLoginButton,
  Icon,
  IconButton,
  Span,
  Link,
  WrapperUserImage
} from "./Button";

export default function ButtonLogin() {
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const user = getCurrentUser();

  function handleTooltipClose() {
    setToolTipOpen(false);
  }

  function handleTooltipOpen() {
    setToolTipOpen(!toolTipOpen);
  }
  const goLoginPage = useCallback(() => {
    window.location.href = `/login?ref=${window.location.pathname}`;
  });
  return !hasUserFeaturesEnable() ? null : (
    <ContentLoginButton>
      <Link onClick={user === null ? goLoginPage : handleTooltipOpen}>
        {user === null ? (
          <IconButton name="userCircle" iconSize={22} />
        ) : (
          <WrapperUserImage>
            <ArrowTooltip
              title={
                <ul>
                  <li>
                    <Link href="/mi-perfil">
                      <Icon name="User" size={16} />
                      <span>Mi perfil</span>
                    </Link>
                  </li>
                  <li>
                    <Link href="/mi-lista">
                      <Icon name="ListUl" size={16} />
                      <span>Mi lista</span>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={logOut}>
                      <Icon name="Close" size={16} />
                      <span>Cerrar Sesión</span>
                    </Link>
                  </li>
                </ul>
              }
              open={toolTipOpen}
              onClose={handleTooltipClose}
            >
              {hasKey(user, "image.picture.path") ? (
                <img alt="Imagen de usuario" src={user.image.picture.path} />
              ) : hasKey(user, "image_social") ? (
                <img alt="Imagen social" src={user.image_social} />
              ) : (
                <IconButton name="userCircle" iconSize={22} />
              )}
            </ArrowTooltip>
          </WrapperUserImage>
        )}
        <Span>
          {user === null
            ? "Iniciar Sesión"
            : getKey(user, "full_name") || getKey(user, "mail")}
        </Span>
      </Link>
    </ContentLoginButton>
  );
}
