import styled from "styled-components";
import MolBrandContainer from "../../MolBrandContainer/MolBrandContainer";
import { Colors, Fonts, Metrics, Wp } from "../../../theme";
import AtIcon from "../../AtIcon/AtIcon";

export const BrandContainer = styled(MolBrandContainer)`
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  bottom: 8%;
  @media ${Metrics.mediaQ.tablet} {
    text-align: initial;
    right: 0;
  }
  .title {
    font-size: 2.5em;
    overflow: visible !important;
    @media ${Metrics.mediaQ.mobileM} {
      font-size: 2.875em;
    }
  }
  .content {
    border-right: none;
  }
`;

export const Title = styled(Fonts.h2)`
  color: ${Colors.white};
  font-size: 2.475em;
  font-weight: normal;
  font-family: "BebasNeue Book";
  line-height: 1em;
  margin: 0.3em 0 0 0;
  text-decoration: none;
  white-space: normal;
  text-shadow: none;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Summary = styled(Fonts.slideSynopsis)`
  line-height: 1em;
  color: ${Colors.white};
  margin: 0;
  padding: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
  @media ${Metrics.mediaQ.tablet} {
    white-space: normal;
    overflow: initial;
    font-size: 20px;
  }
`;

export const ToolbarButtons = styled.div`
  position: relative;
  display: inline-flex;
  gap: 15px;
  @media ${Metrics.mediaQ.tablet} {
    gap: 30px;
  }
`;

export const TextContainer = styled.div`
  max-width: 500px;
  font-size: 10px;
  @media ${Metrics.mediaQ.tablet} {
    font-size: 13px;
  }
  @media ${Metrics.mediaQ.laptop} {
    font-size: initial;
    margin-left: 180px;
  }
`;

export const LabelContainer = styled(Wp.Container)`
  margin-top: 15px;
  width: auto;
  display: inline-flex;
  color: #ffffff;
  a {
    color: #ffffff;
  }
  span {
    margin-left: 5px;
    font-size: 20px;
    text-transform: uppercase;
    display: none;
    @media ${Metrics.mediaQ.tablet} {
      display: initial;
    }
  }
`;

export const LitlePlayIcon = styled(AtIcon)`
  opacity: 1;
`;

export const SocialButtonsWrapper = styled.div`
  position: relative;
  z-index: 99;
  && {
    .generalContainerShareButtons {
      span {
        font-weight: normal;

        display: none;
        @media ${Metrics.mediaQ.tablet} {
          display: initial;
        }
      }
    }
  }
`;
