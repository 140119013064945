import { fetchApi } from "../../server/api/fetchApi";
import {
  SET_SEARCH_FOOTER,
  CHANGE_STATE_ADD_MY_LIST_BUTTON,
  CHANGE_STATE_ACTION_LIKE,
} from "../constants/action-types";
import { getKey, hasKey } from "../../app/utils/helpers";
export const SET_CURRENT_CONTENT = "content/SET_CURRENT_CONTENT";
export const SET_SEARCH_CONTENT = "content/SET_SEARCH_CONTENT";
export const SET_REFERER_CONTENT = "content/SET_REFERER_CONTENT";
export const SET_ENV_CONTENT = "content/SET_ENV_CONTENT";

const initialState = {
  currentContent: {},
  headerContent: null,
  footerContent: null,
  searchContent: {
    q: null,
    widgets: [],
  },
  redirect: {},
  myList: false,
  actionLike: null,
};

// Reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_REFERER_CONTENT:
      return {
        ...state,
        ref: action.ref,
      };
    case SET_ENV_CONTENT:
      return {
        ...state,
        env: action.env,
      };
    case SET_CURRENT_CONTENT:
      const redirect = {
        to: null,
      };
      const redirectFromSearch =
        action.content.searchContent.redirect &&
        action.content.searchContent.redirect.length > 0
          ? action.content.searchContent.redirect[0]
          : null;

      if (hasKey(redirectFromSearch, "to")) {
        redirect.to = redirectFromSearch.to;
      } else if (getKey(action, "content.currentContent.production.unitary")) {
        if (getKey(action, "content.currentContent.type") === "video") {
          redirect.to = getKey(
            action,
            "content.currentContent.production.slug",
          );
        }
      }

      return {
        ...state,
        currentContent: action.content.currentContent,
        headerContent: action.content.header || null,
        footerContent: action.content.footer || null,
        fetched: action.content.fetched || null,
        redirect,
        myList: action.content.currentContent.myList || null,
        actionLike:
          typeof action.content.currentContent.like === "undefined"
            ? null
            : action.content.currentContent.like,
      };

    case SET_SEARCH_CONTENT:
      return {
        ...state,
        searchContent: action.content.searchContent,
        headerContent: action.content.header || null,
        footerContent: action.content.footer || null,
      };
    case SET_SEARCH_FOOTER:
      return {
        ...state,
        headerContent: action.content.header || null,
        footerContent: action.content.footer || null,
      };
    case CHANGE_STATE_ADD_MY_LIST_BUTTON:
      return {
        ...state,
        myList: action.content,
      };
    case CHANGE_STATE_ACTION_LIKE:
      return {
        ...state,
        actionLike: action.content,
      };
    default:
      return state;
  }
};

export const getSearchContent = (term, page = 0, page_size = 12) => {
  return dispatch => {
    return fetchApi(
      `/api/v1/search?key=${term}&page=${page}&page_size=${page_size}`,
      false,
    ).then(content => dispatch({ type: SET_SEARCH_CONTENT, content: content }));
  };
};

export const getCurrentContent = (
  id,
  translatePath = true,
  params = { _format: "json" },
  userFromCookie,
) => dispatch =>
  fetchApi(id, translatePath, params, userFromCookie).then(content => {
    dispatch({ type: SET_CURRENT_CONTENT, content });
  });

export const removeCurrentContent = () => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_CURRENT_CONTENT,
      content: {},
    });
    resolve({});
  });

export const searchFooter = () => dispatch => {
  return fetchApi(`/api/v1/search?key=z&page=0&page_size=1`, false).then(
    content => {
      return dispatch({ type: SET_SEARCH_FOOTER, content: content });
    },
  );
};

export const setRefererToContent = ref => dispatch =>
  dispatch({ type: SET_REFERER_CONTENT, ref });
export const setEnvToContent = env => dispatch =>
  dispatch({ type: SET_ENV_CONTENT, env });
