import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { getUniqKey } from "../../../utils/helpers";
import { Wp } from "../../../theme";

import HorizontalList from "../../../components/OrHorizontalList";

import "./Podcasts.scss";

const PageWrapper = styled(Wp.Page)``;

class Podcasts extends Component {
  render() {
    const { id, titulo_paragraph, podcasts, isNinos, isEducation } = this.props;

    let customStyles = `.contentPodcasts { background:#1c1c1c; padding-bottom: 4em; border-bottom: none;}`;

    return (
      <Fragment>
        <style>{customStyles}</style>
        <div className={`contentPodcasts`}>
          <PageWrapper>
            <h3 className={`title`}>{titulo_paragraph}</h3>
            <div className={`widgetContainer`}>
              <HorizontalList
                key={getUniqKey(id)}
                contents={podcasts}
                initialSlide={-1}
                view="podcast"
                isNinos={isNinos}
                isEducation={isEducation}
                showTitle={false}
                isPodcast={true}
                className={`contentPodcasts_fotos`}
                styles={`
                padding-top: 0px !important
              `}
              />
            </div>
          </PageWrapper>
        </div>
      </Fragment>
    );
  }
}

export default Podcasts;
