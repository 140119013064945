import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";

import pageLoader from "../../../../app/layouts/pages/loader";
import { exists, getKey, isObjectEmpty } from "../../../utils/helpers";
import * as pageType from "../../../constants/pageType";
import { getCurrentContent } from "../../../../store/actions/content";
import { getEnvContent } from "../../../../store/utils/helpers";
import OrNewsletter from "../../../components/OrNewsletter";
import { OrDocumentariesPopup } from "../../../components/OrDocumentariesPopup";

const frontload = async props => {
  const { slug, brand, category } = props.match.params;

  if (slug && brand) {
    return props.getCurrentContent(slug, true, {
      brand,
      _format: "json",
    });
  } else if (slug && category) {
    return props.getCurrentContent(`medios/${slug}`, true, {
      category,
      _format: "json",
    });
  }

  return await props.getCurrentContent(slug || "home");
};

const Gateway = React.memo(
  ({ currentContent, location }) => {
    const { type } = currentContent || {};

    if (type && !exists(pageLoader[type])) {
      currentContent.error = {
        stack: 'El tipo de página "' + type + '" no existe',
      };
    }

    const isNotFound =
      type === undefined ||
      currentContent.error !== undefined ||
      isObjectEmpty(currentContent);
    const LoadablePage = pageLoader[isNotFound ? pageType.NOTFOUND : type];

    // Adding Newsletter Campaign Data
    const newsletterCampaign = getKey(currentContent, "newsletter_campaign");
    return (
      <>
        <LoadablePage currentContent={currentContent} location={{ location }} />
        {newsletterCampaign && <OrNewsletter {...newsletterCampaign} />}
        {<OrDocumentariesPopup slug={currentContent.slug} />}
      </>
    );
  },
  (prevProps, nextProps) => {
    if (
      nextProps.match.params.slug !== prevProps.match.params.slug ||
      nextProps.currentContent.id !== prevProps.currentContent.id
    ) {
      nextProps.getCurrentContent(nextProps.match.params.slug || "home");
      return getEnvContent() === "production";
    }
    return getEnvContent() === "local";
  },
);

const mapStateToProps = state => ({
  currentContent: state.content.currentContent,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getCurrentContent }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    noServerRender: false,
    onMount: true,
    onUpdate: false,
  })(Gateway),
);
