import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors, Metrics } from "../../theme";
import AtIcon from "../AtIcon/AtIcon";
import SocialButtons from "../MolShareButton";
import TplTitleBlock from "../TplTitleBlock/TplTitleBlock";
import "./MolProdInfo.scss";
import MolTrailerPlayer from "../MolTrailerPlayer";

const MolProdInfoTrailer = ({
  seccion,
  frase,
  etiqueta,
  titulo,
  descripcion,
  path,
  trailer,
  reproductor
}) => {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    const updateWidth = () => {
      const width = document.body.clientWidth;
      setWidth(width);
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
  });
  const maxWidth = Metrics.size.maxPageWidth.replace("px", "");
  const left = parseInt((width - maxWidth) / 2);
  const Wrapper = styled.div`
    .inner-container {
      padding-left: 0;
    }
    @media ${Metrics.mediaQ.laptop} {
      max-width: ${Metrics.size.maxPageWidth};
      margin-left: ${left}px;
    }
  `;
  const Container = styled.div`
    color: ${Colors.white};
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 30px;
    @media ${Metrics.mediaQ.mobileS} {
      flex-direction: column;
      justify-content: space-around;
    }
    @media ${Metrics.mediaQ.tablet} {
      flex-direction: column;
      justify-content: space-around;
    }
    @media ${Metrics.mediaQ.laptop} {
      flex-direction: row;
      justify-content: center;
    }
  `;
  const Trailer = styled.div`
    display: inline-block;
    @media ${Metrics.mediaQ.mobileS} {
      width: 90%;
    }
    @media ${Metrics.mediaQ.tablet} {
      width: 90%;
    }
    @media ${Metrics.mediaQ.laptop} {
      width: 50%;
    }
  `;
  const Information = styled.div`
    display: "flex";
    flex-direction: "column";
    font-size: "3rem";
    @media ${Metrics.mediaQ.mobileS} {
      width: 90%;
    }
    @media ${Metrics.mediaQ.tablet} {
      width: 90%;
    }
    @media ${Metrics.mediaQ.laptop} {
      width: 50%;
    }
  `;
  const TitleBlock = styled(TplTitleBlock)`
    background-color: ${props => props.backgroundColor};
    padding-top: ${props => (props.showTitle ? 0.75 : 0)}em;
    padding-bottom: ${props => (props.showTitle || props.isNinos ? 0.75 : 0)}em;
    border-top: ${props => props.borderTop};
    .block-body {
      padding-top: 1em;
    }
    ${props => props.customStyles || ""};
  `;

  const Frase = styled.div`
    font-style: oblique;
    font-family: "Dosis", sans-serif;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 60px;
    text-align: justify;
    @media ${Metrics.mediaQ.mobileS} {
      width: 90%;
    }
    @media ${Metrics.mediaQ.tablet} {
      width: 90%;
    }
    @media ${Metrics.mediaQ.laptop} {
      width: 90%;
    }
  `;
  const Etiqueta = styled.div`
    background: #3fbfb2;
    font-family: "Dosis", sans-serif;
    font-size: 20px;
    color: #fff;
    padding-left: 13px;
    padding-right: 13px;
    text-align: center;
    @media ${Metrics.mediaQ.mobileS} {
      width: 20%;
    }
    @media ${Metrics.mediaQ.tablet} {
      width: 15%;
    }
    @media ${Metrics.mediaQ.laptop} {
      width: 13%;
    }
  `;
  const Titular = styled.div`
    font-family: "BebasNeue Book", sans-serif;
    text-transform: uppercase;
    font-size: 42px;
    color: #fff;
    text-align: left;
    margin-top: 10px;
    @media ${Metrics.mediaQ.mobileS} {
      font-size: 42px;
    }
    @media ${Metrics.mediaQ.laptop} {
      font-size: 42px;
    }
  `;
  const Descripcion = styled.div`
    color: #fff;
    margin-top: 18px;
    text-align: justify;
    width: 90%;
    @media ${Metrics.mediaQ.mobileS} {
      font-size: 18px;
    }
    @media ${Metrics.mediaQ.laptop} {
      font-size: 18px;
    }
  `;
  const NowButton = styled.div`
    background: ${Colors.transparent};
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
  `;
  const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    padding-bottom: 20px;
  `;
  return (
    <Wrapper>
      <Container>
        <Information>
          <TitleBlock
            id={1121}
            title={seccion}
            animateTitle={true}
            showTitle={true}
            isGridMode={false}
          />
          <Frase>{frase}</Frase>
          <Etiqueta>{etiqueta}</Etiqueta>
          <Titular>{titulo}</Titular>
          <Descripcion>{descripcion}</Descripcion>
          <ButtonContainer>
            <a href={path} style={styles.links}>
              <NowButton>
                <AtIcon name={"PlayCircle"} size={32} color={"#FFF"} />
                <div style={styles.buttonText}>&nbsp;VER SERIE</div>
              </NowButton>
            </a>
            <div className="links">
              <ul>
                <li>
                  <SocialButtons
                    label="COMPARTIR"
                    title={titulo}
                    text={descripcion}
                    size={32}
                    sizeButtons={18}
                    uri={path}
                  />
                </li>
              </ul>
            </div>
          </ButtonContainer>
        </Information>
        <Trailer>
          <MolTrailerPlayer
            title={titulo}
            trailer={trailer}
            streaming_provider={reproductor}
          />
        </Trailer>
      </Container>
    </Wrapper>
  );
};

export default MolProdInfoTrailer;

const styles = {
  container: {
    flex: 1,
    fontFamily: "Dosis"
  },
  buttonText: {
    color: "#FFF",
    fontSize: "1rem",
    fontWeight: "bold"
  },
  links: {
    textDecoration: "None"
  },
  imgContainer: {
    position: "relative"
  }
};
