import styled, { createGlobalStyle } from "styled-components";

import Colors from "./Colors";

const H1 = styled.h1`
  font-family: "BebasNeue Regular", sans-serif;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 2.5rem;
  font-display: fallback;
  margin: 0;
  color: ${Colors.white};
`;
const H2 = styled.h2`
  font-family: "BebasNeue Regular", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2rem;
  font-display: fallback;
  margin: 0;
  color: ${Colors.white};
`;
const H3 = styled.h3`
  font-family: "BebasNeue Regular", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5rem;
  font-display: fallback;
  margin: 0;
  color: ${Colors.white};
`;
const H4 = styled.h4`
  font-family: "BebasNeue Regular", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25rem;
  font-display: fallback;
  margin: 0;
  color: ${Colors.white};
`;
const H5 = styled.h5`
  font-family: "BebasNeue Regular", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
  font-display: fallback;
  margin: 0;
  color: ${Colors.white};
`;
const A = styled.a`
  font-family: "Dosis", sans-serif;
  font-display: fallback;
  font-size: 1.25rem;
  font-weight: 600;
  color: ${Colors.white};
  text-decoration: none;
  :active {
    text-decoration: none;
  }
  :hover {
    text-decoration: underline;
  }
`;
const Span = styled.span`
  font-family: "Dosis", sans-serif;
  font-display: fallback;
  font-size: 1rem;
  font-weight: normal;
  color: ${Colors.white};
`;
const P = styled.p`
  font-family: "Dosis", sans-serif;
  font-display: fallback;
  font-size: 1rem;
  font-weight: normal;
  color: ${Colors.white};
`;
const Input = styled.input`
  font-family: "Dosis", sans-serif;
  font-display: fallback;
  font-size: 1rem;
  font-weight: normal;
  color: ${Colors.white};
`;
export default {
  global: createGlobalStyle`      
    body {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    * {
      font-size: 16px;
    }
	`,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  a: A,
  span: Span,
  p: P,
  input: Input,
  slideTitle: styled(H2)`
    font-family: "BebasNeue Regular", sans-serif;
    font-display: fallback;
    font-size: 3rem;
    font-weight: normal;
    color: ${Colors.white};
  `,
  slideLabel: styled(Span)`
    font-family: "Dosis", sans-serif;
    font-display: fallback;
    font-weight: 500;
    font-size: 1.25rem;
    color: ${Colors.white};
  `,
  slideSynopsis: styled(P)`
    font-family: "Dosis", sans-serif;
    font-display: fallback;
    font-weight: 500;
    font-size: 1.125rem;
    color: ${Colors.white};
  `,
  liveStreamingLabel: styled(H2)`
    font-family: "Dosis", sans-serif;
    font-display: fallback;
    font-weight: 600;
    font-size: 1.25rem;
  `,
  liveStreamingTitle: styled(H1)`
    font-family: "BebasNeue Book", sans-serif;
    font-display: fallback;
    font-size: 2rem;
    font-weight: normal;
    color: ${Colors.white};
  `,
  liveStreamingSubtitle: styled(H3)`
    font-family: "Dosis", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: ${Colors.white};
  `,
  liveStreamingSynopsis: styled(P)`
    font-family: "Dosis", sans-serif;
    font-display: fallback;
    font-weight: 500;
    font-size: 1.125rem;
    color: ${Colors.snow};
  `,
  blockTitle: styled(H2)`
    font-family: "Dosis", sans-serif;
    font-display: fallback;
    font-weight: 600;
    font-size: 1.375rem;
    color: ${Colors.snow};
  `,
  rating: styled(Span)`
    font-family: "Dosis", sans-serif;
    font-display: fallback;
    font-size: 1rem;
    font-weight: 600;
    color: ${Colors.white};
  `,
  infoHoverCategory: styled(P)`
    font-family: "Dosis", sans-serif;
    font-display: fallback;
    font-size: 1em;
    font-weight: 300;
  `,
  infoHoverTitle: styled(H3)`
    font-family: "Dosis", sans-serif;
    font-display: fallback;
    font-size: 1.375em;
    font-weight: 600;
    text-shadow: 0px 4px 4px rgba(4, 0, 6, 0.35);
  `,
  infoHoverSynopsis: styled(P)`
    font-family: "Dosis", sans-serif;
    font-display: fallback;
    font-size: 1em;
    font-weight: 400;
  `,
  infoHoverChapters: styled(P)`
    font-family: "Dosis", sans-serif;
    font-display: fallback;
    font-size: 1em;
    font-weight: 700;
  `,
  infoFooterTitle: styled(H3)`
    font-family: "Dosis", sans-serif;
    font-display: fallback;
    font-size: 1em;
    font-weight: 700;
  `,
  infoFooterSubTitle: styled(H4)`
    font-family: "Dosis", sans-serif;
    font-display: fallback;
    font-size: 1.25em;
    font-weight: 300;
  `,
  infoFooterMicroSynopsis: styled(H5)`
    font-family: "Dosis", sans-serif;
    font-display: fallback;
    font-size: 1em;
    font-weight: 300;
  `,
  infoFooterDuration: styled(Span)`
    font-family: "Dosis", sans-serif;
    font-display: fallback;
    font-size: 1.15em;
    font-weight: 300;
  `,
  shareButton: styled(Span)`
    font-size: 1em;
    align-items: center;
    margin-left: 3px;

    @media screen and (min-width: 768px) {
      font-weight: bold;
    }
  `,
  cookieAlert: styled(P)`
    font-size: 1.125em;
    font-family: "Dosis";
    font-display: fallback;
    line-height: 1.056;
  `,
  podcastTitle: styled(H2)`
    font-family: "BebasNeue Book", sans-serif;
    font-display: fallback;
    font-size: 1.725rem;
    font-weight: normal;
    color: ${Colors.white};
  `,
  audioTitle: styled(H5)`
    font-family: "Dosis", sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    color: ${Colors.white};
  `,
  audioDescription: styled.div`
    font-family: "Dosis", sans-serif;
    color: #a5a5a5;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
  `,
};
