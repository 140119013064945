import React from "react";
import HorizontalList from "../../../components/OrHorizontalList";
import { getUniqKey } from "../../../utils/helpers";

export const ElencoMobile = ({
  personajes,
  streaming_provider,
  isNinos,
  isEducation
}) => {
  return (
    <HorizontalList
      key={getUniqKey()}
      contents={personajes}
      initialSlide={-1}
      view="personaje"
      isNinos={isNinos}
      isEducation={isEducation}
      isVideo={true}
      streaming_provider={streaming_provider}
      showTitle={false}
      className={`contentEntrevistas_video`}
    />
  );
};
