import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";
import styled from "styled-components";

import { Colors, Fonts } from "../../../theme";
import { Close } from "../../AtButton";
import AtIcon from "../../AtIcon/AtIcon";

const currentId = "sliderDropDownFilter";

export const DropDownFilter = ({ data }) => {
  const [isOpen, setOpen] = useState(false);

  const toggleButton = e => {
    if (e.type === "focusout") {
      setTimeout(() => setOpen && setOpen(!isOpen), 100);
    }
  };

  const DropDownClick = e => {
    e.preventDefault();
    e.currentTarget.focus();

    setOpen(true);
  };

  return (
    <>
      <Wrapper id={currentId} onClick={DropDownClick} type="button">
        <Text>Categorías/Temas</Text>
        <ArrowIcon name="ArrowDropDown" size={24} />
      </Wrapper>

      <Tooltip
        className="DropDownFiler"
        placement="bottom-start"
        isOpen={isOpen}
        target={currentId}
        toggle={toggleButton}
      >
        <Close onClick={() => setOpen && setOpen(false)} />
        <ToolTipMessage>
          {data.length > 0 ? (
            data.map(({ name, url }) => (
              <Link
                href={url}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {name}
              </Link>
            ))
          ) : (
            <span>No hay Categorías</span>
          )}
        </ToolTipMessage>
      </Tooltip>
    </>
  );
};

const Wrapper = styled(Button)`
  border: 1px solid #73fbfd;
  background-color: black;
  color: white;
  border-radius: 50px;
  padding: 2px 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 10px 0 #73fbfd;
  }
`;

const ArrowIcon = styled(AtIcon)`
  margin: auto 0;
`;

const Text = styled(Fonts.blockTitle)`
  position: relative;
  font-weight: 400;
`;

const Link = styled.a`
  font-family: "Dosis", sans-serif;
  font-display: fallback;
  font-weight: 600;
  font-size: 1.2rem;
  color: ${Colors.snow};
  text-decoration: none;
  text-align: left;
  font-weight: 400;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 767px) {
    text-align: center;
    border-bottom: 1px solid #333;
    padding: 6px;
    font-size: 1.5rem;
  }
`;

export const ToolTipMessage = styled.div`
  color: #fff;
  font-size: 18px;
  font-family: "Dosis", serif;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 0;

  max-height: 450px;
  overflow: hidden;
  overflow-y: auto;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    padding: 10px 15px;
    margin-top: unset;
    max-height: unset;
    overflow: unset;
  }
`;
