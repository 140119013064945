import React, { useContext, createContext, useState, useEffect } from "react";

export const LoadMoreContext = createContext({
  hasContents: [],
  setHasContents: () => {},
  isOpen: false,
  setOpen: () => {},
});

export const useLoadMore = () => {
  return useContext(LoadMoreContext);
};

const LoadMoreProvider = ({ widgets = [], children }) => {
  const [hasContents, setHasContents] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setHasContents(widgets.some(({ load_more }) => load_more));
  }, [widgets]);

  return (
    <LoadMoreContext.Provider
      value={{
        hasContents,
        setHasContents,
        isOpen,
        setOpen,
        isLoading,
        setLoading,
      }}
    >
      {children}
    </LoadMoreContext.Provider>
  );
};

export default LoadMoreProvider;
