import Cookies from "js-cookie";

import {
  fetchAuthTokenApi,
  fetchAuthMeApi,
  pushNewUser,
  pushImageNewUser,
  fetchGeneralPreferences,
  fetchUpdateUser,
  fetchForgotPassword,
  fetchUpdatePassword,
  fetchNewsletter
} from "../../server/api/fetchApi";
import { Store } from "../store";
import { encrypt, decrypt } from "../../app/utils/helpers/crypto";
import { getCurrentFrontEndDomain } from "../utils/helpers";
import { getKey } from "../../app/utils/helpers";
import { addNewsletterCookie } from "../../app/utils/helpers/cookies";

export const AUTHENTICATE = "auth/AUTHENTICATE";
export const CURRENT_USER = "auth/CURRENT_USER";
export const USER_KEY_LOCAL_STORAGE = "RTVCPLAYUSER";
export const USER_PREFERENCES_KEY_LOCAL_STORAGE = "RTVCPLAYUSERP";
export const USER_TOKEN_KEY_LOCAL_STORAGE = "RTVCPLAYUSERT";
export const KEY_AUTH = "rtvcplay-dontshareme";
export const USER_NEWSLETTER = "RTVCPLAYUSERNEWSLETTER¯˚˜«";

const initialState = {
  authenticated: false,
  user: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      return {
        ...state,
        authenticated: action.authenticated
      };

    case CURRENT_USER:
      return {
        ...state,
        user: action.user
      };

    default:
      return state;
  }
};

export const loadUserFromCookies = req => dispatch => {
  let user = undefined;

  if (req) {
    const userKeyCookie = getKey(
      req,
      `cookies.${USER_KEY_LOCAL_STORAGE}`,
      null
    );
    const tokenUserCookie = getKey(
      req,
      `cookies.${USER_TOKEN_KEY_LOCAL_STORAGE}`,
      null
    );
    const preferencesUserCookie = getKey(
      req,
      `cookies.${USER_PREFERENCES_KEY_LOCAL_STORAGE}`,
      null
    );
    const userData = userKeyCookie && JSON.parse(userKeyCookie);
    const tokenData = tokenUserCookie && JSON.parse(tokenUserCookie);
    const preferencesData =
      preferencesUserCookie && JSON.parse(preferencesUserCookie);

    if (userData) {
      user = {
        ...userData,
        tokenData,
        preferences: preferencesData || {}
      };
    }
  } else {
    const userKeyLocaStorage = Cookies.get(USER_KEY_LOCAL_STORAGE);
    const tokenKeyLocaStorage = Cookies.get(USER_TOKEN_KEY_LOCAL_STORAGE);
    const preferencesCookie = Cookies.get(USER_PREFERENCES_KEY_LOCAL_STORAGE);
    if (userKeyLocaStorage) {
      const userData = userKeyLocaStorage && JSON.parse(userKeyLocaStorage);
      const tokenData = tokenKeyLocaStorage && JSON.parse(tokenKeyLocaStorage);
      const preferencesData =
        preferencesCookie && JSON.parse(preferencesCookie);
      if (userData) {
        user = {
          ...userData,
          tokenData,
          preferences: preferencesData || {}
        };
      }
    }
  }

  if (!!user) {
    dispatch({
      type: CURRENT_USER,
      user: user
    });
    dispatch({
      type: AUTHENTICATE,
      authenticated: true
    });
  }
  return user;
};

export const setUser = (user, pwdLogin, token_info) => dispatch => {
  const {
    id,
    mail,
    full_name,
    image,
    birthdate,
    cell_phone_number,
    gender,
    preferences,
    password,
    from_social_network,
    image_social
  } = user || {};

  const userMinified = {
    id,
    mail,
    full_name,
    image,
    birthdate,
    cell_phone_number,
    gender,
    password,
    from_social_network,
    image_social
  };
  if (pwdLogin !== undefined) {
    user.password = userMinified.password = encrypt(pwdLogin, KEY_AUTH);
  }

  const expireInDays =
    token_info.expires_in && token_info.expires_in !== 0
      ? token_info.expires_in / 86400
      : undefined;
  const options = {
    Domain: getCurrentFrontEndDomain(),
    expires: expireInDays
  };

  Cookies.set(USER_KEY_LOCAL_STORAGE, userMinified, options);
  Cookies.set(USER_PREFERENCES_KEY_LOCAL_STORAGE, preferences, options);
  Cookies.set(USER_TOKEN_KEY_LOCAL_STORAGE, token_info, options);

  dispatch({
    type: AUTHENTICATE,
    authenticated: true
  });
  dispatch({
    type: CURRENT_USER,
    user: { ...user, tokenData: token_info }
  });

  return user;
};

export const addNewsletter = (email, campaignId, categoryId, campaignTextId) =>
  fetchNewsletter(email, campaignId, categoryId)
    .then(resultAuth => {
      if (resultAuth.status === 201) {
        addNewsletterCookie(campaignTextId, email);
      }
      return resultAuth.status ? resultAuth : { ...resultAuth, status: 500 };
    })
    .catch(err => {
      return { ...err, status: 500 };
    });

export const loginUser = (email, password) => dispatch =>
  fetchAuthTokenApi(email, password).then(resultAuth => {
    if (!resultAuth.error) {
      return fetchAuthMeApi(resultAuth.access_token)
        .then(user => {
          dispatch(setUser(user, password, resultAuth));
          return user;
        })
        .catch(err => {
          return err;
        });
    }
    return resultAuth;
  });

export const logoutUser = (withRedirect = true) => dispatch => {
  const options = {
    Domain: getCurrentFrontEndDomain()
  };

  Cookies.remove(USER_KEY_LOCAL_STORAGE, options);
  Cookies.remove(USER_PREFERENCES_KEY_LOCAL_STORAGE, options);
  Cookies.remove(USER_TOKEN_KEY_LOCAL_STORAGE, options);

  dispatch(removeUser());

  if (withRedirect && global.window) {
    global.window.location.href = "/";
  }
  return null;
};

export const removeUser = () => dispatch => {
  dispatch({
    type: AUTHENTICATE,
    authenticated: false
  });

  dispatch({
    type: CURRENT_USER,
    user: null
  });
  return null;
};

export const forgotPassword = email => _ =>
  fetchForgotPassword(email).then(result => result);

export const updatePassword = data => _ =>
  fetchUpdatePassword(data).then(result => result);

export const signUpUser = async user => {
  return await pushNewUser(user).then(resultSignUp => {
    return resultSignUp;
  });
};
export const updateUser = async (user, currentUser) => {
  return await fetchUpdateUser(user, currentUser).then(resultUpdate => {
    if (!resultUpdate.error) {
      Store.dispatch(
        loginUser(currentUser.mail, decrypt(currentUser.password, KEY_AUTH))
      );
    }
    return resultUpdate;
  });
};
export const fetchAllPreferences = async () => {
  return await fetchGeneralPreferences().then(result => result);
};

export const uploadImageForUser = async file => {
  return await pushImageNewUser(file).then(resultSignUp => {
    return resultSignUp;
  });
};
