import React from "react";
import { Container, Day, Number } from "./AtDate";

const Index = ({ active, fetchDataGrill, fecha, date, dia, num }) => {
  return (
    <Container
      active={active}
      onClick={() => {
        fetchDataGrill(date);
      }}
    >
      <Day>{dia}</Day>
      <Number>{num}</Number>
    </Container>
  );
};

export default Index;
