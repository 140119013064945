import React, { Component } from "react";

import "./unitary.scss";
import Video from "../../../components/OrVideo";

class Unitary extends Component {
  infoDetail(e) {
    e.preventDefault();
    global.window.document
      .querySelector(".contentDetailInfo")
      .classList.toggle("display");
  }
  render() {
    const { currentContent } = this.props;
    return (
      <div className="wrapperUnitary">
        <Video {...currentContent} />
      </div>
    );
  }
}

export default Unitary;
