import React, { Component } from "react";
import classnames from "classnames";
import styled from "styled-components";
import { rgba } from "polished";

import AtBadge from "../AtBadge/AtBadge";
import AtImgLink from "../AtImgLink/AtImgLink";
import MolPlayer from "../MolPlayer/MolPlayer";
import MolBrandContainer from "../MolBrandContainer/MolBrandContainer";
import TplSwiper from "../TplSwiper/TplSwiper";

import { Wp, Images, Metrics, Fonts, Colors } from "../../theme";
import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import MolGrill from "../MolGrill/MolGrill";
import Index from "../MolCalendar";
import { bindActionCreators } from "redux";
import { updateGrill } from "../../../store/actions/grill";

const MAX_WIDTH = 1024;

const Player = styled(MolPlayer)`
  .inner-container {
    width: 100%;
    left: 0;
    bottom: 0;
  }
  .react-player {
    top: -1px;
    left: -1px;
  }
  @media ${Metrics.mediaQ.tablet} {
    .inner-container {
      width: ${768}px;
      left: calc(50% - ${768 / 2}px);
    }
  }
  @media ${Metrics.mediaQ.laptop} {
    padding-top: ${100 / (21 / 9)}%;
    .cover {
      padding-top: ${100 / (21 / 9)}%;
    }
    .inner-container {
      width: ${MAX_WIDTH}px;
      left: calc(50% - ${MAX_WIDTH / 2}px);
    }
  }
  @media (max-width: ${Metrics.size.laptop}) and (orientation: landscape) {
    padding-top: ${100 / (21 / 9)}%;
    .cover {
      padding-top: ${100 / (21 / 9)}%;
    }
    .inner-container {
      width: ${768}px;
      left: calc(50% - ${768 / 2}px);
    }
  }
  @media (max-width: ${Metrics.size.tablet}) and (orientation: landscape) {
    padding-top: ${100 / (21 / 9)}%;
    .cover {
      padding-top: ${100 / (21 / 9)}%;
    }
    .inner-container {
      width: ${568}px !important;
      left: calc(50% - ${568 / 2}px);
    }
  }
`;
const Title = styled(Fonts.h1)`
  color: ${Colors.white};
  font-size: 2.875rem;
  margin: 0.5rem 0;
`;
const Container = styled(Wp.Container)``;
const ChannelsContainer = styled(TplSwiper)`
  .swiper-wrapper {
    align-items: flex-end;
  }
`;
const ChannelWrapper = styled(Wp.Container)`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 1em 3em 2em;
  .brand-container {
    max-width: 100px;
    &.en-vivoradionica {
      max-width: 90px;
    }
  }
  &.swiper-slide {
    width: 100%;
    @media ${Metrics.mediaQ.tablet} {
      padding: 0 0 60px 0;
      width: ${props => props.widthTablet}px !important;
      align-items: flex-end;
    }
    @media ${Metrics.mediaQ.laptop} {
      padding: 0 0 60px 0;
      width: ${props => props.widthLaptop}px !important;
      align-items: flex-end;
    }
    @media (max-width: ${Metrics.size.laptop}) and (orientation: landscape) {
      padding: 0 0 30px 0;
      width: ${props => props.widthTablet}px !important;
      align-items: flex-end;
    }
    @media (max-width: ${Metrics.size.tablet}) and (orientation: landscape) {
      padding: 0 0 30px 0;
      width: ${props => props.widthMobileLandscape}px !important;
      align-items: flex-end;
    }
  }
`;
const GradientBg = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 50%;
  left: 0;
  bottom: -1px;
  background: linear-gradient(
    to bottom,
    ${rgba(Colors.bg2, 0)} 0%,
    ${rgba(Colors.bg2, 1)} 100%
  );
`;

const BrandContainer = styled(MolBrandContainer)`
  .brand {
    img {
      max-width: 100%;
    }
  }
  .title {
    font-size: 2.5em;
    line-height: 1em;
    @media ${Metrics.mediaQ.mobileM} {
      font-size: 2.875em;
    }
  }
`;
const Badge = styled(AtBadge)`
  display: inline-block !important;
`;

const Parrilla = styled(Wp.Page)`
  margin-top: ${Metrics.size.minHeaderHeight};
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  width: calc(100% - 2.5rem);
  @media ${Metrics.mediaQ.tablet} {
    margin-top: ${Metrics.size.maxHeaderHeight};
    padding: 0;
  }
`;
var fechahoy = new Date();
const fecha =
  fechahoy.getDate() +
  "/" +
  (fechahoy.getMonth() + 1) +
  "/" +
  fechahoy.getFullYear();
var calendario = [];
var fechainicial = new Date();
fechainicial.setDate(fechainicial.getDate() - 7);
var fechaf = fechainicial;
for (var f = 1; f <= 15; f++) {
  fechaf.setDate(fechaf.getDate() + 1);
  var fechaa =
    fechaf.getDate() +
    "/" +
    (fechaf.getMonth() + 1) +
    "/" +
    fechaf.getFullYear();
  calendario.push({
    key: "day" + f,
    value: fechaa
  });
}

const frontload = async props =>
  await props.updateGrill(fecha, props.data.channel.id);

class OrLivestreamingRadio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  isSafari = false;
  state = { componentDidMount: false };
  _imageAttr = image => {
    if (image.cover_mobile && image.cover_desktop) {
      return {
        cover: [
          {
            "": image.cover_mobile.path
          },
          {
            "(min-width: 1024px)": image.cover_desktop.path
          },
          {
            [`(max-width: ${
              Metrics.size.laptop
            }) and (orientation: landscape)`]: image.cover_desktop.path
          }
        ],
        coverResposive: true
      };
    }
    return { imageSource: Images.coverTest };
  };
  _mediaAttr = () => {
    return {
      config: {
        file: {
          attributes: {
            autoPlay: true,
            controls: false,
            muted: true,
            playsInline: true
          },
          forceHLS: !this.isSafari
        }
      }
    };
  };
  componentDidMount() {
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    this.setState({ componentDidMount: true });
  }
  render() {
    const {
      data: { channel, resource, other_channels },
      grill: { grill }
    } = this.props;

    let items = [];
    if (other_channels !== null) {
      items.push({ ...channel, main: true });
      for (let i = 0; i < other_channels.length; i++) {
        items.push(other_channels[i]);
      }
    }
    let presentar = false;
    if (grill.length > 0) {
      presentar = true;
    }
    return (
      <div>
        <Container>
          {this.state.componentDidMount && (
            <Player
              aspectRatio="12-13"
              altText
              {...this._imageAttr(resource.image)}
              {...this._mediaAttr()}
              url={channel.hls}
              coverOverflow
              showAudioButton
              showProgressBar={false}
              width="1px"
              height="1px"
            >
              <ChannelsContainer
                ref="swiperContainer"
                responsive={false}
                swiperParams={{
                  slidesPerView: "auto",
                  autoplay: false
                }}
              >
                {items.map((item, index) => (
                  <ChannelWrapper
                    key={index}
                    widthLaptop={
                      MAX_WIDTH *
                      (item.main ? 40 / 100 : 60 / (items.length - 1) / 100)
                    }
                    widthTablet={
                      768 *
                      (item.main ? 40 / 100 : 60 / (items.length - 1) / 100)
                    }
                    widthMobileLandscape={
                      568 *
                      (item.main ? 40 / 100 : 60 / (items.length - 1) / 100)
                    }
                    count={items.length}
                  >
                    {item.main ? (
                      <BrandContainer
                        brand={item.image.logo.path}
                        brandAlt={item.image.logo.alt}
                        brandTitle={item.image.logo.title}
                      >
                        <Badge className={item.brand.class} el="h3">
                          {item.label}
                        </Badge>
                        <Title className="title">{item.title}</Title>
                      </BrandContainer>
                    ) : (
                      <AtImgLink
                        className={classnames([
                          "brand-container",
                          `${item.slug.replace(/\//g, "")}`
                        ])}
                        href={item.slug}
                        imageSource={item.image.logo_negative.path}
                        imageAlt={item.image.logo_negative.alt}
                      />
                    )}
                  </ChannelWrapper>
                ))}
              </ChannelsContainer>
            </Player>
          )}
          <GradientBg />
        </Container>
        {presentar && (
          <Parrilla>
            <Index
              fechas={calendario}
              activar={presentar}
              canal={this.props.data.channel.id}
            />
            <MolGrill program={grill} />
          </Parrilla>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  grill: state.grill
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateGrill }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    noServerRender: false,
    onMount: true,
    onUpdate: false
  })(OrLivestreamingRadio)
);
