import React, { Component } from "react";
import styled from "styled-components";

import AtImg from "../AtImg/AtImg";

import { Metrics } from "../../theme";

const LogoLink = styled.a`
  display: block;

  .logo {
    width: auto !important;
    max-width: initial !important;
    height: 40px;
    @media ${Metrics.mediaQ.tablet} {
      height: 50px;
    }
    transition: height 0.5s ease;
  }
`;
class AtLogo extends Component {
  static defaultProps = {
    href: "/",
    alt: "RTVCPlay",
    linkable: true
  };
  render() {
    const { href, alt, className, expanded, linkable } = this.props;
    return (
      <LogoLink
        className={className}
        href={linkable ? href : null}
        headerExpanded={expanded}
      >
        <AtImg
          className="logo"
          src="https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/field/image/banners/logo-RTVCPlay-Header.png"
          alt={alt}
          withLazyLoad={false}
        />
      </LogoLink>
    );
  }
}

export default AtLogo;
