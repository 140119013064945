import "@babel/polyfill";
import React from "react";
import { render, hydrate } from "react-dom";
import { Provider } from "react-redux";
import Loadable from "react-loadable";
import { Frontload } from "react-frontload";
import { ConnectedRouter } from "connected-react-router";
import ReactPixel from "react-facebook-pixel";
import { Store, History } from "./store/store";
import { dispatchCookiesToStore } from "./app/utils/helpers/cookies";
import App from "./app";
import "./index.scss";
import {
  getCurrentFrontEndEnvironmentUrl,
  getEnvContent,
} from "./store/utils/helpers";
import { getCurrentContent } from "./store/actions/content";

dispatchCookiesToStore();

const options = {
  debug: false,
  autoConfig: true,
};
ReactPixel.init("183953025695245", {}, options);
ReactPixel.pageView();
ReactPixel.fbq("track", "PageView");

// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
// Let's also let React Frontload explicitly know we're not rendering on the server here
const Application = (
  <Provider store={Store}>
    <ConnectedRouter history={History}>
      <Frontload noServerRender={getEnvContent() === "local"}>
        <App reactPixel={ReactPixel} />
      </Frontload>
    </ConnectedRouter>
  </Provider>
);

const root = document.querySelector("#root");
if (root.hasChildNodes() === true) {
  // If it's a SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrate(Application, root);
  });
} else {
  const currentContent = Store.getState().content.currentContent;
  if (!!currentContent && Object.keys(currentContent).length > 0) {
    render(Application, root);
  } else {
    const path =
      History.location.pathname === "/" ? "/home" : History.location.pathname;

    const skipPages = [
      "/login",
      "/buscar",
      "/no-disponible",
      "/user/reset",
      "/logout",
      "/login/recordar-contrasena",
      "/bienvenido",
      "/registro",
      "/registro",
      "/mi-perfil",
      "/mi-lista",
    ];
    if (skipPages.find(page => path.startsWith(page))) {
      render(Application, root);
    } else if (path.startsWith("/embed")) {
      const currentEnv = getEnvContent() === "local" ? "qa" : getEnvContent();
      const baseUrl = getCurrentFrontEndEnvironmentUrl(currentEnv);

      const currentBody = document.querySelector("body");
      currentBody.style.height = "100vh";
      render(
        <iframe
          src={`${baseUrl}${path}`}
          width="100%"
          height="100%"
          style={{ border: "none" }}
          title="External Page"
        />,
        currentBody,
      );
    } else {
      Store.dispatch(getCurrentContent(path)).then(() => {
        render(Application, root);
      });
    }
  }
  // If we're not running on the server, just render like normal
}
