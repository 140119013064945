/**
 * Represents the audio view
 * @type {string}
 */
export const PODCAST_VIEW = "posteraudio";

/**
 * Represents the cover view
 * @type {string}
 */
export const COVER_VIEW = "cover";
