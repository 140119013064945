import React, { Component } from "react";

import Index from "../../../components/OrHorizontalList";

import "./HorizontalList.scss";

class HorizontalList extends Component {
  render() {
    return <Index {...this.props} />;
  }
}

export default HorizontalList;
