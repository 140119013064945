import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { rgba } from "polished";

import AtImg from "../AtImg/AtImg";
import AtBadge from "../AtBadge/AtBadge";
import AtIcon from "../../../app/components/AtIcon/AtIcon";

import MolBrandContainer from "../MolBrandContainer/MolBrandContainer";
import MolShareButton from "../MolShareButton";

import { Colors, Fonts, Metrics } from "../../theme";

import "./MolModalVideo.scss";
import MolTrailerPlayer from "../MolTrailerPlayer";

const Img = styled(AtImg)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  img {
    width: 100%;
  }
`;

const InfoHoverContainer = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  opacity: 0;
  @media ${Metrics.mediaQ.laptop} {
    :hover {
      opacity: 1;
    }
  }
  transition: opacity 100ms ease-in;
  ${props => props.customStyles || ""};
`;

const InfoHoverBody = styled.a`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 8em;
  width: calc(100% - 2em);
  height: calc(100% - ${isMobile ? "8em" : "12em"});
  overflow: hidden;
  text-decoration: none;
  @media ${Metrics.mediaQ.tablet} {
    bottom: 3em;
    padding-left: 10%;
    padding-right: 10%;
    width: 80%;
  }
`;
const InfoHoverActions = styled.div`
  position: absolute;
  left: 0.5em;
  bottom: 0;
  width: calc(100% - 1em);
  height: 3em;
  border-top: 1px solid ${Colors.gray};
  padding: 0 0.5em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;
const InfoHoverCategory = styled(Fonts.infoHoverCategory)`
  margin: 0;
`;
const InfoHoverTitle = styled(Fonts.infoHoverTitle)`
  margin: 0;
`;
const InfoHoverSynopsis = styled(Fonts.infoHoverSynopsis)`
  margin: 1em 0;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const InfoHoverChapters = styled(Fonts.infoHoverChapters)`
  margin-bottom: 1em;
`;

const InfoFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  ${props => {
    if (props.scale === 1) {
      return `
        font-size: 14px;
        @media ${Metrics.mediaQ.tablet} {
          font-size: 16px;
        }
      `;
    }
    return `font-size: ${16 * props.scale}px;`;
  }};
`;
const InfoFooterTitle = styled(Fonts.infoFooterTitle)`
  display: block;
  line-height: initial;
  width: 100%;
  margin-top: 0.3em;
  .link {
    display: block;
    width: ${props => props.width};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-decoration: none;
    color: ${Colors.white};
    font-size: inherit;
    font-weight: inherit;
    :active,
    :visited {
      text-decoration: none;
      color: ${Colors.white};
    }
    :hover {
      opacity: 0.8;
      text-decoration: none;
    }
    transition: opacity 200ms ease-in;
  }
`;
const InfoFooterSubTitle = styled(Fonts.infoFooterSubTitle)`
  display: block;
  width: 100%;
  .link {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.25rem;
    display: block;
    height: 1.5rem;
    text-decoration: none;
    color: ${Colors.white};
    font-size: inherit;
    font-weight: inherit;
    :active,
    :visited {
      text-decoration: none;
      color: ${Colors.white};
    }
    :hover {
      opacity: 0.8;
      text-decoration: none;
    }
    transition: opacity 200ms ease-in;
  }
`;

const InfoFooterDuration = styled(Fonts.infoFooterSubTitle)`
  position: absolute;
  top: 0.1875em;
  right: 0;
  color: ${Colors.gray};
  span {
    font-size: 0.8em;
  }
`;

const BrandContainer = styled.div`
  position: absolute;
  width: 30%;
  max-width: 100px;
  top: 0.6em;
  right: 0.5em;
  .brand {
    width: 100%;
    opacity: 0.8;
  }
`;
const BrandContainerLive = styled(MolBrandContainer)`
  position: absolute;
  width: 100%;
  padding: 10px 0 10px 10px;
  left: 0;
  bottom: 0;
  .brand {
    width: 125px;
    padding-right: 0.5em;
    margin-right: 0.5em;
    img {
      max-width: 100px;
    }
  }
  :before {
    content: "";
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    background: linear-gradient(
      to bottom,
      ${rgba(Colors.black, 0)} 0%,
      ${rgba(Colors.black, 1)} 100%
    );
    width: 100%;
    height: calc(100% + 1em);
  }
`;

const Title = styled(Fonts.h2)`
  color: ${Colors.white};
  font-size: 1.9375em;
  line-height: 1;
  margin-top: 0.25em;
`;

const PlayIcon = styled(AtIcon)`
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  filter: drop-shadow(0px 1px 2px ${rgba(Colors.black, 0.4)});
  pointer-events: none;
`;

const ContentTypeIcon = styled(AtIcon)`
  position: absolute;
  filter: drop-shadow(0px 1px 2px ${rgba(Colors.black, 0.4)});
  pointer-events: none;
  opacity: 0.5;
`;

const LinkContainer = styled.a`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  background: ${Colors.transparent};
  text-decoration: none;
`;

const VideoVideoContainer = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 20px;
`;

class ModalVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderPlayer: false
    };
  }

  componentDidMount() {
    /**
     * Append Modal to Body
     */

    if (document.querySelectorAll(`#ModalVideo-${this.props.tId}`)) {
      if (
        document.querySelectorAll(`#ModalVideo-${this.props.tId}`).length > 1
      ) {
        document
          .querySelectorAll(`#ModalVideo-${this.props.tId}`)
          .forEach((element, index) => {
            if (index > 0) {
              element.parentElement.remove();
            }
          });
      }
    }
    document
      .querySelector("body")
      .append(
        document.getElementById(`ModalVideo-${this.props.tId}`).parentElement
      );
  }

  divVideo(e) {
    e.stopPropagation();
  }

  detail(e, tId) {
    e.preventDefault();

    window.currentPlayer && window.currentPlayer.pause();

    global.window.document
      .querySelector(`#ModalVideo-${tId}`)
      .classList.toggle("display");
    global.window.document
      .querySelector("body")
      .classList.toggle("disableScroll");

    this.setState({
      renderPlayer: document
        .querySelector(`#ModalVideo-${tId}`)
        .classList.contains("display")
    });
  }

  render() {
    const { title_video, tId, pack } = this.props;
    const {
      slug,
      imageSource,
      imageResponsive,
      imageAlt,
      imageTitle,
      view,
      brand,
      chapter_number,
      content_type,
      image,
      micro_synopsis,
      title,
      subtitle,
      subtitle_slug,
      category,
      duration,
      isActiveClass
    } = this.props.slide_info;

    return (
      <>
        <div className="contentGeneralModalVideo">
          <div
            className="contentModalVideo"
            id={`ModalVideo-${tId}`}
            onClick={e => this.detail(e, tId)}
          >
            <div className="contentVideo">
              <div className="contentUpper">
                <div className="title">
                  <h1>{title_video}</h1>
                </div>
                <a href={_ => {}} className="closeButton">
                  Cerrar <AtIcon name="Close" size={22} />
                </a>
              </div>
              <Fragment>
                {this.state.renderPlayer && (
                  <VideoVideoContainer
                    onClick={e => this.divVideo(e)}
                    className={`videoWrapper`}
                  >
                    <MolTrailerPlayer {...pack} trailer={pack.video} />
                  </VideoVideoContainer>
                )}
              </Fragment>
            </div>
          </div>
        </div>

        <a className="link" href={slug} onClick={e => this.detail(e, tId)}>
          {imageSource ? (
            <Img
              responsive={imageResponsive}
              src={imageSource}
              alt={imageAlt}
              title={imageTitle}
            />
          ) : (
            <AtIcon className="broken-image" name="Image" size={35} />
          )}
          {view.contentTypeIcon &&
            content_type && (
              <>
                {title ? (
                  <ContentTypeIcon
                    className="content-type-icon"
                    name={
                      content_type === "audio" ? "SpeakerNew" : "PlayCircle"
                    }
                    size={28}
                    color={Colors.white}
                  />
                ) : (
                  <ContentTypeIcon
                    className="content-type-icon-prod"
                    name={
                      content_type === "audio" ? "SpeakerNew" : "PlayCircle"
                    }
                    size={28}
                    color={Colors.white}
                  />
                )}
              </>
            )}
          {image &&
            image.logo && (
              <BrandContainerLive
                brand={image.logo.path}
                brandAlt={image.logo.alt}
                brandTitle={image.logo.title}
              >
                <AtBadge className={brand.class} fontSize="0.857em">
                  online
                </AtBadge>
                <Title>{title}</Title>
              </BrandContainerLive>
            )}
        </a>

        {view.infoHover && (
          <InfoHoverContainer customStyles={view.infoHover.customStyles}>
            <PlayIcon
              className="play-button"
              name="PlayCircle"
              color={Colors.white}
              size={64}
            />
            <InfoHoverBody className="info-body" href={slug}>
              {category && <InfoHoverCategory>{category}</InfoHoverCategory>}
              {title && <InfoHoverTitle>{title}</InfoHoverTitle>}
              {micro_synopsis && (
                <InfoHoverSynopsis>{micro_synopsis}</InfoHoverSynopsis>
              )}
              {chapter_number && (
                <InfoHoverChapters>{chapter_number}</InfoHoverChapters>
              )}
            </InfoHoverBody>
            {!isMobile && (
              <InfoHoverActions className="info-actions">
                <MolShareButton
                  size={24}
                  label="COMPARTIR"
                  title={title}
                  text={micro_synopsis}
                  uri={slug}
                />
              </InfoHoverActions>
            )}
            {brand.image && (
              <BrandContainer>
                <AtImg
                  className="brand"
                  src={brand.image.logo_negative.path}
                  title={brand.image.logo_negative.title}
                  alt={brand.image.logo_negative.alt}
                />
              </BrandContainer>
            )}
            {view.linkContainer && <LinkContainer href={slug} />}
          </InfoHoverContainer>
        )}
        {view.infoFooter &&
          title && (
            <Fragment>
              <InfoFooterContainer
                className="info-footer-container"
                scale={view.scale || 1}
              >
                {title && (
                  <InfoFooterTitle
                    width={duration ? `calc(100% - 2em)` : `100%`}
                  >
                    <a className="link" href={slug}>
                      {title}
                    </a>
                  </InfoFooterTitle>
                )}
                {subtitle && (
                  <InfoFooterSubTitle className={isActiveClass}>
                    <a className="link" href={subtitle_slug}>
                      {subtitle}
                    </a>
                  </InfoFooterSubTitle>
                )}
                {duration && (
                  <InfoFooterDuration>
                    <span>{duration}</span>
                  </InfoFooterDuration>
                )}
              </InfoFooterContainer>
            </Fragment>
          )}
      </>
    );
  }
}
export default ModalVideo;
