import styled from "styled-components";

export const ContainerCloseButton = styled.div`
  position: absolute;
  right: 10%;
  top: 5%;
`;

export const ContentSignUpPage = styled.div`
  padding-bottom: 20px;
  padding-top: 66px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  @media screen and (min-width: 768px) {
    padding-top: 90px;
  }
  & > span {
    font-size: 12px;
    font-weight: bold;
  }
  & > h1 {
    padding: 0px;
    margin: 0px;
    margin-bottom: 10px;
  }
  & > img {
    max-width: 900px;
    width: 100%;
  }
  & > p {
    max-width: 900px;
    margin: auto;
    text-align: left;
    margin-top: 20px;
  }
`;
