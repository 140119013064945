import styled from "styled-components";
import { Colors, Fonts } from "../../../theme";
import AtField from "../../AtField";
import { getKey } from "../../../utils/helpers";
import AtIcon from "../../AtIcon/AtIcon";

export const ContentLoginForm = styled.div`
  position: relative;
  max-width: 880px;
  margin: 10px auto;
  color: ${Colors.bg1};
`;
export const Separator = styled.hr`
  border: 1px solid #111;
  margin: 20px 0px 20px 0px;
`;
export const Title = styled(Fonts.h1)`
  color: ${Colors.cerulean};
`;
export const SubTitle = styled(Fonts.slideLabel)`
  && {
    margin: 10px 0 0 10px;
    @media screen and (min-width: 768px) {
      margin: 40px 0 0 10px;
    }
  }
`;
export const FieldContainer = styled.div`
  user-select: none;
  position: relative;
  margin-top: 15px;
  width: 100%;
  text-align: ${props => getKey(props, "align", "inherits")};
`;

export const Field = styled(AtField)`
  &.has,
  &.has:invalid,
  &.hasErrors {
    border-color: red;
  }
  &[type="select"] {
    padding: unset !important;
  }
  input {
    background: #555;
    border: 1px solid #ccc;
    color: #ccc;
    &.fullNameRule {
      @media screen and (min-width: 768px) {
        max-width: 58%;
      }
    }
  }
  &.preferencesField {
    input[type="checkbox"] {
      position: absolute;
      left: -2000px;
    }
    & > ul {
      position: relative;
      max-width: 90%;
      margin: auto;
      margin-top: 20px;
      @media screen and (min-width: 768px) {
        column-count: 3;
      }
      & > li {
        position: relative;
        display: block;
        margin-bottom: 10px;
        & > input {
          display: inline-flex;
          width: 30px;
          border: 1px solid #fff;
        }
        & > label {
          display: inline-flex;
        }
      }
    }
  }
`;

export const Button = styled.button`
  background: ${Colors.cerulean};
  color: ${Colors.white};
  border: none;
  font-size: 1.375em;
  font-family: "Dosis", sans-serif;
  line-height: 0.864;
  padding: 0;
  margin: 0;
  width: 224px;
  height: 59px;
  outline: none;
  cursor: pointer;

  &:hover {
    background: ${Colors.uglyblue};
  }
  &[disabled] {
    background: #ccc;
    color: #888;
    box-shadow: inset 0px 0px 5px 0px #000;
    cursor: progress;
  }
`;

export const ModalContainer = styled.div`
  & > div > div > div:first-child {
    background-color: ${Colors.bg1} !important;
    color: ${Colors.white} !important;
    padding: 10px;
    border-radius: 0px !important;
    button {
      width: 120px;
      height: 50px;
      font-size: 17px;
    }
    pre {
      font-family: monospace;
      font-size: 13px;
      width: 100%;
      padding: 10px;
      margin: 0;
      border: 1px solid #555;
      margin-bottom: 10px;
      height: 100px;
      background: #222;
      overflow: auto;
    }
  }
`;

export const ListColumn = styled.ul`
  margin: 0px;
  padding: 0px;
  list-style: none;
  width: 100%;

  @media screen and (min-width: 768px) {
    display: inline-flex;
  }
`;
export const ListColumnItem = styled.li`
  vertical-align: top;

  @media screen and (min-width: 768px) {
    width: 33%;
    margin-right: 15px;
  }
`;
export const DropZone = styled.div`
  color: #ccc;
  border: 1px dashed #ccc;
  padding: 2px;
  min-height: 100px;
  span {
    display: inline-flex;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 40px;
    margin-left: 10px;
  }
`;
export const PreviewImage = styled.div`
  height: 100px;
  display: flex;
  img {
    height: max-content;
    max-width: 100px;
    max-height: 100%;
    display: inline-flex;

    @media screen and (min-width: 768px) {
      max-width: 200px;
    }
  }
`;
export const DropErrorMessage = styled.div`
  display: block;
  border: red;
  background: red;
  color: #fff;
  padding: 5px;
  margin-top: 4px;
  font-weight: bolder;
  text-align: center;
`;

export const buttonCloseModal = {
  width: "100%"
};
export const ErrorWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  svg {
    display: inline-flex;
  }
`;
export const TextError = styled.span`
  display: inline-flex;
  color: ${Colors.orange};
  font-size: 20px;
  width: 300px;
  margin-left: 10px;
  height: 255px;
  overflow: hidden;
  overflow-y: visible;
`;
export const IconCircle = styled(AtIcon)`
  border-radius: 5px;
  margin-left: 5px;
  border: 2px solid #fff;
  border-radius: 50%;
`;
export const Span = styled(Fonts.span)`
  vertical-align: text-top;
  max-width: 50px;
  overflow: hidden;
  display: inline-flex;
  text-overflow: ellipsis;
  height: 24px;
  word-break: break-word;
  margin-left: 5px;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const Link = styled(Fonts.a)`
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  &.closeModalButton {
    display: flex;
    float: right;
  }
`;

export const ContentErrorForm = styled.div`
  padding: 20px;
`;
