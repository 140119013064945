import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Colors, Metrics } from "../../theme";
import { isValidArray } from "../../utils/helpers";

/**
 * Podcast External urls layout
 *
 * @param {object} props - component props
 * @returns {JSX.Element}
 */
function PodcastExternalPlatform({ url_external_podcasts, isWidget = false }) {
  const imagePlatform = {
    apple:
      "https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/2020-11/apple-podcast.png",
    deezer:
      "https://rtvcplay-v2.s3-accelerate.amazonaws.com/s3fs-public/2021-02/btn-deezer.png",
    google:
      "https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/2020-11/listen-googl2e.png",
    spotify:
      "https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/2020-11/listen-on-spotify.png",
    radionacional:
      "https://rtvcplay-v2.s3-accelerate.amazonaws.com/s3fs-public/2021-03/Escuchar-RadioNacional.png",
    radionica:
      "https://rtvcplay-v2.s3-accelerate.amazonaws.com/s3fs-public/2021-03/Escuchar-Radionica.png"
  };

  const isInternal = platform => {
    return (
      platform === "brand" ||
      platform === "radionacional" ||
      platform === "radionica"
    );
  };

  const getExternalPlatforms = () => {
    if (!!url_external_podcasts) {
      const list = [];
      for (const [platform, url] of Object.entries(url_external_podcasts)) {
        if (isInternal(platform) || url === null) continue;
        list.push({
          platform,
          url,
          image: imagePlatform[platform]
        });
      }
      return list;
    }
    return [];
  };

  const getInternalPlatforms = () => {
    if (!!url_external_podcasts) {
      const list = [];
      for (const [platform, url] of Object.entries(url_external_podcasts)) {
        if (!isInternal(platform) || platform === "brand" || url === null)
          continue;
        list.push({
          platform,
          url,
          image: imagePlatform[platform]
        });
      }
      return list;
    }
    return [];
  };

  const externalPlatforms = getExternalPlatforms();
  const internalPlatforms = getInternalPlatforms();

  return (
    <Wrapper>
      {isValidArray(internalPlatforms) && (
        <List isWidget={isWidget}>
          {internalPlatforms.map(({ url, image }, indx) => (
            <Item key={indx} isWidget={isWidget}>
              <Link
                href={`${url.startsWith("http") ? "" : "https://"}${url}`}
                target="_blank"
              >
                <Image src={image} />
              </Link>
            </Item>
          ))}
        </List>
      )}
      <ExternalPlatformWrapper isWidget={isWidget}>
        {isValidArray(externalPlatforms) && (
          <>
            <Title isWidget={isWidget}>Suscríbete:</Title>
            <List isWidget={isWidget}>
              {externalPlatforms.map(({ url, image }, indx) => (
                <Item key={indx} isWidget={isWidget}>
                  <Link
                    href={`${url.startsWith("http") ? "" : "https://"}${url}`}
                    target="_blank"
                  >
                    <Image src={image} />
                  </Link>
                </Item>
              ))}
            </List>
          </>
        )}
      </ExternalPlatformWrapper>
    </Wrapper>
  );
}

PodcastExternalPlatform.propTypes = {
  props: PropTypes.object
};

export default PodcastExternalPlatform;

const Wrapper = styled.div`
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);

  @media screen and (min-width: ${Metrics.size.tablet}) {
    width: 100%;
    margin: 0;
  }
`;

const ExternalPlatformWrapper = styled.div`
  width: 100%;

  ${({ isWidget }) =>
    !isWidget &&
    css`
      border-top: 1px solid #006ce6;
      padding: 10px 0 0;

      @media screen and (min-width: ${Metrics.size.tablet}) {
        margin: 35px 0 0;
        padding: 35px 0 0;
      }
    `} margin: 0;
`;

const Title = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 22px;
  font-family: Dosis;
  color: ${Colors.white};
  line-height: 1.2;
  font-weight: 500;
  margin: 0;
  text-align: center;


  ${({ isWidget }) =>
    isWidget &&
    css`
      color: ${Colors.gray};
      text-align: left;
    `}

  @media screen and (min-width: ${Metrics.size.tablet}) {
    font-size: 28px;
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 20px 0 20px;
  padding: 0;
  display: inline-block;
  text-align: center;
  width: 100%;
  @media screen and (min-width: ${Metrics.size.tablet}) {
    margin: 40px 0 20px;
    display: flex;
    gap: 5px;
    width: auto;
    ${({ isWidget }) =>
      isWidget &&
      css`
        margin: 20px 0;
        flex-direction: column;
      `};
  }
`;

const Item = styled.li`
  vertical-align: top;
  display: inline-block;

  @media screen and (min-width: ${Metrics.size.tablet}) {
    width: ${({ isWidget }) => (isWidget ? "" : "30%")};
    display: initial;
  }
`;

const Link = styled.a`
  display: block;
  text-decoration: none;
  text-align: center;
`;

const Image = styled.img`
  width: 100%;
  border: 0;
  max-height: 50px;
  max-width: 175px;

  @media screen and (min-width: ${Metrics.size.tablet}) {
    max-width: 195px;
  }
`;
