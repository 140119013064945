import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-awesome-modal";

import { loginUser, signUpUser } from "../../../../store/actions/auth";
import { getRefererContent } from "../../../../store/utils/helpers";

import FacebookLogin from "../../External/FacebookLogin";
import GoogleLogin from "../../External/GoogleLogin";

import { Colors, Fonts } from "../../../theme";
import { getKey } from "../../../utils/helpers";
import AtField from "../../AtField";
import AtLogo from "../../AtLogo/AtLogo";

import { ModalContainer } from "../../OrLogin/Form/LoginForm";
import { PASSWORD_FIELD_ID, REGEX_PASSWORD } from "../constants";
import { Store } from "../../../../store/store";
import { getReCaptchaKey } from "../../../utils/helpers";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider
} from "react-google-recaptcha-v3";

const FormRegister = () => {
  const [googleToken, setGoogleToken] = useState(null);
  const [hasErrors, setHasErrors] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [part1, setPart1] = useState(true);
  const [part2, setPart2] = useState(false);
  const [part3, setPart3] = useState(false);
  const [birthday, setBirthday] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTreatmentDataVisible, setModalTreatmentDataVisible] = useState(
    false
  );
  const [modalMessage, setModalMessage] = useState("");

  const handleSubmit = async event => {
    event.preventDefault();
    setHasErrors(false);
    setErrorList([]);
    setLoading(true);
    const dataForm = event.target;
    const hasGoodPassword = REGEX_PASSWORD.test(dataForm.field_p.value);
    if (
      event.target.checkValidity &&
      event.target.checkValidity() &&
      hasGoodPassword &&
      googleToken !== null
    ) {
      const userInfo = {
        name: { value: dataForm.field_e.value },
        field_full_name: { value: dataForm.field_n.value },
        mail: { value: dataForm.field_e.value },
        field_gender: { value: "NI" },
        field_birthdate: { value: birthday },
        pass: [{ value: dataForm.field_p.value }],
        field_education_content: { value: dataForm.field_c.checked },
        field_from_social_network: { value: false },
        field_ref: { value: getRefererContent() || "/" },
        field_legalterm_policy: {
          value: dataForm.field_legalTermPolicy.checked
        },
        field_legalterm_treatment_data: {
          value: dataForm.field_legalTermDataTreatment.checked
        }
      };

      const result = await signUpUser(userInfo);

      if (!result.error) {
        setHasErrors(false);
        setLoading(false);
        setPart2(false);
        setPart3(true);

        Store.dispatch(
          loginUser(dataForm.field_e.value, dataForm.field_p.value)
        );
      } else {
        setLoading(false);
        setModalMessage(getKey(result, "error.message", result.error));
        openModal();
      }
    } else {
      setHasErrors(true);
      setLoading(false);
      const invalidFields = Array.from(
        document.querySelectorAll("#step2 :invalid")
      ).map(el => el.id);

      if (!hasGoodPassword && !invalidFields.includes(PASSWORD_FIELD_ID)) {
        invalidFields.push(PASSWORD_FIELD_ID);
      }

      setErrorList(invalidFields);
      setHasErrors(true);
    }
  };

  const goNextStep = event => {
    event.preventDefault();
    const birthday = document.querySelector("#field_birthday");

    if (!birthday.validity.valueMissing && !birthday.validity.typeMismatch) {
      setHasErrors(false);
      setPart1(false);
      setPart2(true);
      setBirthday(birthday.value);
    } else {
      setHasErrors(true);
    }
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const closeLegalModal = () => {
    setModalTreatmentDataVisible(false);
  };

  const openLegalModal = () => {
    setModalTreatmentDataVisible(true);
  };

  return (
    <Wrapper>
      {part1 && (
        <Step1>
          <LogoPlay linkable={false} />
          <Title>Registrarse en RTVCPlay</Title>
          <form id="step1" onSubmit={goNextStep} noValidate autocomplete="off">
            <FieldContainer>
              <Field
                label="¿Cuál es tu fecha de cumpleaños?"
                name="birthday"
                type="date"
                disabled={loading && "disabled"}
                required
                className={`birthday ${hasErrors ? "hasErrors" : ""}`}
                errorMessage="Necesitas ingresar una fecha válida."
                hasErrors={hasErrors}
              />
            </FieldContainer>
            <FieldContainer>
              <SubTitle>
                En RTVCPlay encuentras contenidos culturales, educativos y de
                entretenimiento para todos los públicos como: películas,
                documentales, series documentales, programas infantiles,
                radionovelas, podcasts y clásicos de la televisión colombiana.
              </SubTitle>
            </FieldContainer>
            <FieldContainer>
              <Button disabled={loading && "disabled"}>Continuar</Button>
              <ContainerSocialButtons>
                <FacebookLogin />
                <GoogleLogin />
              </ContainerSocialButtons>
            </FieldContainer>
          </form>
        </Step1>
      )}
      {part2 && (
        <Step2>
          <LogoPlay linkable={false} />
          <Title>Registrarse en RTVCPlay</Title>
          <form
            id="step2"
            onSubmit={handleSubmit}
            noValidate
            autocomplete="off"
          >
            <FieldContainer>
              <Field
                name="n"
                type="text"
                label="Nombre Completo *"
                disabled={loading && "disabled"}
                required
                className={`step2 ${hasErrors ? "hasErrors" : ""}`}
                errorMessage="Necesitas ingresar tu nombre Completo."
                hasErrors={errorList.includes("field_n")}
              />
            </FieldContainer>
            <FieldContainer>
              <Field
                name="e"
                type="email"
                label="Correo electrónico *"
                disabled={loading && "disabled"}
                required
                className={`step2 ${hasErrors ? "hasErrors" : ""}`}
                errorMessage="Necesitas ingresar un correo electrónico válido."
                hasErrors={errorList.includes("field_e")}
              />
            </FieldContainer>
            <FieldContainer>
              <Field
                name="p"
                type="password"
                autocomplete="new-password"
                label="Contraseña *"
                disabled={loading && "disabled"}
                required
                className={`step2 ${hasErrors ? "hasErrors" : ""}`}
                errorMessage="Necesitas ingresar una contraseña válida."
                hasErrors={errorList.includes("field_p")}
              />
            </FieldContainer>
            <FieldContainer>
              <SubTitle>
                La contraseña debe incluir:
                <br />
                ·Al menos una letra.
                <br />
                ·Al menos un 2 números.
                <br />
                ·Al menos un carácter especial:
                <br />
                Carácteres especiales válidos:
                <SpecialChar>@ $ ! % * # ? &</SpecialChar>
              </SubTitle>
            </FieldContainer>
            <FieldContainer>
              <LegalTermConatiner>
                <LegalTermItem>
                  <Field
                    required
                    name="legalTermPolicy"
                    type="checkbox"
                    label={`Acepto la <a target="_blank" href="https://s3.amazonaws.com/rtvc-assets-qa-sistemasenalcolombia.gov.co/archivos/politica_operacional-privacidad_datos.pdf">política de privacidad de datos</a>`}
                    className={`checkLegal ${hasErrors ? "hasErrors" : ""}`}
                    errorMessage="Es necesario que acepte la política de privacidad de datos"
                    hasErrors={errorList.includes("field_legalTermPolicy")}
                  />
                </LegalTermItem>
                <LegalTermItem>
                  <Field
                    required
                    name="legalTermDataTreatment"
                    type="checkbox"
                    label={
                      <LegalTreatmentContainer>
                        <div>Autorizo el</div>
                        <div onClick={openLegalModal}>tratamiento de datos</div>
                      </LegalTreatmentContainer>
                    }
                    className={`checkLegal ${hasErrors ? "hasErrors" : ""}`}
                    errorMessage="Es necesario que acepte la autorización de tratamiento de datos"
                    hasErrors={errorList.includes(
                      "field_legalTermDataTreatment"
                    )}
                  />
                </LegalTermItem>
              </LegalTermConatiner>
            </FieldContainer>
            <FieldContainer>
              <Field
                name="c"
                type="checkbox"
                label="Contenido educativo. (Recomendado para profesores)."
                className={`step2 check ${hasErrors ? "hasErrors" : ""}`}
              />
            </FieldContainer>
            <FieldContainer>
              <SubTitle>
                En RTVCPlay encuentras contenidos educativos, en formato
                audiovisual y sonoro, con guías pedagógicas y sugerencias de
                uso, dirigidos a estudiantes, docentes, padres de familia y
                cuidadores, que buscan formar, enseñar y entretener en un
                contexto netamente digital.
              </SubTitle>
            </FieldContainer>
            <FieldContainer>
              <Button disabled={loading && "disabled"}>Continuar</Button>
            </FieldContainer>
          </form>
        </Step2>
      )}
      {part3 && (
        <Step3>
          <LogoPlay linkable={false} />
          <TitleWelcome>
            BIENVENIDO A LA OPCIÓN DE ENTRETENIMIENTO ONLINE DE RTVC.
          </TitleWelcome>
          <SubTitleWelcome>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo.
          </SubTitleWelcome>
          <FieldContainer>
            <ContainerWelcomeButtons>
              <Button onClick={_ => (window.location.href = "/mi-perfil")}>
                Editar mi perfil
              </Button>
              <Button
                onClick={_ =>
                  (window.location.href = getRefererContent() || "/")
                }
              >
                Empezar a ver
              </Button>
            </ContainerWelcomeButtons>
          </FieldContainer>
        </Step3>
      )}

      <GoogleReCaptchaProvider reCaptchaKey={getReCaptchaKey()}>
        <GoogleReCaptcha onVerify={token => setGoogleToken(token)} />
      </GoogleReCaptchaProvider>

      <ModalContainer>
        <Modal
          visible={modalVisible}
          width="370"
          height="230"
          effect="fadeInUp"
          onClickAway={closeModal}
        >
          <div>
            <h1>Error</h1>
            <p>{modalMessage}</p>
            <Button onClick={closeModal}>CERRAR</Button>
          </div>
        </Modal>
      </ModalContainer>

      <ModalContainer>
        <Modal
          visible={modalTreatmentDataVisible}
          width="414"
          height="400"
          effect="fadeInUp"
          onClickAway={closeLegalModal}
        >
          <ModalLegalContainer>
            <h1>AUTORIZACIÓN DEL TRATAMIENTO DE DATOS</h1>
            <p>
              De acuerdo con lo establecido en la Ley 1581 de 2012, el Decreto
              No. 1377 de 2013 y demás normas concordantes, manifiesto de forma
              libre, consciente, expresa, informada y espontánea que autorizo de
              manera previa a RADIO TELEVISIÓN NACIONAL DE COLOMBIA, para que
              recolecte mis datos personales y/o los de la empresa que
              representó, los conserve dentro de sus bases, para ser tratados
              dentro de las finalidades legales, contractuales y comerciales de
              acuerdo con su política de “Protección de datos y privacidad de
              RTVC - Sistema de Medios Públicos”, la cual puede ser consultada
              en el siguiente enlace. &nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.rtvc.gov.co/politicas-de-privacidad"
              >
                https://www.rtvc.gov.co/politicas-de-privacidad
              </a>
              .
            </p>
            <Button onClick={closeLegalModal}>CERRAR</Button>
          </ModalLegalContainer>
        </Modal>
      </ModalContainer>
    </Wrapper>
  );
};

export default FormRegister;

const Wrapper = styled.div`
  @media screen and (min-width: 768px) {
    margin-top: 50px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
`;

const Step1 = styled.div`
  max-width: 440px;
  padding: 20px;
  background-color: ${Colors.bg1};
`;
const Step2 = styled.div`
  max-width: 440px;
  padding: 20px;
  background-color: ${Colors.bg1};
`;
const Step3 = styled.div`
  max-width: 500px;
  padding: 20px;
`;

const LogoPlay = styled(AtLogo)`
  margin: 0 0 10px;
`;
const Title = styled.h1`
  color: ${Colors.cyan};
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 2.5rem;
  margin: 0;
`;
const TitleWelcome = styled(Fonts.h1)`
  line-height: 3rem;
`;
const SubTitle = styled(Fonts.slideLabel)``;
const SubTitleWelcome = styled(Fonts.slideLabel)`
  font-size: 20px;
  line-height: 1.5rem;
  margin-bottom: 15px;
  display: block;
`;
const FieldContainer = styled.div`
  user-select: none;
  margin: 10px 0;
  text-align: ${props => getKey(props, "align", "inherits")};
`;

const Field = styled(AtField)`
  input {
    font-size: 22px;
    outline: none;
    padding-left: 0;
  }

  &.birthday {
    label {
      font-size: 21px;
      margin-bottom: 15px;
      display: block;
    }
    input {
      text-align: center;
    }
  }

  &.step2 {
    label {
      color: ${Colors.cyan};
      font-size: 19px;
    }
    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark:after {
        display: block;
      }
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: ${Colors.white};
      border-radius: 50%;
      &::after {
        display: none;
        content: "";
        position: absolute;
        left: 8px;
        top: 4px;
        width: 5px;
        height: 10px;
        border: solid ${Colors.cyan};
        border-width: 0 5px 5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    &.check {
      label {
        font-size: 17px;
        padding-left: 30px;
        z-index: 99;
      }
    }
  }
`;

const Button = styled.button`
  background: ${Colors.cerulean};
  color: ${Colors.white};
  border: none;
  font-size: 1.375em;
  font-family: "Dosis", sans-serif;
  line-height: 0.864;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 59px;
  outline: none;
  cursor: pointer;

  &:hover {
    background: ${Colors.uglyblue};
  }
  &[disabled] {
    background: #ccc;
    color: #888;
    box-shadow: inset 0px 0px 5px 0px #000;
    cursor: progress;
  }
`;

const ContainerSocialButtons = styled.div`
  position: relative;
  display: flex;
  margin-top: 10px;
`;

const ContainerWelcomeButtons = styled.div`
  position: relative;
  display: flex;
  margin-top: 10px;
  button {
    width: 40%;
    font-weight: 600;
  }
  & > button:first-child {
    color: black;
    margin-right: 30px;
    background: ${Colors.white};
  }
`;

const SpecialChar = styled.i`
  color: orange;
  margin-left: 10px;
`;

const LegalTermConatiner = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 30px 0;
`;
const LegalTermItem = styled.li`
  display: flex;
  gap: 20px;

  .checkLegal {
    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark:after {
        display: block;
      }
    }
    label {
      padding-left: 30px;
      color: #999;
      z-index: 99;
      a {
        color: #999;
      }
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #666;
      border: 1px solid #fff;
      border-radius: 5px;
      &::after {
        display: none;
        content: "";
        position: absolute;
        left: 5px;
        top: 1px;
        width: 4px;
        height: 10px;
        border: solid ${Colors.cyan};
        border-width: 0 5px 5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
`;
const LegalTreatmentContainer = styled.div`
  display: flex;
  gap: 5px;
  div:last-child {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ModalLegalContainer = styled.div`
  a {
    color: #999;
  }
`;
