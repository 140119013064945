import React, { Component } from "react";
import styled, { css } from "styled-components";
import Page from "../";
import "./notLoggedIn.scss";
import { Wp } from "../../../theme";
import Modal from "react-awesome-modal";
import { Colors, Fonts } from "../../../theme";
import AtIcon from "../../../components/AtIcon/AtIcon";
import LoginForm from "../../../components/OrLogin/Form";

const ModalContainer = styled.div`
  ${props =>
    !props.keeponDOM &&
    css`
      display: none;
    `} & > div > div > div:first-child {
    background-color: ${Colors.bg1} !important;
    color: ${Colors.white} !important;
    padding: 10px;
    border-radius: 0px !important;
    button {
      width: 120px;
      height: 50px;
      font-size: 17px;
    }
  }
`;

const IconCircle = styled(AtIcon)`
  border-radius: 5px;
  margin-left: 5px;
  border: 2px solid #fff;
  border-radius: 50%;
`;

const Span = styled(Fonts.span)`
  vertical-align: text-top;
  max-width: 50px;
  overflow: hidden;
  display: inline-flex;
  text-overflow: ellipsis;
  height: 24px;
  word-break: break-word;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const ContentLoginForm = styled.div`
  padding: 20px;
`;
const LinkC = styled(Fonts.a)`
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  &.closeModalButton {
    display: flex;
    float: right;
  }
`;

const Container = styled(Wp.Container)``;
const PageWrapper = styled(Wp.Page)``;

class NotLoggedIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginVisble: false
    };
    this.showLoginForm = this.showLoginForm.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  showLoginForm(event) {
    event.preventDefault();
    this.setState({ loginVisble: true });
  }

  closeModal(event) {
    event.preventDefault();
    this.setState({ loginVisble: false });
  }

  render() {
    return (
      <Page
        id="notLoggedIn"
        title="Sin iniciar sesión"
        description="Sin iniciar sesión"
        noCrawl
      >
        <Container>
          <PageWrapper>
            <div className="contentErrorNotLoggedIn">
              <ul>
                <li className="errorCode">
                  <img
                    src="https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/assets/403.png"
                    title="Error 403"
                    alt="Error 403"
                  />
                </li>
                <li className="message">
                  <div>
                    <div>
                      <h1>
                        Ups…
                        <br />
                      </h1>
                      <h2>
                        <span className="unnderline">
                          No tienes permisos para acceder a este espacio,
                        </span>
                      </h2>
                      <h3>
                        pero tu entretenimiento <b>no puede tener pausa.</b>
                      </h3>
                    </div>
                    <div className="buttonContainer">
                      <a className="singnUp" href="/registro">
                        Registrate
                      </a>
                    </div>
                    <div className="buttonContainer">
                      <a className="backToHomeButton" href="/">
                        Vuelve al inicio
                      </a>
                    </div>
                    <br />
                    <div className="linkContainer">
                      <a
                        href="/#"
                        onClick={e => this.showLoginForm(e)}
                        className="lnk-login"
                      >
                        Iniciar sesión
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </PageWrapper>
        </Container>

        <ModalContainer keeponDOM={this.state.loginVisble}>
          <Modal
            visible={this.state.loginVisble}
            width="450px"
            height="400px"
            effect="fadeInUp"
            onClickAway={this.closeModal}
          >
            <LinkC
              onClick={e => this.closeModal(e)}
              className="closeModalButton"
            >
              <Span>Cerrar</Span>
              <IconCircle name="Close" size={22} />
            </LinkC>
            <ContentLoginForm>
              <LoginForm closeModal={e => this.closeModal(e)} />
            </ContentLoginForm>
          </Modal>
        </ModalContainer>
      </Page>
    );
  }
}
export default NotLoggedIn;
