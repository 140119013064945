import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { rgba } from "polished";

import { Colors, Fonts } from "../../theme";

const Container = styled.a`
  width: 280px;
  height: 34px;
  border-radius: 17px;
  transition: all 1s ease;
  box-shadow: 0px 0px 15px ${Colors.black};
  :hover {
    box-shadow: 0px 0px 15px ${Colors.white};
  }
`;
const Display = styled.div`
  display: block;
  background-color: ${Colors.bunker};
  width: 190px;
  height: 34px;
  border-radius: 17px;
  border: 5px solid ${Colors.bunker};
  box-sizing: border-box;
  overflow: hidden;
  float: left;
`;
const LiveDisplay = styled(Display)`
  border: 5px solid ${Colors.black};
  width: 123px;
  float: right;
  transform: translateY(-34px);
`;
const LiveDisplayInner = styled.div`
  position: relative;
  background-color: ${Colors.black};
  width: 100%;
  height: 100%;
  border: 1px solid ${Colors.bunker};
  border-radius: 13px;
  box-sizing: border-box;
`;
const DisplayText = styled(Fonts.span)`
  display: block;
  line-height: 24px;
  font-weight: 300;
  font-size: 0.875rem;
`;
const LiveText = styled(DisplayText)`
  line-height: 22px;
  font-size: 0.65rem;
  text-align: right;
  margin-right: 1rem;
  text-transform: uppercase;
`;
const RedLightDown = styled.span`
  position: absolute;
  top: 2.5px;
  left: 8px;
  border-radius: 50%;
  border: 2px solid ${rgba(Colors.redlightborder, 0.7)};
  background-color: ${rgba(Colors.redlightdown, 0.7)};
  width: 17px;
  height: 17px;
  box-sizing: border-box;
`;
const blink = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;
const RedLightUp = styled.span`
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
  background-color: ${Colors.redlightup};
  box-shadow: 0px 0px 7px 0px ${rgba(Colors.redlightshadow, 0.35)};
  animation: 2s ${blink} ease infinite;
  opacity: 0;
`;
class AtLiveDisplay extends Component {
  static defaultProps = {
    text: "RTVC Sistema de Medios Públicos",
    link: "#"
  };
  render() {
    const { text, link, className } = this.props;
    return (
      <Container className={className} href={link}>
        <Display>
          {/* eslint-disable-next-line */}
          <marquee>
            <DisplayText>{text}</DisplayText>
          </marquee>
        </Display>
        <LiveDisplay>
          <LiveDisplayInner>
            <RedLightDown>
              <RedLightUp />
            </RedLightDown>
            <LiveText>Evento en vivo</LiveText>
          </LiveDisplayInner>
        </LiveDisplay>
      </Container>
    );
  }
}

export default AtLiveDisplay;
