export const getWidgetIndex = (arr, prop, value) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][prop] === value) {
      return {
        index: i,
        data: arr[i],
        removed: false
      };
    }
  }
  return {
    index: -1,
    data: null,
    removed: false
  };
};

export const getWidgetId = (id, type) => `w${type}${id}`;
