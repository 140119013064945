// AudioPlayerList.js
import React from "react";
import styled from "styled-components";
import { AudioPlayer } from "./AudioPlayer";
import { useAudioPlayer } from "../../contexts/AudioPageContext";

const AudioPlayerList = ({ coverPodcast, audios }) => {
  const { setCurrentIndex, currentIndex } = useAudioPlayer();

  const handleAudioSelect = id => {
    setCurrentIndex(audios.findIndex(audio => audio.id === id));
  };

  return (
    <Container>
      <List>
        {audios.map(audio => (
          <Item
            key={audio.id}
            isSelected={
              audios[currentIndex] && audios[currentIndex].id === audio.id
            }
          >
            <AudioPlayer
              coverPodcast={coverPodcast}
              audio={audio}
              onAudioSelect={handleAudioSelect}
              isSelected={
                audios[currentIndex] && audios[currentIndex].id === audio.id
              }
              audios={audios}
            />
          </Item>
        ))}
      </List>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  gap: 20px;
  display: flex;
  flex-direction: column;
  margin: 0;
`;

const Item = styled.li`
  ${props => props.isSelected && `background-color: rgba(54,54,54,0.4);`}

  @media screen and (min-width: 768px) {
    padding: 20px;
  }
`;

export default AudioPlayerList;
