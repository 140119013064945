import React, { Component, Fragment } from "react";
import { isMobile } from "react-device-detect";

import "./OpeningKids.scss";
import { getKey, getTaxonomy } from "../../../utils/helpers";
import AtIcon from "../../../components/AtIcon/AtIcon";
import SocialButtons from "../../../components/MolShareButton";
import ShowTrailerButton from "../../../components/MolShowTrailerButton";
import Video from "../../../components/AtVideo";

class OpeningKids extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: false
    };
  }
  loadResource(resource) {
    let data = getKey(
      resource,
      "image.cover_" + (isMobile ? "mobile" : "desktop") + ".path",
      null
    );
    if (data === null) {
      data = getKey(resource, "trailer", getKey(resource, "video", null));
    }
    if (data === undefined) return <div />;
    if (typeof data === "string") {
      return <img src={data} alt={this.props.title} title={this.props.title} />;
    }
    if (typeof data === "object") {
      return <Video {...data} />;
    }
  }

  infoDetail(e) {
    e.preventDefault();
    global.window.document
      .querySelector(".contentDetailInfo")
      .classList.toggle("display");
  }
  render() {
    const {
      title,
      taxonomy,
      resource,
      description,
      trailer,
      infoAdditional,
      isNinos,
      slug
    } = this.props;
    const category = getTaxonomy(taxonomy, "brands");
    return (
      <Fragment>
        <div className={`wrapperOpening ${isNinos && "ninosView"}`}>
          <div className="contentOpening">
            {this.loadResource(resource)}
            <div className="wrapperMetaData">
              <div className="metaData">
                <h4>{getKey(category, "value", "")}</h4>
                <h1>{title}</h1>
                <div
                  className="longDescriptionWrapper"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
              <div className="links">
                <ul>
                  <li>
                    <ShowTrailerButton title={title} trailer={trailer} />
                  </li>
                  {infoAdditional && (
                    <li>
                      <a href={_ => {}} onClick={e => this.infoDetail(e)}>
                        <AtIcon name="Info" size={34} /> INFO
                      </a>
                    </li>
                  )}
                  <li>
                    <SocialButtons
                      label="COMPARTIR"
                      title={title}
                      text={description}
                      size={14}
                      uri={slug}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={`contentDetailInfo ${isNinos && "ninosView"}`}>
          {infoAdditional && (
            <ul>
              {infoAdditional.map((info, index) => (
                <li
                  key={index}
                  style={{
                    width: Math.round(100 / infoAdditional.length) + "%"
                  }}
                  dangerouslySetInnerHTML={{ __html: info.value || "<p></p>" }}
                />
              ))}
            </ul>
          )}
          <div className="contentCloseInfoButton">
            <a href={_ => {}} onClick={e => this.infoDetail(e)}>
              <AtIcon name="ArrowDropUp" size={34} />
            </a>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default OpeningKids;
