import React from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { UncontrolledTooltip } from "reactstrap";
import AtIcon from "../AtIcon/AtIcon";
import "./bootstrap.scss";

const IconContainer = styled.span`
  display: inline-flex;
  border-radius: 50%;
  overflow: hidden;

  &:hover,
  &:active {
    svg {
      background-color: #009ad9;
    }
  }
`;
const Container = styled.div`
  position: absolute;
  right: 20px;
  margin-top: 5px;
  z-index: 9999;
`;
const Label = styled.span`
  color: white;
  font-size: 15px;
  text-transform: uppercase;
  display: inline-flex;
  vertical-align: top;
  margin-top: 3px;
  margin-left: 5px;
`;

const ToolTipInfo = ({ id, description, label }) => {
  return (
    <Container>
      <IconContainer id={`tooltip-${id}`}>
        <AtIcon name="InfoCustom" size={25} />
      </IconContainer>
      {label && <Label>{label}</Label>}
      <UncontrolledTooltip
        placement={isMobile ? "top" : "right"}
        target={`tooltip-${id}`}
        autohide={false}
        className="UncontrolledTooltipProduction"
      >
        {description}
      </UncontrolledTooltip>
    </Container>
  );
};
export default ToolTipInfo;
