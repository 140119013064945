import React from "react";
import { isMobile } from "react-device-detect";
import { getKey } from "../../utils/helpers";
import MolTrailerPlayer from "../MolTrailerPlayer";
import AtIcon from "../AtIcon/AtIcon";
import Rating from "../AtRating/AtRating";

import AddMyListButton from "../MolAddMyListButton";

import {
  BrandLogo,
  Category,
  LongDescriptionWrapper,
  RatingContainer
} from "../../layouts/widgets/Opening/CommingSoon/CommingSoon";

import {
  BackgroundLayer,
  ButtonsContainer,
  InnerWrapper,
  MetadataContainer,
  Trailer,
  TrailerContainer,
  Wrapper,
  MetadataInnerContainer,
  SeeNow,
  TitleBlock,
  OpaqueLayer
} from "./Highlight";

export default function OrHighlight({
  title: widgetTitle,
  description,
  showTitle,
  content: production,
  streaming_provider
}) {
  if (
    production === null ||
    getKey(production, "content_type") !== "production"
  ) {
    return null;
  }

  const {
    id,
    brand,
    category,
    image: { cover_desktop, cover_mobile },
    micro_synopsis,
    seasons_number,
    slug,
    title,
    trailer
  } = production;

  const { path: brackgroundImage } = isMobile ? cover_mobile : cover_desktop;
  const brand_logo_negative = getKey(brand, "image.logo_negative.path");

  return (
    <Wrapper>
      <InnerWrapper>
        <BackgroundLayer backgroundImage={encodeURI(brackgroundImage)}>
          <OpaqueLayer />
        </BackgroundLayer>
        <TitleBlock
          title={widgetTitle}
          description={description}
          showTitle={showTitle}
          animateTitle
        />
        <MetadataContainer>
          <MetadataInnerContainer>
            {brand_logo_negative && <BrandLogo src={brand_logo_negative} />}
            <h3>{title}</h3>
            <Category>{seasons_number}</Category>
            <RatingContainer>
              <Rating data={[category]} />
            </RatingContainer>
            <LongDescriptionWrapper
              dangerouslySetInnerHTML={{ __html: micro_synopsis }}
            />
            <ButtonsContainer>
              <SeeNow onClick={_ => (window.location.href = slug)}>
                <AtIcon className="play-icon" name="PlayCircle" size={30} />
                Ver ahora
              </SeeNow>
              <AddMyListButton id={id} />
            </ButtonsContainer>
          </MetadataInnerContainer>
        </MetadataContainer>
        <TrailerContainer>
          <Trailer>
            <MolTrailerPlayer
              title={title}
              trailer={trailer}
              streaming_provider={streaming_provider}
            />
          </Trailer>
        </TrailerContainer>
      </InnerWrapper>
    </Wrapper>
  );
}
