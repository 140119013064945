import React from "react";
import styled from "styled-components";
import { Colors, Fonts } from "../../theme";

export const AudioPlayerSeasonSelector = ({
  selectedSeasonRef,
  seasons,
  onSeasonSelect,
}) => {
  return (
    <Container>
      <Text>Temporadas</Text>
      <List>
        {seasons.map(({ value, title }) => (
          <SeasonItem
            isSelect={selectedSeasonRef.current === value}
            key={value}
            onClick={() => onSeasonSelect(value)}
          >
            <SeasonItemText>{title.replace("Temporada ", "")}</SeasonItemText>
          </SeasonItem>
        ))}
      </List>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Text = styled(Fonts.audioTitle)`
  color: ${Colors.white};
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
`;

const List = styled.div`
  display: flex;
  gap: 5px;
`;

const SeasonItem = styled.div`
  border: 1px solid ${Colors.white};
  padding: 6px 13px;
  background: ${Colors.black};
  color: ${Colors.white};
  cursor: pointer;
  &:hover {
    background: #a633e1;
  }

  ${({ isSelect }) =>
    isSelect &&
    `
    background: #a633e1;
  `}
`;

const SeasonItemText = styled(Text)`
  font-size: 24px;
`;
