import React, { useEffect } from "react";
import { GoogleWrapper } from "./styles";
import { bindActionCreators } from "redux";
import { loginUser } from "../../../../store/actions/auth";
import { connect } from "react-redux";
import { getKey, hasKey } from "../../../utils/helpers";
import md5 from "md5";
import { signUp } from "../utils";
import { getRefererContent } from "../../../../store/utils/helpers";
import * as jose from "jose";

const GOOGLE_CLIENT_ID =
  "888503281483-3h3evln11e0v7hqjufvmlalmtj1hjj74.apps.googleusercontent.com";

function ButtonLogin({ loginUser }) {
  const decodeJwtResponse = async token => {
    const protectedHeader = jose.decodeJwt(token);
    return protectedHeader;
  };
  const handleCredentialResponse = async jwt => {
    const response = await decodeJwtResponse(jwt.credential);
    if (hasKey(response, "email")) {
      const { email: emailUser, name, sub: userID, picture: image } = response;
      const data = {
        name,
        userID,
        image,
        email: `gg-${emailUser}`,
        pass: md5(`ggrtvcplay_${userID}`)
      };
      const { email, pass } = data;
      const result = await loginUser(email, pass);
      console.log({ result });
      if (getKey(result, "error") === "invalid_credentials") {
        await signUp(data, async () => await loginUser(email, pass));
        await loginUser(email, pass);
      }
      window.location.href = getRefererContent() || "/";
    }
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.onload = function() {
        window.google.accounts.id.initialize({
          client_id: GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse
        });
        window.google.accounts.id.renderButton(
          document.getElementById("gButtonLogon"),
          {
            theme: "filled_black",
            size: "large",
            text: "signin",
            width: "150px"
          }
        );
        window.google.accounts.id.prompt(); // also display the One Tap dialog
      };
    }
  }, []);
  return (
    <GoogleWrapper>
      <div id="gButtonLogon" />
    </GoogleWrapper>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ loginUser }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(ButtonLogin);
