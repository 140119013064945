import moment from "moment";
import { SET_GRILL } from "../constants/action-types";
const initialState = {
  date: moment().format("D/M/YYYY"),
  channel_id: -1,
  grill: []
};
export default function grill(state = initialState, action) {
  switch (action.type) {
    case SET_GRILL:
      return {
        ...state,
        ...action.content
      };
    default:
      return state;
  }
}
