import React from "react";
import Swiper from "react-id-swiper";
import "react-id-swiper/src/styles/scss/swiper.scss";
import styled, { css } from "styled-components";

import { Logo } from ".";
import SocialButtons from "../MolShareButton";
import MolTrailerPlayer from "../MolTrailerPlayer";
import CinemaPromItem from "./CinemaPromItem";
import { THEME } from ".";
import { Metrics, Wp } from "../../theme";
import { getKey } from "../../utils/helpers";
import AtDescription from "../AtDescription";
import AtTitle from "../AtTitle";

export default function CinemaPromotionItem({
  background,
  color,
  contents,
  description,
  index,
  logo,
  streaming_provider,
  theme,
  title
}) {
  const [contentSpotlighted] = contents;
  const params = {
    allowTouchMove: false,
    breakpoints: {
      3000: {
        effect: "coverflow",
        slidesPerView: 3,
        spaceBetween: 20,
        initialSlide: 1
      },
      767: {
        slidesPerView: 1.1,
        spaceBetween: 10,
        allowTouchMove: true
      }
    }
  };
  return (
    <Wrapper background={background}>
      <InnerWrapper>
        <SpotlightVideoWrapper reverse={index % 2 !== 0}>
          <SpotlightMetadata>
            <Logo logo={logo} />
            <SpotlightMetadataTitle color={color}>
              {title}
            </SpotlightMetadataTitle>
            <SpotlightMetadataDescription theme={theme}>
              {description}
            </SpotlightMetadataDescription>
            <SpotlightSocialButtonContainer>
              <SocialButtons
                label="COMPARTIR"
                title={title}
                text={description}
                size={22}
                sizeButtons={15}
              />
            </SpotlightSocialButtonContainer>
          </SpotlightMetadata>
          <SpotlightVideoPlayer>
            <Trailer>
              {contentSpotlighted &&
                contentSpotlighted.video && (
                  <MolTrailerPlayer
                    title={title}
                    trailer={contentSpotlighted.video.trailer}
                    streaming_provider={streaming_provider}
                  />
                )}
            </Trailer>
          </SpotlightVideoPlayer>
        </SpotlightVideoWrapper>
        <ContentsRelatedWrapper>
          <Swiper {...params} activeSlideKey="1">
            {contents.slice(1).map((itm, index) => (
              <div key={index}>
                <CinemaPromItem
                  key={index}
                  color={color}
                  theme={theme}
                  {...itm}
                />
              </div>
            ))}
          </Swiper>
        </ContentsRelatedWrapper>
      </InnerWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(Wp.Container)`
  display: inline-block;
  width: 100%;

  ${({ background }) =>
    !!background &&
    css`
      background-image: url(${getKey(background, "mobile.path")});
      background-size: cover;
      background-position: center center;

      @media screen and (min-width: ${Metrics.size.tablet}) {
        min-height: 610px;
        background-image: url(${getKey(background, "desktop.path")});
      }
    `};

  @media screen and (min-width: ${Metrics.size.tablet}) {
    min-height: 930px;
  }
`;

const InnerWrapper = styled(Wp.Page)`
  position: relative;
  min-height: 465px;
  margin: 100px auto 30px;

  h3 {
    font-weight: normal;
    font-size: 51px;

    @media screen and (min-width: ${Metrics.size.tablet}) {
      font-size: 42px;
    }
  }

  @media screen and (min-width: ${Metrics.size.tablet}) {
    min-height: 610px;
  }
`;

const SpotlightVideoWrapper = styled.div`
  display: flex;
  max-width: 970px;
  margin: 0 auto;
  text-align: center;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  @media screen and (min-width: ${Metrics.size.tablet}) {
    gap: 30px;
    text-align: inherit;
  }
`;
const SpotlightMetadata = styled.div`
  @media screen and (min-width: ${Metrics.size.tablet}) {
    max-width: 364px;
  }
`;

const SpotlightMetadataTitle = styled(AtTitle)`
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};
`;

const SpotlightMetadataDescription = styled(AtDescription)`
  margin: 0;
  ${({ theme }) =>
    theme &&
    css`
      color: ${theme === THEME.DARK ? "black" : "white"};
    `};
`;

const SpotlightSocialButtonContainer = styled.div`
  position: relative;
  margin: 23px 0 0;
`;

const SpotlightVideoPlayer = styled.div`
  display: none;
  @media screen and (min-width: ${Metrics.size.tablet}) {
    display: contents;
  }
`;

const Trailer = styled.div`
  width: 100%;

  @media screen and (min-width: ${Metrics.size.tablet}) {
    max-width: 768px;
    margin-top: 0;
    margin-left: 0;
  }
`;

const ContentsRelatedWrapper = styled.div`
  margin: 40px 0 0;
`;
