import React, { useCallback } from "react";
import Page from "../";
import OrLoginForm from "../../../components/OrLogin/Form";
import { setRefererContent } from "../../../../store/utils/helpers";

import { ContainerCloseButton, ContentLoginPage } from "./Login";
import { getKey, searchToObject } from "../../../utils/helpers";
import AtCloseButton from "../../../components/AtCloseButton";

const Login = ({ location: { search } }) => {
  const refer = searchToObject(search);
  setRefererContent(refer);
  const goBack = useCallback(
    () => {
      window.location.href = getKey(refer, "ref", "/");
    },
    [refer]
  );
  return (
    <Page
      id="login"
      title="Inicio de sesión"
      description="Página de inicio de sesión de RTVCPlay"
    >
      <ContentLoginPage>
        <OrLoginForm />
        <ContainerCloseButton>
          <AtCloseButton onClick={goBack} />
        </ContainerCloseButton>
      </ContentLoginPage>
    </Page>
  );
};
export default Login;
