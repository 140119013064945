import { combineReducers } from "redux";
import auth from "./auth";
import content from "./content";
import intro from "./intro";
import myList from "../reducers/myList";
import grill from "../reducers/grill";
import country from "./country";

export default combineReducers({
  auth,
  country,
  content,
  grill,
  intro,
  myList
});
