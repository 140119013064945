import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

import MolGrill from "../MolGrill/MolGrill";
import MolCalendar from "../MolCalendar";
import { fetchGrill } from "../../../server/api/fetchApi";
import { isValidArray } from "../../utils/helpers";

import {
  Container,
  Row,
  IFrame,
  Column,
  Label,
  Brand,
  Title,
  SubTitle,
  Summary,
  WrapperPlayer,
  KalturaPlayer,
  ShareButton
} from "./OrLivestreamingTv";

moment.tz.add(
  "America/Bogota|BMT -05 -04|4U.g 50 40|0121|-2eb73.I 38yo3.I 2en0|90e5"
);

const currentDate = new Date();
const fecha = `${currentDate.getFullYear()}-${currentDate.getMonth() +
  1}-${currentDate.getDate()}`;
const calendario = [];
const fechainicial = new Date();
fechainicial.setDate(fechainicial.getDate() - 7);

const fechaf = fechainicial;
for (let f = 1; f <= 15; f++) {
  fechaf.setDate(fechaf.getDate() + 1);
  calendario.push({
    key: `day${f}`,
    valueForRequest: `${fechaf.getFullYear()}-${fechaf.getMonth() +
      1}-${fechaf.getDate()}`,
    value: `${fechaf.getDate()}/${fechaf.getMonth() +
      1}/${fechaf.getFullYear()}`
  });
}

const OrLivesreamingTv = props => {
  const [grill, setGrill] = useState([]);
  const [dateSelected, setDateSelected] = useState(fecha);

  const {
    data: {
      channel: {
        hls,
        id,
        label,
        title,
        subtitle,
        image,
        description,
        player,
        slug
      },
      streaming_provider
    }
  } = props;

  const fetchDataGrill = async date => {
    const grillFetched = await fetchGrill(date, id);
    const grillData = isValidArray(grillFetched) ? grillFetched : [];
    setGrill(grillData);
    setDateSelected(date);
  };

  useEffect(() => {
    fetchDataGrill(fecha);
  }, []);

  useEffect(
    () => {
      var config = {
        targetId: "player-placeholder",
        provider: {},
        adobe: {
          name: `${title} ${subtitle}`,
          player: "rtvcplay",
          production: `${title} ${subtitle}`,
          type: "live",
          duration: 1800
        },
        rtvcplay: {
          isLive: true,
          channelId: id
        },
        sources: {
          hls: [
            {
              mimetype: "application/x-mpegurl",
              url: hls
            }
          ]
        }
      };

      if (streaming_provider === "cg_producciones_hls") {
        try {
          global.KalturaPlayer.setup(config);
        } catch (e) {
          console.error(e.message);
        }
      }
    },
    [title, subtitle, id, hls, streaming_provider]
  );

  const currentTime = moment().utcOffset(-5);
  const currentShow = !isValidArray(grill)
    ? null
    : grill.find(g => {
        const startDate = moment.tz(
          `${g.rango_de_tiempo} ${g.rango_de_tiempo_inicio}`,
          "YYYY-M-D H:mm",
          "America/Bogota"
        );
        const endDate = moment.tz(
          `${g.rango_de_tiempo} ${g.rango_de_tiempo_final}`,
          "YYYY-M-D H:mm",
          "America/Bogota"
        );
        return currentTime >= startDate && currentTime <= endDate;
      });

  let livestream;

  if (streaming_provider !== "cg_producciones_hls") {
    livestream = <IFrame aspectRatio="16-9" title={title} src={player} />;
  } else {
    livestream = (
      <WrapperPlayer>
        <KalturaPlayer className="player-placeholder" id="player-placeholder" />
      </WrapperPlayer>
    );
  }

  return (
    <Container>
      <Row className="first-row">
        <Column>
          <Label>{label}</Label>
          <Title>{title}</Title>
        </Column>
        {image && (
          <Column className="brand-container">
            <Brand src={image.logo_negative.path} />
          </Column>
        )}
      </Row>
      <Row>
        {!isValidArray(grill) ||
        (!!currentShow && currentShow.streaming === "1") ? (
          livestream
        ) : (
          <WrapperPlayer>
            <img
              alt={title}
              src="https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/field/image/banners/Contenido-No-Area.jpg"
            />
          </WrapperPlayer>
        )}
      </Row>
      <Row className="ItemCentered">
        <Column>
          <SubTitle>{subtitle}</SubTitle>
        </Column>
        <Column className="share">
          <ShareButton
            label="COMPARTIR"
            title={title}
            uri={slug}
            size={36}
            sizeButtons={18}
            text={description}
          />
        </Column>
      </Row>
      <Row>
        {description && (
          <Summary dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </Row>
      {isValidArray(grill) && (
        <>
          <MolCalendar
            fechas={calendario}
            activar
            canal={props.data.channel.id}
            fetchDataGrill={fetchDataGrill}
            dateSelected={dateSelected}
          />
          <MolGrill program={grill} />
        </>
      )}
    </Container>
  );
};

export default OrLivesreamingTv;
