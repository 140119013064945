import React, { Fragment } from "react";
import styled from "styled-components";
import { Colors, Metrics } from "../../theme";
import HorizontalList from "../OrHorizontalList";
import "./MolGallery.scss";
import AtIcon from "../AtIcon/AtIcon";
import SocialButtons from "../MolShareButton";
import LazyLoad from "react-lazyload";

const MolGallery = ({ gallery, path, titulo, descripcion }) => {
  const nuevo = JSON.parse(JSON.stringify(gallery.data));
  const datos = [];
  nuevo.map(function(nuevo) {
    if (nuevo.type === "image") {
      if (!nuevo.cover_chapter.alt) nuevo.cover_chapter.alt = "Image";
      if (!nuevo.cover_chapter.title) nuevo.cover_chapter.title = "Image";
      if (!nuevo.originales.alt) nuevo.originales.alt = "Image";
      if (!nuevo.originales.title) nuevo.originales.title = "Image";
      datos.push({
        image: {
          cover: {
            alt:
              nuevo.cover_chapter.alt !== ""
                ? nuevo.cover_chapter.alt
                : "Image",
            path: nuevo.cover_chapter.path,
            title:
              nuevo.cover_chapter.title !== ""
                ? nuevo.cover_chapter.title
                : "Image"
          },
          originales: {
            alt: nuevo.originales.alt !== "" ? nuevo.originales.alt : "Image",
            path: nuevo.originales.path,
            title:
              nuevo.originales.title !== "" ? nuevo.originales.title : "Image"
          }
        }
      });
    } else {
      datos.push({
        image: {
          cover: {
            path: nuevo.thumbnail.path,
            title: !nuevo.thumbnail.title ? nuevo.thumbnail.title : "Video",
            alt: !nuevo.thumbnail.alt ? nuevo.thumbnail.alt : "Video"
          }
        },
        video: {
          uri_embed: nuevo.uri_embed,
          title: !nuevo.thumbnail.title ? nuevo.thumbnail.title : "Video"
        }
      });
    }

    return datos;
  });

  const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
    @media ${Metrics.mediaQ.mobileS} {
    }
    @media ${Metrics.mediaQ.tablet} {
    }
    @media ${Metrics.mediaQ.laptop} {
    }
  `;
  const Footer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    align-items: center;
    justify-content: center;
  `;
  const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    align-content: center;
  `;
  const NowButton = styled.div`
    background: ${Colors.transparent};
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
  `;
  const Line = styled.div`
    color: ${Colors.white};
    border-style: solid;
    border-width: 0.75px;
    border-color: #019be3;
    margin-top: 45px;
    margin-bottom: 45px;
    display: flex;
    @media ${Metrics.mediaQ.mobileS} {
      width: 80%;
    }
    @media ${Metrics.mediaQ.tablet} {
      width: 50%;
    }
    @media ${Metrics.mediaQ.laptop} {
      width: 50%;
    }
  `;

  return (
    <Fragment>
      <PageWrapper>
        <LazyLoad>
          <HorizontalList
            key={"4745"}
            contents={datos}
            initialSlide={-1}
            view="cover_nocolor"
            isNinos={false}
            isEducation={false}
            isLightBox={true}
            showTitle={false}
            className={`contentGallery`}
            styles={`
              padding-top: 0px !important
            `}
          />
        </LazyLoad>
      </PageWrapper>
      <Footer>
        <Line />
        <ButtonContainer>
          <a href={path} style={styles.links}>
            <NowButton>
              <AtIcon name={"PlayCircle"} size={40} color={"#FFF"} />
              <div style={styles.buttonText}>&nbsp;VER AHORA</div>
            </NowButton>
          </a>
          <div className="links">
            <ul>
              <li>
                <SocialButtons
                  label="COMPARTIR"
                  title={titulo}
                  text={descripcion}
                  size={40}
                  sizeButtons={30}
                  uri={path}
                />
              </li>
            </ul>
          </div>
        </ButtonContainer>
      </Footer>
    </Fragment>
  );
};

export default MolGallery;

MolGallery.propTypes = {};

const styles = {
  container: {
    flex: 1
  },
  buttonText: {
    color: "#FFF",
    fontSize: "1rem",
    fontWeight: "bold"
  },
  links: {
    textDecoration: "None"
  },
  imgContainer: {
    position: "relative"
  }
};
