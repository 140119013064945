import styled from "styled-components";
import { Fonts } from "../../../theme";

export const Wrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 93px;
`;

export const WrapperProgressBar = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ProgressBar = styled.div`
  width: 100%;
  position: relative;
  height: 4px;
  background-color: #777777;
  cursor: pointer;
  border-top: 0.5px solid #777777;
  margin-left: 10px;
  margin-right: 10px;
`;

export const Value = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #06b0da;
  width: 0%;
  height: 100%;
`;
export const Selector = styled.span`
  position: absolute;
  top: -4px;
  right: -4px;
  border-radius: 50%;
  border: 7px solid #ffffff;
  box-shadow: 0px 0px 2px 0px #000;
`;

export const TimeText = styled(Fonts.infoFooterDuration)`
  margin: 0;
  width: 42px;
  min-width: 42px;
`;

export const WrapperButtons = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  svg {
    margin-right: 25px;
  }

  @media screen and (max-width: 767px) {
    justify-content: center;
  }
`;

export const AudioElement = styled.div`
  position: absolute;
  top: -9999px;
  left: -9999px;
`;
