import React, { Component, Fragment } from "react";
import styled from "styled-components";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { cookiesAccepted } from "../../../store/actions/intro";

import { Colors, Fonts, Wp, Metrics } from "../../theme";

const Container = styled(Wp.Container)`
  background: ${Colors.tuatara};
  z-index: 2147483646;
  width: 100vw;
  height: auto;
  padding: 20px 0px;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  @media ${Metrics.mediaQ.tablet} {
    height: 146px;
    padding: 0px;
  }
`;

const Body = styled(Wp.Page)`
  display: flex;
  flex-direction: column;
  max-width: ${Metrics.size.laptop};
  align-items: center;
  @media ${Metrics.mediaQ.tablet} {
    flex-direction: row;
  }
`;

const Text = styled(Fonts.cookieAlert)`
  color: ${Colors.alto};
  margin: 0em 0em 1.125em 0em;
  text-align: center;
  @media ${Metrics.mediaQ.tablet} {
    margin: 1em 2em 1em 0em;
    text-align: left;
  }
  a {
    color: ${Colors.alto};
    text-decoration: none;
  }
`;

const Button = styled.button`
  background: ${Colors.cerulean};
  color: ${Colors.white};
  border: none;
  font-size: 1.375em;
  font-family: "Dosis", sans-serif;
  line-height: 0.864;
  padding: 0;
  margin: 0;
  width: 224px;
  height: 59px;
  outline: none;
`;

class AtCookieAlert extends Component {
  render() {
    const { className, cookiesAccepted, cookies } = this.props;
    if (cookies) {
      return <Fragment />;
    }

    return (
      <Container className={className}>
        <Body>
          <Text>
            Esta web utiliza cookies propias o de terceros. Al continuar con la
            navegación, aceptas el uso que hacemos de ellas. Si lo deseas puedes
            modificar tus preferencias en el navegador u obtener{" "}
            <a
              href="https://www.rtvc.gov.co/politica-de-uso-de-cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              más información aquí
            </a>
            .
          </Text>
          <Button onClick={() => cookiesAccepted()}>Aceptar</Button>
        </Body>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  introFinished: state.intro.finished,
  cookies: state.intro.cookies
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ cookiesAccepted }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AtCookieAlert);
