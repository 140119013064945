import React, { Component, Fragment } from "react";
import ReactImageVideoLightbox from "react-image-video-lightbox";

import "./MolLightbox.scss";

class Lightbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lightBoxIndex: this.props.lightBoxIndex || 1
    };
  }

  render() {
    return (
      <div className="contentGeneralModalLightbox">
        <div className="contentModalLightbox display">
          <div className="contentLightbox">
            <Fragment>
              {this.props.lightBoxIndex && (
                <div className={`imagevideoligthbox`}>
                  <ReactImageVideoLightbox
                    data={this.props.lightBoxData}
                    startIndex={this.state.lightBoxIndex - 1}
                    showResourceCount={true}
                    onCloseCallback={this.props.closeLightBox}
                  />
                </div>
              )}
            </Fragment>
          </div>
        </div>
      </div>
    );
  }
}

export default Lightbox;
