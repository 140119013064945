import styled from "styled-components";

export const ContentLoginPage = styled.div`
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/field/field_cover_bg/slider/2020-03-19-La-De-Troya-1920X823.jpg);
  position: relative;
  background-size: cover;
  height: 100vh;
  & > span {
    font-size: 12px;
    font-weight: bold;
  }
  & > h1 {
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
  }
  & > img {
    max-width: 900px;
    width: 100%;
  }
  & > p {
    max-width: 900px;
    margin: auto;
    text-align: left;
    margin-top: 20px;
  }

  @media screen and (min-width: 768px) {
    padding: 50px;
  }
`;

export const ContainerCloseButton = styled.div`
  position: absolute;
  right: 10%;
  top: 5%;
`;
