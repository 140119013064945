import { Colors } from "../../../theme";
import React from "react";
import { AudioDescription } from "./index";
import styled from "styled-components";
import AtIcon from "../../AtIcon/AtIcon";
import { TreeDotsButton } from "./TreeDotsButton";

export const AudioTools = ({ playing, audio }) => {
  return (
    <Wrapper>
      <AudioDescription>{audio.duration}</AudioDescription>
      <PlayIcon
        name={playing ? "PauseCircleCustom" : "PlayCircle"}
        fill={Colors.white}
        size={40}
        className={playing ? "PauseBorder" : ""}
      />
      <TreeDotsButton audio={audio} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  height: 40px;
  align-items: center;
`;

const PlayIcon = styled(AtIcon)`
  &:hover {
    path,
    circle {
      fill: ${Colors.orangeBright};
    }
  }
`;
