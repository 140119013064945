import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { loginUser } from "../../../store/actions/auth";
import { Colors, Fonts, Metrics } from "../../theme";
import { getKey } from "../../utils/helpers";
import AtIcon from "../AtIcon/AtIcon";
import AtLogo from "../AtLogo/AtLogo";

const MIN_HEADER_HEIGHT = parseInt(Metrics.size.minHeaderHeight, 10);

const ContentLoginForm = styled.div`
  position: relative;
  max-width: 440px;
  margin: 5% auto;
  color: ${Colors.bg1};
  float: left;
  background-color: #222222;
  padding: 30px;
`;
const Title = styled(Fonts.h1)`
  color: ${Colors.cerulean};
`;
const SubTitle = styled(Fonts.slideLabel)``;
const FieldContainer = styled.div`
  user-select: none;
  margin-top: 5px;
  text-align: ${props => getKey(props, "align", "inherits")};
`;

const ButtonFacebook = styled.button`
  background: #0178f6;
  color: ${Colors.white};
  border: 1px solid #fff;
  font-size: 1.375em;
  font-family: "Dosis", sans-serif;
  line-height: 0.864;
  padding: 0;
  margin: 15px 2% 0 0;
  width: 48%;
  height: 59px;
  outline: none;
  cursor: pointer;

  &:hover {
    background: ${Colors.white};
    color: #0178f6;
  }
`;

const ButtonGoogle = styled.button`
  background: #ff0101;
  color: ${Colors.white};
  border: 1px solid #fff;
  font-size: 1.375em;
  font-family: "Dosis", sans-serif;
  line-height: 0.864;
  padding: 0;
  margin: 15px 0 0 2%;
  width: 48%;
  height: 59px;
  outline: none;
  cursor: pointer;

  &:hover {
    background: ${Colors.white};
    color: #ff0101;
  }
`;

const ErrorWrapper = styled.div`
  display: ${props => (props.hasErrors ? "flex" : "none")};
  margin-top: 5px;
  margin-bottom: 5px;
  svg {
    display: inline-flex;
  }
`;
const TextError = styled.span`
  display: inline-flex;
  color: ${Colors.orange};
  font-size: 20px;
  width: 300px;
  margin-left: 10px;
`;

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      hasErrors: false,
      modalVisible: false,
      loading: false
    };
  }
  async handleSubmit(event) {
    event.preventDefault();
    if (event.target.checkValidity()) {
      this.setState({
        loading: true
      });
      const result = await this.props.loginUser(
        event.target.field_e.value,
        event.target.field_p.value
      );
      if (!result.error) {
        this.setState({ hasErrors: false, loading: false });
        window.location.href = "/";
      } else {
        this.setState({
          hasErrors: true,
          modalVisible: false,
          modalMessage:
            "Ingresó una combinación incorrecta de nombre de usuario y contraseña",
          loading: false
        });
      }
    } else {
      if (
        event.target.field_e.value !== "" &&
        !event.target.field_e.checkValidity()
      ) {
        this.setState({
          hasErrors: true,
          modalVisible: false,
          modalMessage: "Ingrese un email válido."
        });
      } else {
        this.setState({
          hasErrors: true,
          modalVisible: false,
          modalMessage: "Todos los campos son necesarios."
        });
      }
    }
  }
  openModal() {
    this.setState({
      modalVisible: false
    });
  }

  closeModal() {
    this.setState({
      modalVisible: false
    });
  }
  setShowForgotForm() {
    if (this.props.setShowForgotForm !== undefined) {
      this.setState({
        loading: true
      });
      this.props.setShowForgotForm(true);
    }
  }
  render() {
    const { hasErrors } = this.state;
    const expanded = this.state.headerHeight !== MIN_HEADER_HEIGHT;
    return (
      <ContentLoginForm>
        <AtLogo className="logo" expanded={expanded} />
        <Title>BIENVENIDO A LA OPCIÓN DE ENTRETENIMIENTO ONLINE DE RTVC.</Title>
        <ErrorWrapper hasErrors={hasErrors}>
          <AtIcon name="ErrorOutline" size={48} color={Colors.orange} />
          <TextError>{this.state.modalMessage}</TextError>
        </ErrorWrapper>
        <SubTitle>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo.
        </SubTitle>

        <FieldContainer>
          <ButtonFacebook onClick={_ => (window.location.href = "/mi-perfil")}>
            Editar mi perfil
          </ButtonFacebook>
          <ButtonGoogle onClick={_ => (window.location.href = "/")}>
            Empezar a ver
          </ButtonGoogle>
        </FieldContainer>
      </ContentLoginForm>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ loginUser }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(Welcome);
