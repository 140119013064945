import styled, { css } from "styled-components";
import { Colors } from "../../theme";

export const Container = styled.a`
  text-align: center;
  text-decoration: none;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
  ${props =>
    props.active &&
    css`
      background: ${Colors.cerulean};
      margin-top: -10px;
      padding: 10px;
      > span {
        color: ${Colors.white};
      }
    `};
`;

export const Day = styled.span`
  color: ${Colors.gray};
`;
export const Number = styled.div`
  color: ${Colors.white};
`;
