import React, { Component } from "react";
import Modal from "react-awesome-modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loginUser } from "../../../../store/actions/auth";
import { Colors, Metrics } from "../../../theme";
import AtIcon from "../../AtIcon/AtIcon";
import AtLogo from "../../AtLogo/AtLogo";
import FacebookLogin from "../../External/FacebookLogin";
import GoogleLogin from "../../External/GoogleLogin";
import ErrorBoundary from "../../ErrorBoundary";
import {
  Button,
  ButtonSignUp,
  ContentLoginForm,
  ErrorWrapper,
  Field,
  FieldContainer,
  Link,
  ModalContainer,
  Separator,
  SubTitle,
  TextError,
  Title,
  ContainerSocialButtons
} from "./LoginForm";
import { getRefererContent } from "../../../../store/utils/helpers";
import { getReCaptchaKey } from "../../../utils/helpers";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider
} from "react-google-recaptcha-v3";

const MIN_HEADER_HEIGHT = parseInt(Metrics.size.minHeaderHeight, 10);

class FormLogin extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      hasErrors: false,
      modalVisible: false,
      googleToken: null,
      loading: false,
      ref: getRefererContent() || "/"
    };
  }
  async handleSubmit(event) {
    event.preventDefault();
    if (event.target.checkValidity() && this.state.googleToken !== undefined) {
      this.setState({
        loading: true
      });
      const result = await this.props.loginUser(
        event.target.field_e.value,
        event.target.field_p.value
      );
      if (!result.error) {
        this.setState({ hasErrors: false, loading: false });

        window.location.href = this.state.ref;
      } else {
        this.setState({
          hasErrors: true,
          modalVisible: false,
          modalMessage:
            "Ingresó una combinación incorrecta de nombre de usuario y contraseña",
          loading: false
        });
      }
    } else {
      if (
        event.target.field_e.value !== "" &&
        !event.target.field_e.checkValidity()
      ) {
        this.setState({
          hasErrors: true,
          modalVisible: false,
          modalMessage: "Ingrese un email válido."
        });
      } else {
        this.setState({
          hasErrors: true,
          modalVisible: false,
          modalMessage: "Todos los campos son necesarios."
        });
      }
    }
  }
  openModal() {
    this.setState({
      modalVisible: false
    });
  }

  closeModal() {
    this.setState({
      modalVisible: false
    });
  }
  setShowForgotForm() {
    const ref = getRefererContent();
    window.location.href = `/login/recordar-contrasena${ref && `?ref=${ref}`}`;
  }
  signUpNow() {
    const currentRef = getRefererContent();
    window.location.href = `/registro${currentRef && `?ref=${currentRef}`}`;
  }
  setGoogleToken(token) {
    this.setState({ googleToken: token });
  }
  render() {
    const { hasErrors } = this.state;
    const expanded = this.state.headerHeight !== MIN_HEADER_HEIGHT;
    return (
      <ErrorBoundary>
        <ContentLoginForm>
          <AtLogo className="logo" expanded={expanded} />
          <Title>Iniciar sesión</Title>
          <ErrorWrapper hasErrors={hasErrors}>
            <AtIcon name="ErrorOutline" size={48} color={Colors.orange} />
            <TextError>{this.state.modalMessage}</TextError>
          </ErrorWrapper>
          <SubTitle>
            <b>
              RTVCPlay tiene a tu disposición, en un solo lugar y de forma
              gratuita,
            </b>{" "}
            una gran variedad de contenidos audiovisuales y sonoros de calidad.
          </SubTitle>
          <form onSubmit={this.handleSubmit} noValidate>
            <FieldContainer>
              <Field
                name="e"
                type="email"
                disabled={this.state.loading && "disabled"}
                required
                className={hasErrors ? "hasErrors" : ""}
                placeholder="Correo electrónico"
              />
            </FieldContainer>
            <FieldContainer>
              <Field
                name="p"
                type="password"
                disabled={this.state.loading && "disabled"}
                required
                className={hasErrors ? "hasErrors" : ""}
                placeholder="Contraseña"
              />
            </FieldContainer>
            <FieldContainer>
              <Link href={_ => {}} onClick={_ => this.setShowForgotForm()}>
                Olvidé mi contraseña
              </Link>
            </FieldContainer>
            <FieldContainer>
              <Button disabled={this.state.loading && "disabled"}>
                Ingresar
              </Button>
            </FieldContainer>
          </form>
          <Separator />
          <FieldContainer>
            <Title>REGISTRARSE</Title>
            <ButtonSignUp onClick={this.signUpNow}>Crear cuenta</ButtonSignUp>
            <ContainerSocialButtons>
              <FacebookLogin />
              <GoogleLogin />
            </ContainerSocialButtons>
          </FieldContainer>
          <ModalContainer>
            <Modal
              visible={this.state.modalVisible}
              width="370"
              height="142"
              effect="fadeInUp"
              onClickAway={() => this.closeModal()}
            >
              <div>
                <h1>Error</h1>
                <p>{this.state.modalMessage}</p>
                <Button onClick={() => this.closeModal()}>CERRAR</Button>
              </div>
            </Modal>
          </ModalContainer>
          <GoogleReCaptchaProvider reCaptchaKey={getReCaptchaKey()}>
            <GoogleReCaptcha onVerify={token => this.setGoogleToken(token)} />
          </GoogleReCaptchaProvider>
        </ContentLoginForm>
      </ErrorBoundary>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ loginUser }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(FormLogin);
