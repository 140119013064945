import React from "react";
import styled from "styled-components";
import AtProgram from "../AtProgram/AtProgram";

class MolGrill extends React.Component {
  render() {
    const Container = styled("div")`
      margin-bottom: 2rem;
    `;
    return (
      <Container>
        {this.props.program.map(programa => {
          const diasel = programa.rango_de_tiempo.split("-");
          const tiempo = programa.rango_de_tiempo_inicio.split(":");
          const tiempof = programa.rango_de_tiempo_final.split(":");
          const fechahoy = new Date();
          const fechasel = new Date(diasel[0], diasel[1] - 1, diasel[2]);
          let active = false;
          if (
            fechahoy.getFullYear() === fechasel.getFullYear() &&
            fechahoy.getMonth() === fechasel.getMonth() &&
            fechahoy.getDate() === fechasel.getDate()
          ) {
            const horaactual =
              fechahoy.getHours() * 100 + fechahoy.getMinutes();
            const horainicio = parseInt(tiempo[0]) * 100 + parseInt(tiempo[1]);
            const horafinal = parseInt(tiempof[0]) * 100 + parseInt(tiempof[1]);
            if (horaactual >= horainicio && horaactual <= horafinal) {
              active = true;
            }
          }
          let cero = "";
          if (tiempo[0] <= 9) {
            cero = "0";
          }
          return (
            <AtProgram
              key={programa.nid}
              hora={cero + programa.rango_de_tiempo_inicio}
              imagen={programa.miniatura}
              programa={programa.programa}
              activo={active}
              inicio={programa.rango_de_tiempo_inicio}
              final={programa.rango_de_tiempo_final}
            />
          );
        })}
      </Container>
    );
  }
}

export default MolGrill;
