import React, { Component } from "react";
import styled from "styled-components";

import AtIconButton from "../AtIconButton/AtIconButton";
import AtSearchFocus from "../AtSearchFocus/AtSearchFocus";

import { Colors, Wp } from "../../theme";

const Container = styled(Wp.Container)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  form {
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
  }
`;

const RenderSearch = styled(AtSearchFocus)``;

class MolInputSearch extends Component {
  static defaultProps = {
    mobile: false,
    buttonLeft: false,
    buttonRight: false,
    onInputShow: () => {},
    onInputHide: () => {}
  };

  _hideInput = () => {
    this.setState({ showInput: false });
    this.props.onInputHide();
  };
  _handleChange = ev => {
    this.setState({ value: ev.target.value });
  };
  _handleSubmit = ev => {
    if (this.state.value !== "") {
      this.setState({ invalid: false });
      document.location.href = `/buscar/${this.state.value}`;
    } else {
      this.setState({ invalid: true });
    }
    ev.preventDefault();
  };
  _handleInputFocus = () => {
    this.setState({ focus: true });
  };
  _close = () => {
    this.setState({ focus: false });
  };
  constructor(props) {
    super(props);
    this.state = {
      showInput: props.mobile,
      value: "",
      invalid: false,
      focus: false
    };
    this._close = this._close.bind(this);
  }

  render() {
    const { className, buttonLeft, buttonRight } = this.props;
    return (
      <Container className={className}>
        <form onSubmit={this._handleSubmit}>
          {buttonLeft && (
            <AtIconButton
              name="search"
              iconSize={22}
              color={Colors.transparent}
              type="submit"
              onClick={this._handleInputFocus}
              className="iconsearch"
            />
          )}
          {this.state.focus ? (
            <RenderSearch focus={this.state.focus} close={this._close} />
          ) : null}
          {buttonRight && (
            <AtIconButton
              name="search"
              iconSize={22}
              color={Colors.transparent}
              type="submit"
              onClick={this._handleInputFocus}
              className="iconsearch"
            />
          )}
        </form>
      </Container>
    );
  }
}

export default MolInputSearch;
