import React, { Component } from "react";
import styled from "styled-components";
import { rgba } from "polished";

import AtImgLink from "../AtImgLink/AtImgLink";

import { Colors, Wp, Images, Metrics } from "../../theme";

const Container = styled(Wp.Container)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;
const ImgLink = styled(AtImgLink)`
  height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
  @media ${Metrics.mediaQ.mobileM} {
    margin-right: 18px;
    margin-left: 18px;
  }
  @media ${Metrics.mediaQ.tablet} {
    margin-right: 0px;
    margin-left: 0px;
  }
  box-sizing: border-box;
  display: flex;
  align-items: center;
  img {
    width: initial;
    max-width: initial;
    height: 100%;
  }
  :first-child {
    @media ${Metrics.mediaQ.tablet} {
      margin-left: 0px;
      :after {
        content: "";
        width: 1px;
        height: 38px;
        background-color: ${rgba(Colors.gray, 0.5)};
        margin-right: 10px;
        margin-left: 8px;
      }
    }
  }
  :last-child {
    @media ${Metrics.mediaQ.tablet} {
      margin-right: 0px;
    }
  }
`;
class MolBrands extends Component {
  static defaultProps = {
    data: [
      {
        image: {
          source: Images.rtvc,
          alt: "RTVC Sistema de Medios Públicos",
          title: "RTVC Sistema de Medios Públicos"
        },
        link: "#"
      },
      {
        image: {
          source: Images.canalInstitucional,
          alt: "Canal Institucional",
          title: "Canal Institucional"
        },
        link: "#"
      },
      {
        image: {
          source: Images.senalColombia,
          alt: "Señal Colombia",
          title: "Señal Colombia"
        },
        link: "#"
      },
      {
        image: {
          source: Images.radioNacional,
          alt: "Radio Nacional",
          title: "Radio Nacional"
        },
        link: "#"
      },
      {
        image: {
          source: Images.radionica,
          alt: "Radiónica",
          title: "Radiónica"
        },
        link: "#"
      },
      {
        image: {
          source: Images.senalMemoria,
          alt: "Señal Memoria",
          title: "Señal Memoria"
        },
        link: "https://www.senalmemoria.co/"
      }
    ]
  };
  render() {
    const { data, className } = this.props;
    return (
      <Container className={className}>
        {data.map((itm, index) => (
          <ImgLink
            key={index}
            href={itm.link}
            imageSource={itm.image.source}
            imageAlt={itm.image.alt}
            imageTitle={itm.image.title}
            target={itm.target}
          />
        ))}
      </Container>
    );
  }
}

export default MolBrands;
