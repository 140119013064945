import React from "react";
import styled from "styled-components";

import AddMyListButton from "../MolAddMyListButton";
import SocialButtons from "../MolShareButton";
import { setDataRecommender } from "../../utils/helpers/recommender";
import { Wp, Colors, Metrics } from "../../theme";
import { LongDescriptionWrapper } from "../../layouts/widgets/Opening/CommingSoon/CommingSoon";
import AtIcon from "../AtIcon/AtIcon";
import { CreditButton, CreditButtonType } from "./CreditButton";
import { useAudioPlayer } from "../../contexts/AudioPageContext";
import MolLikeButton from "../MolLikeButton";

/**
 * Podcast Opening Layout
 *
 * @param {object} props - podcast opening props
 * @returns {JSX.Element}
 */
function PodcastOpening({
  id,
  title,
  description,
  longDescription,
  slug,
  taxonomy,
  topics,
  resource,
  infoAdditional,
}) {
  setDataRecommender(id, new Date().getTime(), "CLICK", 0);
  const {
    image: {
      cover_podcast: { path: coverPodcast },
    },
  } = resource;
  const { value: category } =
    taxonomy.find(({ vid }) => vid === "categories") || {};
  const { value: brand } = taxonomy.find(({ vid }) => vid === "brands") || {};
  const { value: topicsName } = topics || {};
  const { isPlaying, toggle, audioCredit, currentIndex, setCurrentIndex } = useAudioPlayer();

  const onPlay = () => {
    if (currentIndex === -1) {
      setCurrentIndex(0);
    } else {
      toggle();
    }
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <Category>{category}</Category>
        <InfoContainer>
          <MetadataContainer>
            <Brand>{brand}</Brand>
            <TitleContainer>
              <Title>{title}</Title>
              <TitleButtonsContainer>
                <LikeContainer>
                  <MolLikeButton
                    size={20}
                    idVideo={id}
                    label="ME GUSTA"
                    icon="Heart"
                    colorSelected={Colors.raljah}
                  />
                </LikeContainer>
                <PlayButton onClick={onPlay}>
                  <PlayIcon
                    name={isPlaying ? "PauseCircleCustom" : "PlayCircle"}
                    size={40}
                  />
                </PlayButton>
              </TitleButtonsContainer>
            </TitleContainer>
            <Topics>{topicsName}</Topics>
            <DescriptionWrapper
              dangerouslySetInnerHTML={{ __html: longDescription }}
            />
            <ButtonsContainer>
              <AddToMyListContainer>
                <AddMyListButton id={id} size={36} />
              </AddToMyListContainer>
              <SocialButtons
                label="COMPARTIR"
                title={title}
                text={description}
                size={36}
                sizeButtons={18}
                uri={slug}
              />
              <CreditButton
                size={36}
                productionTitle={title}
                brand={brand}
                coverPodcast={coverPodcast}
                infoAdditional={infoAdditional}
              />
              <CreditButton
                hidden
                size={36}
                productionTitle={title}
                brand={brand}
                coverPodcast={coverPodcast}
                type={CreditButtonType.audio}
                audioTitle={audioCredit && audioCredit.title}
                duration={audioCredit && audioCredit.duration}
                infoAdditional={
                  (audioCredit && audioCredit.infoAdditional) || infoAdditional
                }
                chapterNumber={audioCredit && audioCredit.chapter_number}
                open={audioCredit ? audioCredit.open : null}
              />
            </ButtonsContainer>
          </MetadataContainer>
        </InfoContainer>
      </InnerWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const InnerWrapper = styled(Wp.Page)`
  position: relative;
  padding: 67px 0 20px;
  @media screen and (min-width: ${Metrics.size.tablet}) {
    padding: 100px 0 40px;
  }
`;
const Category = styled.p`
  padding: 0;
  font-size: 18px;
  font-family: Dosis, serif;
  color: ${Colors.white};
  line-height: 1.2;
  font-weight: 500;
  margin: 0 10px 0;
  max-width: 1050px;
  @media screen and (min-width: ${Metrics.size.tablet}) {
    font-size: 28px;
    margin: 0 auto;
  }
`;

const InfoContainer = styled.div`
  width: 100%;
  margin: 90px auto 0;
  position: relative;

  @media screen and (min-width: ${Metrics.size.tablet}) {
    display: flex;
    margin: 88px auto 0;
  }
`;

const MetadataContainer = styled.div`
  z-index: 1;
  position: relative;

  @media screen and (min-width: ${Metrics.size.tablet}) {
  }
`;

const Brand = styled.p`
  padding: 0;
  font-size: 22px;
  font-family: Dosis, sans-serif;
  color: ${Colors.white};
  line-height: 1.2;
  font-weight: 500;
  margin: 0 0 20px;
`;

const TitleContainer = styled.div`
  margin: 0 0 10px;
  padding: 0;
  display: flex;
  gap: 40px;
`;

const Title = styled.h1`
  font-family: "BebasNeue Book", sans-serif;
  color: ${Colors.orangeBright};
  letter-spacing: 0;
  line-height: 0.8;
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-size: 62px;

  @media screen and (max-width: 767px) {
    font-size: 42px;
    min-height: 90px;
    line-height: 38px;
  }
`;

const TitleButtonsContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const LikeContainer = styled.div`
  border: 2px solid ${Colors.white};
  border-radius: 100%;
  justify-items: center;
  align-items: center;
  cursor: pointer;
  height: 37px;
  width: 37px;
  display: flex;
  place-content: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
    border-color: ${Colors.raljah};
    svg {
      fill: ${Colors.raljah};
      color: ${Colors.raljah};
    }
  }
  &:active {
    transform: scale(0.9);
  }
`;

const PlayButton = styled.a`
  display: flex;
  height: 40px;
  width: 40px;
`;

const PlayIcon = styled(AtIcon)`
  background-color: ${Colors.raljah};
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
`;

const Topics = styled.div`
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-family: Dosis, sans-serif;
  font-weight: 600;
  color: ${Colors.nobel};
  line-height: 1.2;
`;

const DescriptionWrapper = styled(LongDescriptionWrapper)`
  &&& {
    font-size: 22px;
  }
  & * {
    font-size: 22px;
  }
  p {
    margin: 0;
  }
  line-height: 22px;
  margin: 10px 0 20px;

  a {
    color: ${Colors.white};
    font-size: 22px;
    font-weight: 500;
    line-height: 1;
  }

  @media screen and (max-width: 767px) {
    display: block;
    margin: 10px 0 20px;
  }
`;

const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  gap: 20px;
  padding: 0 0 10px;
  button {
    margin: 0;
    padding: 0;
  }

  @media screen and (min-width: ${Metrics.size.tablet}) {
    padding: 0;
  }

  @media screen and (max-width: 767px) {
    justify-content: center;
    a {
      flex-direction: column;
      align-items: center;
      span {
        font-weight: normal;
        margin: 5px 0 0;
        font-size: 14px;
      }
    }
  }
`;

const AddToMyListContainer = styled.div`
  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }
`;

export default PodcastOpening;
