import React from "react";
import AtBadge from "../../AtBadge/AtBadge";
import {
  BrandContainer,
  Title,
  Summary,
  ToolbarButtons,
  TextContainer,
  LabelContainer,
  LitlePlayIcon,
  SocialButtonsWrapper
} from "./Metadata";
import SocialButtons from "../../MolShareButton";

export default function Metadata({
  brand,
  category,
  title,
  micro_synopsis,
  label,
  slug
}) {
  return (
    <BrandContainer hideLogo>
      <TextContainer>
        <AtBadge color={brand.color_label} el="h3">
          {category}
        </AtBadge>
        <Title className="title">{title}</Title>
        <Summary>{micro_synopsis}</Summary>
        <ToolbarButtons>
          {label && (
            <LabelContainer>
              <LitlePlayIcon
                className="play-icon"
                name="PlayCircle"
                size={30}
              />
              <span>{label}</span>
            </LabelContainer>
          )}
          <LabelContainer>
            <SocialButtonsWrapper>
              <SocialButtons
                label="COMPARTIR"
                title={title}
                text={micro_synopsis}
                size={30}
                sizeButtons={20}
                uri={slug}
              />
            </SocialButtonsWrapper>
          </LabelContainer>
        </ToolbarButtons>
      </TextContainer>
    </BrandContainer>
  );
}
