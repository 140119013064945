import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import styled from "styled-components";

import AtIcon from "../../../app/components/AtIcon/AtIcon";
import { Fonts } from "../../theme";
import {
  addToMyList,
  getMyList,
  isUserLogged,
  removeFromMyList
} from "../../../store/utils/helpers";

import MyListToolTip from "./MyListToolTip";

import { hasUserFeaturesEnable } from "../../utils/helpers";

const MolMyListButton = ({ id, label, size, isLogged, myList }) => {
  const [isOpen, setOpen] = useState(false);
  const color = { color: myList ? "#3C63AD" : "#FFFFFF" };
  const currentId = `btn-add-myList-${id}`;

  const addMyList = useCallback(
    event => {
      event.preventDefault();
      event.currentTarget.focus();

      if (!isLogged) {
        setOpen(!isOpen);
      } else {
        if (myList) {
          removeFromMyList(id);
        } else {
          addToMyList(id);
        }
      }
    },
    [isLogged, isOpen, setOpen, myList, id]
  );

  return (
    hasUserFeaturesEnable() && (
      <div>
        <MyListButtonContainer onClick={addMyList} id={currentId} type="button">
          <AtIcon name="MyListCustom" size={size || 20} color={color.color} />{" "}
          <Label style={color}>{label || ""}</Label>
        </MyListButtonContainer>
        <MyListToolTip
          currentId={currentId}
          isOpen={isOpen}
          setOpen={setOpen}
        />
      </div>
    )
  );
};

/**
 * Connector to subscribe on myList store changes
 * @param {Object} state of the page
 * @returns {{myList: Object, isLogged: Object}}
 */
const mapStateToProps = state => {
  return {
    myList: getMyList(state),
    isLogged: isUserLogged(state)
  };
};

const MyListButtonContainer = styled(Button)`
  display: flex;
  cursor: pointer;
  background: transparent;
  border: 0;
  svg {
    display: inline-flex;
  }
  span {
    display: inline-flex;
  }
`;

const Label = styled(Fonts.shareButton)`
  align-self: center;
`;

export default connect(mapStateToProps)(MolMyListButton);
