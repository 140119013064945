import React, { useState, useCallback } from "react";
import Modal from "react-awesome-modal";
import { useDropzone } from "react-dropzone";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3";
import { getKey, getReCaptchaKey, hasKey } from "../../../utils/helpers";
import AtIcon from "../../AtIcon/AtIcon";
import {
  signUpUser,
  uploadImageForUser,
  fetchAllPreferences,
  updateUser
} from "../../../../store/actions/auth";
import {
  ContentLoginForm,
  Separator,
  Title,
  SubTitle,
  FieldContainer,
  Field,
  Button,
  ModalContainer,
  ListColumn,
  ListColumnItem,
  DropZone,
  PreviewImage,
  DropErrorMessage,
  buttonCloseModal,
  ErrorWrapper,
  TextError,
  IconCircle,
  Span,
  Link,
  ContentErrorForm
} from "./SignupForm";
import { Colors } from "../../../theme";
import { REGEX_PASSWORD } from "../constants";
import { getCurrentUser, isUserLogged } from "../../../../store/utils/helpers";

export default function FormSignUp() {
  const maxSize = 1048576;
  const isLogged = isUserLogged();
  const user = getCurrentUser();

  const [errors, setErrors] = useState({
    has: false,
    message: "",
    title: "Error"
  });
  const [googleToken, setGoogleToken] = useState(null);
  const [generalPreferences, setPreferences] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const isFromSocialNetwork = useCallback(
    () => user.from_social_network === "1" || user.from_social_network === true,
    [user]
  );

  if (generalPreferences.length === 0) {
    setTimeout(async () => {
      setPreferences(await fetchAllPreferences());
    }, 100);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const dataForm = event.target;
    const hasGoodPassword =
      dataForm.field_p.value === "" ||
      dataForm.field_p.value === undefined ||
      REGEX_PASSWORD.test(dataForm.field_p.value);
    if (dataForm.checkValidity() && googleToken !== null && hasGoodPassword) {
      if (
        !dataForm.field_birthday.value.match(
          // eslint-disable-next-line
          /^[0-9]{4}[\-]{1}[0-9]{2}[\-]{1}[0-9]{2}$/g
        )
      ) {
        displayError({
          message: "El formato de la fecha debe ser: YYYY-MM-DD (año-mes-día)."
        });
        return;
      }

      setLoading(true);
      const preferences = [];
      for (var i = 0; i <= 20; i++) {
        if (dataForm["field_check_" + i].checked) {
          preferences.push({
            target_id: dataForm["field_check_" + i].value
          });
        }
      }
      let user_picture = undefined;

      if (acceptedFiles.length > 0) {
        const imageResult = await uploadImageForUser(acceptedFiles[0]);
        const fid = getKey(imageResult, "fid[0].value", null);
        if (fid !== null) {
          user_picture = [{ target_id: fid }];
        }
      }
      let pass = isNullOrEmpty(dataForm.field_p.value)
        ? undefined
        : [
            {
              value: dataForm.field_p.value
            }
          ];
      const userInfo = {
        name:
          isLogged || !dataForm.field_e.value
            ? undefined
            : {
                value: dataForm.field_e.value
              },
        field_full_name: !dataForm.field_full_nane.value
          ? undefined
          : {
              value: dataForm.field_full_nane.value
            },
        mail: !dataForm.field_e.value
          ? undefined
          : {
              value: dataForm.field_e.value
            },
        field_gender: {
          value: dataForm.field_gender.value === "Masculino" ? "M" : "F"
        },
        field_cell_phone_number: !dataForm.field_cellphone.value
          ? null
          : {
              value: dataForm.field_cellphone.value.substring(0, 20)
            },
        field_birthdate: !dataForm.field_birthday.value
          ? undefined
          : {
              value: dataForm.field_birthday.value
            },
        field_preferences: preferences,
        pass,
        user_picture
      };
      if (isLogged) {
        const result = await updateUser(userInfo, user);

        if (result && result.error !== undefined) {
          displayError({ message: result.error });
        } else {
          displayInfo({ message: "Los datos fueron actualizados." });
        }
        return;
      }
      const result = await signUpUser(userInfo);

      if (!result.error) {
        setErrors({ has: false });
        if (window !== undefined) {
          window.location.href = "/login";
        }
      } else {
        displayError({ message: result.error });
        setLoading(false);
      }
    } else if (
      dataForm.field_p.value !== "" &&
      dataForm.field_p.value !== undefined &&
      !hasGoodPassword
    ) {
      displayError({
        message:
          "La contraseña debe incluir: 8 caracteres, al menos una letra y al menos un número o un simbolo"
      });
      setLoading(false);
    }
  }

  function isNullOrEmpty(value) {
    return (
      value === null ||
      value === undefined ||
      value.replace(/([ ])/gs, "") === ""
    );
  }
  function displayError(data) {
    setErrors({
      ...data,
      title: "Error",
      has: true
    });
    setModalVisible(true);
    setLoading(false);
  }
  function displayInfo(data) {
    setErrors({
      ...data,
      has: false,
      title: "Mensaje"
    });
    setModalVisible(true);
    setLoading(false);
  }
  function closeModal() {
    setModalVisible(false);
  }
  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.length > 0 && readURL(acceptedFiles[0]);
  }, []);

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    acceptedFiles,
    rejectedFiles
  } = useDropzone({
    onDrop,
    accept: "image/png, image/jpg, image/jpeg",
    minSize: 0,
    maxSize
  });
  const isFileTooLarge =
    rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  function readURL(file) {
    if (file !== null && typeof FileReader !== "undefined") {
      var reader = new FileReader();

      reader.onload = function(e) {
        const userWithoutImg = document.querySelector(".UserWithouImage");
        if (userWithoutImg !== null && userWithoutImg !== undefined) {
          userWithoutImg.style.display = "none";
        }
        document
          .querySelector("#previewImage")
          .setAttribute("src", e.target.result);
      };

      reader.readAsDataURL(file);
    }
  }
  // eslint-disable-next-line
  Date.prototype.formatDate = function() {
    const mm = this.getMonth() + 1;
    const dd = this.getDate();
    const yy = this.getFullYear();
    return (
      yy + "-" + (mm < 10 ? "0" : "") + mm + "-" + (dd < 10 ? "0" : "") + dd
    );
  };

  return (
    <ContentLoginForm>
      <Title>{isLogged ? "Mi Perfil" : "Registro"}</Title>
      <SubTitle>
        <b>DATOS GENERALES</b>
      </SubTitle>
      <form onSubmit={handleSubmit} noValidate autocomplete="off">
        <FieldContainer>
          <DropZone {...getRootProps()}>
            <input {...getInputProps()} />
            {acceptedFiles.length === 0 &&
              !isDragActive &&
              !isLogged && (
                <PreviewImage>
                  <AtIcon name="User" size={100} color={Colors.white} />
                  <SubTitle>
                    Arrastra tu imagen de perfil o haz clic para seleccionarla
                  </SubTitle>
                </PreviewImage>
              )}
            {isDragActive &&
              !isDragReject && <SubTitle>Suelta el archivo</SubTitle>}
            {isDragReject && (
              <DropErrorMessage>El archivo no es una imagen</DropErrorMessage>
            )}
            {isFileTooLarge && (
              <DropErrorMessage>
                El archivo es muy grande, limite: 1MB
              </DropErrorMessage>
            )}
            {!isDragActive &&
              (acceptedFiles.length > 0 || isLogged) && (
                <PreviewImage>
                  <img
                    id="previewImage"
                    alt=""
                    src={
                      isLogged
                        ? getKey(
                            user,
                            "image.picture.path",
                            getKey(user, "image_social", "#")
                          )
                        : "#"
                    }
                  />
                  {isLogged &&
                    (!hasKey(user, "image.picture.path") &&
                      !hasKey(user, "image_social")) && (
                      <AtIcon
                        name="User"
                        className="UserWithouImage"
                        size={100}
                        color={Colors.white}
                      />
                    )}
                  <SubTitle>
                    Arrastra tu imagen de perfil o haz clic para seleccionarla
                  </SubTitle>
                </PreviewImage>
              )}
          </DropZone>
          <br />
          <Field
            name="full_nane"
            type="text"
            className="fullNameRule"
            label="Nombre Completo"
            disabled={loading && "disabled"}
            onlyChar
            style={{ display: "block" }}
            value={getKey(user, "full_name")}
          />
        </FieldContainer>
        <ListColumn>
          <ListColumnItem>
            <FieldContainer>
              <Field
                name="birthday"
                type="date"
                label="Fecha de nacimiento"
                disabled={loading && "disabled"}
                placeholder="YYYY-MM-DD"
                value={
                  isLogged
                    ? new Date(
                        getKey(
                          user,
                          "birthdate",
                          new Date("1970-01-01").getTime()
                        ) * 1000
                      ).formatDate()
                    : undefined
                }
              />
            </FieldContainer>
          </ListColumnItem>
          <ListColumnItem>
            <FieldContainer>
              <Field
                name="gender"
                type="select"
                label="Género"
                disabled={loading && "disabled"}
                list="genderList"
              >
                <option
                  selected={getKey(user, "gender") === "M"}
                  value="Masculino"
                >
                  Masculino
                </option>
                <option
                  selected={getKey(user, "gender") === "F"}
                  value="Femenino"
                >
                  Femenino
                </option>
              </Field>
            </FieldContainer>
          </ListColumnItem>
          <ListColumnItem>
            <FieldContainer>
              <Field
                name="cellphone"
                maxlength="20"
                type="text"
                label="Celular"
                disabled={loading && "disabled"}
                value={getKey(user, "cell_phone_number")}
              />
            </FieldContainer>
          </ListColumnItem>
        </ListColumn>
        <Separator />
        <FieldContainer>
          <SubTitle>
            <b>PREFERENCIAS</b>
          </SubTitle>
          <Field
            name="preferences"
            type="listCheckBox"
            label=""
            disabled={loading && "disabled"}
            className={"preferencesField " + (errors.has ? "has" : "")}
            checks={generalPreferences}
            selected={getKey(user, "preferences")}
          />
        </FieldContainer>
        <Separator />
        <SubTitle>
          <b>
            {!isFromSocialNetwork()
              ? "USUARIO"
              : "USUARIO LOGUEADO DESDE UNA RED SOCIAL"}
          </b>
        </SubTitle>
        <ListColumn>
          <ListColumnItem>
            <FieldContainer>
              <Field
                name="e"
                type="email"
                label="Correo electrónico"
                disabled={(loading && "disabled") || isLogged}
                required
                value={getKey(user, "mail")}
              />
            </FieldContainer>
          </ListColumnItem>
          <ListColumnItem>
            <FieldContainer>
              <Field
                name="p"
                type="password"
                autocomplete="new-password"
                label={"Nueva contraseña"}
                disabled={(loading && "disabled") || isFromSocialNetwork()}
              />
            </FieldContainer>
          </ListColumnItem>
        </ListColumn>
        <FieldContainer align="right">
          <GoogleReCaptchaProvider reCaptchaKey={getReCaptchaKey()}>
            <GoogleReCaptcha onVerify={token => setGoogleToken(token)} />
          </GoogleReCaptchaProvider>
          <Button disabled={loading && "disabled"}>Guardar</Button>
        </FieldContainer>
      </form>
      <ModalContainer>
        <Modal
          visible={modalVisible}
          width={getKey(errors, "width", "400")}
          height={getKey(errors, "height", "400")}
          effect="fadeInUp"
          onClickAway={closeModal}
        >
          <Link onClick={closeModal} className="closeModalButton">
            <Span>Cerrar</Span>
            <IconCircle name="Close" size={22} />
          </Link>
          <ContentErrorForm>
            <Title>{errors.title}</Title>
            <ErrorWrapper>
              <AtIcon name="ErrorOutline" size={48} color={Colors.orange} />
              <TextError>{errors.message}</TextError>
            </ErrorWrapper>
            <Button style={buttonCloseModal} onClick={closeModal}>
              Cerrar
            </Button>
          </ContentErrorForm>
        </Modal>
      </ModalContainer>
    </ContentLoginForm>
  );
}
