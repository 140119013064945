import React from "react";
import styled from "styled-components";
import { getEnvContent } from "../../../store/utils/helpers";

const Container = styled.div`
  position: absolute;
  width: 100%;
  left: 0px;
  z-index: 9999999999999;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  h1 {
    font-style: normal;
    b {
      color: red;
    }
  }
  pre {
    font-family: Consolas, monaco, monospace;
    padding: 10px;
    border: 1px solid #000;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    font-size: 87%;
  }
`;

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error({
      message: "Error not handled catched!",
      error,
      errorInfo
    });
  }

  render() {
    if (this.state.hasError) {
      const { message, stack } = this.state.error;
      console.error({
        message: "Error not handled catched!",
        error: this.state.error
      });
      return (
        <>
          {getEnvContent() !== "production" && (
            <Container>
              <h1>
                Error: <b>{message}</b>
              </h1>
              <div className="errorStack">
                <pre>{stack}</pre>
              </div>
            </Container>
          )}
          {this.props.children}
        </>
      );
    }

    return this.props.children;
  }
}
