import React from "react";

import {
  ContentMetadata,
  Description,
  Link,
  Separator,
  Wrapper,
  PlayerContainer,
  MetadataContainerItem,
  TitleContainer
} from "./PromoteListItem";
import AtDescription from "../../AtDescription";
import AtImg from "../../AtImg/AtImg";
import { getKey } from "../../../utils/helpers";
import { PlayerWrapper } from "../PromoteWidget";
import AtVideo from "../../AtVideo";

export default function PromoteListItem({
  description,
  image,
  title,
  url,
  video,
  streaming_provider
}) {
  const img = getKey(image, "logo.path");
  const videoData = getKey(video, "trailer");
  return (
    <Wrapper>
      <Link href={url} target="_blank">
        {videoData && (
          <PlayerContainer>
            <PlayerWrapper>
              <AtVideo
                video={videoData}
                streaming_provider={streaming_provider}
              />
            </PlayerWrapper>
          </PlayerContainer>
        )}
        {!videoData && <AtImg src={img} />}
        <ContentMetadata>
          <MetadataContainerItem>
            <Separator />
            <TitleContainer>{title}</TitleContainer>
            <Description>
              <AtDescription>{description}</AtDescription>
            </Description>
          </MetadataContainerItem>
        </ContentMetadata>
      </Link>
    </Wrapper>
  );
}
