import React from "react";

import SocialButtons from "../../MolShareButton";
import { getKey } from "../../../utils/helpers";

import {
  InnerWrapper,
  TitleWidget,
  Description,
  Wrapper,
  MetatagContainer,
  ImagePodcast,
  ListenNowContainer,
  LitlePlayIcon,
  Link,
  SocialButtonContainer,
  ListenButtonContainer,
  DescriptionWrapper,
  ProductionList,
  ProductionListInnerContainer
} from "./PodcastCategoryPromoteItem";

export default function OrPodcastCategoryPromoteItem({
  contents,
  description,
  image,
  title,
  slug
}) {
  return (
    <Wrapper>
      <InnerWrapper back={image}>
        <MetatagContainer>
          <TitleWidget isWidgetTitle>{title}</TitleWidget>
          <DescriptionWrapper>
            <Description>{description}</Description>
          </DescriptionWrapper>
          <SocialButtonContainer>
            <SocialButtons
              label="COMPARTIR"
              title={title}
              text={description}
              size={22}
              sizeButtons={15}
              uri={slug}
            />
          </SocialButtonContainer>
          <ProductionList>
            <ProductionListInnerContainer>
              {contents.map(({ url, image }, index) => (
                <ListenNowContainer key={`itmPodCat${index}`}>
                  <Link href={url}>
                    <ImagePodcast src={getKey(image, "cover.path")} />
                    <ListenButtonContainer>
                      <LitlePlayIcon
                        className="play-icon"
                        name="PlayCircle"
                        size={22}
                      />
                      <span> ESCUCHAR </span>
                    </ListenButtonContainer>
                  </Link>
                </ListenNowContainer>
              ))}
            </ProductionListInnerContainer>
          </ProductionList>
        </MetatagContainer>
      </InnerWrapper>
    </Wrapper>
  );
}
