import React, { useRef } from "react";
import ReactPlayer from "react-player";
import LazyLoad from "react-lazyload";

import AtImg from "../AtImg/AtImg";
import { Container, InnerContainer, Cover } from "./MolPlayerStyles";
import { logInfo } from "../../utils/helpers";

const MolPlayerBackground = ({
  className,
  aspectRatio,
  url,
  pip,
  cover,
  coverOverflow,
  coverResposive,
  children,
  config,
  width,
  height,
  withLazyLoad,
  loop,
  playbackRate
}) => {
  const ref = useRef(null);
  const aspectRatioValues = aspectRatio.split("-");
  const ReactPlayerComponent = () => (
    <ReactPlayer
      ref={ref}
      className="react-player"
      width={width}
      height={height}
      url={url}
      pip={pip}
      playing
      loop={loop}
      playbackRate={playbackRate}
      volume={0}
      muted
      onError={e =>
        logInfo({
          source: "MolPlayerBackground",
          message: "player error:",
          e
        })
      }
      config={config}
    />
  );

  return (
    <Container
      className={className}
      ratio={(aspectRatioValues[1] / aspectRatioValues[0]) * 100}
      coverOverflow={coverOverflow}
    >
      {cover && (
        <Cover className="cover">
          <AtImg src={cover} responsive={coverResposive} />
        </Cover>
      )}
      {withLazyLoad ? (
        <LazyLoad height={height} once>
          <ReactPlayerComponent />
        </LazyLoad>
      ) : (
        <ReactPlayerComponent />
      )}
      {children && (
        <InnerContainer className="inner-container">{children}</InnerContainer>
      )}
    </Container>
  );
};
MolPlayerBackground.defaultProps = {
  aspectRatio: "16-9",
  pip: false,
  coverOverflow: false,
  coverResposive: false,
  showAudioButton: true,
  showProgressBar: true,
  showLoading: true,
  loop: true,
  onEnded: () => {},
  width: "100%",
  height: "100%",
  withLazyLoad: true,
  playbackRate: 1.0
};
export default MolPlayerBackground;
