import styled from "styled-components";
import Colors from "../../theme/Colors";

export const Description = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${Colors.white};
  line-height: 1.2;
  margin-top: 10px;
  margin-bottom: 35px;
  @media screen and (max-width: 1024px) {
    max-width: 97%;
    margin: 5px auto 20px auto;
  }
  strong,
  em {
    font-size: 18px;
    line-height: 1.2;
  }
`;
