import React from "react";
import styled from "styled-components";
import { Metrics } from "../../theme";
import { SideBarInternalPlatforms } from "./SideBarInternalPlatforms";
import { SideBarExternalPlatforms } from "./SideBarExternalPlatforms";

export const SideBar = ({ url_external_podcasts, coverPodcast }) => {
  return (
    <Container>
      <CoverPodcastContainer>
        <CoverPodcast src={coverPodcast} />
        <SideBarInternalPlatforms
          url_external_podcasts={url_external_podcasts}
        />
      </CoverPodcastContainer>
      <SideBarExternalPlatforms url_external_podcasts={url_external_podcasts} />
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: 100px;
  margin-bottom: 150px;
`;
export const CoverPodcastContainer = styled.div`
  border-radius: 8px;
  padding: 22px 17px;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 1;

  @media screen and (min-width: ${Metrics.size.tablet}) {
    display: block;
    height: fit-content;
  }
`;

export const CoverPodcast = styled.img`
  width: 100%;
  border-radius: 8px;
`;
