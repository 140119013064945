import styled from "styled-components";
import { rgba } from "polished";
import AtIcon from "../AtIcon/AtIcon";
import AtIconButton from "../AtIconButton/AtIconButton";

import { Wp, Colors, Fonts, Metrics, Animations } from "../../theme";

export const Container = styled(Wp.Container)`
  position: relative;
  padding-top: ${props => props.ratio}%;
  width: ${props => (props.coverOverflow ? "100%" : Metrics.size.maxPageWidth)};
  max-width: ${props =>
    props.coverOverflow ? "100%" : Metrics.size.maxPageWidth};
  margin: 0 auto;
  height: 0px;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${props => (props.coverOverflow ? `0px` : `100%`)};
    padding-top: ${props => (props.coverOverflow ? props.ratio : `0`)}%;

    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
  }
`;
export const InnerContainer = styled(Wp.Container)`
  position: absolute;
  width: 500px;
  bottom: 30px;
  left: calc(50% - 250px);
`;
export const PlayPauseButton = styled(AtIconButton)`
  display: none;
  position: absolute;
  opacity: 0.5;
  top: ${props =>
    props.hasInnerContent ? `calc(50% - 200px)` : `calc(50% - 100px)`};
  left: calc(50% - 32px);
  svg {
    filter: drop-shadow(0px 1px 2px ${rgba(Colors.black, 0.4)});
  }
`;
export const VolumeButton = styled(AtIconButton)`
  position: absolute;
  top: ${props =>
    props.hasInnerContent ? `calc(50% - 100px)` : `calc(50% - 40px)`};
  left: calc(50% - 40px);
  svg {
    filter: drop-shadow(0px 1px 2px ${rgba(Colors.black, 0.4)});
  }
`;
export const LoadingIcon = styled(AtIcon)`
  position: absolute;
  top: calc(50% - 70px);
  left: calc(50% - 20px);
  svg {
    filter: drop-shadow(0px 1px 2px ${rgba(Colors.black, 0.4)});
  }
  animation: ${Animations.Rotate} 1s linear infinite;
`;

export const AltText = styled(Fonts.span)`
  position: absolute;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;
  color: ${Colors.white};
  display: block;
  width: 250px;
  top: ${props =>
    props.hasInnerContent ? `calc(50% - 30px)` : `calc(50% + 40px)`};
  left: calc(50% - 125px);
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: nowrap;
`;
export const ProgressBarContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 6px;
  bottom: 0;
  left: 0;
  background-color: ${rgba(Colors.gray, 0.3)};
`;
export const ProgressBarLoaded = styled.div`
  position: absolute;
  width: ${props => props.value * 100}%;
  height: 6px;
  top: 0;
  left: 0;
  background-color: ${rgba(Colors.gray, 0.3)};
`;
export const ProgressBarPlayed = styled.div`
  position: absolute;
  width: ${props => props.value * 100}%;
  height: 6px;
  top: 0;
  left: 0;
  background-color: ${Colors.cerulean};
`;
export const Cover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
