import React, { useCallback, useState } from "react";
import styled from "styled-components";

import AddMyListButton from "../../../components/MolAddMyListButton";
import SocialButtons from "../../../components/MolShareButton";
import { setDataRecommender } from "../../../utils/helpers/recommender";
import { Wp, Colors, Metrics } from "../../../theme";
import { LongDescriptionWrapper } from "../../widgets/Opening/CommingSoon/CommingSoon";
import AtIcon from "../../../components/AtIcon/AtIcon";
import {
  CreditButton,
  CreditButtonType,
} from "../../../components/podcast/CreditButton";
import { WrapperBlur } from "../show/Podcast";
import {
  CoverPodcast,
  CoverPodcastContainer,
} from "../../../components/podcast/SideBar";
import {
  AudioPlayer,
  AudioPlayerType,
} from "../../../components/podcast/AudioPlayer";
import { SideBarExternalPlatforms } from "../../../components/podcast/SideBarExternalPlatforms";
import MolLikeButton from "../../../components/MolLikeButton";

/**
 * Podcast Opening Layout
 *
 * @param {object} props - podcast opening props
 * @returns {JSX.Element}
 */
const Audio = audio => {
  const {
    id,
    title,
    description,
    chapter_number: chapterNumber,
    duration,
    slug,
    taxonomy = [],
    production,
    infoAdditional,
    thumbnail,
  } = audio;
  const [togglePlay, setTogglePlay] = useState(false);
  setDataRecommender(id, new Date().getTime(), "CLICK", 0);
  const {
    resource: {
      image: {
        cover_podcast: { path: coverPodcast },
      },
    },
  } = production || {};
  const { value: category } =
    taxonomy.find(({ vid }) => vid === "categories") || {};
  const { value: brand } = taxonomy.find(({ vid }) => vid === "brands") || {};

  const handleTogglePlay = useCallback(() => {
    setTogglePlay(!togglePlay);
  }, [togglePlay, setTogglePlay]);

  return (
    <Wrapper background={coverPodcast}>
      <InnerWrapper>
        <Category>{category}</Category>
        <Brand>{brand}</Brand>
        {production && (
          <ProductionTitle href={production.slug}>
            {production.name}
          </ProductionTitle>
        )}
        <InfoContainer>
          <CoverPodcastContainer>
            <CoverPodcast
              src={
                thumbnail && !!thumbnail.cover_podcast
                  ? thumbnail.cover_podcast.path
                  : coverPodcast
              }
            />
          </CoverPodcastContainer>
          <MetadataContainer>
            <ChapterInfo>
              {chapterNumber && (
                <>
                  <ChapterNumber>Cap. {chapterNumber}</ChapterNumber>{" "}
                  <ChapterInfoSep></ChapterInfoSep>
                </>
              )}
              <Duration>{duration}</Duration>
            </ChapterInfo>
            <TitleContainer>
              <Title>{title}</Title>
              <TitleButtonsContainer>
                <LikeContainer>
                  <MolLikeButton
                    size={20}
                    idVideo={id}
                    label="ME GUSTA"
                    icon="Heart"
                    colorSelected={Colors.raljah}
                  />
                </LikeContainer>
                <PlayButton onClick={handleTogglePlay}>
                  <PlayIcon
                    name={togglePlay ? "PauseCircleCustom" : "PlayCircle"}
                    size={40}
                  />
                </PlayButton>
              </TitleButtonsContainer>
            </TitleContainer>
            <DescriptionWrapper
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <ButtonsContainer>
              <AddToMyListContainer>
                <AddMyListButton id={id} size={36} />
              </AddToMyListContainer>
              <SocialButtons
                label="COMPARTIR"
                title={title}
                text={description}
                size={36}
                sizeButtons={18}
                uri={slug}
              />

              <CreditButton
                size={36}
                productionTitle={production && production.title}
                duration={duration}
                audioTitle={title}
                brand={brand}
                coverPodcast={coverPodcast}
                infoAdditional={
                  infoAdditional || (production && production.infoAdditional)
                }
                type={CreditButtonType.audio}
                chapterNumber={chapterNumber}
              />
            </ButtonsContainer>
            <AudioPlayerContainer>
              <AudioPlayer
                audio={audio}
                audios={[audio]}
                isSelected={togglePlay}
                keepCurrentTime
                type={AudioPlayerType.SIMPLE}
              />
            </AudioPlayerContainer>
            {production && (
              <>
                <SeeAllEpisodesButton href={production.slug}>
                  Ver todos los capítulos
                </SeeAllEpisodesButton>
                <SideBarExternalPlatforms
                  url_external_podcasts={production.url_external_podcasts}
                />
              </>
            )}
          </MetadataContainer>
        </InfoContainer>
      </InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(WrapperBlur)`
  width: 100%;
  position: relative;
`;

const InnerWrapper = styled(Wp.Page)`
  position: relative;
  padding-top: 67px;
  padding-bottom: 30px;
  @media screen and (min-width: ${Metrics.size.tablet}) {
    padding-top: 90px;
    padding-bottom: 40px;
  }
`;
const Category = styled.p`
  padding: 0;
  font-size: 18px;
  font-family: Dosis, serif;
  color: ${Colors.white};
  line-height: 1.2;
  font-weight: 500;
  max-width: 1050px;
  @media screen and (min-width: ${Metrics.size.tablet}) {
    font-size: 28px;
  }
`;

const InfoContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 25px 0;

  @media screen and (min-width: ${Metrics.size.tablet}) {
    flex-direction: row;
    gap: 40px;
  }
`;

const MetadataContainer = styled.div`
  z-index: 1;
  position: relative;

  @media screen and (min-width: ${Metrics.size.tablet}) {
  }
`;

const Brand = styled.p`
  padding: 0;
  font-size: 22px;
  font-family: Dosis, sans-serif;
  color: ${Colors.white};
  line-height: 1.2;
  font-weight: 500;
  margin: 40px 0 10px;
`;

const ProductionTitle = styled.a`
  padding: 0;
  font-size: 32px;
  font-family: Dosis, sans-serif;
  color: ${Colors.white};
  line-height: 20px;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ChapterInfo = styled.div`
  display: flex;
  gap: 10px;
  align-items: baseline;
  margin-bottom: 30px;
`;

const ChapterInfoSep = styled.div`
  height: 20px;
  width: 1px;
  background-color: #707070;
`;

const ChapterNumber = styled.p`
  padding: 0;
  margin: 0;
  font-size: 44px;
  font-family: Dosis, sans-serif;
  color: ${Colors.white};
  line-height: 34px;
  font-weight: 400;
`;

const Duration = styled.p`
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-family: Dosis, sans-serif;
  color: ${Colors.white};
  line-height: 20px;
  font-weight: 400;
`;

const TitleContainer = styled.div`
  margin: 0 0 10px;
  padding: 0;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  width: 100%;|
`;

const Title = styled.h1`
  font-family: "BebasNeue Book", sans-serif;
  color: ${Colors.orangeBright};
  letter-spacing: 0;
  line-height: 0.8;
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-size: 62px;

  @media screen and (max-width: 767px) {
    font-size: 42px;
    min-height: 90px;
    line-height: 38px;
  }
`;

const TitleButtonsContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const LikeContainer = styled.div`
  border: 2px solid ${Colors.white};
  border-radius: 100%;
  justify-items: center;
  align-items: center;
  cursor: pointer;
  height: 37px;
  width: 37px;
  display: flex;
  place-content: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
    border-color: ${Colors.raljah};
    svg {
      fill: ${Colors.raljah};
      color: ${Colors.raljah};
    }
  }
  &:active {
    transform: scale(0.9);
  }
`;

const PlayButton = styled.div`
  display: flex;
  height: 40px;
  width: 40px;
`;

const PlayIcon = styled(AtIcon)`
  background-color: ${Colors.raljah};
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
`;

const DescriptionWrapper = styled(LongDescriptionWrapper)`
  &&& {
    font-size: 22px;
  }
  & * {
    font-size: 22px;
  }
  p {
    margin: 0;
  }
  line-height: 22px;
  margin: 10px 0 20px !important;

  a {
    color: ${Colors.white};
    font-size: 22px;
    font-weight: 500;
    line-height: 1;
  }

  @media screen and (max-width: 767px) {
    display: block;
    max-width: 100%;
  }
`;

const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  gap: 20px;
  padding: 0 0 10px;
  button {
    margin: 0;
    padding: 0;
  }

  margin-bottom: 60px;

  @media screen and (min-width: ${Metrics.size.tablet}) {
    padding: 0;
    margin-bottom: inherit;
  }

  @media screen and (max-width: 767px) {
    justify-content: center;
    a {
      flex-direction: column;
      align-items: center;
      span {
        font-weight: normal;
        margin: 5px 0 0;
        font-size: 14px;
      }
    }
  }
`;

const AddToMyListContainer = styled.div`
  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }
`;

const AudioPlayerContainer = styled.div`
  margin: 20px 0;
  @media screen and (min-width: ${Metrics.size.tablet}) {
    min-width: 737px;
  }
`;

const SeeAllEpisodesButton = styled.a`
  font-size: 24px;
  font-family: Dosis, sans-serif;
  color: ${Colors.white};
  line-height: 20px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid ${Colors.dimGray};
  padding: 10px 21px 15px;
  border-radius: 13px;
  width: fit-content;
  margin-bottom: 12px;
  &:hover {
    border-color: ${Colors.white};
  }
`;

export default Audio;
