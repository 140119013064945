import React, { Component } from "react";

import OrVerticalList from "../../../components/OrVerticalList/OrVerticalList";

class VerticalList extends Component {
  render() {
    return <OrVerticalList {...this.props} />;
  }
}

export default VerticalList;
