import React, { Component } from "react";
import MolProdInfoTrailer from "../../../components/MolProdInfoTrailer/MolProdInfoTrailer";

export default class ProdInfoTrailer extends Component {
  render() {
    const {
      content: { micro_synopsis, slug, image, trailer, title },
      frase,
      label,
      streaming_provider
    } = this.props;
    return (
      <MolProdInfoTrailer
        seccion={this.props.title}
        frase={frase}
        etiqueta={label}
        titulo={title}
        descripcion={micro_synopsis}
        path={slug}
        trailer={trailer}
        imageMobile={image.cover_mobile.path}
        imageDesktop={image.cover_desktop.path}
        reproductor={streaming_provider}
      />
    );
  }
}
