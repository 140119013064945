/**
 * Represents video content type
 * @type {string}
 */
export const VIDEO = "video";

/**
 * Represents audio content type
 * @type {string}
 */
export const AUDIO = "audio";

/**
 * Represents livestream content type
 * @type {string}
 */
export const LIVESTREAM = "livestream";

/**
 * Represents livestream tv content type
 * @type {string}
 */
export const LIVESTREAMTV = "livestream-tv";

/**
 * Represents livestream radio content type
 * @type {string}
 */
export const LIVESTREAMRADIO = "livestream-radio";

/**
 * Represents livestream event content type
 * @type {string}
 */
export const LIVESTREAMEVENT = "livestream-event";

/**
 * Represents section content type
 * @type {string}
 */
export const SECTION = "section";

/**
 * Represents section content type
 * @type {string}
 */
export const SHOW = "show";

/**
 * Represents section content type
 * @type {string}
 */
export const SHOWVIDEO = "production-video";

/**
 * Represents section content type
 * @type {string}
 */
export const SHOWAUDIO = "production-audio";

/**
 * Represents section content type
 * @type {string}
 */
export const SHOWKIDS = "production-kids";

/**
 * Represents comming soon show type.
 * @type {string}
 */
export const SHOW_COMMING_SOON = "production-comming-soon";

/**
 * Represents section content type
 * @type {string}
 */
export const SHOWLEARN = "production-learn";
/**
 * Represents basic page content type
 * @type {string}
 */
export const BASICPAGE = "page";

/**
 * Represents section content type
 * @type {string}
 */
export const GATEWAY = "gateway";

/**
 * Represents quienes somos page
 * @type {string}
 */
export const QUIENESSOMOS = "quienesSomos";

/**
 * Represents buscar page
 * @type {string}
 */
export const SEARCH = "buscar";

/**
 * Represents dashboard page
 * @type {string}
 */
export const DASHBOARD = "dashboard";

/**
 * Represents login page
 * @type {string}
 */
export const LOGIN = "login";

/**
 * Represents logout page
 * @type {string}
 */
export const LOGOUT = "logout";

/**
 * Represents notfound page
 * @type {string}
 */
export const NOTFOUND = "notfound";

/**
 * Represents category page
 * @type {string}
 */
export const CATEGORY = "category";

/**
 * Represents geo blocked page
 * @type {string}
 */
export const GEO_BLOCKED = "geoBlocked";
