import React, { Component } from "react";
import styled from "styled-components";

import { Colors, Fonts, Wp } from "../../theme";

const Container = styled(Wp.Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${props => props.size}px;
  width: initial;
`;

const RatingPart1 = styled(Fonts.rating)`
  display: block;
  background-color: ${props =>
    props.showBluCircle ? Colors.rating : "transparent"};
  height: 1.9375em;
  width: 1.9375em;
  line-height: 1.9375em;
  font-size: 1em;
  border-radius: 50%;
  text-align: center;
`;
const RatingPart2 = styled(Fonts.rating)`
  margin-left: 0.375em;
  font-size: 1em;
`;

class AtRating extends Component {
  static defaultProps = {
    data: ["+13", "Adolescente"],
    size: 16
  };
  render() {
    const { data, className, size } = this.props;
    return (
      <Container className={className} size={size}>
        <RatingPart1 showBluCircle={data[1]}>{data[0]}</RatingPart1>
        <RatingPart2>{data[1]}</RatingPart2>
      </Container>
    );
  }
}

export default AtRating;
