import AtIcon from "../AtIcon/AtIcon";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Button } from "reactstrap";
import { Colors, Fonts } from "../../theme";
import Modal from "react-awesome-modal";

export const CreditButtonType = {
  production: "production",
  audio: "audio",
};

export const CreditButton = ({
  size,
  label,
  coverPodcast,
  brand,
  productionTitle,
  infoAdditional,
  type = CreditButtonType.production,
  audioTitle,
  duration,
  chapterNumber,
  hidden = false,
  open = null,
}) => {
  const [isOpen, setOpen] = useState(false);
  const hasCredits = infoAdditional && infoAdditional.length > 0;

  const closeModal = () => {
    setOpen(false);
  };
  const openModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (open !== null) {
      setOpen(true);
    }
  }, [open]);

  return !hasCredits && !hidden ? null : (
    <>
      {!hidden && (
        <CreditButtonContainer onClick={openModal} type="button">
          <AtIcon name="MyListCustom" size={size || 20} color={Colors.white} />{" "}
          <Label>{label || "CRÉDITOS"}</Label>
        </CreditButtonContainer>
      )}
      <ModalContainer keeponDOM={isOpen}>
        <Modal
          visible={isOpen}
          width="921"
          height="476"
          effect="fadeInUp"
          onClickAway={closeModal}
        >
          <div>
            <ModalTitleContainer>
              <ModalTitle>CRÉDITOS</ModalTitle>
              <ModalCloseButton onClick={closeModal}>
                <AtIcon name={"Close"} size={19} color={Colors.darkGray} />
              </ModalCloseButton>
            </ModalTitleContainer>
            <ModalContentContainer>
              {coverPodcast && (
                <ModalContentImageContainer>
                  <img src={coverPodcast} alt={productionTitle || audioTitle} />
                </ModalContentImageContainer>
              )}
              <ModalContentInfo>
                {brand && type === CreditButtonType.production && (
                  <ModalContentBrand>{brand}</ModalContentBrand>
                )}
                <ModalContentTitle type={type}>
                  {productionTitle}
                </ModalContentTitle>
                {audioTitle && type === CreditButtonType.audio && (
                  <AudioTitle>
                    {chapterNumber && <>Cap. {chapterNumber} |</>} {audioTitle}
                  </AudioTitle>
                )}
                {duration && type === CreditButtonType.audio && (
                  <ModalContentDuration>{duration}</ModalContentDuration>
                )}
              </ModalContentInfo>
            </ModalContentContainer>
            {hasCredits && <ModalContentAdditionalInfo>
              {infoAdditional.map(({ value }, index) => (
                  <div
                      key={index}
                      dangerouslySetInnerHTML={{ __html: value }}
                  ></div>
              ))}
            </ModalContentAdditionalInfo>}
          </div>
        </Modal>
      </ModalContainer>
    </>
  );
};

const CreditButtonContainer = styled(Button)`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: transparent;
  border: 0;
  gap: 15px;
  align-items: center;
  svg {
    display: inline-flex;
  }
  span {
    display: inline-flex;
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    gap: 5px;
  }
`;

const Label = styled(Fonts.shareButton)`
  align-self: center;
  color: ${Colors.white};
`;

const ModalContainer = styled.div`
  ${props =>
    !props.keeponDOM &&
    css`
      display: none;
    `} & > div > div > div:first-child {
    background: transparent linear-gradient(223deg, #575757 0%, #2d2d2d 100%) 0%
      0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 8px;
    color: ${Colors.white} !important;
    padding: 30px;
    z-index: 999999;
  }
`;

const ModalTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Colors.dimGray};
  padding-bottom: 5px;
  margin-bottom: 20px;
`;

const ModalTitle = styled.h1`
  font-family: "BebasNeue Book", sans-serif;
  color: ${Colors.white};
  line-height: 52px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  font-size: 40px;
`;

const ModalCloseButton = styled.a`
  text-decoration: none;
  border: 2px solid ${Colors.darkGray};
  border-radius: 50%;
  padding: 8px;
  display: flex;
  cursor: pointer;
`;

const ModalContentContainer = styled.div`
  display: flex;
  gap: 20px;
  
  margin-bottom: 10px;
`;

const ModalContentInfo = styled.div`
  margin: 0;
  padding: 0;
  
  @media screen and (min-width: 768px) {
    margin: 0 0 10px;
  }
`;

const ModalContentBrand = styled.p`
  padding: 0;
  font-size: 22px;
  font-family: Dosis, sans-serif;
  color: ${Colors.white};
  line-height: 1.2;
  font-weight: 500;
  margin: 0 0 5px;
  
  @media screen and (min-width: 768px) {
    margin: 0 0 20px;
  }
`;

const ModalContentTitle = styled.h1`
  font-family: "BebasNeue Book", sans-serif;
  color: ${Colors.orangeBright};
  letter-spacing: 0;
  line-height: 0.8;
  font-weight: 400;
  padding: 0;
  font-size: 24px;
  margin: 0;

  ${props =>
    props.type === CreditButtonType.audio &&
    css`
      font-size: 30px;
      line-height: inherit;
    `}

  @media screen and (min-width: 768px) {
    font-size: 40px;
    line-height: 38px;
  }
`;

const ModalContentDuration = styled.div`
  font-family: Dosis, sans-serif;
  color: ${Colors.white};
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  margin: 10px 0;
  padding: 0;
  display: none;
  
  @media screen and (min-width: 768px) {  
    display: block;
  }
`;

const ModalContentAdditionalInfo = styled.div`
  font-family: Dosis, sans-serif;
  color: ${Colors.white};
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  max-height: 150px;
  overflow: hidden;
  overflow-y: auto;
  
  & p:first-child {
    margin-top: 0;
  }

  @media screen and (min-width: 768px) {
    max-height: 210px;
  }
`;

const ModalContentImageContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 106px;
  max-height: 106px;
  img {
    width: 100%;
    height: 100%;
  }
`;

const AudioTitle = styled.p`
  padding: 0;
  margin: 0;
  font-size: 32px;
  font-family: Dosis, sans-serif;
  color: ${Colors.white};
  font-weight: 400;
`;
