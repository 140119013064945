import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import AtIcon from "../../../components/AtIcon/AtIcon";
import AtImg from "../../../components/AtImg/AtImg";
import TplTitleBlock from "../../../components/TplTitleBlock/TplTitleBlock";

import "./OutStandingTwoCover.scss";

import { getUniqKey, getKey } from "../../../utils/helpers";

const TitleBlock = styled(TplTitleBlock)`
  padding-top: ${props => (props.showTitle ? 0.75 : 0)}em;
  .block-body {
    padding-top: 1em;
  }
  ${props => props.customStyles || ""};
`;

class OutStandingTwoCoverWithAnimatedTitle extends Component {
  state = { animateTitleBlock: false };
  static defaultProps = {
    showTitle: false,
    title: ""
  };
  _scroll = ev => {
    if (this.refs.titleBlock && this.refs.titleBlock.refs.blockTitle) {
      let blockTitleElTop = this.refs.titleBlock.refs.blockTitle.getBoundingClientRect()
        .top;
      if (
        blockTitleElTop < window.innerHeight - 250 &&
        !this.state.animateTitleBlock
      ) {
        this.setState({ animateTitleBlock: true });
      }
    }
  };
  componentDidMount() {
    document.addEventListener("scroll", this._scroll);
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this._scroll);
  }
  render() {
    return (
      <TitleBlock
        ref="titleBlock"
        {...this.props}
        animateTitle={this.state.animateTitleBlock}
      />
    );
  }
}

class OutStandingTwoCover extends Component {
  render() {
    const { contents, styles, title, showTitle, production } = this.props;
    return (
      <OutStandingTwoCoverWithAnimatedTitle
        title={title}
        customStyles={styles}
        showTitle={title !== "" && showTitle}
      >
        <div className="contentOutStanding2Cover">
          <ul>
            {production && (
              <li>
                <a href={production.slug}>
                  <AtImg
                    alt={production.title || ""}
                    src={getKey(
                      production,
                      "image.poster_large.path",
                      getKey(production, "image.cover.path")
                    )}
                    withLazyLoad={true}
                  />
                  <div className="hoverMain">
                    <h1>{production.title}</h1>
                    <AtIcon name="PlayCircle" size={95} />
                  </div>
                </a>
              </li>
            )}
            {contents.map((content, idx) => {
              return (
                <li key={getUniqKey(idx)}>
                  <a href={content.slug}>
                    <AtImg
                      alt={content.title || ""}
                      src={getKey(
                        content,
                        "image.poster_large.path",
                        getKey(content, "image.cover.path")
                      )}
                      withLazyLoad={true}
                    />
                    <div className="metaData">
                      <ul>
                        <li>
                          <h2>{content.title}</h2>
                        </li>
                        <li>
                          <AtIcon name="PlayCircle" size={25} />
                        </li>
                      </ul>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </OutStandingTwoCoverWithAnimatedTitle>
    );
  }
}
OutStandingTwoCover.propTypes = {
  id: PropTypes.any,
  type: PropTypes.string
};
export default OutStandingTwoCover;
