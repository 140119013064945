import React, { useCallback, useRef } from "react";
import { Arrow, LabelSelected, Option, Select, Wrapper } from "./DropDown";
import { isValidArray } from "../../../utils/helpers";

export default function DropDown({ list, onChange }) {
  const dropDownRef = useRef();
  const labelRef = useRef();
  const clickHandler = useCallback(_ => {
    dropDownRef.current.classList.toggle("active");
  });
  const optionClickHandler = useCallback(e => {
    labelRef.current.innerHTML = e.target.textContent;
    dropDownRef.current.classList.toggle("active");

    if (!!onChange && typeof onChange === "function") {
      onChange(e);
    }
  });
  return (
    <Wrapper>
      <LabelSelected ref={labelRef} onClick={clickHandler}>
        {isValidArray(list) ? list[0].title : ""}
      </LabelSelected>
      <Select ref={dropDownRef} className="custom-select">
        {list.map((option, indx) => (
          <Option key={indx} onClick={optionClickHandler} value={option.value}>
            {option.title}
          </Option>
        ))}
      </Select>
      <Arrow
        className="play-icon"
        color="#8E8E8E"
        name="IconArrowDropDown"
        size={29}
      />
    </Wrapper>
  );
}
